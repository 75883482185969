import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { InitialContractTemplateEntity } from "../../domain/entities";


export class InitialContractTemplateClient extends ModelClient<InitialContractTemplateEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'InitialContractTemplate')
  }

  async getOriginal(templateId: InitialContractTemplateEntity['templateId']) {
    const url = new URL(this.modelUrl + "/getOriginal")
    url.searchParams.append("templateId", String(templateId))

    const response = await this.apiClient.get<{}>(url.href, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }
}