import { ContractEntity, ContractAIEntity, DocumentEntity, FormEntity, ProjectEntity } from "./entities";

export enum ProjectTemplateRequirementType {
	DOCUMENT = 1,
	CONTRACT = 2,
	CONTRACTAI = 4,
	FORM = 8,
	// TABLE = 8,
}

// Function to convert list of enum values to a number
export function enumListToNumber(enumList: ProjectTemplateRequirementType[]): number {
	return enumList.reduce((acc, val) => acc | val, 0);
}

// Function to convert number to a list of enum values
export function numberToEnumList(value: number): ProjectTemplateRequirementType[] {
	const enumList: ProjectTemplateRequirementType[] = [];
	for (const enumValue in ProjectTemplateRequirementType) {
		const enumNumericValue = parseInt(enumValue);
		if (!isNaN(enumNumericValue) && (value & enumNumericValue) === enumNumericValue) {
			enumList.push(enumNumericValue as ProjectTemplateRequirementType);
		}
	}
	return enumList;
}

export type ProjectRequirementValue = {
	type: ProjectTemplateRequirementType;
	id: number;
}

export type LinkedRessources = {
	DOCUMENT: DocumentEntity[];
	CONTRACT: ContractEntity[];
	CONTRACTAI: ContractAIEntity[];
	FORM: FormEntity[];
}

// Function to convert ProjectTemplateRequirementType to string
export function enumToString(value: ProjectTemplateRequirementType):
	'DOCUMENT' |
	'CONTRACT' |
	'CONTRACTAI' |
	'FORM' {
	return ProjectTemplateRequirementType[value] as any;
}

// Function to convert string to ProjectTemplateRequirementType
export function stringToEnum(value: string): ProjectTemplateRequirementType {
	const key = (<any>ProjectTemplateRequirementType)[value.toUpperCase()];
	return key;
}
export interface ValidationResult {
  isValid: boolean;
  errors: {
    name?: {
      isValid: boolean;
    };
    templateId?: {
      isValid: boolean;
    };
  };
}

export function validateProjectCreation(data: ProjectEntity): ValidationResult {
  const { name, templateId } = data;
  const errors: ValidationResult['errors'] = {};
  if (typeof name !== 'string' || name==="") {
    errors.name = {
      isValid: false,
    };
  } else {
    errors.name = {
      isValid: true,
    };
  }
  if (!templateId) {
    errors.templateId = {
      isValid: false,
    };
  } else {
    errors.templateId = {
      isValid: true,
    };
  }
  const isValid = Object.values(errors).every((field) => field.isValid);
  return {
    isValid,
    errors,
  };
}