import React, {useState, useMemo} from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import "./groupProjects.scss"
import ProjectItemTable from "../../components/common/newDesign/ProjectCard/ProjectItemTable";
import AdvancedTable from "../../components/AdvancedTable";
import project from "../../assets/new-design-icons/Projects.svg";
import { formatDate } from "../../helpers/helper";
import { FiMoreHorizontal } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
import ConfirmDeleteMessage from "../../components/modals/ConfirmDeteleMessage";
import { getName } from "../../helpers/Translation";

function GroupProjectsTableItem({ projects, groups, reloadProjects }) {
  const { t, language } = useTranslation();
  const navigate = useNavigate();
  const [confirmDeleteProjectId, setConfirmDeleteProjectId] = useState(null);
  const [openFormPopup, setOpenFormPopup] = useState<number | null>(null);
  const [projectListChanged, setProjectListChanged] = useState(false);
  const togglePopup = (e, id) => {
    e.stopPropagation();
    setOpenFormPopup(openFormPopup == id ? null : id);
  };
  const projectIcon = project.toString();
  const sortedProjects = React.useMemo(() => projects.map(c=>({...c, type: "Project"})).sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()), [projects]);
  const tableItems = useMemo(() => {
    const tableItems = [];
      groups.map((group) => {
      const groupProjects = sortedProjects.filter((project) => project.assignedToGroupId === group.id);
      groupProjects.map((project) => {
        tableItems.push({
          ...project,
          name: project.name,
          group: group.name,
          type: [project?.level1, project?.level2, project?.level3].filter(Boolean).map(level => getName(level, language)).join("/"),
          status: project.status ? t("status." + project.status) : "",
          createdAt: project.createdAt,
          updatedAt: project.updatedAt,
          onDelete: () => {
            setConfirmDeleteProjectId(project.id); 
          },
          onEdit: () => {
            navigate(`/edit-project/${project?.id}`);
          },
          actions: (<>
             {confirmDeleteProjectId === project.id && (
                <ConfirmDeleteMessage
                  onClose={() => setConfirmDeleteProjectId(null)} 
                  messageDelete={t("pages.viewProject.deleteMessage")}
                  project={project}
                  setNewChange={setProjectListChanged}
                />
              )}
            <div className="actions">
              <button className="plus-icon" onClick={(e) => togglePopup(e, project.id)}>
                <FiMoreHorizontal size={20} />
              </button>
              {openFormPopup == project.id && (
                <div className="">
                  <ContractCardActionsModal
                    onView={() => navigate(`/projet/${project?.id}`)}
                    onDelete={() => setConfirmDeleteProjectId(project.id)}
                    onEdit={() => navigate(`/edit-project/${project?.id}`)}
                  />
                </div>
              )}
            </div>
          </>
          )
        });
      }
      );
    })
    return tableItems;
  } , [groups, sortedProjects, confirmDeleteProjectId, openFormPopup]);


  return (
    <section id="projects-list" className="group-projects-table">
                  <>
              <AdvancedTable
                columns={[
                  {
                    display: t("pages.projects.tableHeaders.name"),
                    accessorKey: "name",
                    render: (row) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <img
                            src={projectIcon}
                            alt="icon"
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div style={{ marginLeft: "20px", flex: "1", textOverflow: "ellipsis" }}>{row.name}</div>
                        </div>
                      )
                    },
                    width: 3
                  },
                  {
                    display: t("pages.groupContracts.tableHeaders.group"),
                    accessorKey: "group",
                    width: 2
                  },
                  {
                    display: t("pages.projects.tableHeaders.type"),
                    accessorKey: "type",
                    width: 2
                  },
                  {
                    display: t("pages.projects.tableHeaders.status"),
                    accessorKey: "status",
                    width: 2,
                    render: (row) => {
                      return row.status ? (
                        <div className="status">{row.status}</div>
                      ) : (
                        ""
                      );
                    }
                  },
                  {
                    display: t("pages.projects.tableHeaders.createdAt"),
                    accessorKey: "createdAt",
                    render: (row) => {
                      if (row.createdAt) {
                        return formatDate(row.createdAt);
                      }
                    },
                    width: 2,
                    isDate: true
                  },
                  {
                    display: t("pages.projects.tableHeaders.updatedAt"),
                    accessorKey: "updatedAt",
                    render: (row) => {
                      if (row.updatedAt) {
                        return formatDate(row.updatedAt);
                      }
                    },
                    width: 2,
                    isDate: true
                  },
                  {
                    display: t("pages.projects.tableHeaders.actions"),
                    accessorKey: "actions",
                    sortable: false,
                    filtrable: false,
                    width: 2
                  }
                ]}
                originalData={tableItems}
              />
            </>
    </section>
  );
}

export default GroupProjectsTableItem;
