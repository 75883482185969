import React, { useEffect, useState } from "react";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";
import { intersectionByKey } from "../../../utils/array";
import CustomCombobox from "../../common/CustomCombobox";
import { getUserGroups } from "../../../services/api/UserAuth";
import { ProjectEntity, GroupEntity, UserEntity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { GroupClient } from "../../../services/api/GroupClient";
import { UserClient } from "../../../services/api/UserClient";
import { getName } from "../../../helpers/Translation";
import { ProjectClient } from "../../../services/api/ProjectClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function ValidateProjectModal({ project, onClose }: {
  project: ProjectEntity;
  onClose: () => void;

}) {

  const { t, language } = useTranslation();
  const navigate = useNavigate()
  const {
    template,
    validationGroups,
  } = project

  const [validationData, setValidationData] = useState({
    validateGroupId: project?.assignedToGroupId,
    assignToGroupId: undefined,
    assignToUserId: undefined,
  });
  const [invalidData, setInvalidData] = useState(false);


  const [userGroups, setUserGroups] = useState<GroupEntity[]>([]);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const validateGroupOptions: GroupEntity[] = intersectionByKey(
    template.groups,
    userGroups,
    "id"
  );
  const [assignToUserOptions, setAssignToUserOptions] = useState<UserEntity[]>([]);

  const currentValidateGroupOption = validateGroupOptions.find(
    (o) => o.id == validationData.validateGroupId
  );

  const currentAssignToGroupOption = template.groups.find(
    (o) => o.id == validationData.assignToGroupId
  );
  const currentAssignToUserOption = assignToUserOptions.find(
    (o) => o.id == validationData.assignToUserId
  );



  const [loading, setLoading] = useState(false);
  const apiClient = useApiClientWithLoading(setLoading);
  useEffect(() => {
    getUserGroups(apiClient).then(({ rows }) => {
      setUserGroups(rows);
      if (validationData.validateGroupId && !rows.find(g => g.id == validationData.validateGroupId)) {
        setValidationData((prev) => {
          return {
            ...prev,
            validateGroupId: null,
          }
        })
      }
    });
  }, []);

  const groupClient = new GroupClient(apiClient)
  const userClient = new UserClient(apiClient)

  useEffect(() => {
    setLoading(true);
    setAssignToUserOptions([]);
    if (validationData.assignToGroupId) {
      groupClient.getUsers(validationData.assignToGroupId).then(
        ({ rows }) => {
          setAssignToUserOptions(rows);
          if (rows.find((row) => row.id == project?.assignedToUserId))
            setValidationData({
              ...validationData,
              assignToUserId: project?.assignedToUserId,
            });
          else
            setValidationData({
              ...validationData,
              assignToUserId: null,
            });
          setLoading(false);
        }
      );
    } else
      userClient.getAll("pure").then(({ rows }) => {
        setAssignToUserOptions(rows);
        setLoading(false);
      });
  }, [validationData.assignToGroupId]);

  const handleInputChange = (field) => (value) => {
    setValidationData({
      ...validationData,
      [field]: value,
    });
  };


  const submitValidationData = async () => {
    try {
      const projectClient = new ProjectClient(apiClient)
      if (validationData.assignToGroupId && validationData.assignToUserId && validationData.assignToGroupId) {

        setLoading(true);
        await projectClient.validate(project.id, validationData);
        setLoading(false);
        toast.success(t("modals.validateProject.messages.success"));

        navigate("/projets");
        onClose();
      }
      else {
        setInvalidData(true)
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("modals.validateProject.messages.error"));
      console.error(error);
      onClose();
    }
  };
  return (
    <>
      <div
        className="modal-backdrop"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content" style={{ width: "960px" }}>
              <div className="modal-header">
                <h5 className="modal-title">{t("modals.validateProject.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body" style={{ paddingBottom: "5%" }}>
                  <div className="project-group validate">
                    <div className="project-floating mb-3">
                      <CustomCombobox
                        isValid={(invalidData && !validationData.assignToGroupId) ? false : true}
                        label={t("modals.validateProject.destination")}
                        onDropdownOpen={() => { }}
                        options={template.groups}
                        value={currentAssignToGroupOption}
                        onChange={handleInputChange("assignToGroupId")}
                        optionValue={(option) => option?.id || null}
                        optionDisplay={(option) => getName(option, language)}
                        isOpen={openCombobox === "destination"}
                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "destination" : null)}
                      />
                    </div>
                    <div className="project-floating mb-3">
                      <CustomCombobox
                        canDeselect
                        isValid={(invalidData && !validationData.validateGroupId) ? false : true}
                        label={t("modals.validateProject.source")}
                        onDropdownOpen={() => { }}
                        options={validateGroupOptions}
                        value={currentValidateGroupOption}
                        onChange={handleInputChange("validateGroupId")}
                        optionValue={(option) => option?.id || null}
                        optionDisplay={(option) => getName(option, language)}
                        optionIsEnabled={(option) =>
                          !validationGroups.find(vg => vg.id == option.id)
                        }
                        isOpen={openCombobox === "source"}
                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "source" : null)}
                      />
                    </div>
                    <div className="project-floating mb-3">
                      <CustomCombobox
                        isValid={(invalidData && !validationData.assignToUserId) ? false : true}
                        label={t("modals.validateProject.assignee")}
                        onDropdownOpen={() => { }}
                        options={assignToUserOptions}
                        value={currentAssignToUserOption}
                        onChange={handleInputChange("assignToUserId")}
                        optionValue={(option) => option?.id || null}
                        optionDisplay={(option: UserEntity) => option?.fullName}
                        isOpen={openCombobox === "assignee"}
                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "assignee" : null)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary submit-validate-contract-btn"
                  onClick={submitValidationData}
                >
                  {t("modals.validateProject.validate")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ValidateProjectModal;
