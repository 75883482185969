import React, { createContext, useState, ReactNode, useContext, Dispatch, SetStateAction } from "react";

type LoadingContextType = {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const LoadingContext = createContext<LoadingContextType>(null);


const LoadingProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}
export default LoadingProvider
