import React, { useState } from 'react'
import './generateAIportal.scss'
import { GenerateClauseParamType } from '../../../domain/types/Clause'
import { useTranslation } from '../../../contexts/TranslationProvider'

function GenerateAIPortal({reset, generate, close, initialClause}: {reset: () => any, generate: (promptTypes: any, comments: string) => any, close: () => any, initialClause: any}) {

  const [promptIDs, setPromptIDs] = useState([])
  const [errors, setErrors] = useState([])
  const [comments, setComments] = useState("")
  const { t } = useTranslation()
  const translationPath = "pages.contractTemplateParam.generateAIportal"
  const handleGenerate = () => {
    if(promptIDs.length === 0) {
      setErrors([t(translationPath + ".error")])
      return
    }
    setErrors([])
    let promptTypes: GenerateClauseParamType[] = []
    promptIDs.forEach((promptID) => {
      switch(promptID) {
        case 0:
          promptTypes.push('BOOLEAN')
          break
        case 1:
          promptTypes.push('BENEFICIAL')
          break
        case 2:
          promptTypes.push('TABLE')
          break
        case 3:
          promptTypes.push('PRIMITIVE')
          break
      }
    })
    generate(promptTypes, comments)
    close()
  }

  const prompts = [
    {
      display: "Enum and Boolean",
      name: "EnumAndBoolean",
      id: 0
    },
    {
      display: "Beneficial",
      name: "Beneficial",
      id: 1
    },
    {
      display: "Table",
      name: "Table",
      id: 2
    }, 
    {
      display: "Primitive",
      name: "Primitive",
      id: 3
    }
  ]
  const handleChange = (e: any, id: number) => {
    setErrors([])
    if(e.target.checked) {
      setPromptIDs([...promptIDs, id].sort())
    } else {
      setPromptIDs(promptIDs.filter((promptID) => promptID !== id).sort())
    }
  }

  const handleCommentChange = (e: any) => {
    setComments(e.target.value)
  }

  const handleReset = () => {
    setPromptIDs([])
    setComments("")
    reset()
    close()
  }

  return (
    <div>
      <div className="generate-ai-portal">
        <div className="generate-ai-portal-header">
        <span className='close-dialog' onClick={()=> close()}>X</span>
        </div>
        <div className="generate-ai-portal-content">
          {prompts.map((prompt, index) => {
            return (
              <div key={index} className="generate-ai-portal-content-checkbox">
                <input type="checkbox" id={prompt.name} name={prompt.name} onChange={(e)=>handleChange(e, prompt.id)} />
                <label className='generate-ai-portal-label' htmlFor={prompt.name}>{prompt.display}</label>
              </div>
            )
          })}
          {errors.length > 0 && <div className="generate-ai-portal-errors">{errors.map((error, index) => <div key={index}>{error}</div>)}</div>}
          <div className='generate-ai-portal-add-comments'>
            <label className='generate-ai-portal-add-label'>Recommendations</label>
            <textarea  maxLength={200} className='generate-ai-portal-add-input' placeholder={"Type comments..."} onChange={handleCommentChange} />
          </div>
        </div>
        <div className='generate-ai-portal-footer'>
          <button className='generate-ai-portal-button' onClick={()=> handleGenerate()}>
            {t(translationPath + ".generate")}
          </button>
          <button disabled={!initialClause} className={`reset-ai-portal-button ${!initialClause && "disabled"}`} onClick={handleReset}>
            {t(translationPath + ".reset")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default GenerateAIPortal