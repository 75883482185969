import React from 'react'
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../services/api/UserAuth';
import { Link } from 'react-router-dom';
import "./signin.scss"
import { useTranslation } from '../../contexts/TranslationProvider';
import CarouselContainer from '../../components/common/newDesign/Carousel';
import InputField from '../../components/common/newDesign/InputField';
import FlagCombobox from '../../components/common/CustomCombobox/FlagCombobox';
import { set } from 'react-hook-form';

const SignIn = () => {
    const { t, language } = useTranslation()
    const translationPath = "pages.signIn.newDesign."
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState<Record<string, string[]>>({
        email: [],
        signIn: [],
    });

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ ...formData, email: value });
        setErrors({ ...errors, signIn: [] });
        if (!validateEmail(value)) {
            setErrors({ ...errors, email: [t(translationPath + "inputs.email.error")] });
        } else {
            setErrors({ ...errors, email: [] });
            return;
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ ...formData, password: value });
        setErrors({ ...errors, signIn: [] });
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            setLoading(true);
            const { token, user } = await signIn(formData.email, formData.password);
            setLoading(false);
            setErrors({ ...errors, signIn: [] });
            navigate("/")
        } catch (error) {
            setLoading(false);
            console.log(error);
            if (error.response?.status === 401) {
                setErrors({ ...errors, signIn: [t(translationPath + "error1")] });
            } else {
                setErrors({ ...errors, signIn: [t(translationPath + "error2")] });
            }
            
        }
    };


    return (
        <div className='sign-in-content'>
            <CarouselContainer />
            <div className='sign-in-sub-content'>
                <div className='header'>
                    <div className='logo-back'></div>
                    <FlagCombobox
                        optionValue={(option) => option?.language || null}
                        optionDisplay={(option) => option?.flag || ""}
                    />
                </div>
                <div className='form-container'>
                    <div className='form-section'>
                        <h1 className='greeting'>
                            {t(translationPath + "title")}
                        </h1>
                        <div className='divider-container'>
                            <div className='divider'></div>
                            <p className='content'>
                                {t(translationPath + "description")}
                            </p>
                            <div className='divider'></div>
                        </div>
                        <div className='signin-form'>
                            <InputField
                                value={formData.email}
                                onChange={handleEmailChange}
                                placeholder={
                                    t(translationPath + "inputs.email.placeholder")
                                }
                                type='email'
                                label={
                                    t(translationPath + "inputs.email.label")
                                }
                                name={
                                    t(translationPath + "inputs.email.name")
                                }
                                error={errors.email}
                                required
                            />

                            <InputField
                                value={formData.password}
                                onChange={handlePasswordChange}
                                placeholder={"Password"}
                                type='password'
                                label={"Password"}
                                name='password'
                                required
                            />
                            <button
                                disabled={errors.email.length !== 0 || loading}
                                className={`create-account ${errors.email.length !== 0 && !loading && "disabled"}`}
                                onClick={handleSubmit}
                            >
                                {t(translationPath + "signIn")}
                            </button>
                            {
                                errors.signIn.length !== 0 && (
                                    <div style={{ color: "red" }} className='error-container'>
                                        {errors.signIn.map((error, index) => (
                                            <>{error}</>
                                        ))}
                                    </div>
                                )
                            }
                            <p className='signin-suggestion'>
                                <span>
                                    <Link className='link' to='/forget-password'>
                                        {t(translationPath + "forgotPassword")}
                                    </Link>
                                </span>
                                <span>
                                    {t(translationPath + "noAccount")}
                                    &nbsp;
                                    <Link className='link' to='/sign-up'>
                                        {t(translationPath + "signUp")}
                                    </Link>
                                </span>

                            </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default SignIn