import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditProjectTemplateContext from "../../contexts/ProjectTemplateContext";
import RessourcesProject from "../CreateProjectTemplate/components/RessourcesProject";
import OptionsRessourceProject from "../CreateProjectTemplate/components/OptionsRessourceProject";
import { FiEdit2 } from "react-icons/fi";
import { ar, fr, enUS } from "date-fns/locale";
import project from "../../assets/new-design-icons/Projects.svg";
import { formatDistanceToNow } from "date-fns";
import { useTranslation } from "../../contexts/TranslationProvider";
import ProjectTemplateGroups from "../ContractTemplate/components/ProjectTemplateGroups";
import form from "../../assets/new-design-icons/Form.svg"
import contract from "../../assets/new-design-icons/Contract.svg"
import CustomCombobox from "../../components/common/CustomCombobox";
import { ProjectTemplateRequirementType } from "../../domain/Project";
import LayoutFilter from "../../components/LayoutFilter";
const { default: EyeOffIcon } = require("../../assets/eye-off.svg");
const { default: EyeOnIcon } = require("../../assets/eye-on.svg");
const docIcon = require('../../assets/new-design-icons/doc.png')
const lookup = require("../../assets/lookup.png")

function EditProjectTemplate() {
  const { t, language, setLanguage } = useTranslation();
  const navigate = useNavigate();
  const projectIcon = project.toString();
  const [typeFilter, setTypeFilter] = useState(null);
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const [appliedFilters, setAppliedFilters] = useState({ type: null });
  const [filteredRequirement, setFilteredRequirement] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayCards, setDisplayCards] = useState("grid");


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const translationPathProject = "pages.EditProjectTemplate.";
  const {
    onSelectGroupInRequirement,
    groups
  } = useContext(EditProjectTemplateContext);
  const translationPath = "pages.ressources."
  const types = [
    {
      name: t(translationPath + "types.contract"),
      id: ProjectTemplateRequirementType.CONTRACT,
      icon: contractIcon
    },
    {
      name: t(translationPath + "types.document"),
      id:ProjectTemplateRequirementType.DOCUMENT,
      icon: docIcon
    },
    {
      name: t(translationPath + "types.form"),
      id: ProjectTemplateRequirementType.FORM,
      icon: formIcon
    }
  ]
  const [timeSinceUpdate, setTimeSinceUpdate] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [renameTrigger, setRenameTrigger] = useState(false);
  const [active, setActive] = useState(false);

  const {
    projectTemplate,
    onTemplateRename,
    projectTemplateRequirements,
    setProjectTemplateRequirements,
  } = useContext(EditProjectTemplateContext);
  useEffect(() => {
    const updateDate = new Date(projectTemplate?.updatedAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar,
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setTimeSinceUpdate(time);
    }
  }, [projectTemplate, language]);
  const toggleEyeIcon=()=>{
    setActive(!active)
  }
  const handleTypeFilterChange = (type) => {
    setAppliedFilters(prev => ({
      ...prev,
      type: type
    }));
    setTypeFilter(types.find(t => t.id === type));
  };

  useEffect(() => {
    let filteredRessources = projectTemplateRequirements || [];
    if (appliedFilters.type !== null) {
      filteredRessources = filteredRessources.filter(r => r.types[0] === appliedFilters.type);
    }
    if (searchTerm) {
      filteredRessources = filteredRessources.filter(r => r.name.toLowerCase().includes(searchTerm));
    }
    setFilteredRequirement(filteredRessources);
  }, [appliedFilters, projectTemplateRequirements, searchTerm]);
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };

  return (
    <div className="projectTemplate px-3 pt-2 w-100">
      <section id="display-project">
        <div className="container-paths">
          <span
            className="previous-pathname"
            onClick={() => navigate("/project-templates")}
          >
            {t(translationPathProject + "title")}

          </span>
          <span className="path-separator"> {">"} </span>
          <span className="current-pathname">{projectTemplate?.name}</span>
        </div>
        <div className="project-template-title">
          <div
            style={{
              overflow: "hidden",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={projectIcon}
              alt="icon"
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <div className="project-template-title-details">
            <span className="title">
              {renameTrigger ? (
                <input
                  className="input-title"
                  value={projectTemplate?.name}
                  onChange={(e) => {
                    onTemplateRename(e.target.value);
                  }}
                />
              ) : (
                projectTemplate?.name
              )}
              &nbsp; &nbsp;
              <FiEdit2
                style={{ cursor: "pointer" }}
                color={`${renameTrigger ? "blue" : "gray"}`}
                onClick={() => setRenameTrigger(!renameTrigger)}
              />
              <div style={{position:"relative"}}>
              <img
                src={ active ? EyeOnIcon : EyeOffIcon}
                alt="#"
                style={{ color: "gray", maxHeight: "20px",cursor:"pointer", marginLeft: "15px", marginTop: "1px" }}
                onClick={toggleEyeIcon}
              />
              { active&& (
            <div className="generate-ai-container groups-project">
              <ProjectTemplateGroups
                clauseGroup={projectTemplate?.groups || []}
                onSelectGroup={onSelectGroupInRequirement}
                groups={groups}
                close={toggleEyeIcon}
              />
            </div>
          )}
              </div>
            </span>
            <span className="modified">
              {t("pages.contractTemplateParam.modified")} : {timeSinceUpdate}
            </span>
          </div>
        </div>
        <div className="ressources-filters">
        <div className="search-container">
          <input
            type="text"
            placeholder={t(translationPathProject + "searchPlaceholder")}

            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
            <img src={lookup} alt="#" className={`lookup-img ${language == "ar" && "lookup-img-arabic"}`} />

        </div>
            <div className="filter-item">
              <CustomCombobox
                canDeselect
                label=""
                onDropdownOpen={() => { }}
                options={types}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                optionValue={(option) => option?.id || null}
                optionDisplay={(option) => (<>
                  {option ? <div className="filter-type-item">
                    <div className='icon'>
                      {
                        option.id === 8 ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                          option.id === 2  ? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                            option.id === 1 ? <img src={docIcon} alt="icon" style={{ width: "25px", height: "25px" }} /> :
                              <></>
                      }
                    </div>
                    <span>
                      {option.name}
                    </span>
                  </div> :
                    <span>
                      {t(translationPath + "type")}
                    </span>
                  }
                </>)}
                fullWidth={true}
                isOpen={openCombobox === "ressourceFilter"}
                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "ressourceFilter" : null)}
              />
            </div>
          </div>
        <button onClick={() => setOpenModal(true)}>
        {t(translationPathProject + "btn")}

        </button>
        <LayoutFilter onLayoutChange={handleLayoutChange} />

        <div className={`${displayCards==="grid"&&"document-container"}`}>
          <RessourcesProject
          display={displayCards}
            interfaceTemplate={true}
            ressources={filteredRequirement}
          />
        </div>
        {openModal && (
          <OptionsRessourceProject
            projectTemplate={projectTemplate}
            ressources={projectTemplateRequirements}
            setRessources={setProjectTemplateRequirements}
            onClose={() => setOpenModal(false)}
          />
        )}
      </section>
    </div>
  );
}

export default EditProjectTemplate;
