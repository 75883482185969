import React from 'react'
import useTranslation from '../../../../../hooks/useTranslation';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function CPALevel3Value({ contracts, cpaTypes }) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ChartDataLabels
    );
    const { t } = useTranslation();
    const translationPath = 'pages.analytics.CPA.value.graphs.logiciel';
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: t(`${translationPath}.title`),
            },
            datalabels: {
                display: true,
                color: "white",
                anchor: 'end' as const,
                align: 'start' as const,
                padding: {
                    top: -10
                },
                formatter: function (value, context) {
                    return value > 1 ? value : "";
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    precision: 0, // Set precision to 0 to remove decimal values
                },
            },
            x: {
                ticks: {
                    callback: function (value, index, values) {
                        return this.getLabelForValue(value).length > 10 ? this.getLabelForValue(value).substr(0, 10) + '...' : this.getLabelForValue(value);
                    }
                }
            }
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
            },
        },
    };

    const labels = cpaTypes.map((type) => type.level3Name);
    const groupedContractsCount = cpaTypes.map((type) => {
        const level1Id = type.level1Id;
        const level2Id = type.level2Id;
        const level3Id = type.level3Id;
        const typeContracts = contracts.filter((contract) => contract.level1Id == level1Id && contract.level2Id == level2Id && contract.level3Id == level3Id);
        let value = 0;
        switch (level3Id) {
            case 13:
                value = typeContracts.reduce((acc, contract) => {
                    const users = contract.paramValues.nombre_utilisateur ?? 0;
                    const price = contract.paramValues.prix_abonnement ?? 0;
                    return acc + (users * price);
                }, 0);
                break;
            case 8:
                value = typeContracts.reduce((acc, contract) => {
                    const price = contract.paramValues.prix_global ?? 0;
                    return acc + parseFloat(price);
                }, 0);
                break;
            case 14:
                value = typeContracts.reduce((acc, contract) => {
                    const users = contract.paramValues.nombre_utilisateur ?? 0;
                    const price = contract.paramValues.prix_abonnement ?? 0;
                    return acc + (users * price);
                }, 0);
                break;
            default:
                value = typeContracts.length;
        }
        return value;
    });
    const data = {
        labels: labels.map((status) => t(`status.${status}`) ?? ""),
        datasets: [
            {
                label: t(`${translationPath}.label`),
                data: groupedContractsCount,
                backgroundColor: '#7014E5',

            },
        ],
    };
    return (
        <div className="chart" style={{ width: "500px" }}>
            <Bar data={data} options={options} />
        </div>
    )
}

export default CPALevel3Value