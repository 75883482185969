import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import NotFound from '../../pages/NotFound'
import SignIn from '../../pages/SignIn'
import SignUp from '../../pages/SignUp'
import ForgetPassword from '../../pages/ForgetPassword'
import ResetPassword from '../../pages/ResetPassword'
import { useTranslation } from '../../contexts/TranslationProvider'
import FlagCombobox from '../common/CustomCombobox/FlagCombobox'
import { flags } from '../../assets/icons/flags'
import EditContractRestricted from '../../pages/EditContractRestricted'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { RestrictedHome } from '../../RestrictedHome'
import './transition.scss'

function AuthRouter({isLoggedIn}) {
  const { setLanguage, language } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const storedLanguage = localStorage.getItem('language') || 'fr';
  const [flag, setFlag] = React.useState({
    language: storedLanguage,
    flag: flags[storedLanguage],
  });
  const dir = language === "ar" ? "rtl" : "ltr";
  const languages = [
    {
      language: "fr",
      flag: flags.fr,
    },
    {
      language: "en",
      flag: flags.en,
    },
    {
      language: "ar",
      flag: flags.ar,
    },
  ];
  const handleFlagChange = async (value) => {
    setLanguage(value);
    setFlag(languages.find((element) => element.language == value));
  }
  return (
    <div dir={dir} className="auth-container" style={{ position: "relative" }}>
     {path === '/restricted/*' && (<div className="d-flex justify-content-between align-items-center px-2" style={{ position: "absolute", top: "30px", left: language != "ar" ? "auto" : "0", right: language == "ar" ? "auto" : "0", zIndex: "50" }}>
        <div>
          <div>
            <FlagCombobox
              optionValue={(option) => option?.language || null}
              optionDisplay={(option) => option?.flag || ""}
            />
          </div>
        </div>
      </div>)}
      <TransitionGroup>
      <CSSTransition
            key={location.key}
            timeout={400}
            classNames="fade"
          >
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="*" element={!isLoggedIn&&<Navigate to="/sign-in" replace />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/restricted/*" element={<RestrictedHome />} />
      </Routes>
      </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default AuthRouter