import React from 'react'
import RegulationCard from './RegulationCard'
import {  DocumentUseCaseResponseRiskEntity } from '../../../domain/entities'

function RiskRenderer({ useCaseResponse }:{
  useCaseResponse:DocumentUseCaseResponseRiskEntity
}) {
  return (
    <div className='regulation-list'>

      {
        useCaseResponse?.useCase?.args.map((item, index) => {
          return (
            <div key={index} className="regulation-container">
              <RegulationCard
                regulationName={item.regulation}
                risks={item.risks}
                response={useCaseResponse.response}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default RiskRenderer