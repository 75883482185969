import React, { useMemo, useState, useEffect } from 'react'
import EditReglementContext from '../../contexts/EditReglementContext';
import { ReglementEntity } from '../../domain/entities';
import ragClientWithLoading from '../../services/rag/RagClient';
import { ReglementClient } from '../../services/rag/ReglementClient';
import { useParams } from 'react-router-dom';
import { set } from 'react-hook-form';
import Loading from '../../components/common/Loading';
import './reglement.scss'
import SVGRender from '../../helpers/SVGRender';
import { close } from '../../assets/icons/close'
import chat from "../../assets/new-design-icons/Chat.svg"
import RessourceCard from './components/RessourceCard';
import AddFile from './components/AddFile';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationProvider';
function ReglementPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const chatIcon = chat.toString()
  const ragClient = ragClientWithLoading()
  const reglementClient = new ReglementClient(ragClient)
  const { reglementId } = useParams();
  const [reglement, setReglement] = useState<ReglementEntity | null>(null);
  const [loading, setLoading] = useState(false);
  const getReglement = async () => {
    try {
      setLoading(true);
      const row = await reglementClient.getById(reglementId);
      if (!row) return;
      setReglement(row);
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getReglement()
  }, []);

  const EditReglementContextValue = useMemo(() => ({
    reglement,
    setReglement
  }), [
    reglement,
    setReglement
  ]);

  const uploadFile = async (file: File) => {
    try {
      setLoading(true);
      await reglementClient.addFile(reglementId, file);
      getReglement();
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const handleCommit = async () => {
    if (!reglement || reglement.has_vectorstore || reglement.files.length === 0) return;
    try {
      setLoading(true);
      await reglementClient.commit(reglementId);
      getReglement();
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  return (
    <EditReglementContext.Provider value={EditReglementContextValue}>
      <div style={{ width: "100%" }}>
        {loading ? (<Loading />) : (
          <div className='reglement-page'>
            <div className='reglement-page-header'>
              <div className='left-section'>
                <span style={{ cursor: "pointer" }} onClick={() => navigate("/reglements")} className='close'>
                  <SVGRender svgContent={close} />
                </span>
                <div className='title'>
                  <img src={chatIcon} alt="icon" style={{ width: "45px", height: "45px" }} />
                  <span className='name'>{reglement?.name}</span>
                </div>
              </div>
              <div className='right-section'>
                {
                  reglement && reglement.has_vectorstore && (
                    <div className='status-active'>
                      <span>Active</span>
                    </div>
                  )
                }
              </div>
            </div>
            <div className='reglement-page-body'>
              <div className='reglement-files-list'>
                {
                  reglement && !reglement.has_vectorstore && (
                    <AddFile handleUpload={uploadFile} />
                  )
                }
                {
                  reglement && reglement.files?.map(file => (
                    <RessourceCard key={file.id} ressource={file} />
                  ))
                }
              </div>
              <div className='actions'>
                <div onClick={handleCommit} className={`commit-button ${(!reglement || reglement.has_vectorstore || reglement.files.length === 0) && "disabled"}`}>
                  {t('pages.reglement.commit')}
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    </EditReglementContext.Provider>
  )
}

export default ReglementPage