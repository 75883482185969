import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from '../../../contexts/TranslationProvider';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function PeriodLineChart({ data, title, label }) {
  const [classifiedData, setClassifiedData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const now = new Date();
    const classifyData = data.map(item => {
      const createdAtDate = new Date(item.createdAt);
      const differenceInDays = Math.floor((now.getTime() - createdAtDate.getTime()) / (1000 * 3600 * 24));

      if (differenceInDays <= 15) return { ...item, classification: `0-15 ${t('pages.adminAnalytics.days')}` };
      if (differenceInDays <= 30) return { ...item, classification: `15-30 ${t('pages.adminAnalytics.days')}` };
      if (differenceInDays <= 90) return { ...item, classification: `1-3 ${t('pages.adminAnalytics.month')}` };
      if (differenceInDays <= 180) return { ...item, classification: `3-6 ${t('pages.adminAnalytics.month')}` };
      return { ...item, classification: `+6 ${t('pages.adminAnalytics.month')}` };
    });

    setClassifiedData(classifyData);
  }, [data]);

  const labels = [
    `0-15 ${t('pages.adminAnalytics.days')}`,
    `15-30 ${t('pages.adminAnalytics.days')}`,
    `1-3 ${t('pages.adminAnalytics.month')}`,
    `3-6 ${t('pages.adminAnalytics.month')}`,
    `+6 ${t('pages.adminAnalytics.month')}`,
  ]
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Classified Data',
        data: labels.map(label => 
          classifiedData.filter(item => item.classification === label).length
        ),
        borderColor: '#7014E5',
        backgroundColor: 'rgba(112, 20, 229, 0.1)',
        tension: 0,
        pointBorderWidth: 5,
        pointBackgroundColor: 'white',
        pointBorderColor: '#7014E5',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    stacked: false,
    plugins: {
        legend: {
            display: false,
            labels: {
                filter: function(item, chart) {
                    // Logic to remove a particular legend item goes here
                    console.log(chart)
                    return false;
                }
            }
        },
        title: {
            display: true,
            text: title,
        },
        datalabels: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    if(context.dataset){
                    const value = context.parsed.y;
                    const xValue = context.parsed.x;
                    return `${label} ${labels[xValue]}: ${value}`;
                    }
                },
            },
            filter: function (tooltipItem) {
                return tooltipItem.datasetIndex === 0;
            }
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                stepSize: 1, // Set the step size to 1 to ensure whole numbers
                precision: 0, // Set precision to 0 to remove decimal values

            },
        },
    }
};

  return (
    <div className="chart-line line-chart">
      <Line data={chartData} options={options} />
    </div>
  );
}

export default PeriodLineChart;
