import { useTranslation } from "../../../../../contexts/TranslationProvider"
const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.highlight) {
    children = <span   id="slate_editor_is_highlited" style={{backgroundColor: "#fff4e7", padding: "3px"}} className='test'>{children}</span>
  }
  if (leaf.comment) {
    children = <em  className='comment'>{children}</em>
  }
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span className='stale text-block' {...attributes}>{children}</span>
}

export default Leaf