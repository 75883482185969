import EventManager from "../../../../services/EventManager";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'

function EditFormTemplateCommitButton() {
  const { t } = useTranslation();
  const handleFormTemplateSubmit = async () => {
   EventManager.invoke('ActivateFormTemplate',true)
  };

  return (
    <button className="navbar-button" onClick={handleFormTemplateSubmit}>
        {t("navbar.activateFormTemplate")}
    </button>
  );
}

export default EditFormTemplateCommitButton;
