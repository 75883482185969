import React, { useState, useEffect } from 'react'
import { GroupEntity, Group_UserEntity, UserEntity } from "../../domain/entities";
import { AiFillDelete } from "react-icons/ai";
import { WithContext as ReactTags } from 'react-tag-input';
import { useTranslation } from '../../contexts/TranslationProvider';
import { getName } from "../../helpers/Translation";
import EditGroup from '../../components/modals/EditGroup';
import ConfirmDeleteGroup from '../../components/modals/ConfirmDeteleGroup';
const { default: EditIcon } = require("../../assets/edit_icon.svg");
function GroupInput({
  users,
  onSave,
  currentValue,
  handleDeleteGroup,
  index,
}: {
  users: UserEntity[],
  onSave: (group: GroupEntity) => void,
  currentValue: GroupEntity,
  handleDeleteGroup: (group: GroupEntity) => void,
  index: number,
}) {
  const [group, setGroup] = useState<GroupEntity>(currentValue);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<GroupEntity | null>(null);
  const [translations, setTranslations] = useState(currentValue.translations.map(translation => { return { language: translation.language, name: translation.name } })
  )
  const [showEdit, setShowEdit] = useState(false);
  const { t, language } = useTranslation();
  useEffect(() => {
    const oldTranslations = currentValue.translations
    translations.forEach(translation => {
      const foundTranslation = oldTranslations.find(oldTranslation => oldTranslation.language === translation.language)
      if (foundTranslation) {
        foundTranslation.name = translation.name
      } else {
        oldTranslations.push({ language: translation.language, name: translation.name })
      }
    })
    setGroup({ ...group, translations: oldTranslations })
    onSave(group)
  }, [translations]);
  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  const handleDelete = i => {
    group.users = group.users.filter((user, index) => index !== i)
    setGroup({ ...group });
    onSave(group)
  };
  const handleAddition = tag => {
    const user = users.find(user => user.id == tag.id)
    group.users = group.users = [...group.users, user]
    setGroup({ ...group });
    onSave(group)
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const onEditGroup = (result) => {
    setGroup(result)
    onSave(result)
  }
  const confirmDelete = (group) => {
    setGroupToDelete(group);
    setShowConfirmation(true);
  };

  return (
    <>
      {
        showEdit && <EditGroup onClose={() => setShowEdit(false)} inComingGroup={currentValue} onUpdate={(result) => onEditGroup(result)} />
      }
      <tr className={`${language == "ar" && "arabic-row"}`}>
        <td >
          {group.code!}
        </td>
        <td >
          {/* {getName(group, language)} */}
          {getName(group, language)}
        </td>
        <td className={language == "ar" && "container-users"}>
          <ReactTags
            tags={group.users.map(user => { return { id: user.id, text: user.email } })}
            suggestions={users.map(user => { return { id: user.id, text: user.email } })}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            placeholder={t("modals.listGroups.placeholder")}
            inputFieldPosition="bottom"
            allowDeleteFromEmptyInput={false}
            autofocus={index === 0 ? true : false}
            autocomplete
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <img
            src={EditIcon}
            alt="edit"
            className="edit-user"
            onClick={() => { setShowEdit(true) }}
            style={{ cursor: "pointer" }}
          />
          &nbsp;
          &nbsp;
          <AiFillDelete 
          className="delete-user"
          style={{
            color: "#e5142f",
            cursor: "pointer",
            marginTop: "1px",
            fontSize: "18px",
          }}
          onClick={() => confirmDelete(group)}
        />
      </td>
        {showConfirmation&&<ConfirmDeleteGroup group={groupToDelete} onDelete={handleDeleteGroup}  onClose={()=>setShowConfirmation(false)}/>}
      </tr>
    </>
  )
}

export default GroupInput