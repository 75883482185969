import React, { useEffect, useState } from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import {
  BeneficialEntity,
} from "../../domain/entities";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import { AiFillDelete } from "react-icons/ai";
import AddBeneficialModal from "../../components/modals/AddBeneficialModal";
import "./beneficialsPage.scss";
import { BeneficialClient } from "../../services/api/BeneficialClient";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import { toast } from "react-toastify";

const { default: EditIcon } = require("../../assets/edit_icon.svg");

function BeneficialsPage() {
  const { t, language } = useTranslation();
  const [beneficials, setBeneficials] = useState<BeneficialEntity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [beneficialToHandle, setBeneficialToHandle] =
    useState<BeneficialEntity | null>(null);
  const beneficialClient = new BeneficialClient(useApiClientWithLoading());

  const fetchBeneficials = async () => {
    try {
      const { rows: beneficials } = await beneficialClient.getAll("pure");
      setBeneficials(beneficials);
    } catch (error) {
      console.error("Error fetching beneficials: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBeneficials();
  }, []);

  const renderCommonFields = (beneficial: BeneficialEntity) => (
    <>
   <td>{(beneficial?.firstName || "") + " " + (beneficial?.lastName || "")}</td>

      <td>{beneficial?.nationality}</td>
      <td>{beneficial?.cin}</td>
      <td>{beneficial?.email}</td>
      <td>{beneficial?.jobTitle}</td>
    </>
  );
  const HandleDeleteBeneficial=async(id)=>{
    try {
      setLoading(true);
      const { deleted } = await beneficialClient.delete(id);
      if (deleted) {
        toast.success(t("pages.beneficials.popup.messages.successDelete"))
        setLoading(false);
        fetchBeneficials()
      } else {
        throw ""
      }
    } catch (err) {
      toast.error(t("pages.beneficials.popup.messages.errorDelete"))
      setLoading(false);
    }
  }
  

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section className="beneficials-list w-100">
          <span className="current-pathname">
            {t("pages.beneficials.title")}
          </span>
          <div className="table-container">
            <table
              className={`fixed-header-table ${
                language === "ar" && "arabic-table"
              }`}
            >
              <thead>
                <tr>
                  <th>{t("pages.beneficials.type")}</th>
                  <th>{t("pages.beneficials.fullName")}</th>
                  <th>{t("pages.beneficials.nationality")}</th>
                  <th>{t("pages.beneficials.cin")}</th>
                  <th>{t("pages.beneficials.email")}</th>
                  <th>{t("pages.beneficials.jobTitle")}</th>
                  <th>{t("pages.beneficials.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {beneficials.map((beneficial) => (
                  <tr key={beneficial.id}>
                    <td>{beneficial?.type}</td>
                    {renderCommonFields(beneficial)}
                    <td className="action-buttons">
                      <img
                        src={EditIcon}
                        alt="edit"
                        className="edit-beneficial"
                        onClick={() => {
                          setBeneficialToHandle(beneficial);
                          setShowModal(true);
                        }}
                      />
                      <AiFillDelete
                        className="delete-beneficial"
                        style={{
                          color: "#e5142f",
                          cursor: "pointer",
                          marginTop: "1px",
                          fontSize: "18px",
                        }}
                        onClick={() => {
                          setBeneficialToHandle(beneficial);
                          setConfirmDelete(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="button-add-new-beneficial">
            <div className="vertical-line me-3"></div>
            <button
              className="action-add-beneficial"
              onClick={() => {
                setBeneficialToHandle({});
                setShowModal(true);
              }}
            >
              {t("pages.beneficials.addBeneficial")}
            </button>
          </div>
          <div className="import-document-modal">
            {showModal && (
              <AddBeneficialModal
                disableLookup={true}
                currentBenificialData={beneficialToHandle ? beneficialToHandle :null}
                setNewBeneficial={fetchBeneficials}
                onClose={() => {
                  setShowModal(false);
                  setBeneficialToHandle(null);
                }}
                beneficialTypes={beneficialToHandle?.type  ?[beneficialToHandle.type]: ['Company', 'Person']}

              />
            )}
            {showConfirmDelete && beneficialToHandle?.type && (
              <ConfirmDeletePopup
                msgDelete={t(
                  "pages.beneficials.msgDeleteBeneficial"
                )}
                onClose={() => {
                  setConfirmDelete(false);
                  setBeneficialToHandle(null);
                }}
                entityToDelete={(beneficialToHandle?.firstName || "") + " " + (beneficialToHandle?.lastName || "")}
                onDelete={()=>HandleDeleteBeneficial(beneficialToHandle?.id)}
              />
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default BeneficialsPage;
