import { AxiosInstance } from "axios";
import { ProjectTemplateEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";

export class ProjectTemplateClient extends ModelClient<ProjectTemplateEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ProjectTemplate')
  }

  async import(file: File, type: 'xlsx' | 'json' = 'xlsx', name?: string, level1Id?: TypeLevel1Entity['id'],
    level2Id?: TypeLevel2Entity['id'],
    level3Id?: TypeLevel3Entity['id']) {
    const url = type == 'xlsx' ? new URL(this.modelUrl + "/importFromXlsx")
      : new URL(this.modelUrl + "/importFromJSON")
    const formData = new FormData();
    formData.append("file", file);
    url.searchParams.append("name", name);
    url.searchParams.append("level1Id", level1Id.toString());
    level2Id && url.searchParams.append("level2Id", level2Id.toString());
    level3Id && url.searchParams.append("level3Id", level3Id.toString());
    const response = await this.apiClient.post<{
      errors: any[],
      rows: {
        id: ProjectTemplateEntity['id'], name: ProjectTemplateEntity['name'],
      }[]
    }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async export(id: ProjectTemplateEntity['id'], type: 'xlsx' | 'json' = 'xlsx') {
    try {
      const url = type == 'xlsx' ? new URL(this.modelUrl + "/exportToXlsx")
        : new URL(this.modelUrl + "/exportToJSON")
      url.searchParams.append("id", String(id))

      const response = await this.apiClient.get<{ url: string }>(url.href);
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }

  async select(level1Id: TypeLevel1Entity['id'],
    level2Id: TypeLevel2Entity['id'],
    level3Id: TypeLevel3Entity['id'],
    schema: string = "full") {
    try {
      const response = await this.apiClient.get<{ row: ProjectTemplateEntity }>(`${this.modelUrl}/select`, {
        params: {
          level1Id,
          level2Id,
          level3Id,
          schema,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error selecting template : ${[
        level1Id,
        level2Id,
        level3Id,]}`, error);
      return null;
    }
  }
  async activate(id: ProjectTemplateEntity['id']) {
    try {
      const response = await this.apiClient.post<{ row: ProjectTemplateEntity }>(`${this.modelUrl}/activate`, {}, {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }
  async desactivate(id: ProjectTemplateEntity['id']) {
    try {
      const response = await this.apiClient.post<{ row: ProjectTemplateEntity }>(`${this.modelUrl}/deactivate`, {}, {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }
}