import { AxiosInstance } from "axios";
import { GroupEntity, NotificationEntity, UserEntity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";

export class UserClient extends ModelClient<UserEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'User')
  }
  async clickNotification(notificationId: NotificationEntity['id']) {
    const url = new URL(this.modelUrl + "/clickNotification")
    url.searchParams.append("notificationId", String(notificationId))
    const reponse = await this.apiClient.post<{ result: number }>(url.href);
    return reponse.data;
  };
  async setLanguage(language: UserEntity['language']) {
    const url = new URL(this.modelUrl + "/setLanguage")
    url.searchParams.append("language", String(language))
    const reponse = await this.apiClient.post<{ language: UserEntity['language'] }>(url.href);
    return reponse.data;
  };
  async setRoles(userId: UserEntity['id'], roles: UserEntity['roles']) {
    const url = new URL(this.modelUrl + "/setRoles")
    url.searchParams.append("userId", String(userId))
    const reponse = await this.apiClient.post<{ count: number, rows: UserEntity[], groups: GroupEntity[] }>(url.href, { roles });
    return reponse.data;
  }
}