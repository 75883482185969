import { groupBy } from "lodash";
import { CustomPropertyEntity } from "./entities/CustomProperty";

export function getNestedProperties(properties: CustomPropertyEntity[]) {
  let tagMapping: Record<string, string> = {}

  properties.forEach((property) => {
    property.parentProperty = property.parentPropertyId ? properties.find(cp => cp.id == property.parentPropertyId) : null
    if (property.parentProperty) {
      tagMapping[property.tag!] = property.parentProperty.tag!
    }
  })

  const propertiesGroup = groupBy(properties, 'tag')
  for (const key in propertiesGroup) {
    propertiesGroup[key] = propertiesGroup[key].sort((a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime())
  }
  return { propertiesGroup, tagMapping }
}