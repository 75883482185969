import React, { useState, useEffect, useRef } from "react";
import Loading from "../../common/Loading";
import { useTranslation } from "../../../contexts/TranslationProvider";
import EventManager from "../../../services/EventManager";
const ExportTemplateModal = ({ onClose, type }) => {
  const modalref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalref.current && !modalref.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalref]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const GenerateJson = async () => {
    EventManager.invoke('ExportJSON', true)
    setTimeout(()=>{
      onClose()
    },500)
    
  };
  const GenerateXlsxUrl = async () => {
      EventManager.invoke('ExportCSV', true)
      setTimeout(()=>{
        onClose()
      },500)

  }
  const GenerateJsonFormTemplate = async () => {
    EventManager.invoke('ExportFormTemplateJSON', true)
    setTimeout(()=>{
      onClose()
    },500)

    
  };
  const GenerateXlsxUrlFormTemplate = async () => {
      EventManager.invoke('ExportFormTemplateCSV', true)
      setTimeout(()=>{
        onClose()
      },500)

  }



  return (
    <>
      <>
        <div id="contractz-lab" ref={modalref} className="contractz-lab-export" style={{ position: "absolute", top: "9vh",right: "30%" }}>
          <div className="modal-dialog export-modal-specific">
            <div className="modal-content" style={{ width: "fit-content" }}>
              <div className="modal-header header-export" >
                <h5 className="modal-title export-title">{t("pages.contractTemplateParam.edition.popups.export.title")}</h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-export">
                  <div className="form-group">
                    <p className="export-contract-text">{t("pages.contractTemplateParam.edition.popups.export.description")}</p>
                    <div className="form-floating mb-3 export-buttons">
                      <>
                        <button className="btn export-btn" onClick={type=="contractTemplate" ?GenerateJson :GenerateJsonFormTemplate} style={{ margin: "10px 0px" }}>
                          <div className="json-img"></div>
                          <p>{t("pages.contractTemplateParam.edition.popups.export.exportJSON")}</p>
                        </button>
                      </>

                    </div>
                    <p className="export-contract-text">{t("pages.contractTemplateParam.edition.popups.export.footer")}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ExportTemplateModal;
