import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/common/Loading";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { fetchTextFileUrl, formatDate } from "../../helpers/helper";
import { LoadingContext } from "../../contexts/LoadingContext";
import { DocumentEntity } from "../../domain/entities";
import useTranslation from "../../hooks/useTranslation";
import "./DocumentSummaryPage.scss"
import { DocumentClient } from "../../services/api/DocumentClient";
import InputValuesContext from "../../contexts/InputValuesContext";
import { ContractTemplateClient } from "../../services/api/ContractTemplateClient";
import { toast } from "react-toastify";
import parseTemplateData from "../../helpers/ParseTemplateData";
import EditContractInputList from "../EditContractPage/components/EditContractInputList";
import SelectionContext, { SelectedType } from "../../contexts/SelectionContext";
import EventManager from "../../services/EventManager";
import ContractInputList from "./components/ContractInputList";
import JustificationProvider from "../../contexts/JustificationContext";
import TextRender from "./components/TextRender";
import { previous } from "slate";
function DocumentSummaryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, setLoading } = useContext(LoadingContext);
  //const [justifications, setJustifications] = useState(Record<string, number[]>());
  const [justifications, setJustifications] = useState<Record<string, number[]>>(null);
  const {
    templateData,
    setContractTemplate,
    setInputValues,
    setFileNames,
    setBeneficialsMap,
  } = useContext(InputValuesContext);
  const [document, setDocument] = useState<DocumentEntity>(null);
  const [text, setText] = useState<string>(null);
  const { documentId } = useParams<{ documentId?: string }>() || {};
  const { previousPathname, fromHomePage } = location?.state || { previousPathname: [], fromHomePage: false };
  const { t, language, setLanguage } = useTranslation();
  const apiClient = useApiClientWithLoading(setLoading);
  const documentClient = new DocumentClient(apiClient)

  const handleNavigate = () => {
    if (fromHomePage) {
      navigate("/ressources")
    } else {
      window.history.length > 1 ? navigate(-1) : navigate("/")
    }
    setTimeout(() => {
      const newLocation = window.location.pathname;
      if (newLocation.startsWith("/createDocumentRequirement")) {
        window.history.length > 1 ? navigate(-1) : navigate("/")
      }
    }, 1000)
  }
  useEffect(() => {
    EventManager.subscribe('SaveSummary', handleNavigate);
    return () => {
      EventManager.unsubscribe('SaveSummary', handleNavigate);
    };
  }, []);
  useEffect(() => {
    (async () => {

      const row = await documentClient.getById(parseInt(documentId), "summary");
      console.log(row);

      if (!row?.summary?.contractTemplate) {
        toast("Document have no summary", { type: 'error' })
        return navigate(`/document/${documentId}`)
      }
      let text = ""
      try {
        console.log(row?.textFile);

        text = await fetchTextFileUrl(row?.textFile);
        console.log(text);

      } catch (error) {
        console.error(error);

      }


      const summary = row.summary
      const template = row.summary.contractTemplate
      setContractTemplate(template)
      setInputValues(summary.values)
      setJustifications(summary.justifications)
      setBeneficialsMap(row.beneficialsMap)
      setDocument(row);

      setText(text);
      const arabicRegex = /[\u0600-\u06FF]/;
      if (arabicRegex.test(text)) {
        setLanguage('ar');
      }
    })();
  }, []);
  useEffect(() => {
    if (document?.useCaseResponses?.length === 1) {
      navigate(`/ai-document-response/${document.useCaseResponses[0]?.id}`)
    }
  }, [document])

  const handleUseCaseClick = (item) => {
    navigate(`/ai-document-response/${item?.id}`, {
    });
  };
  const inputListRef = useRef(null);
  const [selected, setSelected] = useState<SelectedType>({
    eventType: null,
    clauseId: null,
    paramName: null,
  });
  const selectionContextValue = useMemo(
    () => ({
      selected, setSelected,
      isClauseEditing: null, setIsClauseEditing: null,
    }),
    [selected, setSelected]
  );
  console.log(document?.summary?.contractTemplateId);
  console.log(templateData);


  return document ? (
    <JustificationProvider>
      <div className="row w-100 document-page-container">
        {(previousPathname && previousPathname.length > 0) && <div className="px-2 container-paths">
          {previousPathname && previousPathname?.map((item, index) => (
            <span key={index}>
              <span className="previous-pathname" onClick={() => navigate(item.link)}>{item.label}</span>
              <span className="path-separator"> {">"} </span>
            </span>
          ))}
          {previousPathname && previousPathname.length > 0 && <span className="current-pathname">{document.name}</span>}
        </div>}
        <div className="document-page-text-section">
          <h1 className="contract-name">{document.name}</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TextRender text={text} />
          </div>
        </div>

        {document?.summary?.contractTemplateId && templateData &&
          <SelectionContext.Provider value={selectionContextValue}>

            <div ref={inputListRef} className="document-page-use-case-section px-3 mt-2">
              <h4 className="header-useCaseQA">{t("pages.document.usecases")}</h4>
              <div ref={inputListRef} className="variable-input-container">
                <ContractInputList
                  templateData={templateData}
                  inputListRef={inputListRef}
                  isEditing={false}
                  justifications={justifications}
                />
                <div style={{ height: 500 }} />
              </div>
            </div>
          </SelectionContext.Provider>
        }
      </div>
    </JustificationProvider>
  ) : (
    <Loading height="90vh" />
  );
}

export default DocumentSummaryPage;
