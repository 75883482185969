import React, { useState } from 'react'
import CPAMarketPeriod from './charts/CPA/Periods/CPAMarketPeriod'
import CPAMaintenancePeriod from './charts/CPA/Periods/CPAMaintenancePeriod'
import CPAExpertisePeriod from './charts/CPA/Periods/CPAExpertisePeriod'
import CPALevel2Value from './charts/CPA/Values/CPALevel2Value'
import CPALevel3Value from './charts/CPA/Values/CPALevel3Value'
import { transformTypes, transformTypesLevel2 } from '../../helpers/Analytics'
import useTranslation from '../../hooks/useTranslation'

function CPAGraphs({ contracts, typeLevels }) {
  const { t } = useTranslation();
  const translationPath = 'pages.analytics.CPA';
  const cpaTypes = transformTypes(typeLevels.filter(type => type.name === "CPA"));
  const level2Types = transformTypesLevel2(typeLevels.filter(type => type.name === "CPA"));
  const level3Types = cpaTypes.filter(type => type.level3Id !== null);
  return (
    <>
      <h6 style={{ marginTop: "5px" }}>{t(translationPath + ".period.title")} </h6>
      <div className='charts-line borderB'>
        <CPAMarketPeriod contracts={contracts.filter(con => con.level1?.name === "CPA")} />
        <CPAMaintenancePeriod contracts={contracts.filter(con => con.level1?.name === "CPA")} />
        <CPAExpertisePeriod contracts={contracts.filter(con => con.level1?.name === "CPA")} />
      </div>
      <h6 style={{ marginTop: "5px" }}>{t(translationPath + ".value.title")} </h6>
      <div className="borderB" style={{ display: "flex", justifyContent: "left", alignItems: "left", marginTop: "20px" }}>
        <div className="charts" id="main-function">
          <CPALevel2Value contracts={contracts.filter(con => con.level1?.name === "CPA")} cpaTypes={level2Types} />
          <CPALevel3Value contracts={contracts.filter(con => con.level1?.name === "CPA")} cpaTypes={level3Types} />
        </div>
      </div>
    </>
  )
}

export default CPAGraphs