import React, { useEffect, useState } from "react";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'
import FormAuditModal from "../../../modals/FormAuditModal";
import EventManager from "../../../../services/EventManager";
import { EditFormContextType } from "../../../../contexts/EditFormContext";

function AuditFormButton() {
  const { t } = useTranslation();
  const [editFormContext, setEditFormContext] = useState<EditFormContextType>(null)

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    EventManager.subscribe('EditFormContext', setEditFormContext)
    return () => {
      EventManager.unsubscribe('EditFormContext', setEditFormContext)
    }
  }, [])
  return <>
    <button className="navbar-button" onClick={() => setShowModal(true)}>
      {t("navbar.audit")}
    </button>
    {showModal && <FormAuditModal onClose={() => setShowModal(false)}  editFormContext={editFormContext}/>}
  </>
}

export default AuditFormButton;
