import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { DocumentUseCaseResponseEntity } from "../../domain/entities/DocumentUseCaseResponse";
import { DocumentEntity } from "../../domain/entities";

export class DocumentUseCaseResponseClient extends ModelClient<DocumentUseCaseResponseEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'DocumentUseCaseResponse')
  }
  async updateUseCaseResponse(
    useCaseResponsesId: DocumentEntity["useCaseResponsesId"],
    data: { responseValidation: DocumentUseCaseResponseEntity['responseValidation'][] }) {
    const response = await this.apiClient.patch<{ data: { responseValidation: DocumentUseCaseResponseEntity['responseValidation'][] } }>(
      `${this.modelUrl}?id=${useCaseResponsesId}&schema=nested`,
      { data }
    );
    return response.data;
  };

}