import React, { useState, useContext } from 'react'
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { FiPlusCircle } from "react-icons/fi";
import EventManager from '../../../services/EventManager';
import "./addClausePlaceholder.scss";
import { useTranslation } from '../../../contexts/TranslationProvider';
import ClauseSelectModal from '../../../components/modals/ClauseSelectModal';
import { normalizeName } from '../../../utils/string';

function AddClausePlaceholder({ clauseIndex, addNewClause, setAddNewClause, clauses  }) {
  const [clauseName, setClauseName] = useState("")
  const [clauseSelector, setClauseSelector] = useState(false)
  const [clauseNameError, setClauseNameError] = useState(false)
  const { t } = useTranslation()
  const translationPath = "pages.contractTemplateParam.addClausePlaceholder"
  const { insertClause } = useContext(EditContractTemplateContext);
  const handleAddClause = async () => {
    if (clauses.some((clause) => normalizeName(clause.name) === normalizeName(clauseName))) {
      setClauseNameError(true)
    }
    else{
    const result = await insertClause(addNewClause, clauseName)
    if (!result) return;
    setAddNewClause(null);
    setClauseName("");
    EventManager.invoke('SaveContractTemplate', true)
  }
  }
  const handleClose = () => {
    setClauseSelector(false)
    setAddNewClause(null)
    setClauseNameError(false)
  }

  return (
    <div key={"placeholder-" + clauseIndex} style={{ backgroundColor: addNewClause == clauseIndex ? '#eee3fd' : 'white' }} className="add-clause px-4 py-2 mx-1 my-2">
      {clauseSelector && (
        <ClauseSelectModal
          onClose={handleClose}
          addNewClause={addNewClause}
        />
      )}
      {
        addNewClause !== clauseIndex && <div onClick={() => setAddNewClause(clauseIndex)} className="add-clause-tag">
          <FiPlusCircle />
          &nbsp;
          <b>{t(translationPath + ".title")}</b>
        </div>
      }
      {
        addNewClause == clauseIndex && <div className="add-clause-form">
          <div className="add-clause-name">
            <label className="add-clause-name-label">{t(translationPath + ".name")}</label>
            <input value={clauseName} onChange={(event) =>{ setClauseName(event.target.value); setClauseNameError(false)}} className="add-clause-name-input" type="text" placeholder={t(translationPath + ".placeholder")} />
          </div>
          <div className="add-clause-actions">
            <button className="add-clause-button" onClick={handleAddClause}>{t(translationPath + ".add")}</button>
            <button className="add-clause-button" onClick={() =>{ setAddNewClause(null); setClauseNameError(false)}}>{t(translationPath + ".cancel")}</button>
            <button className="select-clause-button" onClick={() => setClauseSelector(true)}>{t(translationPath + ".selectFromExisting")}</button>
          </div>
        </div>
      }
       {clauseNameError&& <div className="error-clause-name">
       <p className='error-message'>{t(translationPath + ".error-clause-name")}</p>
          </div>}
    </div>)
}

export default AddClausePlaceholder