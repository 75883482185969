import React, { useEffect, useRef, useState } from "react";
import "../commonmodal.scss";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../../contexts/TranslationProvider';
import CustomCombobox from "../../common/CustomCombobox";
import ShowCase from "../../ShowCase";
import FileInputAdvanced from "../../common/FileInputAdvanced";
import { FormClient } from "../../../services/api/FormClient";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { getName } from "../../../helpers/Translation";
import { FormTemplateClient } from "../../../services/api/FormTemplateClient";
import PendingAi from "../../common/newDesign/PendingAi";

function CreateFormFromDocModal({onClose}) {
  const [loading, setLoading] = useState(false);
  type CreateFormFromDocRequest = {
    file: Blob & { name: string };
    name: string;
    level1Id?: TypeLevel1Entity['id'];
    level2Id?: TypeLevel2Entity['id'];
    level3Id?: TypeLevel3Entity['id'];
  }
  const [req, setReq] = useState<CreateFormFromDocRequest>({
    name: "",
    file: undefined
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[],
    level2: TypeLevel2Entity[],
    level3: TypeLevel3Entity[],
  }>({
    level1: [],
    level2: [],
    level3: [],
  });

  const navigate = useNavigate()
  const modalContentRef = useRef(null);
  const { t, language } = useTranslation();
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const [formExistsError, setFormExistsError] = useState(false);
  const [checkFormExistsTimeout, setCheckFormExistsTimeout] = useState<NodeJS.Timeout>(null);
  const apiClient = useApiClientWithLoading();
  const formClient = new FormClient(apiClient)
  const typeLevel1Client = new TypeLevel1Client(apiClient)
  const formTemplateClient = new FormTemplateClient(apiClient)
  const translationPath = "modals.importForm."
  const [errors, setErrors] = useState<{
    formName: boolean
    level1: boolean;
    level2: boolean;
    level3: boolean;
  }>({
    formName: false,
    level1: false,
    level2: false,
    level3: false,
  });
  const checkFormFields = (formName, selectedLevel1, selectedLevel2, selectedLevel3) => {
    let hasError = false;
    if (formName === "") {
      setErrors(prevErrors => ({
        ...prevErrors,
        formName: true,
      }));
      hasError = true;
    }
    if (!selectedLevel1) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level1: true,
      }));
      hasError = true;
    }
    if (!selectedLevel2 && options.level2.length>0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level2: true,
      }));
      hasError = true;
    }
    if (!selectedLevel3 && options.level3.length > 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level3: true,
      }));
      hasError = true;
    }

    return !hasError;
  };
  const handleSave = async (e) => {
    e.preventDefault()
    const result = checkFormFields(req?.name, req?.level1Id, req?.level2Id,req?.level3Id)
    if (result === true&&req.file) {
        setLoading(true)
        try {
          const { row: template } = await formTemplateClient.select(req.level1Id, req.level2Id, req.level3Id, language, 'pure');
          if(!template) throw new Error("Error selecting template")
          const { row: newForm } = await formClient.generateFormFromDocAndTemplate({file: req.file, name: req.name, templateId: template.id})
          if(!newForm || !(newForm as any).id) throw new Error("Error generating form")
          toast.success(t(translationPath + "messages.success"));
          console.log(newForm.id)
          return navigate(`/edit-form/${newForm.id}`)
        } catch (error) {
          setLoading(false)
          toast.error(t(translationPath + "messages.error"));
        } finally {
          setLoading(false)
          onClose()
        }
    }
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAllWithFormTemplate(language)
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => item.id === value);
    setReq({
      ...req,
      level1Id: selectedLevel1 ? selectedLevel1.id : null,
      level2Id: null,
      level3Id: null,
    })
    setOptions((prevOptions) => ({
      ...prevOptions,
      level2: selectedLevel1 ? selectedLevel1.levels2 : [],
      level3: [],
    }));
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => item.id === value);
    setReq({
      ...req,
      level2Id: selectedLevel2 ? selectedLevel2.id : null,
      level3Id: null,
    })
    setOptions((prevOptions) => ({
      ...prevOptions,
      level3: selectedLevel2 ? selectedLevel2.levels3 : [],
    }));

  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => item.id === value);
    setReq({
      ...req,
      level3Id: selectedLevel3 ? selectedLevel3.id : null,
    })

  };
 useEffect(() => {
    if (checkFormExistsTimeout) {
      clearTimeout(checkFormExistsTimeout)
    }
    setCheckFormExistsTimeout(setTimeout(async () => {
      try {
        if(req?.name!==""){
          const count = await formClient.countAllByFilter({ "name": req?.name});
          setFormExistsError(count >= 1);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setCheckFormExistsTimeout(null)
      }
    }, 500))
  }, [req?.name]);

  return (
    <>
      <div
        className="modal-backdrop fade show"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog-generate-contract" style={{ width: "60%" }}>
            <div className="modal-content" style={{ maxHeight: "fit-content" }}>
              <div className="modal-header">
                <h5 className="modal-title">{t(translationPath + "title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  disabled={loading}
                  style={{marginRight:language=="ar"&&"85%"}}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" style={{ paddingBottom: "0" }} ref={modalContentRef}>
                {!loading && (
                  <p>
                    {t(translationPath + "description")}
                  </p>
                )}
                {loading ? (
                  <PendingAi height="100%" />
                ) : (
                  <form
                    action=""
                    className="form-group"
                    style={{ height: '550px' }}
                    onSubmit={handleSave}
                  >
                    <p className="fw-bolder">
                      {t("pages.listener.popups.contract.required")}
                    </p>
                    <div className="form-input-content">
                      <div className="form-input-first-content" style={{width:"100%"}}>
                        <label htmlFor="contractName" className="case-form-labelName">
                          {t(translationPath + "inputs.name")} {" "}
                        </label>
                        <div className="form-floating mb-3 case-form-labelInput">
                          <input
                            type="text"
                            style={{width:"80%", marginTop:"20px", borderRadius:"8px"}}
                            className="custom-color-input"
                            id="contractName"
                            onChange={(e) => {
                              setReq({ ...req, name: e.target.value })
                              setErrors(prevErrors => ({
                                ...prevErrors,
                                formName: false,
                              }));
                            }}
                          />
                        </div>
                        {errors.formName && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.formName.error1")}</p>}
                        {formExistsError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.formName.error2")}</p>}

                      </div>
                      <div className="form-input-last-content">
                        <CustomCombobox
                          label={t("pages.pilot.popups.contract.inputs.type1.name")}
                          options={options.level1.map((item) => item)}
                          optionDisplay={(option) => getName(option, language)}
                          optionValue={(option) => option?.id ?? null}
                          value={options.level1.find(option => option.id == req.level1Id)}
                          onChange={(value) => {
                            handleLevel1Change(value);
                            setErrors(prevErrors => ({
                              ...prevErrors,
                              level1: false,
                            }));
                          }}
                          isOpen={openCombobox === "level1"}
                          setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
                        />
                          {errors.level1 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type1.error")}</p>}


                        {(options.level2.length>0) && (
                          <CustomCombobox
                            label={t("pages.pilot.popups.contract.inputs.type2.name")}
                            options={options.level2.map((item) => item)}
                            optionDisplay={(option) => getName(option, language)}
                            optionValue={(option) => option?.id ?? null}
                            value={options.level2.find(option => option.id == req.level2Id)}
                            onChange={(value) => {
                              handleLevel2Change(value);
                              setErrors(prevErrors => ({
                                ...prevErrors,
                                level2: false,
                              }));
                            }}
                            isOpen={openCombobox === "level2"}
                            setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                          />
                        )}
                          {errors.level2 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type1.error")}</p>}

                        {(!req.level1Id || options.level2.length && !req.level2Id || options.level3.length > 0) && (
                          <CustomCombobox
                            label={t("pages.pilot.popups.contract.inputs.type3.name")}
                            options={options.level3.map((item) => item)}
                            optionDisplay={(option) => getName(option, language)}
                            optionValue={(option) => option?.id ?? null}
                            value={options.level3.find(option => option.id == req.level3Id)}
                            onChange={(value) => {
                              handleLevel3Change(value);
                              setErrors(prevErrors => ({
                                ...prevErrors,
                                level3: false,
                              }));
                            }}
                            isOpen={openCombobox === "level3"}
                            setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                          />
                        )}
                          {errors.level3 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type1.error")}</p>}

                      </div>
                    </div>
                    <FileInputAdvanced
                      value={req.file}
                      label={t("pages.listener.popups.contract.inputs.file.placeholder")}
                      onChange={(file) => setReq({
                        ...req, file
                      })}
                      className="mt-3"
                    />
                    <div className="modal-footer footer-form-pilotage">
                      <button type="submit" className="btn btn-primary">
                        {t("pages.listener.popups.contract.button")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFormFromDocModal