import "./showcase.scss";
import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useTranslation } from "../../contexts/TranslationProvider";
const { default: MySvg } = require('../../assets/Group_308.svg');

function ShowCase({ height = "90vh", withTimer = false }) {
  const {t}=useTranslation()
  const texts = [
    t("modals.showCase.text1"),
    t("modals.showCase.text2"),
    t("modals.showCase.text3"),
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <div
      className="showcase"
      style={{
        height: height,
      }}
    >
      <img src={MySvg} alt="Contractzlab Show Case" />
      <h3 className="fw-bold">CONTRACTZLAB</h3>
      <div className="slideshow d-flex flex-column justify-content-center align-items-center">
        <TransitionGroup
          style={{
            height: "70px",
          }}
        >
          <CSSTransition key={index} timeout={500} classNames="fade">
            <div className="slideshow-text">{texts[index]}</div>
          </CSSTransition>
        </TransitionGroup>
        <div className="slideshow-bar">
          {texts.map((_, i) => (
            <div
              key={i}
              className={`bar-item text-center mx-1 ${i === index ? "bar-active" : ""
                }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShowCase;
