import React from "react"
import { createPromiseSub, PromiseSub } from "./promise"

type StateHandlerSub<T> = {
    sub: PromiseSub,
    timer?: NodeJS.Timeout,
    value: T,
}
const bulkRideAwaitTime = 5 * 1000
export class StateSync<S> {
    private stateSub: StateHandlerSub<S> = null
    constructor(private setter: React.Dispatch<React.SetStateAction<any>>, private append: (prev: S, newValue: S,) => S, private awaitTime = bulkRideAwaitTime) {
    }
    set(value: S) {
        let promise = null
        if (this.stateSub) {
            promise = this.stateSub.sub.promise
            const { timer } = this.stateSub
            if (timer)
                clearTimeout(timer)
            this.stateSub.value = this.append(this.stateSub.value, value)
        } else {
            let sub = createPromiseSub()
            this.stateSub = {
                sub,
                value
            }
        }
        const timer = setTimeout(async () => {
            const { sub, value } = this.stateSub
            delete this.stateSub
            try {
                console.log("StateSync.setter", value);
                sub.resolve(await this.setter(value))
            } catch (error) {
                sub.reject(error)
            }
        }, this.awaitTime)
        this.stateSub.timer = timer
        return promise
    }

}