export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


export const validateNumber = (value) => {
  const numberRegex = /^[0-9]+$/;
  return numberRegex.test(value.trim());
};
