import React, { useContext, useState, useEffect } from "react";
import useApiClientWithLoading from "../../../../services/api/ApiClient";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'
import { FormClient } from "../../../../services/api/FormClient";
import ValidateFormModal from "../../../modals/ValidateFormModal";
import EditFormContext from "../../../../contexts/EditFormContext";
import { FormEntity } from "../../../../domain/entities";
import Loading from "../../Loading";

function EditionFormValidateButton() {

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<FormEntity | null>(null);
  const [showValidateFormModal, setShowValidateFormModal] = useState(false);
  const { t } = useTranslation();

  const apiClient = useApiClientWithLoading(setLoading);
  const formClient = new FormClient(apiClient)

  useEffect(() => {
    const path = window.location.pathname;
    const id = path.split("/").pop();
    const parsedId = parseInt(id, 10);
    if (!isNaN(parsedId)) {
      formClient.getById(parsedId).then((row) => {
        setForm(row);
        setLoading(false);
      });
    } else {
      console.error('Invalid form ID:', parsedId);
    }
  }, []);
  return (
    <>
      <button className="navbar-button" onClick={() => setShowValidateFormModal(true)}>
        {t("navbar.validateForm")}
      </button>
      {showValidateFormModal && form &&
        <ValidateFormModal form={form} onClose={() => setShowValidateFormModal(false)} />
      }

    </>
  )
}

export default EditionFormValidateButton;
