import React, { useState } from 'react';
import { useTranslation } from '../../../../contexts/TranslationProvider';
import ShareModal from '../../../modals/ShareModal';
import InviteModal from '../../../modals/InviteModal';
const { default: ShareIcon } = require('../../../../assets/share.svg');


const ShareButton = () => {
    const { t } = useTranslation();
    const [showExportModal, setShowExportModal] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    return (
        <>
            <button
                className="navbar-button"
                onClick={() => setShowExportModal(!showExportModal)}
                style={{ position: 'relative' }}
            >
            {t('navbar.share')}
            &nbsp;

            <img  src={ShareIcon}  width={15}/>

            </button>

            {showExportModal ? <ShareModal onClose={() => setShowExportModal(false)} setIsVisible={()=>setShowInviteModal(true)} /> : ""}
            {showInviteModal ? <InviteModal onClose={() => setShowInviteModal(false)} setShowExportModal={setShowExportModal} /> : ""}

        </>
    );
};

export default ShareButton;
