import React, { createContext } from "react";
import { ReglementEntity, ConversationEntity, AiFileEntity } from "../domain/entities";
import { FormParam } from "../domain/types/FormParams";
import { EnumOption, ListOption, TableLigne } from "../domain/types/ClauseParams";


export interface AskYourQuestionsContextType {
reglements: ReglementEntity[];
setReglements: React.Dispatch<React.SetStateAction<ReglementEntity[]>>;
conversations: ConversationEntity[];
setConversations: React.Dispatch<React.SetStateAction<ConversationEntity[]>>;
currentConversation: ConversationEntity | null;
setCurrentConversation: React.Dispatch<React.SetStateAction<ConversationEntity | null>>;
pendingQuestion: any;
setPendingQuestion: React.Dispatch<React.SetStateAction<any>>;
handleQueryChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
handleSubmit: (incomingQuery: string) => void;
query: string;
setAdd: React.Dispatch<React.SetStateAction<boolean>>;
add: boolean;
handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
newName: string;
handleAddConversation: (id?: string) => Promise<ConversationEntity>;
handleDelete: (e: React.MouseEvent<SVGElement, MouseEvent>, conversation: ConversationEntity) => void;
currentReglement: ReglementEntity | null;
setCurrentReglement: React.Dispatch<React.SetStateAction<ReglementEntity | null>>;
currentFile: AiFileEntity | null;
setCurrentFile: React.Dispatch<React.SetStateAction<AiFileEntity | null>>;
aiFiles: AiFileEntity[];
setAiFiles: React.Dispatch<React.SetStateAction<AiFileEntity[]>>;
getAllAiFiles: () => Promise<void>;
}

const AskYourQuestionsContext = createContext<AskYourQuestionsContextType>({

} as any);

export default AskYourQuestionsContext;