import React from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";

const ProjectTemplateCardActionsModal = ({ onDelete, onEdit, onView, onDesactivate}:{
  onDelete?: () => void;
  onEdit?: () => void;
  onView?: () => void;
  onDesactivate?: () => void;

}) => {
  const { t, language } = useTranslation();
  
  const actionCaller = (e, action) => {
    e.stopPropagation();
    action();
  };

  return (
    <div className="contract-card-actions-modal" style={{ marginRight: language === "ar" && "-30px" }}>
      <ul>
      {onView && (
          <li onClick={(e) => {actionCaller(e, onView) ;e.preventDefault(); e.stopPropagation()}}>
            {t("pages.ressources.actions.view")}
          </li>
        )}
        {onEdit && (
          <li onClick={(e) => actionCaller(e, onEdit)}>
            {t("pages.ressources.actions.edit")}
          </li>
        )}
         <li onClick={(e) => actionCaller(e, onDelete)}>
          {t("pages.ressources.actions.delete")}
        </li>
          {
          onDesactivate && <li onClick={(e) =>{actionCaller(e, onDesactivate)
             ;e.preventDefault(); e.stopPropagation()}}>{t("pages.ressources.actions.desactivate")}</li>
        }
      </ul>
    </div>
  );
};

export default ProjectTemplateCardActionsModal;
