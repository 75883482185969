import React, { useState, useEffect } from 'react'
import "./pendingAi.scss"
import { useTranslation } from '../../../../contexts/TranslationProvider'
import { TransitionGroup, CSSTransition } from "react-transition-group";

function PendingAi({ height = "90vh", withTimer = false }) {
  const {t, language}=useTranslation()
  const translationPath = "carousel.content"
  const [index, setIndex] = React.useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % t(translationPath).length);
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='pending-ai-container'>
      <video autoPlay muted loop className="background-video">
        <source src="/loading_video.mp4" type="video/mp4" />
      </video>
      <h3 style={{color: "#2B2E31"}} className="fw-bold title">CONTRACTZLAB</h3>
      <div className="slideshow d-flex flex-column justify-content-center align-items-center">
        <TransitionGroup
          style={{
            height: "70px",
          }}
        >
          <CSSTransition key={index} timeout={500} classNames="fading">
              <div style={{overflow: "hidden"}} className="slideshow-text">{t(translationPath)[index].description}</div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  )
}

export default PendingAi