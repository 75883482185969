import { AxiosInstance } from "axios";
import { RuleEntity } from "../../domain/entities/Rule";
import { ModelClient } from "../ModelClient";

export class RuleClient extends ModelClient<RuleEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Rule')
  }
  async import(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.apiClient.post<{ rows: RuleEntity[] }>(`${this.modelUrl}/importfromxlsx`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  }
}