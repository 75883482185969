import { dots } from "../../../assets/icons/dots"
import { useTranslation } from "../../../contexts/TranslationProvider";

function ContractElement({ contract, getGroupName, setShowContractModal, setSelectedContractId }) {
    const { t } = useTranslation();

    return <>
        <div className="contract-row" style={{ marginTop: "20px", display: "flex", flexDirection: "row", borderBottom: "1px solid #DBDFE" }}>
            <div className="table-cell" style={{ flex: 1 }}>
                <span style={{ wordBreak: 'break-all' }}>{contract.name}</span>
            </div>
            <div className="table-cell" style={{ flex: 1 }}>
                <span>{new Date(contract.updatedAt).toLocaleString()}</span>
            </div>
            <div className="table-cell" style={{ flex: 1 }}>
                <span>{new Date(contract.createdAt).toLocaleString()}</span>
            </div>
            <div className="table-cell" style={{ flex: 1 }}>
                <span>{t(`status.${contract.status}`) ?? ""}</span>
            </div>
            <div className="table-cell" style={{ flex: 1 }}>
                <span>{getGroupName(contract.assignedToGroupId)}</span>
            </div>
            <div className="table-cell" style={{ flex: 1 }}>
                <span>{contract?.client?.fullName}</span>
            </div>
            <div className="table-cell" style={{ flex: 1 }}>
                <span>{contract?.assignedToUser?.fullName}</span>
            </div>
            <div onClick={() => {
                setSelectedContractId(contract.id)
                setShowContractModal(true)
            }} className="dots">
                {dots}
            </div>
        </div>
    </>
}

export default ContractElement;
