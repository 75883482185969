import React, { useEffect, useState, useRef } from 'react'
import SVGRender from '../../helpers/SVGRender'
import { close } from '../../assets/icons/close'
import './chat.scss'
import Loading from '../../components/common/Loading'
import { RiDeleteBinLine } from "react-icons/ri";
import ragClientWithLoading from '../../services/rag/RagClient'
import { ReglementClient } from '../../services/rag/ReglementClient'
import { useParams } from 'react-router-dom'
import { ReglementEntity } from '../../domain/entities'
import { ConversationClient } from '../../services/rag/ConversationClient'
import { useNavigate } from 'react-router-dom'
import chat from "../../assets/new-design-icons/Chat.svg"
import document from "../../assets/new-design-icons/Document.svg"
import { get } from 'http'

function ChatPage() {
  const chatIcon = chat.toString()
  const documentIcon = document.toString()
  const navigate = useNavigate();
  const { reglementId } = useParams();
  const [reglement, setReglement] = useState<ReglementEntity | null>(null);
  const [conversations, setConversations] = useState([])
  const [currentConversation, setCurrentConversation] = useState(null)
  const [newName, setNewName] = useState('')
  const [query, setQuery] = useState('')
  const [pendingQuestion, setPendingQuestion] = useState(null)
  const [loading, setLoading] = useState(null)

  const scorllRef = useRef(null)
  const listRef = useRef(null)
  const [add, setAdd] = useState(false)
  const ragClient = ragClientWithLoading()
  const reglementClient = new ReglementClient(ragClient)
  const conversationClient = new ConversationClient(ragClient)
  const getReglement = async () => {
    try {
      setLoading(true);
      const row = await reglementClient.getById(reglementId);
      if (!row) return;
      setReglement(row);
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const getReglementConversations = async () => {
    try {
      setLoading(true);
      const result = await conversationClient.getAllFromReglement(reglementId);
      if (!result || !result.rows) return;
      setConversations(result.rows);
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getReglement()
    getReglementConversations()
  }, []);



  const handleNameChange = (e) => {
    setNewName(e.target.value)
  }

  const handleDelete = async (e, conversation) => {
    e.stopPropagation()
    try {
      if(currentConversation.id === conversation.id) {
        setCurrentConversation(null)
      }
      const data = await conversationClient.delete(conversation.id);
      await getReglementConversations()
    } catch (e) {
      console.log(e)
    }
  }
  const handleAdd = async () => {
    try {
      const data = await conversationClient.create({
        name: newName,
        reglement: reglementId,
        messages: []
      });
      
      setNewName('')
      setAdd(false)
      setCurrentConversation(data)
      await getReglementConversations()
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (currentConversation) {
      // scroll to bottom of list ref
      setTimeout(() => {
        const container = listRef.current;
        container.scrollTo({
          top: container.scrollHeight,
          // not smooth
          behavior: "smooth",
        });
      }, 100);
    }
  }, [currentConversation]);
  const handleQueryChange = (e) => {
    setQuery(e.target.value)
  }
  const handleSubmit = async (comingQuery = "") => {
    setPendingQuestion({
      "question": comingQuery || query,
    })
    const question = comingQuery || query
    // scroll to scrollRef to the top of the list ref
    setTimeout(() => {
      const container = listRef.current;
      const containerRect = container.getBoundingClientRect();
      const element = scorllRef.current;
      const elementRect = element.getBoundingClientRect();
      const relativeTop = elementRect.top - containerRect.top;
      const newScrollTop = container.scrollTop + relativeTop;
      container.scrollTo({
        top: newScrollTop,
        behavior: "smooth",
      });
    }, 400);
    setQuery('')
    // send post request to http://127.0.0.1:8000/chat/query with body containing the query and the conversation id
    try {
      const data = await conversationClient.query(currentConversation.id, question)
      setCurrentConversation(data)
      setPendingQuestion(null)
    } catch (e) {
      console.log(e)
      setPendingQuestion(null)
    }
  }
  return (
    <div className='chat-page-container'>
      <div className='ask-page-header'>
        <div className='left-section'>
          <span style={{ cursor: "pointer" }} onClick={() => navigate("/chats")} className='close'>
            <SVGRender svgContent={close} />
          </span>
          <div className='title'>
            <img src={chatIcon} alt="icon" style={{ width: "45px", height: "45px" }} />
            <span className='name'>{reglement?.name}</span>
          </div>
        </div>
        <div className='right-section'>
        </div>
      </div>
      <div className='sub-header'>
        {
          reglement && reglement.files &&
          reglement.files.map((file, index) => (
            <div className='header-file' key={index}>
              <img src={documentIcon} alt="icon" style={{ width: "20px", height: "20px" }} />
              <span className='title'>{file.name}</span>
            </div>
          ))
        }
      </div>
      <div className='ask-page-body'>
        <div className='body-interactions-container'>
          <div ref={listRef} className='body-chats-container'>
            {
              !currentConversation && (
                <div className='body-start-chat'>
                <div className='start-chat-title'>
                  <img src={chatIcon} alt="icon" style={{ width: "150px", height: "150px" }} />
                  <span className='title'>Select a conversation and start asking your questions</span>
                </div>
                </div>
              )
            }
            {
              currentConversation && !currentConversation.messages.length && !pendingQuestion &&
              <div className='body-start-chat'>
                <div className='start-chat-title'>
                  <img src={chatIcon} alt="icon" style={{ width: "150px", height: "150px" }} />
                  <span className='title'>Start asking your questions</span>
                </div>
                <div className='suggest'>
                <div onClick={() => {
                  handleSubmit('Explain the key points of CSRD')
                }}
                  className='suggest-card'>
                  Explain the key points of CSRD
                </div>
                <div onClick={() => {
                  handleSubmit('Explain the key points of CSRD')
                }}
                  className='suggest-card'>
                  How does CSRD impact reporting on great scale?
                </div>
                <div onClick={() => {
                  handleSubmit('Explain the key points of CSRD')
                }}
                  className='suggest-card'>
                  What are the main difference between CSRD and SFDR?
                </div>
              </div>
              </div>

            }
            {
              currentConversation &&
              currentConversation.messages &&
              currentConversation.messages.map((qNa, index) => {
                const breakedHTMLText = qNa.answer?.replaceAll(/\n/g, "<br/>")
                return <div key={index} className='body-chat'>
                  <div className='question-container'>
                    <div className='question'>
                      {qNa.question}
                    </div>
                  </div>
                  <div className='answer'>
                    <span dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
                  </div>
                </div>
              }
              )
            }
            {
              pendingQuestion &&
              <div ref={scorllRef} className='body-chat'>
                <div className='question-container'>
                  <div className='question'>
                    {pendingQuestion.question}
                  </div>
                </div>
                <div className='answer'>
                  <Loading height="7vh" />
                </div>
              </div>
            }
          </div>
          {currentConversation && <div className='body-interactions-input-container'>
            <input className='ask-input' type='text' placeholder='Ask your question' onChange={handleQueryChange} value={query} />
            <button style={{ backgroundColor: pendingQuestion && "gray" }} disabled={pendingQuestion} onClick={() => handleSubmit()} className='ask-button'>
              Ask
            </button>
          </div>}
        </div>
        <div className='body-conversations-container'>
          <div className='conversations-container'>
            conversations
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: "20px", flex: 1 }}>
              {conversations.map((conversation, index) => (
                <div style={{ cursor: "pointer", color: currentConversation?.id === conversation.id ? "blue" : "black" }} key={index} onClick={() => {
                  setCurrentConversation(conversation)
                  setPendingQuestion(null)
                }} className='conversation'>
                  {conversation.name}
                  <RiDeleteBinLine onClick={(e) => handleDelete(e, conversation)} />
                </div>
              ))}
            </div>
            <div className='add-conversation-container'>
              {add ?
                <div className='add-conversation-form'>
                  <div onClick={() => setAdd(false)} className='close'>X</div>
                  <input onChange={handleNameChange} value={newName} className='add-conversation-input' type='text' placeholder='Enter conversation name' />
                  <button onClick={handleAdd}>
                    Add
                  </button>
                </div> :
                <div className='add-conversation' onClick={() => setAdd(true)}>
                  Add new conversation
                </div>}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ChatPage