import CheckBoxInput from "../../../components/common/CheckBoxInput";
import { ListParam } from "../../../domain/types/ClauseParams";

// ListInputItem component
export function FormListInputItem({
  param,
  index,
  handleInputChange,
  currentValue,
  colorLabel
}: {
  param: ListParam,
  index: number,
  handleInputChange(value: any, paramName: string): void;
  currentValue: number[],
  colorLabel?:string
}) {
  const arg = param.args[index]
  const listFieldName = `${param.name}.${index}`;
  const isOptionChecked = currentValue?.includes(index) || arg.required;

  return (
    <div className="d-flex py-2" key={listFieldName}>
      <CheckBoxInput
        checked={isOptionChecked}
        name={arg.option}
        onChange={(e) => {
          const newValue = e.target.checked ? [...currentValue ?? [], index].sort() : currentValue?.filter(i => i != index)
          handleInputChange(
            newValue,
            param.name,
          );
        }}
        disabled={arg.required}
        colorLabel={colorLabel}
      />
    </div>
  );
}
