import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { DocumentEntity } from "../../domain/entities/Document";
import { ContractTemplateEntity, DocumentUseCaseResponseEntity, DocumentUseCaseResponseQuestionEntity } from "../../domain/entities";
import { DocumentSummaryEntity } from "../../domain/entities/DocumentSummary";

export class DocumentClient extends ModelClient<DocumentEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Document')
  }
  async questionUseCase(documentId: DocumentEntity['id'], questions: string[]) {
    const data = {
      documentId,
      questions
    }
    const response = await this.apiClient.post<{ row: DocumentUseCaseResponseEntity }>("/Document/questionUseCase", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  async fillSummaryWithAIAndContractTemplate(documentId: DocumentEntity['id'], templateId: ContractTemplateEntity['id']) {
    const response = await this.apiClient.post<{ row: DocumentSummaryEntity }>("/Document/fillSummaryWithAIAndContractTemplate", {},
      {
        params: {
          documentId,
          templateId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    return response.data.row;
  };

}