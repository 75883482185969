import React from 'react'
import "./comingSoon.scss"
import { useTranslation } from '../../../contexts/TranslationProvider';
import Logo from "../../../assets/Logo.svg"
function ComingSoon() {
    const { t } = useTranslation();
    return (
        <div className='comingSoon-container'>
            <img src={String(Logo)} width={150} className='img'/>
            <div className='comingSoon-sub-content'>
                <h1 className='header'>{t("pages.topic.comingSoon")}!</h1>
                <div className='comingSoon-time'>
                    <p className='time'>35<span className='unit-time'> {t("pages.topic.popupComingSoon.time.days")}</span></p>
                    <p className='time'>59<span className='unit-time'> {t("pages.topic.popupComingSoon.time.minutes")}</span></p>
                    <p className='time'>17<span className='unit-time'> {t("pages.topic.popupComingSoon.time.hours")}</span></p>
                    <p className='time'>59<span className='unit-time'> {t("pages.topic.popupComingSoon.time.seconds")}</span></p>
                </div>
                <div className='comingSoon-send-email'>
                    <input placeholder={t("pages.topic.popupComingSoon.input-placeholder")}/>
                    <button>{t("pages.topic.popupComingSoon.btn")}</button>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon