import React from 'react'

function StatusPicker({ status, setStatus, t, type }) {
  return (
    <div className="status-group">
    <div className={`status-btn ${status == "" && "active-status"}`} onClick={() => setStatus("")}>
      {`${t("status.any")} ${type}`}
    </div>
    <div className={`status-btn ${status == "Draft" && "active-status"}`} onClick={() => setStatus("Draft")}>
      {t("status.Draft")}
    </div>
    <div className={`status-btn ${status == "Negotiation" && "active-status"}`} onClick={() => setStatus("Negotiation")}>
      {t("status.Negotiation")}
    </div>
    <div className={`status-btn ${status == "ToBeSigned" && "active-status"}`} onClick={() => setStatus("ToBeSigned")}>
      {t("status.ToBeSigned")}
    </div>
    <div className={`status-btn ${status == "Signed" && "active-status"}`} onClick={() => setStatus("Signed")}>
      {t("status.Signed")}
    </div>
    <div className={`status-btn ${status == "Done" && "active-status"}`} onClick={() => setStatus("Done")}>
      {t("status.Done")}
    </div>
  </div>
  )
}

export default StatusPicker