import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useTranslation from '../../../hooks/useTranslation';

function TypesBarChart({ contracts, typeLevels }) {
    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
    const position: "right" | "left" = "right";
    const { t } = useTranslation();
    const translationPath = 'pages.analytics.graphs.typesPieChart';
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: t(`${translationPath}.title`),
            },
            datalabels: {
                display: false,
            },
            legend: {
                display: true,
                position: position,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding: 15,
                    borderRadius: 5,
                    // only display the first 10 characters of each label
                    generateLabels: chart => chart.data.labels.map((label, i) => {
                        return {
                            text: label.length > 16 ? label.substring(0, 16) + '...' : label,
                            fillStyle: chart.data.datasets[0].backgroundColor[i],
                            strokeStyle: chart.data.datasets[0].backgroundColor[i],
                            index: i,
                            fontColor: '#666'
                        };
                    }
                    ),

                }
            },
        },
        layout: {

        },
    };
    const groupedContracts = contracts.reduce((acc, contract) => {
        const { level1Id, level2Id, level3Id } = contract;

        if (!acc[level1Id]) {
            acc[level1Id] = {};
        }

        if (!acc[level1Id][level2Id]) {
            acc[level1Id][level2Id] = {};
        }

        if (!acc[level1Id][level2Id][level3Id]) {
            acc[level1Id][level2Id][level3Id] = [];
        }

        acc[level1Id][level2Id][level3Id].push(contract);

        return acc;
    }, {});

    function transformTypes(types, parentDisplay = '', parentLevel1Id = null, parentLevel2Id = null) {
        let combinations = [];

        types.forEach((type) => {
            const display = parentDisplay ? `${parentDisplay}->${type.name}` : type.name;
            const level1Id = parentLevel1Id !== null ? parentLevel1Id : type.id;
            const level2Id = type.levels2 ? null : type.id;

            if (type.levels3 && type.levels3.length > 0) {
                // If levels3 exist, generate combinations for each levels3
                type.levels3.forEach((level3) => {
                    combinations.push({
                        display: parentDisplay ? `${parentDisplay}->${type.name}->${level3.name}` : `${type.name}->${level3.name}`,
                        level1Id,
                        level2Id,
                        level3Id: level3.id,
                    });
                });
            } else if (!type.levels2) {
                // If no levels3 and no levels2, add a combination without level3
                combinations.push({
                    display,
                    level1Id,
                    level2Id,
                    level3Id: null,
                });
            }

            // If there are levels2 or levels3, recursively add combinations for them
            if (type.levels2) {
                // If there are levels3, recursively add combinations for levels2
                if (!type.levels3 || type.levels3.length === 0) {
                    combinations = combinations.concat(transformTypes(type.levels2, display, level1Id, null));
                }
            }
        });

        return combinations;
    }
    const types = transformTypes(typeLevels);
    const labels = types.map((type) => {
        const level1Id = type.level1Id;
        const level2Id = type.level2Id;
        const level3Id = type.level3Id;
        const typeContracts = contracts.filter((contract) => contract.level1Id == level1Id && contract.level2Id == level2Id && contract.level3Id == level3Id);
        if (typeContracts.length > 0) {
            return type.display;
        }
    }).filter((label) => label !== undefined);

    const groupedContractsCount = types.map((type) => {
        const level1Id = type.level1Id;
        const level2Id = type.level2Id;
        const level3Id = type.level3Id;
        const typeContracts = contracts.filter((contract) => contract.level1Id == level1Id && contract.level2Id == level2Id && contract.level3Id == level3Id);
        if (typeContracts.length > 0) {
            return typeContracts.length;
        }
    }).filter((count) => count !== undefined);


    // we work only with level 1
    const contractsByLevel1 = typeLevels.map((type) => {
        const level1Id = type.id;
        if (type.name == null)
            return null;
        const typeContracts = contracts.filter((contract) => contract.level1Id == level1Id);
        return typeContracts.length;
    }).filter((count) => count !== null).filter((count) => count !== 0);
    const labelLevel1 = typeLevels.map((type) => {
        const level1Id = type.id;
        const typeContracts = contracts.filter((contract) => contract.level1Id == level1Id);
        if (typeContracts.length == 0)
            return null;
        return type.name;
    }).filter((label) => label !== null);
    const data = {
        labels: labelLevel1,
        datasets: [
            {
                label: t(`${translationPath}.label`),
                data: contractsByLevel1,
                backgroundColor: [
                    'rgb(112, 20, 229)',
                    'rgb(47, 20, 229)',
                    "#3F83F5",
                    "#FFC542",
                    'rgba(255, 20, 0, 0.2)'
                    // Add more colors as needed
                ],
                borderwidth: 0.1,
            },
        ],
    };
    return (
        <div className="chart">
            <Pie options={options} data={data} />
        </div>
    );
}

export default TypesBarChart;
