import React, { useState, useRef, useCallback } from 'react'
import AskYourQuestionsContext from '../../../contexts/AskYourQuestionsContext'
import chat from "../../../assets/new-design-icons/Chat.svg"
import ChatSuggestions from './ChatSuggestions'
import Loading from '../../../components/common/Loading'
import { AiFileClient } from '../../../services/rag/AiFileClient'
import ragClientWithLoading from '../../../services/rag/RagClient'
import { AiOutlineUpload } from 'react-icons/ai'
import document from "../../../assets/new-design-icons/Document.svg"
import { set } from 'react-hook-form'
import ChatQuery from './ChatQuery'

function FileChatBody({ listRef, scorllRef }) {
  const documentIcon = document.toString()
  const ragClient = ragClientWithLoading()
  const aiFileClient = new AiFileClient(ragClient)
  const [uploadLoading, setUploadLoading] = useState(false)
  const { pendingQuestion, handleQueryChange, handleSubmit, query, currentReglement, currentFile, setCurrentFile } = React.useContext(AskYourQuestionsContext)
  const chatIcon = chat.toString()
  const [file, setFile] = useState(null)
  const fileInputRef = useRef(null);


  
  return (
    <>
      <div ref={listRef} className='body-chats-container'>
        {
          (!currentFile.messages.length) && !pendingQuestion &&
          <div className='body-start-chat'>
            <div className='start-chat-title'>
              <img src={chatIcon} alt="icon" style={{ width: "150px", height: "150px" }} />
              <span className='title'>Start asking your questions</span>
            </div>
          </div>

        }
        {
          currentFile &&
          currentFile.messages &&
          currentFile.messages.map((qNa, index) => {
            const breakedHTMLText = qNa.answer?.replaceAll(/\n/g, "<br/>")
            return <div key={index} className='body-chat'>
              <div className='question-container'>
                <div className='question-wrapper'>
                  <div className='added-file'>
                    <img src={documentIcon} alt="icon" style={{ width: "20px", height: "20px" }} />
                    <span>{currentFile.name}</span>
                  </div>
                  <div className='question'>
                  {qNa.question}
                </div>
                </div>
                
              </div>
              <div className='answer'>
                <span dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
              </div>
            </div>
          }
          )
        }
        {
          pendingQuestion &&
          <div ref={scorllRef} className='body-chat'>
            <div className='question-container'>
              <div className='question-wrapper'>
                <div className='added-file'>
                  <img src={documentIcon} alt="icon" style={{ width: "20px", height: "20px" }} />
                  <span>{currentFile.name}</span>
                </div>
                <div className='question'>
                  {pendingQuestion.question}
                </div>
                </div>
            </div>
            <div className='answer'>
              <Loading height="7vh" />
            </div>
          </div>
        }
      </div>
      <ChatQuery/>
    </>
  )
}

export default FileChatBody