import { useEffect, useRef, useState } from "react";
import FileInput from "../../../common/FileInput";
import ShowCase from "../../../ShowCase";
import { ValidationWarning } from "../../../../domain/ContractTemplate";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../../services/api/ApiClient";
import { ContractTemplateClient } from "../../../../services/api/ContractTemplateClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PendingAi from "../../../common/newDesign/PendingAi";
import { TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../../domain/entities";
import { useForm } from "react-hook-form";
import { TypeLevel1Client } from "../../../../services/api/TypeLevel1Client";
import FileInputAdvanced from "../../../common/FileInputAdvanced";
import CustomCombobox from "../../../common/CustomCombobox";
import { getName } from "../../../../helpers/Translation";

function ImportJSON({onClose, setLoading, loading}) {
  const [Errors, setErrors] = useState([]);
  const [Warnings, setWarnings] = useState<ValidationWarning[]>([]);
  const [showWarningsModal, setShowWarningsModal] = useState(false);
  const [file, setFile] = useState<File>(null);
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [templateExistsError, setTemplateExistsError] = useState(false);
  const [checkTemplateExistsTimeout, setCheckTemplateExistsTimeout] = useState<NodeJS.Timeout>(null);
  const { t, language } = useTranslation();
  const modalContentRef = useRef(null);
  const navigate = useNavigate();
  const [req, setReq] = useState({
    name: "",
    file: file,
    level1Id:null,
    level2Id:null,
    level3Id:null
    
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[],
    level2: TypeLevel2Entity[],
    level3: TypeLevel3Entity[],
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const {
    watch,
    register
  } = useForm();

  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const typeLevel1Client = new TypeLevel1Client(apiClient)
  const handleSave = async (e) => {
    e.preventDefault()
    try {
      if(isFormValid){
        setLoading(true)
        const response = await contractTemplateClient.import(req.file,"json",req.name, req.level1Id,req.level2Id, req.level3Id);
        if (response.warnings && response.warnings.length > 0) {
          setWarnings(response.warnings);
          setShowWarningsModal(true);
        } else {
         navigate(`/edition-contrat-template/${response?.rows[0]?.id}`);
          onClose();
        }
        toast.success(t("modals.importContractTemplate.messages.success"));
      } else{
        setLoading(false)
        setError(t("modals.createBlankTemplate.error"))
      }
    }
      catch (error) {
        if (error.response && error.response.data) {
          const { errors, warnings } = error.response.data;
          setErrors(errors);
          setWarnings(warnings);
          toast.error(
              t("modals.importContractTemplate.messages.error")
          );
        }
      }
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAll();
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => item.id === value);
    setReq({
      ...req,
      level1Id: selectedLevel1 ? selectedLevel1.id : null,
      level2Id: null,
      level3Id: null,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level2: selectedLevel1 ? selectedLevel1.levels2 : [],
      level3: [],
    }));
    setError("")
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => item.id === value);
    setReq({
      ...req,
      level2Id: selectedLevel2 ? selectedLevel2.id : null,
      level3Id: null,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level3: selectedLevel2 ? selectedLevel2.levels3 : [],
    }));
    setError("")
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => item.id === value);
    setReq({
      ...req,
      level3Id: selectedLevel3 ? selectedLevel3.id : null,
    });
    setError("")
  };
  const validateForm = () => {
    const isValid =
      req.name !== "" &&!templateExistsError&&
      req.level1Id != null &&req.file!==null&&
      (options.level2.length === 0 || req.level2Id != null) &&
      (options.level3.length === 0 || req.level3Id != null);
    setIsFormValid(isValid);
  };
  const templateName = watch("templateName");

  useEffect(() => {
    validateForm();
  }, [req]);
  useEffect(() => {
    if (checkTemplateExistsTimeout) {
      clearTimeout(checkTemplateExistsTimeout);
    }
    setCheckTemplateExistsTimeout(
      setTimeout(async () => {
        try {
          if(req?.name){
            const count = await contractTemplateClient.countAllByFilter({
              name: String(req?.name),
            });
            setTemplateExistsError(count >= 1);
          }
          else{
            setTemplateExistsError(false)
          }
        } catch (error) {
          console.error(error);
        } finally {
          setCheckTemplateExistsTimeout(null);
        }
      }, 0)
    );
  }, [req?.name]);
  return (
    <div>
      {loading ? (
        <PendingAi height="100%" />
      ) : (
        <div ref={modalContentRef}>
          <div className="pb-5" style={{ minHeight: "200px" }}>
          <p className="fw-bolder">
            {t("pages.listener.popups.contract.required")}
          </p>
          <div className="form-input-content">
            <div className="form-input-first-content" style={{ width: "100%" }}>
              <label htmlFor="contractName" className="case-form-labelName">
                {t("pages.listener.popups.contract.inputs.contractName.name")}{" "}
              </label>
              <div className="form-floating mb-3 w-100">
                <input
                  type="text"
                  className="form-control custom-color-input input-popup"
                  id="contractName"
                  placeholder={t(
                    "pages.listener.popups.contract.inputs.contractName.placeholder"
                  )}
                  {...register("templateName", { required: true })}
                  onChange={(e) => {
                    setError("")
                    setReq({ ...req, name: e.target.value });
                    setTemplateExistsError(false) 
                  }}
                />
                  {templateExistsError && (
                    <p className="text-danger py-0 my-0 py-0 my-0">
                      {t("modals.createBlankTemplate.unicityError")}
                    </p>
                  )}
              </div>
            </div>
            <div className="form-input-last-content" style={{ width: "80%" }}>
              <CustomCombobox
                fullWidth
                label={t("pages.pilot.popups.contract.inputs.type1.name")}
                options={options.level1.map((item) => item)}
                optionDisplay={(option) => getName(option, language)}
                optionValue={(option) => option?.id ?? null}
                value={options.level1.find(
                  (option) => option.id == req.level1Id
                )}
                onChange={(value) => {
                  handleLevel1Change(value);
                }}
                isOpen={openCombobox === "level1"}
                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
              />

              {(!req.level1Id || options.level2.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type2.name")}
                  options={options.level2.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  optionValue={(option) => option?.id ?? null}
                  value={options.level2.find(
                    (option) => option.id == req.level2Id
                  )}
                  onChange={(value) => {
                    handleLevel2Change(value);
                  }}
                  isOpen={openCombobox === "level2"}
                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                />
              )}
              {(!req.level1Id ||
                (options.level2.length > 0 && !req.level2Id) ||
                options.level3.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type3.name")}
                  options={options.level3.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  optionValue={(option) => option?.id ?? null}
                  value={options.level3.find(
                    (option) => option.id == req.level3Id
                  )}
                  onChange={(value) => {
                    handleLevel3Change(value);
                  }}
                  isOpen={openCombobox === "level3"}
                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                />
              )}
            </div>
          </div>
          <div style={{marginBottom:"2vh"}}>
          <FileInputAdvanced
            value={req.file}
            label={t("pages.listener.popups.contract.inputs.file.placeholder")}
            onChange={(file) =>
              setReq({
                ...req,
                file,
              })
            }
            className="mt-3 mb-1"
          />
          </div>
           {!isFormValid&&error&&<p style={{color:"red" , fontSize:"13px"}}>{error}</p>}
            <div
              style={{
                maxHeight: "350px",
                overflowY: "auto",
                marginBottom: "8px",
              }}
            >
              {Errors.length > 0 && (
                <div>
                  {Errors?.map((error, index) => (
                    <p
                      key={index}
                      className="alert alert-danger error-after-import"
                      role="alert"
                    >{`Template [${error.templateCode}] : ${error.error}`}</p>
                  ))}
                </div>
              )}
              {Warnings.length > 0 && (
                <div>
                  <p>
                    {Warnings.map((warning, index) => (
                      <p
                        key={index}
                        className="alert alert-warning error-after-import"
                        role="alert"
                      >
                        {t(
                          "modals.importContractTemplate.warnings.message.template"
                        )}{" "}
                        [{warning.templateCode}] : {warning?.message}{" "}
                        <strong>{warning?.paramName}</strong>{" "}
                        {t(
                          "modals.importContractTemplate.warnings.message.clause"
                        )}
                        {warning?.clauseCode}{" "}
                        {t(
                          "modals.importContractTemplate.warnings.message.subclause"
                        )}
                        {warning?.subClauseCode}.
                      </p>
                    ))}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ left: "4.5%" }}
              onClick={handleSave}
            >
               {t("pages.listener.popups.contract.button")}
            </button>
          </div>
        </div>
      )}
      {showWarningsModal && (
        <>
          <div className="modal d-flex justify-content-center align-items-center">
            <div style={{ width: "50%" }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {t("modals.importContractTemplate.warnings.title")}
                  </h5>
                  <button
                    className="btn-close"
                    onClick={() => {
                      setShowWarningsModal(false);
                      onClose();
                    }}
                    aria-label="Close"
                    style={{ marginRight: language == "ar" && "90vh" }}
                  ></button>
                </div>
                <div
                  className="d-flex"
                  style={{
                    maxHeight: "350px",
                    overflowY: "auto",
                    marginBottom: "8px",
                    justifyContent: "center",
                  }}
                >
                  {Warnings?.length > 0 && (
                    <div style={{ width: "90%", height: "" }}>
                      <p>
                        {Warnings?.map((warning, index) => (
                          <p
                            key={index}
                            className="alert alert-warning error-after-import"
                            role="alert"
                          >
                            {t(
                              "modals.importContractTemplate.warnings.message.template"
                            )}{" "}
                            [{warning.templateCode}] : {warning?.message}{" "}
                            <strong>{warning?.paramName}</strong>{" "}
                            {t(
                              "modals.importContractTemplate.warnings.message.clause"
                            )}
                            {warning?.clauseCode},{" "}
                            {t(
                              "modals.importContractTemplate.warnings.message.subclause"
                            )}
                            {warning?.subClauseCode}.
                          </p>
                        ))}
                      </p>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <div
                    className="btn-warning"
                    onClick={() => setShowWarningsModal(false)}
                  >
                    {t("modals.importContractTemplate.warnings.btn-fix")}
                  </div>
                  <div
                    className="btn-warning"
                    onClick={() => {
                      setShowWarningsModal(false);
                      onClose();
                    }}
                  >
                    {t("modals.importContractTemplate.warnings.continue")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ImportJSON;
