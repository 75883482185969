import React, {useContext, useMemo} from 'react'
import EditFormTemplateContext from '../../../contexts/EditFormTemplateContext';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { getName } from '../../../helpers/Translation';

function GroupsForm({ onClose }: { onClose: () => void }) {
  const { t, language } = useTranslation();
  const {groups, formTemplate, setTemplateGroups} = useContext(EditFormTemplateContext);
  const selectedGroups = useMemo(() => formTemplate.groups, [formTemplate]);
  const handleChange = (e, group) => {
    if (e.target.checked) {
      setTemplateGroups(group, true);
  }else{
    setTemplateGroups(group, false);
  }
}
  return (
    <div>
    <div className="generate-ai-portal" style={{ marginTop: "" }}>
      <div className="generate-ai-portal-header">
        <span className='close-dialog' onClick={() => onClose()}>X</span>
      </div>
      <div className="generate-ai-portal-content">
        {groups.map((group, index) => {
          const isChecked = selectedGroups.some(selectedGroup =>
            selectedGroup.id === group.id 
          );

          return (
            <div key={index} className="generate-ai-portal-content-checkbox">
              <input
                type="checkbox"
                id={String(group.id)}
                name={group.name}
                checked={isChecked}
                onChange={(e) => handleChange(e, group)}
              />
              <label className='generate-ai-portal-label' htmlFor={String(group.id)}>{getName(group, language)}</label>
            </div>
          );
        })}
      </div>
    </div>
  </div>
  )
}

export default GroupsForm