import "./sidebar.scss";
import SVGRender from "../../../helpers/SVGRender";
import { icons } from "../../../assets/icons/sidebarIcon";
import { useState } from "react";
import expand from "../../../assets/icons/expand";
import { useTranslation } from "../../../contexts/TranslationProvider";

function SidebarList({ isHovering, text, children, destinations, location }) {
    const [expanded, setExpanded] = useState(false);
    const { language } = useTranslation();
    const handleExpand = () => {
        setExpanded(!expanded);
    }
    const located = destinations?.includes(location);
    const rotate = expanded ? language == "ar" ? "rotate-arabic" : "rotate" : "";
    return (
        <div className="sidebar-list-element">
            <div onClick={handleExpand} className="sidebar-list-header">
                <SVGRender svgContent={icons.ressources} color={"#fff"} />
                {isHovering && <> <span className={`sidebar-list-name ${located && "list-located"}`}>
                    {text}
                    &nbsp;
                </span>
                    <div className={`topic-expand ${rotate}`}>
                        {expand}
                    </div>
                </>
                }
            </div>

            {isHovering && expanded && <div className="sidebar-list-items">
                {children}
            </div>}
        </div>
    );
}

export default SidebarList;
