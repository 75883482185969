import { FormEntity, FormTemplateEntity } from "./entities";
import { FormParam } from "./types/FormParams";


var counter = 0
export const genIdFactory = (code: string) => {
	counter = 0
	return () => `${code}-${++counter}`
}

type RawFormParam = {
	type: FormParam['type']
	| "beneficialCompany"
	| "beneficialPerson"
	| "beneficialCompany[]"
	| "beneficialPerson[]"
	;
	name: FormParam['name']
	label: FormParam['label'],
	args: string;
	condition: string;
}

export function FormParamToRaw(param: FormParam): RawFormParam {
	const condition = param.condition?.map(andCond =>
		`[${andCond.map(cond =>
			`${cond.name}:${cond.value}`
		).join('&')
		}]`
	).join("") ?? ""
	switch (param.type) {
		case 'comment':
		case 'date':
		case 'file':
		case 'number':
		case 'string':
		case 'boolean':
		case 'csv':
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: "",
			}
		case 'beneficial':
			if (!param.args.beneficialTypes)
				param.args = { beneficialTypes: ['Company', 'Person'] }
			return {
				name: param.name,
				label: param.label,
				condition,
				args: "",
				type: (param?.args.beneficialTypes.length == 2) ?
					'beneficial'
					: param?.args.beneficialTypes.includes('Company') ?
						'beneficialCompany'
						: 'beneficialPerson' as any,
			}
		case 'beneficial[]':
			if (!param.args.beneficialTypes)
				param.args = { beneficialTypes: ['Company', 'Person'] }
			return {
				name: param.name,
				label: param.label,
				condition,
				args: "",
				type: (param?.args.beneficialTypes.length == 2) ?
					'beneficial[]'
					: param?.args.beneficialTypes.includes('Company') ?
						'beneficialCompany[]'
						: 'beneficialPerson[]' as any,
			}
		case "enum":
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: param.args.map(arg => `[${arg.option}]`).join("")
			}
		case 'list':
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: param.args.map(arg => `[${arg.required ? "!" : ""}${arg.option}]`).join("")
			}
		case 'table':
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: [
					...(param.transposed ? ["[T]"] : []),
					...param.args.map(arg => `[${arg.header}:${arg.type}]`)
				].join("")
			}
	}
}
export function getAllParams(template: FormTemplateEntity) {
	const params: FormParam[] = []
	template?.pages?.forEach((page) => {
		params.push(...(page.params ?? []))
	})
	return params
}
export function areConditionsMet(
  param: FormParam, 
  inputValues: Required<FormEntity>['paramValues']
): boolean {
  const condition = param.condition;
  if (!condition || !condition.length) {
    return true; 
  }

  for (const andCond of condition) {
    let groupMet = true;
    for (const cond of andCond) {
      groupMet = groupMet && (inputValues[cond.name] === cond.value);
      if (!groupMet) break; 
    }
    if (groupMet) {
      return true; 
    }
  }
  return false;
}
export function getAllRenderedParams(
  params: FormParam[], 
  inputValues: Required<FormEntity>['paramValues']
): FormParam[] {

  const renderedParams = params.filter(param => {
    const isMet = areConditionsMet(param, inputValues);
    return isMet;
  });
  return renderedParams;
}

export function orderRenderedParams(renderedParams: FormParam[]): FormParam[] {
	const orderedParams: FormParam[] = []
	const highLevelParams = renderedParams.filter(param => !param.condition || !param.condition.length)
	const lowLevelParams = renderedParams.filter(param => param.condition && param.condition.length)
	highLevelParams.forEach(param => {
		orderedParams.push(param)
		const dependantParams = lowLevelParams.filter(p => p.condition?.some(andCond => andCond.some(cond => cond.name == param.name)))
		dependantParams.forEach(p => {
			orderedParams.push(p)
		})
	})
	return orderedParams
}
export function getAllRenderedParamNames(params: FormParam[], inputValues: Required<FormEntity>['paramValues']): string[] {
	return getAllRenderedParams(params, inputValues).map(param => param.name)
}

export type ValidationWarning = {
	message: string;
	templateCode: string;
	pageCode: string;
	paramName: string;
}

