import React from 'react'
import useTranslation from '../../../hooks/useTranslation'
import { JustificationContext } from '../../../contexts/JustificationContext'
const scoreColor = (score) => {
    switch (score) {
        case 1:
            return "#45e271"
        case 2:
            return "#FDEBD0"
        case 3:
            return "#F98543"
        case 4:
            return "#e51414"
        default:
            return "#D9D9D9"
    }
}
function RiskElement({ risk, remedialAction, score, index, justification }) {
    const { t } = useTranslation()
    const { setJustification } = React.useContext(JustificationContext);
    const [hovered, setHovered] = React.useState(false)
    return (
        <div
            key={index}
            className='question-container'
            onMouseEnter={() => {
                setHovered(true)
                setJustification(justification || [])
            }}
            onMouseLeave={() => {
                setHovered(false)
                setJustification([])
            }}
            style={{ 
                backgroundColor: hovered ? '#f0f0f0' : 'white',
                borderRadius: '5px',
                padding: "3px"
            }}
        >
            <div className='question-content'>
                <div className='question-text'>
                    {risk}
                </div>
                <div className='question-remidial'>
                    {remedialAction && <> <div className='question-remidial-tag'>
                        {t('pages.document.remedialAction')}
                    </div>
                        <div className='question-remidial-answer'>
                            {remedialAction}
                        </div>
                    </>
                    }
                </div>
            </div>
            <div className='question-scoring'>
                <span className='question-scoring-text'>Score</span>
                <div style={{ backgroundColor: scoreColor(score) }} className='question-scoring-item'>
                    {score}
                </div>
            </div>
        </div>
    )
}

export default RiskElement