import React, { useEffect, useState } from "react";
import '../buttons.scss'
import EntityLookupModal from "../../../modals/EntityLookupModal";
import { ContractDocxExportEntity } from "../../../../domain/entities/ContractDocxExport";
import EventManager from "../../../../services/EventManager";
import { EditContractTemplateContextType } from "../../../../contexts/EditContractTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";

const ContractDocxExportLookupModal = EntityLookupModal<ContractDocxExportEntity>()
function EditContractTemplateSelectDocxExportButton() {
  const [showValidateContractModal, setShowValidateContractModal] = useState(false);
  const [context, setContext] = useState<EditContractTemplateContextType | null>(null)
  const { t, language } = useTranslation();
  const translationPath = t("modals.exportToDocx");
  useEffect(() => {
    EventManager.subscribe("EditContractTemplateContext", setContext)
    return () => {
      EventManager.unsubscribe("EditContractTemplateContext", setContext)
    }
  }, [])
  const { contractTemplate, setContractTemplate } = context || {}

  return (
    <>
      <button className="navbar-button" onClick={() => setShowValidateContractModal(true)}>
        {translationPath.btn}
      </button>
      {showValidateContractModal &&
        <ContractDocxExportLookupModal
          modelName={translationPath.title}
          lookUpField={translationPath.fields.name}
          value={contractTemplate.contractDocxExport}
          onClose={() => {
            setShowValidateContractModal(false)
          }}
          onSave={(contractDocxExport) => {
            console.log(contractDocxExport);
            setContractTemplate((prev) => ({
              ...prev,
              contractDocxExport,
              contractDocxExportId: contractDocxExport?.id ?? null
            }))
          }}
        />
      }
    </>
  )
}

export default EditContractTemplateSelectDocxExportButton;
