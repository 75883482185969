import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import { getAllUsers } from "../../services/api/UserAuth";
import { UserEntity } from "../../domain/entities";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import "./usersPage.scss";
import AddUser from "../../components/modals/AddUser";
import { AiFillDelete } from "react-icons/ai";
import ConfirmDeleteMessage from "../../components/modals/ConfirmDeteleMessage";
import { UserClient } from "../../services/api/UserClient";
import { RestrictedUserClient } from "../../services/api/RestrictedUserClient";
import { RestrictedUserEntity } from "../../domain/entities/RestrictedUser";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { ADMIN_ADD_USER } from "../../config";
const { default: EditIcon } = require("../../assets/edit_icon.svg");

function UsersPage() {
  const { authUser, activeRole } = useContext(AuthUserContext)
  const { t, language } = useTranslation();
  const [users, setUsers] = useState<(UserEntity)[]>([]);
  const [restrictedUsers, setRestrictedUsers] = useState<(RestrictedUserEntity)[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const apiClient = useApiClientWithLoading();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [userToHandle, setUserToHandle] = useState<UserEntity | null>(null);
  const [restricteduserToHandle, setRestrictedUserToHandle] = useState<RestrictedUserEntity | null>(null);
  const userClient = new UserClient(apiClient)
  const restrictedUserClient = new RestrictedUserClient(apiClient)

  const fetchUsers = async () => {
    try {
      const { rows: validUsers } = await userClient.getAll("pure")
      let { rows: restrictedUsers } = await restrictedUserClient.getAll("pure")
      restrictedUsers = restrictedUsers.filter(ru => !validUsers.find(vu => vu.email == ru.email))
      setUsers(validUsers);
      setRestrictedUsers(restrictedUsers)
    } catch (error) {
      console.error("Error fetching users: ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      {loading ? <Loading /> : <section className="users-list w-100">
        <span className="current-pathname">{t("pages.users.title")}</span>
        <div className="table-container">
          <table
            className={`fixed-header-table ${language == "ar" && "arabic-table"
              } `}
          >
            <thead>
              <tr>
                <th>{t("pages.users.firstname")}</th>
                <th>{t("pages.users.lastname")}</th>
                <th>{t("pages.users.email")}</th>
                <th>{t("pages.users.status.label")}</th>
                <th>{t("pages.users.userRole")}</th>
                <th>{t("pages.users.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => (
                <tr key={user.id} id={`user_${user.id}`} className="user-row">
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{t(`pages.users.status.${user.activated}`)}</td>
                  <td> {user?.roles?.map(r => t(`pages.users.userType.${r}`))?.join(" , ") ?? ""}</td>

                  <td>
                    <img
                      src={EditIcon}
                      alt="edit"
                      className="edit-user"
                      onClick={() => { setUserToHandle(user); setShowModal(true) }}
                    />
                    {(activeRole == 'SuperAdmin' || ADMIN_ADD_USER) &&
                      <AiFillDelete
                        className="delete-user"
                        style={{
                          color: "#e5142f",
                          cursor: "pointer",
                          marginTop: "1px",
                          fontSize: "18px"
                        }}
                        onClick={() => { setUserToHandle(user); setConfirmDelete(true) }}
                      />
                    }
                  </td>
                </tr>
              ))}
              {restrictedUsers?.map((user) => (
                <tr key={user.id} id={`user_${user.id}`} className="user-row">
                  <td>{""}</td>
                  <td>{""}</td>
                  <td>{user.email}</td>
                  <td>{t(`pages.users.status.${false}`)}</td>
                  <td>{t(`pages.users.userType.${"Other"}`)}</td>
                  <td>
                    {(activeRole == 'SuperAdmin' || ADMIN_ADD_USER) &&
                      <AiFillDelete
                        className="delete-user"
                        style={{
                          color: "#e5142f",
                          cursor: "pointer",
                          marginTop: "1px",
                          fontSize: "18px"
                        }}
                        onClick={() => { setRestrictedUserToHandle(user); setConfirmDelete(true) }}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {
          (activeRole == 'SuperAdmin' || ADMIN_ADD_USER) &&
          <div className="button-add-new-user">
            <div className="vertical-line me-3"></div>
            <button className="action-add-user" onClick={() => { setUserToHandle({}); setShowModal(true) }}>
              {t("pages.users.addUser")}
            </button>
          </div>
        }
        <div className="import-document-modal">
          {showModal && (
            <AddUser
              user={userToHandle}
              titleEdit="edit user"
              setNewUser={fetchUsers}
              onClose={() => {
                setShowModal(false)
                setUserToHandle(null)
                setRestrictedUserToHandle(null)
              }}
            />
          )}
          {showConfirmDelete && userToHandle && (
            <ConfirmDeleteMessage
              setNewUser={fetchUsers}
              msgDeleteUser={t("pages.users.msgDeleteUser")}
              onClose={() => {
                setConfirmDelete(false)
                setUserToHandle(null)
                setRestrictedUserToHandle(null)
              }}
              user={userToHandle}
            />

          )}
          {showConfirmDelete && restricteduserToHandle && (
            <ConfirmDeleteMessage
              setNewUser={fetchUsers}
              msgDeleteUser={t("pages.users.msgDeleteUser")}
              onClose={() => {
                setConfirmDelete(false)
                setUserToHandle(null)
                setRestrictedUserToHandle(null)
              }}
              restrictedUser={restricteduserToHandle}
            />
          )}
        </div>
      </section>}
    </>
  );
}
export default UsersPage;
