export class EventManager {
    static events: Record<string, ((...args: any[]) => void)[]> = {};
    static subscribe(eventName: string, callback: (...args: any[]) => void) {
        if (!EventManager.events[eventName]) {
            EventManager.events[eventName] = [];
        }
        EventManager.events[eventName].push(callback);
    }
    static unsubscribe(eventName: string, callback: (...args: any[]) => void) {
        if (EventManager.events[eventName]) {
            EventManager.events[eventName] = EventManager.events[eventName].filter(
                (cb) => cb !== callback
            );
        }
    }
    static invoke(eventName: string, ...args: any[]) {
        if (EventManager.events[eventName]) {
            EventManager.events[eventName].forEach((callback) => callback(...args));
        }
    }
};

export default EventManager;
