import React, { useEffect, useRef, useState } from "react";
import "../commonmodal.scss";
import { useTranslation } from "../../../contexts/TranslationProvider";
import Slider from "../../common/SliderWrapper";
import CreateBlankTemplate from "./content/CreateBlankTemplate";
import GenerateWithAi from "./content/GenerateWithAi";
import ImportJSON from "./content/ImportJSON";

function CreateFormTemplateModal({ onClose }) {
  const [typeOfCreation, setTypeOfCreation] = useState("blankTemplate");
  const [selectedType, setSelectedType] = useState("");
  const { t, language } = useTranslation()
  const [loading, setLoading] = useState(false);


  const translationPath=t("pages.admin.actionsOption")
  const options: any[] = [
  { value: "blankTemplate", label: translationPath.blankTemplate },
  { value: "generateAI", label: translationPath.generateAI },
  { value: "importJSON", label: translationPath.importJSON },
];

  const handleComboBoxChange = (field) => (value) => {
    setTypeOfCreation(value)
  };
  useEffect(()=>{
      selectedType==""&&setSelectedType(options[0].value)
  },[selectedType])

  return (
    <>
    <div className="modal-backdrop fade show"></div>
    <div
      id="contractz-lab"
    >
      <div className="modal d-flex justify-content-center align-items-center">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {t("modals.importFormTemplate.title")}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
                style={{ marginRight: language == "ar" && "83%" }}
              ></button>
            </div>
           
              {!loading&&<Slider
              selectedType={selectedType}
              setSelectedType={setSelectedType}
                options={options}
                handleComboBoxChange={handleComboBoxChange}
                language={language} 
              />}
               <div className="modal-body pb-5" style={{ minHeight: "200px" }}>
              {typeOfCreation=="blankTemplate" ? 
              <CreateBlankTemplate onClose={onClose} loading={loading} setLoading={setLoading}/> :
              typeOfCreation=="generateAI" ?
              <GenerateWithAi onClose={onClose} loading={loading} setLoading={setLoading} /> :
              <ImportJSON onClose={onClose} loading={loading} setLoading={setLoading} />
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default CreateFormTemplateModal;
