import React, { useState, useRef, useContext, useEffect } from "react";
import { ClauseEntity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./importConfirmModal.scss"
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { toast } from "react-toastify";
import EventManager from "../../../services/EventManager";

function ClauseConfirmModal({
  onClose,
  currentClause,
  closeParentModal,
  insertIdx
}: {
  onClose: () => void;
  currentClause: ClauseEntity;
  closeParentModal: () => void;
  insertIdx: number;
}) {
  const { contractTemplate, insertImportedClause, onClauseDelete } = useContext(EditContractTemplateContext);
  const [errors, setErrors] = useState({name: ""})
  const [NewName, setNewName] = useState("")
  const { t } = useTranslation();
  const translationPath = "modals.importConfirm."
  const handleInputChange = (event) => {
    setNewName(event.target.value)
  }
  useEffect(() => {
    const nameExists = contractTemplate.clauses.find(clause => clause.name === NewName)
    if (nameExists) {
      setErrors({ name: t(translationPath + "error") })
    } else {
      setErrors({name: ""})
    }
  }, [NewName])

  const handleImport = async () => {
    if (errors.name) return;
    try{
      await insertImportedClause(insertIdx, currentClause.id, NewName)
      toast.success(t(translationPath + "toastsImport.success") )
    }catch(e){
      console.log(e)
      toast.error(t(translationPath + "toastsImport.error") )
    }
    onClose()
    closeParentModal()
  } 
  const handleReplace = async () => {
    try {
      const existingClause = contractTemplate.clauses.find(clause => clause.name === currentClause.name)
    if (existingClause) {
      const existingIdx = contractTemplate.clauses.findIndex(clause => clause.name === currentClause.name)
      const newIdx = existingIdx < insertIdx ? insertIdx - 1 : insertIdx
      await onClauseDelete(existingClause.id, null, false)
      await insertImportedClause(newIdx, currentClause.id, currentClause.name)
      toast.success(t(translationPath + "toastsReplace.success"))
    }
    }catch(e){
      console.log(e)
      toast.error(t(translationPath + "toastsReplace.success"))
    }
    onClose()
    closeParentModal()
  }
  return (
    <>
      <div className="full-screen">
        <div className="modal-wrapper">
          <div className="modal-header">
            <h1 className="modal-title">
            {t(translationPath + "title")}
            </h1>
            <button onClick={onClose}>
              X
            </button>
            </div>
            <div className="modal-body">
              {
                // select a new name or replace the existing clause
              }
             <div className="modal-input">
                <label>{t(translationPath + "label")}</label>
              <input type="text" value={NewName} onChange={handleInputChange} placeholder={t(translationPath + "placeholder")}  />
              {
                errors && errors.name && <span className="error">{errors.name}</span>
              }
             </div>
             <div className="modal-buttons">
              <button className={`${(!NewName || errors.name) && "disabled-confirm-button"}`} disabled={!NewName && !errors.name} onClick={handleImport}>{t(translationPath + "import")}</button>
              <button onClick={handleReplace}>{t(translationPath + "replace")}</button>
             </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default ClauseConfirmModal;
