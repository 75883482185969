import { AxiosInstance } from "axios";
import { API_BASE_URL } from "../../config";
import { RessourceEntity } from "../../domain/entities/Ressource";

export class AnalyticsClient {
  protected apiUrl: string;
  protected mainUrl: string;
  constructor(protected apiClient: AxiosInstance, protected modelName: string) {
    const baseUrl = apiClient.defaults.baseURL
    this.apiUrl = baseUrl || API_BASE_URL
    this.mainUrl = `${this.apiUrl}/Analytics`
  }

  async getAllRessources(): Promise<{ ressources: Pick<RessourceEntity, 'type' | 'status' | 'createdAt'>[] }> {
    const url = new URL(`${this.mainUrl}/ressources`)
    const response = await this.apiClient.post<{ ressources: Pick<RessourceEntity, 'type' | 'status' | 'createdAt'>[] }>(url.href)
    return response.data
  }
}
