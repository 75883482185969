// MainContent.js
import { Route, Routes } from "react-router-dom";

import "./pages/index.scss"
import EditContractRestricted from "./pages/EditContractRestricted";

function RestrictedContent() {
  return (
    <div
      className="main-content"
    >
      <Routes>
        <Route path="/edition-contrat/:contractId" element={<EditContractRestricted />} />
      </Routes>
    </div>
  );
}

export default RestrictedContent;
