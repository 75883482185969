import { AxiosInstance } from "axios";
import { GroupEntity, ProjectEntity, ValidateReq, } from "../../domain/entities";
import { ModelClient } from "../ModelClient";

export class ProjectClient extends ModelClient<ProjectEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Project')
  }
  async validate(id: ProjectEntity['id'], validationData: ValidateReq) {
    const response = await this.apiClient.post<{ count: number, rows: ProjectEntity[] }>(`${this.modelUrl}/${id}/validate`, validationData);
    return response.data;
  }

  async getAssignedGroupProjects(schema: string = 'nested') {
    const reponse = await this.apiClient.get<{ count: number, rows: ProjectEntity[], groups: GroupEntity[] }>(`${this.modelUrl}/assignedGroupProjects?schema=${schema}`);
    return reponse.data;
  }
  async getCommits(id: ProjectEntity['id']) {
    const response = await this.apiClient.get<{ commits: ProjectEntity['commits'], }>(`${this.modelUrl}/${id}/get/commits`);
    return response.data;
  }
}