import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";
import "./fileInput.scss";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
import { useTranslation } from "../../../contexts/TranslationProvider";
import FilePreviewModal from "../../modals/FilePreviewModal";
import { DocumentClient } from "../../../services/api/DocumentClient";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { fetchTextFileUrl } from "../../../helpers/helper";
import { DocumentEntity } from "../../../domain/entities";
const FileInput = forwardRef<any, any>(
  (
    {
      onChange,
      value,
      label,
      id,
      openModal,
      onOpenModal
    }: {
      onChange: (file: File) => void;
      value: File | { name: string };
      label?: string;
      id?: number;
      onOpenModal ?:()=>void,
      openModal:boolean
    },
    ref
  ) => {
    const fileInputRef = useRef(null);
    const { t } = useTranslation();
    const [fileToPreview, setFileToPreview] = useState<DocumentEntity | null>(null);
    const [files, setFiles] = useState<DocumentEntity[] | null>(null);
    const [fileOptions, setFileOptions] = useState<DocumentEntity[]>([]);
    const [selectedFileName, setSelectedFileName] = useState<DocumentEntity["name"]>();
    const apiClient = useApiClientWithLoading();
    const documentClient = new DocumentClient(apiClient);
    const [text, setText] = useState<string>(null);
    const [newFile, setNewFile] = useState<File | null>(null);

    useEffect(() => {
      console.log("FileInput Value:", value);
    }, [value]);
    useEffect(()=>{
      const fetchAllFiles=async()=>{
        const response = await documentClient.getAll();      
        setFiles(response?.rows)
      }
    fetchAllFiles()
    },[])
    const triggerFileInput = useCallback(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }, [fileInputRef]);

    useImperativeHandle(ref, () => ({
      triggerFileInput,
    }));

    const handleFileChange = (e) => {
      const files: FileList = e.target.files;
      const file = files[0];
      if (file) {
        onChange(file);
        setFileToPreview(null);
      }
    };

    const deleteFile = () => {
      onChange(null);
      setText("");
      setFileToPreview(null);
      setSelectedFileName("")
      setNewFile(null)
      setFileOptions([])
    };
    const openFilePreview = async () => {
      setFileToPreview(value as File);
      setSelectedFileName("")
      setFileOptions([])
      onOpenModal();
    };

    const closeModal = () => {
      onOpenModal();
      setFileToPreview(null);
      setSelectedFileName("")
      setFileOptions([])
    };
  
    useEffect(() => {
      const fetchData = async () => {
        if (id && Number(id)) {
          try {
            const row = await documentClient.getById(id);
            setFileToPreview(row);
            if (row?.textFile) {
              const text = await fetchTextFileUrl(row.textFile);
              setText(text);
            }
          } catch (err) {
            console.error("Error fetching document on load:", err);
            if (err.response?.status === 400) {
              setFileToPreview(null);
              setText("");
            }
          }
        } else {
          console.warn("Invalid ID on load:", id);
        }
      };
      fetchData();
    }, [id, value])
    const handleFileLookup = async (query: string) => {
      try {
       if (!query) return;
        const filteredFiles = files.filter(file => 
          file.name.toLowerCase().includes(query.toLowerCase())
        );
        setFileOptions(filteredFiles);
      } catch (error) {
        console.error("Error during file lookup:", error);
        setFileOptions([]);
      }
    };
    const handleFileSelect = async (document: DocumentEntity) => {
      try {
        setFileToPreview(document)
        setSelectedFileName(document.name)
      
      } catch (error) {
        console.error("Error during file selection:", error);
      }
    };
    const handleAddDocument = async (document: File | DocumentEntity) => {
      try {
        if (document instanceof File) {
          onChange(document);
        } else if (document.file && document.name) {
          const response = await fetch(document.file.url);
          const blob = await response.blob();
          
          const file = new File([blob], document.name, {
            type: blob.type,
            lastModified: new Date(document.updatedAt).getTime(),
          });
          onChange(file);
          setSelectedFileName("");
          setFileToPreview(null)
        } else {
          console.error("Invalid document provided:", document);
        }
        onOpenModal && onOpenModal();
      } catch (error) {
        console.error("Error during document addition:", error);
      }
    };
    
    return (
      <div
        className="file-input-component"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 4px 5px 10px",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex ms-1">
          {(value?.name ||selectedFileName)&& (
            <AiFillDelete
              style={{
                marginRight: "0.5rem",
                color: "#2f14e5",
                cursor: "pointer",
              }}
              onClick={deleteFile}
            />
          )}
          <AiOutlineUpload
            style={{
              marginRight: "0.5rem",
              color: "#2f14e5",
              cursor: "pointer",
            }}
            onClick={onOpenModal ?  onOpenModal :triggerFileInput}
          />
        </div>
        <p
          onClick={(value?.name ||selectedFileName)&& openFilePreview}
          className="input-type-file"
          style={{ margin: 0 }}
        >
          {value?.name || selectedFileName|| label || t("pages.editionContract.params.file")}
        </p>
        <input
          type="file"
          style={{ display: "none", border: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
          value={value ? undefined : ""}
        />
        <FilePreviewModal
          isOpen={openModal}
          newFile={newFile}
          setNewFile={setNewFile}
          onRequestClose={closeModal}
          fileDocument={fileToPreview}
          text={text}
          onLookup={!fileToPreview ||(fileToPreview&&selectedFileName)? handleFileLookup :null}
          fileOptions={fileOptions}
          onSelect={handleFileSelect}
          selectedFileName={selectedFileName}
          setSelectedFileName={setSelectedFileName}
          onAddDocument={handleAddDocument}
          setFileToPreview={setFileToPreview}
          files={files}
        />
      </div>
    );
  }
);

export default FileInput;
