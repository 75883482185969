import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationProvider";
import "./createProjectTemplate.scss";
import {
  ProjectTemplateEntity,
  TypeLevel1Entity,
  TypeLevel2Entity,
  TypeLevel3Entity,
} from "../../domain/entities";
import useApiClientWithLoading from "../../services/api/ApiClient";
import CustomCombobox from "../../components/common/CustomCombobox";
import { ValidationResult } from "../../domain/Project";
import { TypeLevel1Client } from "../../services/api/TypeLevel1Client";
import { getName } from "../../helpers/Translation";
import OptionsRessourceProject from "./components/OptionsRessourceProject";
import RessourcesProject from "./components/RessourcesProject";
import { ProjectTemplateClient } from "../../services/api/ProjectTemplateClient";
import { toast } from "react-toastify";
import EventManager from "../../services/EventManager";
const CreateProjectTemplate = () => {
  const { t, language } = useTranslation();
  const [projectNameUnicity, setProjectNameUnicity] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [ressources, setRessources] = useState([]);
  const [projectTemplate, setProjectTemplate] = useState<ProjectTemplateEntity | undefined>(undefined);

  const location = useLocation();
  const { state } = location;
  const [validationData, setValidationData] = useState<
    ValidationResult["errors"]
  >({});
  const navigate = useNavigate()

  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity["id"];
    level2Id: TypeLevel2Entity["id"];
    level3Id: TypeLevel3Entity["id"];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [levels1, setLevels1] = useState<TypeLevel1Entity[]>([]);
  const [loading, setLoading] = useState(false);
  const selectedLevel1 =
    levelsFilter.level1Id && levels1.find((l) => l.id == levelsFilter.level1Id);
  const levels2 = selectedLevel1 && selectedLevel1.levels2;
  const selectedLevel2 =
    levelsFilter.level2Id &&
    levels2?.find((l) => l.id == levelsFilter.level2Id);
  const levels3 = selectedLevel2 && selectedLevel2.levels3;
  const selectedLevel3 =
    levelsFilter.level3Id &&
    levels3?.find((l) => l.id == levelsFilter.level3Id);
  const [data, setData] = useState({
    name: "",
    description: "",
  });
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const apiClient = useApiClientWithLoading();
  const typeLevel1Client = new TypeLevel1Client(apiClient);
  const projectTemplateClient = new ProjectTemplateClient(apiClient);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { rows } = await typeLevel1Client.getAll();
      setLevels1(rows);
      setLoading(false);
    })();
  }, []);
  const createProjectTemplate = async () => {
    try {
      if (!projectTemplate) {
        toast.error(t("pages.createProject.messages.error"))
      }
      const updatedRessources = ressources.map((requirement, index) => ({
        ...requirement,
        index: index + 1
      }));

      const projectData = {
        ...data,
        ...levelsFilter,
        requirements: updatedRessources
      };
      const response = await projectTemplateClient.create(projectData);
      setProjectTemplate(response);
      toast.success(t("pages.createProject.messages.success"))
      navigate(`/edition-project-template/${response.id}`)
    } catch (error) {
      toast.error(t("pages.createProject.messages.error"))
      console.error("Error creating project template:", error);
    }
  };
  useEffect(() => {
    EventManager.subscribe('saveProjectTemplate', createProjectTemplate)
    return () => {
      EventManager.unsubscribe('saveProjectTemplate', createProjectTemplate)
    }
  }, [projectTemplate])

  useEffect(() => {
    const projectData = {
      ...data,
      ...levelsFilter,
      requirements: ressources
    }
    setProjectTemplate(projectData)
  }, [data, levelsFilter, ressources]);
  return (
    <div className="projects px-3 pt-2 w-100">
      <div style={{ marginBottom: "40px", marginTop: "3px" }}>
        <span>
          <span
            className="previous-pathname"
            onClick={() => navigate("/admin")}
          >
            {t("sidebar.admin")}
          </span>
          <span className="path-separator"> {">"} </span>
        </span>
        <span className="current-pathname">
          {" "}
          {t("pages.createProjectTemplate.title")}
        </span>
      </div>
      <section id="create-project" className="create-project">
        <h3 className="create-project-title py-1">
          {t("pages.createProjectTemplate.title")}
        </h3>
        <form className="d-flex form-project-items">
          <div>
            <div className="form-project-item">
              <label className="label-form-create-project">
                {t("pages.createProjectTemplate.projectName")}
              </label>
              <p className="item-description">
                {t("pages.createProjectTemplate.projectNameDescription")}
              </p>
              <input
                type="text"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                  setProjectNameUnicity(true);
                }}
              />
              {validationData.name && !validationData.name.isValid && (
                <div className="text-danger mt-2">
                  {t("pages.createProject.errors.errorNameProject")}
                </div>
              )}
              {!projectNameUnicity && (
                <div className="text-danger mt-2">
                  {t("pages.createProject.errors.errorNameProjectUnicity")}
                </div>
              )}
            </div>
            <div className="form-project-item">
              <label className="label-form-create-project">
                {t("pages.createProjectTemplate.projectDescription")}
              </label>
              <p className="item-description">
                {t("pages.createProjectTemplate.projectSubDescription")}
              </p>
              <textarea
                className="project-description"
                value={data.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div>
            <label className="label-form-create-project">
              {t("pages.createProjectTemplate.projectType")}
            </label>
            <p className="item-description">
              {t("pages.createProjectTemplate.projectTypeDescription")}
            </p>
            <div className="form-project-item">
              <CustomCombobox
                label={t("pages.createProject.typeProject1")}
                options={levels1}
                value={selectedLevel1}
                optionDisplay={(option: TypeLevel1Entity) =>
                  getName(option, language)
                }
                optionValue={(option: TypeLevel1Entity) => option?.id}
                onChange={(level1Id: TypeLevel1Entity["id"]) => {
                  setLevelsFilter({
                    level1Id,
                    level2Id: null,
                    level3Id: null,
                  });
                }}
                isOpen={openCombobox === "typeProject1"}
                setIsOpen={(isOpen) =>
                  setOpenCombobox(isOpen ? "typeProject1" : null)
                }
              />
              {!selectedLevel1 && validationData?.name && (
                <div className="text-danger">
                  {t("pages.createProject.errors.errorLevelProject")}
                </div>
              )}
              {levels2?.length > 0 && (
                <>
                  <CustomCombobox
                    label={t("pages.createProject.typeProject2")}
                    options={levels2}
                    value={selectedLevel2}
                    optionDisplay={(option: TypeLevel2Entity) =>
                      getName(option, language)
                    }
                    optionValue={(option: TypeLevel2Entity) => option?.id}
                    onChange={(level2Id: TypeLevel2Entity["id"]) => {
                      setLevelsFilter({
                        ...levelsFilter,
                        level2Id,
                        level3Id: null,
                      });
                    }}
                    isOpen={openCombobox === "typeProject2"}
                    setIsOpen={(isOpen) =>
                      setOpenCombobox(isOpen ? "typeProject2" : null)
                    }
                  />
                  {!selectedLevel2 && validationData?.name && (
                    <div className="text-danger">
                      {t("pages.createProject.errors.errorLevelProject")}
                    </div>
                  )}
                </>
              )}
              {levels3?.length > 0 && (
                <>
                  <CustomCombobox
                    label={t("pages.createProject.typeProject3")}
                    options={levels3}
                    value={selectedLevel3}
                    optionDisplay={(option: TypeLevel3Entity) =>
                      getName(option, language)
                    }
                    optionValue={(option: TypeLevel3Entity) => option?.id}
                    onChange={(level3Id: TypeLevel3Entity["id"]) => {
                      setLevelsFilter({
                        ...levelsFilter,
                        level3Id,
                      });
                    }}
                    isOpen={openCombobox === "typeProject3"}
                    setIsOpen={(isOpen) =>
                      setOpenCombobox(isOpen ? "typeProject3" : null)
                    }
                  />
                  {!selectedLevel3 && validationData?.name && (
                    <div className="text-danger">
                      {t("pages.createProject.errors.errorLevelProject")}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
        <button onClick={() => setOpenModal(true)}>
          {t("pages.createProjectTemplate.btn")}
        </button>
        {openModal && (
          <OptionsRessourceProject

            projectTemplate={projectTemplate} ressources={ressources} setRessources={setRessources} onClose={() => setOpenModal(false)} />
        )}
        <RessourcesProject ressources={ressources} setRessources={setRessources} />
      </section>
    </div>
  );
};

export default CreateProjectTemplate;
