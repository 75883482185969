import React from "react";
import { getName } from "../../helpers/Translation";
import { useTranslation } from "../../contexts/TranslationProvider";
import ProjectCard from "../../components/common/newDesign/ProjectCard";
import "./groupProjects.scss"

function GroupProjectsList({ projects, groups, reloadProjects }) {
  const { t, language } = useTranslation();
  

  const sortedProjects = React.useMemo(() => projects.map(c=>({...c, type: "Project"})).sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()), [projects]);

  const GroupColumn = (group) => {
    const groupProjects = sortedProjects.filter((project) => project.assignedToGroupId === group.id);
    return (
      <div className="projects-space" key={group.id}>
        <h3 className="m-3 contracts-space-title">{getName(group, language)}</h3>
        <div className="project-group">
          {groupProjects.map((project) => {
            return (
              <div key={project.id} className="m-2 ressource-card">
                <ProjectCard 
                  data={project}
                  setProjectListChanged={reloadProjects}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <section id="projects-list" className="group-projects-list">
      <div className="projects-list-container">
        {groups.map((group) => GroupColumn(group))}
      </div>
    </section>
  );
}

export default GroupProjectsList;
