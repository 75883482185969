import React, { useState, useEffect } from 'react';
import './askCreateAccount.scss';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { migrateRestrictedUser } from '../../../services/api/UserAuth';
import { UserEntity } from '../../../domain/entities';
import { toast } from 'react-toastify';

function AskCreateAccount({ setVisible, restrictedToken }) {
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const { t, language } = useTranslation();
  const [formState, setFormState] = useState<{
    data: UserEntity;
    errors: Partial<UserEntity>;
  }>({
    data: {
      firstName: '',
      lastName: '',
      password: '',
    },
    errors: {},
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  const validate = () => {
    const errors: Partial<UserEntity> = {};
    if (!formState.data.firstName) errors.firstName = t(pathTranslation + "errors.firstname");
    if (!formState.data.lastName) errors.lastName = t(pathTranslation + "errors.lastname");
    if (!formState.data.password) errors.password = t(pathTranslation + "errors.password");
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      console.log('Form Data Submitted:', formState.data);
      toast.success(t(t(pathTranslation + "toasts.success")))
      try {
        const user: UserEntity = {
          ...formState.data,
          roles: ['CoContractor'],
        };
        await migrateRestrictedUser(restrictedToken, user);
        setVisible(false)
      } catch (error) {
        console.error('Error migrating user:', error);
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        errors,
      }));
    }
  };
  const path = "pages.restrictedScreen.modal.";
  const pathTranslation = "pages.restrictedScreen.modalCreateAccount.";


  useEffect(() => {
    const userPreference = localStorage.getItem(`dontAskAgain_${restrictedToken}`);
    if (userPreference === 'true') {
      setVisible(false);
    }
  }, [restrictedToken, setVisible]);

  const handleDontAskAgainChange = (e) => {
    setDontAskAgain(e.target.checked);
  };

  const handleYes = async () => {
    if (dontAskAgain) {
      localStorage.setItem(`dontAskAgain_${restrictedToken}`, 'true');
    }
    setCreateAccount(true)
  };

  const handleNo = () => {
    setVisible(false);
    if (dontAskAgain) {
      localStorage.setItem(`dontAskAgain_${restrictedToken}`, 'true');
    }
  };

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="create-account-question" className='create-account-popup'>
        <div className="modal modal-add-user d-flex justify-content-center align-items-center">
          <div>
            {!createAccount ? (<div className="modal-content">
              <div className="modal-header">
                <h5 className='create-account-header'>{t(path + "title")}</h5>
              </div>
              <div className='content'>
                {t(path + "content")}
              </div>
              <div className="buttons">
                <button className="create-account" onClick={handleYes}>{t(path + "btns.yes")}</button>
                <button className="no-account" onClick={handleNo}>{t(path + "btns.no")}</button>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="dontAskAgain"
                  checked={dontAskAgain}
                  onChange={handleDontAskAgainChange}
                />
                <label htmlFor="dontAskAgain">{t(path + "checkbox")}</label>
              </div>
            </div>) : (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className='create-account-header'>{t(path + "title")}</h5>
                </div>
                <h6 className='content-form'>{t(pathTranslation + "content")}</h6>
                <form onSubmit={handleSubmit} className='form-create-account'>
                  <div className="form-group">
                    <label htmlFor="firstName">{t(pathTranslation + "firstname")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={formState.data.firstName}
                      onChange={handleChange}
                    />
                    {formState.errors.firstName && (
                      <div className="error-message">{formState.errors.firstName}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="lastName">{t(pathTranslation + "lastname")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={formState.data.lastName}
                      onChange={handleChange}
                    />
                    {formState.errors.lastName && (
                      <div className="error-message">{formState.errors.lastName}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">{t(pathTranslation + "password")}</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={formState.data.password}
                      onChange={handleChange}
                    />
                    {formState.errors.password && (
                      <div className="error-message">{formState.errors.password}</div>
                    )}
                  </div>
                  <div className='actions-create-account w-100'>
                    <button type="reset" className="previous-button" onClick={() => setCreateAccount(false)}>
                      {t(pathTranslation + "btns.previous")}
                    </button>
                    <button type="submit" className='submit-create-account'>{t(pathTranslation + "btns.submit")}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AskCreateAccount;
