import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./EditContractRestricted.scss";
import Loading from "../../components/common/Loading";
import { useContractData } from "../../hooks/useContractData";
import EditContract from "../EditContractPage/components/EditContract";
import AskCreateAccount from "../../components/modals/AskCreateAccount";
import { useState } from "react";

function EditContractRestricted() {
  const location = useLocation();
  const [visible, setIsVisible]=useState(true)
  const { contractId } = useParams<{ contractId?: string }>() || {};
  const [searchParams] = useSearchParams();
  const restrictedToken = searchParams.get("restrictedToken")
  const navigate = useNavigate();

  const initialContractName =
    location.state?.contractDataFromModal?.contractName;
  const levelsFilter = location.state?.levelsFilter;

  const {
    templateData,
    isLoading,
    contractName,
    isEditing,
    paramValues,
    excludedClauses,
    excludedSubClauses,
  } = useContractData(
    parseInt(contractId),
    initialContractName,
    levelsFilter,
  );

  const { previousPathname } = location?.state || { previousPathname: [] };

  return (
    <>
    {visible&&<AskCreateAccount restrictedToken={restrictedToken} setVisible={setIsVisible} />}
    <div className="contract-container d-flex justify-content-between align-items-center">
      {isLoading ? (
        <div className="contract-loading-container">
          <Loading height="90vh" />
        </div>
      ) : (
        <div className="edition-contract-page w-100">
          <div className="px-2 container-paths">
            {previousPathname && previousPathname?.map((item, index) => (
              <span key={index}>
                <span className="previous-pathname" onClick={() => navigate(item.link)}>{item.label}</span>
                <span className="path-separator"> {">"} </span>
              </span>
            ))}
            {previousPathname && previousPathname.length > 0 && <span className="current-pathname">{contractName}</span>}
          </div>
          <EditContract
            templateData={templateData}
            contractName={contractName}
            isEditing={isEditing}
            paramValues={paramValues}
            initialExcludedClauses={excludedClauses}
            initialExcludedSubClauses={excludedSubClauses}
          />
        </div>
      )}
    </div>
    </>
  );
}

export default EditContractRestricted;
