import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/common/Loading";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { fetchTextFileUrl, formatDate } from "../../helpers/helper";
import { LoadingContext } from "../../contexts/LoadingContext";
import { DocumentEntity } from "../../domain/entities";
import useTranslation from "../../hooks/useTranslation";
import "./documentPage.scss";
import { DocumentClient } from "../../services/api/DocumentClient";
import EventManager from "../../services/EventManager";
function DocumentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, setLoading } = useContext(LoadingContext);
  const [document, setDocument] = useState<DocumentEntity>(null);
  const [text, setText] = useState<string>(null);
  const { documentId } = useParams<{ documentId?: string }>() || {};
  const { previousPathname } = location?.state || { previousPathname: [] };
  const { t, language, setLanguage } = useTranslation();
  const apiClient = useApiClientWithLoading(setLoading);
  const documentClient = new DocumentClient(apiClient)
  const saveDoc = async () => {
    window.history.length > 1 ? navigate(-1) : navigate("/")
  };
  useEffect(() => {
    (async () => {
      const row = await documentClient.getById(parseInt(documentId));
      const text = await fetchTextFileUrl(row?.textFile);
      setDocument(row);
      setText(text);
      const arabicRegex = /[\u0600-\u06FF]/;
      if (arabicRegex.test(text)) {
        setLanguage('ar');
      }
    })();
    EventManager.subscribe('SaveDoc', saveDoc)
    return () => {
      EventManager.unsubscribe('SaveDoc', saveDoc)
    }
  }, []);
  useEffect(() => {
    if (document?.useCaseResponses?.length === 1) {
      navigate(`/ai-document-response/${document.useCaseResponses[0]?.id}`);
    }
  }, [document]);

  const handleUseCaseClick = (item) => {
    navigate(`/ai-document-response/${item?.id}`, {});
  };

  useEffect(() => {
    const handleDocumentSummary = () => {
      navigate(`/document-summary/${documentId}`);
    };
    EventManager.subscribe("DocumentSummary", handleDocumentSummary);
    return () => {
      EventManager.unsubscribe("DocumentSummary", handleDocumentSummary);
    };
  }, []);

  useEffect(() => {
    EventManager.invoke("hasSummary", !!document?.summary?.id);
  }, [document?.summary]);

  const renderContentBasedOnFileExtension = () => {
    switch (document?.fileExtenstion.toLowerCase()) {
      case "pdf":
        return (
          <div className="document-pdf-viewer">
            <iframe src={document?.file.url} width="100%" height="600px" />
          </div>
        );
      case "docx":
        return (
          <div className="document-docx-viewer">
            <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(document?.file.url)}`} width="100%" height="600px" />
          </div>
        );
      case "jpeg":
      case "jpg":
      case "png":

        return (
          <div className="document-image-viewer">
            <img src={document?.file.url} alt={document?.name} style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        );
      default:
        return (
          <div className="ai-text-container">
            <div className="ai-text">{text}</div>
          </div>
        );
    }
  };

  return document ? (
    <div className="row w-100 document-page-container">
      {previousPathname?.length > 0 && (
        <div className="px-2 container-paths">
          {previousPathname?.map((item, index) => (
            <span key={index}>
              <span className="previous-pathname" onClick={() => navigate(item.link)}>{item.label}</span>
              <span className="path-separator"> {">"} </span>
            </span>
          ))}
          <span className="current-pathname">{document.name}</span>
        </div>
      )}
      <div className="document-page-text-section">
        <h1 className="contract-name">{document.name}</h1>
        {renderContentBasedOnFileExtension()}
      </div>
      {document?.useCaseResponses?.length > 1 && (
        <div className="document-page-use-case-section px-5 mt-2">
          <h4 className="header-useCaseQA">{t("pages.document.usecases")}</h4>
          <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
            {document?.useCaseResponses?.map((item, index) => (
              <div
                key={`${index}_${item?.createdAt}`}
                className="border p-2 m-2"
                style={{ cursor: "pointer" }}
                onClick={() => handleUseCaseClick(item)}
              >
                <h4>{item?.type === "Risk" ? t("pages.document.rsikScoring") : t("pages.document.generalQuestions")}</h4>
                <p>{item?.createdAt ? formatDate(new Date(item?.createdAt).toISOString()) : ""}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  ) : (
    <Loading height="90vh" />
  );
}

export default DocumentPage;
