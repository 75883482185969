import React from "react";
import "./confirmationPopup.scss"; 
import useTranslation from "../../../hooks/useTranslation";

interface ConfirmationPopupProps {
  isVisible: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  isVisible,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation()

  if (!isVisible) return null;


  return (
    <>
    <div className="popup-overlay"></div>
    <div className="modal  d-flex justify-content-center align-items-center">
      <div className="popup-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <button className="confirm" onClick={onConfirm}>
        {t("modals.confirmationSignature.yes")}
        </button>
        <button className="cancel" onClick={onCancel}>
        {t("modals.confirmationSignature.no")}
        </button>
      </div>
      </div>
    </>
  );
};

export default ConfirmationPopup;
