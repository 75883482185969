import React, { useEffect, useState } from "react";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'
import CreateContractModal from "../../../modals/CreateContractModal";
import EventManager from "../../../../services/EventManager";
import { EditFormContextType } from "../../../../contexts/EditFormContext";
import { useSaveForm } from "../../../../hooks/useSubmitForm";

function MigrateToContract() {
  const [showPopUp, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const [formId, setFormId] = useState(null);
  const [editFormContext, setEditFormContext] = useState<EditFormContextType>(null)

  useEffect(() => {
    EventManager.subscribe('EditFormContext', setEditFormContext)
    return () => {
      EventManager.unsubscribe('EditFormContext', setEditFormContext)
    }
  }, [])

  useEffect(() => {
    const path = window.location.pathname;
    const id = path.split("/").pop();
    const parsedId = parseInt(id, 10);
    if (!isNaN(parsedId)) {
      setFormId(parsedId);
    } else {
      console.error('Invalid form ID:', id);
    }
  }, []);
  const saveForm = useSaveForm()
  const handleFormSubmit = async () => {
    saveForm(editFormContext)
    setShowPopup(!showPopUp)
  };

  return <>
    <button className="navbar-button button-migrate" onClick={handleFormSubmit} style={{ position: "relative" }}>
      {t("pages.editionForm.exportToContract")}
    </button>
    {showPopUp && (
      <CreateContractModal
        formId={formId}
        migrateToContract={true}
        onClose={() => setShowPopup(false)}
      />)}
  </>
}

export default MigrateToContract;
