import React, { useState } from "react";
import Loading from "../../common/Loading";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeletePopup.scss";

interface ConfirmDeleteTypeProps {
  onClose: () => void;
  msgDelete?: string;
  entityToDelete?: any;
  onDelete?: () => void;
  onRemove?: () => void;
}

function ConfirmDeletePopup({
  onClose,
  msgDelete,
  entityToDelete,
  onDelete,
  onRemove,
}: ConfirmDeleteTypeProps) {
  const [loading] = useState(false);
  const { t, language } = useTranslation();

  const handleClick = async () => {
    onDelete();
    onClose();
  };
  const handleRemove = async () => {
    onRemove();
    onClose();
  };

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "85%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">
                      {msgDelete +
                        " " +
                        entityToDelete +
                        (language === "ar" ? " ؟" : " ?")}
                    </h1>

                    <div className="modal-buttons">
                      <button
                        className="btn submit-delete"
                        onClick={handleClick}
                      >
                        {t("modals.confirmDelete.delete")}
                      </button>
                      {onRemove ? (
                        <button
                          onClick={handleRemove}
                          className="btn submit-delete"
                          style={{
                            marginLeft: "20px",
                            backgroundColor: "gray",
                          }}
                        >
                       {t("modals.confirmDelete.remove")} 
                        </button>
                      ) : (
                        <button
                          onClick={() => onClose()}
                          className="btn btn-secondary cancel-delete"
                          style={{
                            marginLeft: "20px",
                            backgroundColor: "gray",
                          }}
                        >
                          {t("modals.confirmDelete.cancel")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeletePopup;
