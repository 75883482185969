import "./ourDocs.scss";
import { BsSearch } from "react-icons/bs";
import { useCallback, useEffect, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import GroupContractsList from "./GroupContractsList";
import { useTranslation } from '../../contexts/TranslationProvider';
import { ContractClient } from "../../services/api/ContractClient";
import { FormClient } from "../../services/api/FormClient";
import { ContractEntity } from "../../domain/entities";
import CustomCombobox from "../../components/common/CustomCombobox";
import { isBuffer } from "util";
import form from "../../assets/new-design-icons/Form.svg"
import contract from "../../assets/new-design-icons/Contract.svg"
import LayoutFilter from "../../components/LayoutFilter";
import GroupContractTableItem from "./GroupContractTableItem";

function OurDocuments() {
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const { t, language } = useTranslation();
  const types = [
    {
      name: t("pages.ressources." + "types.contract"),
      id: "Contract",
      icon: contractIcon
    },
    {
      name: t("pages.ressources." + "types.form"),
      id: "Form",
      icon: formIcon
    }
  ]
  const [loading, setLoading] = useState(true);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({ type: null });
  const [groups, setGroups] = useState([]);
  const [ressources, setRessources] = useState([]);
  const [filteredRessources, setFilteredRessources] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const apiClient = apiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const formClient = new FormClient(apiClient)
  const statuses: ContractEntity["status"][] = ['Draft', 'Done', 'Negotiation', 'ToBeSigned', 'Signed']
  const translationPath = "pages.groupContracts."
  const [displayCards, setDisplayCards] = useState("grid");

  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const fetchData = useCallback(async () => {
    try {
      const data = await contractClient.getAssignedGroupContracts();
      const formData = await formClient.getAssignedGroupForms();
      const formresult = formData.rows.map((form) => ({ ...form, type: "Form" }));
      const contrats = data.rows.map((contract) => ({ ...contract, type: "Contract" }));
      const combinedRessources = [...formresult, ...contrats].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      const groups = data.groups;
      setRessources(combinedRessources);
      setFilteredRessources(combinedRessources);
      setGroups(groups);
      setFilteredContracts(contrats);
      setLoading(false); // Set loading to false after contracts are fetched
    } catch (error) {
      console.error("Error fetching contracts: ", error);
      setLoading(false); // Also set loading to false in case of error
    }
  }, []);
  useEffect(() => {
  }, [filteredRessources]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let filteredRessources = ressources;
    if (searchTerm) {
      filteredRessources = ressources.filter((r) =>
        r.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (typeFilter) {
      if (typeFilter === 'Contract') {
        filteredRessources = filteredRessources.filter(r => (r.type === 'Contract' || r.type === 'Summary'));
      } else {
        filteredRessources = filteredRessources.filter(r => r.type.includes(typeFilter));
      }
    }
    setFilteredRessources(filteredRessources);
  }, [searchTerm, appliedFilters]);
  const handleTypeFilterChange = (type) => {
    setAppliedFilters(prev => {
      return { ...prev, type: type }
    });
    setTypeFilter(types.find(t => t.id === type));
  }
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  return (
    <div className="group-contracts-page">
      <section id="contracts" className="pt-2 px-2">
        <span className="current-pathname">{t("pages.groupContracts.title")}</span>
        <div style={{ padding: "20px" }} className="search-zone">
          <div className="ressources-filters">
            <div className="filter-item">
            <CustomCombobox
                canDeselect
                label=""
                onDropdownOpen={() => { }}
                options={types}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                optionValue={(option) => option?.id || null}
                optionDisplay={(option) => (<>
                  {option ? <div className="filter-type-item">
                    <div className='icon'>
                      {
                        option.id === 'Form' ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                          option.id === 'Contract' || option.id === 'ContractAI' || option.id === 'Summary' ? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                              <></>
                      }
                    </div>
                    <span>
                      {option.name}
                    </span>
                  </div> :
                    <span>
                      {t(translationPath + "type")}
                    </span>
                  }
                </>)}
                fullWidth={true}
                isOpen={openCombobox === "ressourceFilter"}
                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "ressourceFilter" : null)}
              />
            </div>
          </div>
          <div className="search-section">
            <div className="search w-75">
              <BsSearch className="search-icon" />
              <input
                type="text"
                placeholder={t("pages.ressources.search")}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
       <LayoutFilter onLayoutChange={handleLayoutChange} />
        <div className="ressources-list">
          {loading ? (
            <div style={{ width: "83vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Loading height="75vh" />
            </div>
          ) :  displayCards=="grid"?(
            <div>
              <GroupContractsList reloadContracts={fetchData} ressources={filteredRessources} groups={groups} />
            </div>
          ):<GroupContractTableItem reloadContracts={fetchData} ressources={filteredRessources} groups={groups} />}
        </div>
      </section>
    </div>

  );
}

export default OurDocuments;