import { AxiosInstance } from "axios";
import {TypeLevel3Entity, UserEntity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";
import { TypeLevel3Stat } from "../../domain/types/TypeLevel";

export class TypeLevel3Client extends ModelClient<TypeLevel3Entity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'TypeLevel3')
  }
  async import(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.apiClient.post<{ rows: TypeLevel3Entity[] }>("TypeLevel/importFromXlsx", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  }
  async getAllWithContractTemplate(language: UserEntity['language']) {
    const response = await this.apiClient.get<{ rows: TypeLevel3Entity[] }>("TypeLevel/withContractTemplate", {
      params: { language }
    });
    return response.data;
  };
  async getAllWithContractAIForm(language: UserEntity['language']) {
    const response = await this.apiClient.get<{ rows: TypeLevel3Entity[] }>("TypeLevel/withContractAIForm", {
      params: { language }
    });
    return response.data;
  };

  async getAllWithFormTemplate(language: UserEntity['language']) {
    const response = await this.apiClient.get<{ rows: TypeLevel3Entity[] }>("TypeLevel/withFormTemplate", {
      params: { language }
    });
    return response.data;
  };
  async getAllWithProjectTemplate() {
    const response = await this.apiClient.get<{ rows: TypeLevel3Entity[] }>("TypeLevel/withProjectTemplate");
    return response.data;
  };
  async getStats() {
    const response = await this.apiClient.get<{ rows: TypeLevel3Stat[] }>(`TypeLevel/stats`);
    return response.data;
  };
  async export() {
    try {
      const response = await this.apiClient.get<{ url: string }>("TypeLevel/exportToXlsx");
      return response.data;
    } catch (error) {
      console.error(`Error exporting types`, error);
      return null;
    }
  };
}