import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
export type LanguageType = 'en' | 'fr' | 'ar'
interface TranslationContextProps {
  language: LanguageType;
  setLanguage: (lang: LanguageType) => void;
  t: (key: any) => any;
  tFromTranslations?: (key: any, translations: any) => any;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

const TranslationProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const storedLanguage = localStorage.getItem('language') || 'fr';
  const [language, setLanguageState] = useState<LanguageType>(storedLanguage as any);

  const setLanguage = (lang: LanguageType) => {
    localStorage.setItem('language', lang);
    setLanguageState(lang);
  };

  const translations = require(`../content/${language}.json`);

  const t = (key) => {
    const keys = key.split('.');
    let result = translations;

    for (const k of keys) {
      if (result && result[k] !== undefined) {
        result = result[k];
      } else {
        return key;
      }
    }

    return result;
  };

  useEffect(() => {
    // Ensure that the language in localStorage is in sync with the state
    const storedLanguage = localStorage.getItem('language') as LanguageType;
    if (storedLanguage && storedLanguage !== language) {
      setLanguageState(storedLanguage);
    }
  }, [language]);

  return (
    <TranslationContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = (): TranslationContextProps => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};

export { TranslationProvider, useTranslation };
