import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { ParamDefinitionEntity } from "../../domain/entities/ParamDefinitionEntity";

export class ParamDefinitionClient extends ModelClient<ParamDefinitionEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ParamDefinition')
  }

}