
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ClauseEntity, ContractEntity, SubClauseEntity } from "../../../domain/entities";
import { findParamBoundaries, getRenderSegments } from "../../../helpers/segmentation";
import { RenderSegments, SegmentedTextType } from "../../../domain/types/ClauseParams";
import { renderStyledSegment } from "../../../helpers/renderSegment";
import SelectionContext from "../../../contexts/SelectionContext";
import "./renderTemplateSegmentedText.scss"
import SlateEditor from "./StaleEditor/SlateEditor";
import { useTranslation } from "../../../contexts/TranslationProvider";
import RenderTemplateSingleSegmentedText from "./RenderTemplateSingleSegmentedText";
import RenderTemplateSegmentedTable from "./RenderTemplateSegmentedTable";
import RenderListSegmentedText from "./RenderListSegmentedText";

interface RenderTemplateSegmentedTextProps {
	inputValues: Record<string, any>;
	clauseId: ClauseEntity['id'];
	segmentation: ClauseEntity['segmentation'];
	subClauseId?: SubClauseEntity['id'];
	fileNames?: ContractEntity['fileNames'];
	beneficialsMap?: ContractEntity['beneficialsMap'];
	segmentsOverrides: Record<string, string>;
	//setSegmentsOverrides: (v: Record<string, string>) => void;
	onSegmentChange: (id: string, text: string) => void
	isSelected: boolean;
	isEditing: boolean;
	formattedRawRef?: React.MutableRefObject<any>;
	setOpenedPopups: (num: any) => void;
	openPopups: Boolean
}
function RenderTemplateSegmentedText({
	clauseId,
	segmentation,
	subClauseId,
	//setSegmentsOverrides,
	segmentsOverrides,
	onSegmentChange,
	isSelected,
	isEditing,
	inputValues,
	setOpenedPopups,
	openPopups
}: RenderTemplateSegmentedTextProps) {

	const { t, language, tFromTranslations } = useTranslation();

	const {
		setSelected,
		selected,
		isClauseEditing,
		setIsClauseEditing,
	} = useContext(SelectionContext);
	const [changedParamBoundaries, setChangedParamBoundaries] = useState<[number, number][]>([])

	useEffect(() => {
		const paramNames = renderSegments.map(seg => (seg as any).paramName).filter(seg => seg)
		if (selected.eventType == 'ParamValueChange' && selected.paramName && paramNames.includes(selected.paramName)) {
			const boundaries = findParamBoundaries(renderSegments, selected.paramName)
			setChangedParamBoundaries(boundaries)
		} else {
			setChangedParamBoundaries([])
		}
	}, [selected]);

	// use usememo for renderSegments
	let renderSegments: RenderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
		{}, {}, segmentation.segmentedParams, t, language)
		.map(seg => {
			return {
				...seg,
				value: seg.value?.replaceAll(/\r\n/g, "\n"),
			}
		})

	const persistentSegments = useMemo(() => {
		const renderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
			{}, {}, segmentation.segmentedParams, t, language)
			.map(seg => {
				return {
					...seg,
					value: seg.value?.replaceAll(/\r\n/g, "\n"),
				}
			})
		const paramNames = renderSegments.map(seg => (seg as any).paramName).filter(seg => seg)
		if (selected.eventType == 'ParamValueChange' && selected.paramName && paramNames.includes(selected.paramName)) {
			const boundaries = findParamBoundaries(renderSegments, selected.paramName)
			return renderSegments.map((seg, idx) => {
				const isHighlighted = boundaries.some((b) => b[0] < idx && idx < b[1])
				return {
					...seg,
					isHighlighted,
				}
			})
		} else {
			return renderSegments
		}
	}, [segmentation.segmentedText, inputValues, segmentation.segmentedParams])

	const filteredSegments = [];
	const currentParagraph = { children: [], alignment: "left" };
	let isInTable = false; // Flag to track if we are inside a table
	let alignment: "left" | "center" | "right" | "justify" = "left";
	let insideList = false;
	let currentListElement = { children: [], alignment: "left" };
	let currentList = { children: [], type: "bulleted-list" };
	renderSegments.forEach((segment, idx) => {
		(segment as any).actualIdx = idx;
		if (segment.type === SegmentedTextType.PARAGRAPH_START) {
			if (currentParagraph.children.length > 0) {
				filteredSegments.push(
					<>
						<span key={segment.id} style={{ textAlign: alignment, display: "inline-block", width: "100%", marginBottom: "10px" }}>
							{currentParagraph.children}
						</span>
					</>
				)
				currentParagraph.children = [];
			}
			alignment = segment.style?.textAlign as "left" | "center" | "right" | "justify" || "left";
			if (insideList) {
				if (currentListElement.children.length > 0) {
					currentList.children.push(currentListElement);
					currentListElement = { children: [], alignment: "left" };
				}
				if (currentList.children.length > 0) {
					filteredSegments.push(
						<RenderListSegmentedText
							list={currentList}
							segmentation={segmentation}
							segmentsOverrides={segmentsOverrides}
							changedParamBoundaries={changedParamBoundaries}
							isSelected={isSelected}
						/>
					)
					currentList = { type: 'bulleted-list', children: [] };
				}
				insideList = false;
			}
		}
		if (segment.type === SegmentedTextType.STATIC_TABLE_START) {
			insideList = false;
			if (currentParagraph.children.length > 0) {
				filteredSegments.push(
					<span key={segment.id} style={{ textAlign: alignment, display: "inline-block", width: "100%" }}>
						{currentParagraph.children}
					</span>
				)
				currentParagraph.children = [];
			}
			// Extract segments for the table separately
			const paramName = segment.paramName;
			const tableEndIndex = renderSegments.findIndex(
				seg => seg.type === SegmentedTextType.STATIC_TABLE_END && seg.paramName === paramName
			);

			// Extract table segments
			const tableSegments = renderSegments.slice(idx, tableEndIndex + 1);

			// Render table component
			const tableComponent = (
				<RenderTemplateSegmentedTable
					key={`table-${paramName}`}
					tableSegments={tableSegments}
					segmentation={segmentation}
					segmentsOverrides={segmentsOverrides}
					changedParamBoundaries={changedParamBoundaries}
					isSelected={isSelected}
					style={{...segment.style}}
				/>
			);
			filteredSegments.push(tableComponent);
			isInTable = true;
			return;
		}
		if (segment.type === SegmentedTextType.STATIC_TABLE_END) {
			isInTable = false;
			return;
		}
		if (insideList && (segment.type === SegmentedTextType.STATIC || segment.type === SegmentedTextType.PARAM || segment.type === SegmentedTextType.COMMENT || segment.type === SegmentedTextType.PARAM_VALUE)) {
      currentListElement.children.push(segment);
      return;
    }
		if (segment.type === SegmentedTextType.LIST_ITEM_START) {
      if(insideList){
        if(currentListElement.children.length > 0){
          currentList.children.push(currentListElement);
          currentListElement = { children: [], alignment: segment?.style?.textAlign };
        }
        const isListNew = segment.style.listType ? true : false;
        if(isListNew){
          if(currentList.children.length > 0){
            filteredSegments.push(
							<RenderListSegmentedText
								list={currentList}
								segmentation={segmentation}
								segmentsOverrides={segmentsOverrides}
								changedParamBoundaries={changedParamBoundaries}
								isSelected={isSelected}
							/>
						)
            currentList = { type: segment.style.listType, children: [] };
          }
        }
      }else{
        if (currentParagraph.children.length > 0) {
					filteredSegments.push(
						<span key={filteredSegments.length} style={{ textAlign: alignment, display: "inline-block", width: "100%" }}>
							{currentParagraph.children}
						</span>
					)
          currentParagraph.children = [];
        }
        currentList = { type: segment?.style?.listType, children: [] };
        currentListElement = { children: [], alignment: segment?.style?.textAlign };
        insideList = true;
      }
    }

		// If we are not inside a table, push the segment to filteredSegments
		if (!isInTable && !insideList) {
			const segmentComponent = (
				<RenderTemplateSingleSegmentedText
					key={segment.id}
					segment={segment}
					segmentsOverrides={segmentsOverrides}
					changedParamBoundaries={changedParamBoundaries}
					isSelected={isSelected}
					segmentation={segmentation}
					idx={idx}
				/>
			);
			currentParagraph.children.push(segmentComponent);
		}
	});

  if(insideList){
    if(currentListElement.children.length > 0){
      currentList.children.push(currentListElement);
      currentListElement = { children: [], alignment: "left" };
    }
    if(currentList.children.length > 0){
      filteredSegments.push(
				<RenderListSegmentedText
					list={currentList}
					segmentation={segmentation}
					segmentsOverrides={segmentsOverrides}
					changedParamBoundaries={changedParamBoundaries}
					isSelected={isSelected}	/>
			)
			currentList = { type: 'bulleted-list', children: [] };
    }
  }
	if (currentParagraph.children.length > 0) {
		filteredSegments.push(
			<span key={filteredSegments.length} style={{ textAlign: alignment, display: "inline-block", width: "100%" }}>
				{currentParagraph.children}
			</span>
		)
		currentParagraph.children = [];
	}

	return (
		<div className="template-render" style={{ marginBottom: '20px', marginTop: "5px" }}>
			{isEditing ? (
				(<>
					<SlateEditor
						clauseId={clauseId}
						subClauseId={subClauseId}
						onSegmentChange={onSegmentChange}
						segments={persistentSegments}
						params={segmentation.segmentedParams}
						setOpenedPopups={setOpenedPopups}
						openPopups={openPopups}
					/>

				</>)) : (<>
					{filteredSegments}
				</>
			)
			}

		</div>
	);
}

export default RenderTemplateSegmentedText;
