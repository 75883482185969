import React, { useState } from 'react'
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'
import ExportTemplateModal from '../../../modals/ExportTemplateModal';

const ExportTemplateButton = () => {
    const { t } = useTranslation();
    const [showExportModal, setShowExportModal] = useState(false);
    return (
        <>
        <button className="navbar-button" onClick={()=>setShowExportModal(!showExportModal)} style={{position:"relative"}}>
        {t("navbar.export")}  &nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path d="M10.334 7.64746V10.3887H1.66599V7.64746H0V11.1945C0 11.6392 0.372427 11.9999 0.834236 11.9999H11.1658C11.6272 11.9999 12 11.6396 12 11.1945V7.64746H10.334Z" fill="white" />
          <path d="M5.84637 7.37581L3.46118 4.58777C3.46118 4.58777 3.09827 4.25632 3.4918 4.25632C3.88534 4.25632 4.83585 4.25632 4.83585 4.25632C4.83585 4.25632 4.83585 4.03336 4.83585 3.68951C4.83585 2.70919 4.83585 0.925079 4.83585 0.197745C4.83585 0.197745 4.78247 0 5.09034 0C5.4007 0 6.76005 0 6.98227 0C7.20407 0 7.1991 0.166522 7.1991 0.166522C7.1991 0.871839 7.1991 2.71759 7.1991 3.66589C7.1991 3.97331 7.1991 4.17266 7.1991 4.17266C7.1991 4.17266 7.96134 4.17266 8.4397 4.17266C8.91723 4.17266 8.55763 4.51972 8.55763 4.51972C8.55763 4.51972 6.52832 7.12563 6.24528 7.39903C6.04168 7.59717 5.84637 7.37581 5.84637 7.37581Z" fill="white" />
        </svg>
      </button>
      {showExportModal ? <ExportTemplateModal type="contractTemplate" onClose={()=>setShowExportModal(false)} />:""}
        </>
    )
}

export default ExportTemplateButton