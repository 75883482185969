import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationProvider';
import "./createProject.scss"
import { BeneficialCompanyEntity, ProjectTemplateEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from '../../domain/entities';
import useApiClientWithLoading from '../../services/api/ApiClient';
import CustomCombobox from '../../components/common/CustomCombobox';
import MatchingTemplates from './MatchingTemplates';
import { ValidationResult } from '../../domain/Project';
import { BeneficialEntity } from '../../domain/entities';
import AddBenificialModel from '../../components/modals/AddBeneficialModal';
import InputValuesContext from '../../contexts/InputValuesContext';
import { TypeLevel1Client } from '../../services/api/TypeLevel1Client';
import { BeneficialClient } from '../../services/api/BeneficialClient';
import { getName } from '../../helpers/Translation';
const CreateProject = () => {
    const { t, language } = useTranslation();
    const [projectNameUnicity, setProjectNameUnicity] = useState(true);
    const location = useLocation();
    const { state } = location;
    const [matchingTemplates, setMatchingTemplates] = useState<ProjectTemplateEntity[]>([]);
    const [validationData, setValidationData] = useState<ValidationResult['errors']>({});
    const [filteredCompanyData, setFilteredCompanyData] = useState<BeneficialCompanyEntity[]>([]);
    const [beneficials, setBeneficials] = useState<BeneficialEntity[]>([]);
    const [selectedBeneficial, setSelectedBeneficial] = useState<BeneficialEntity>();
    const [searchBeneficial, setSearchBeneficial] = useState<string>();
    const [showDropdown, setShowDropdown] = useState(false);
    const [popupBeneficial, setPopupBeneficial] = useState(false);
    const lookup = require("../../assets/lookup.png")



    const [levelsFilter, setLevelsFilter] = useState<{
        level1Id: TypeLevel1Entity['id'];
        level2Id: TypeLevel2Entity['id'];
        level3Id: TypeLevel3Entity['id'];
    }>({
        level1Id: null,
        level2Id: null,
        level3Id: null,
    });
    const [levels1, setLevels1] = useState<TypeLevel1Entity[]>([]);
    const [loading, setLoading] = useState(false);
    const selectedLevel1 = levelsFilter.level1Id && levels1.find(l => l.id == levelsFilter.level1Id)
    const levels2 = selectedLevel1 && selectedLevel1.levels2
    const selectedLevel2 = levelsFilter.level2Id && levels2?.find(l => l.id == levelsFilter.level2Id)
    const levels3 = selectedLevel2 && selectedLevel2.levels3
    const selectedLevel3 = levelsFilter.level3Id && levels3?.find(l => l.id == levelsFilter.level3Id)
    const [data, setData] = useState({
        name: '',
        description: '',
    });
    const [openCombobox, setOpenCombobox] = useState<string | null>(null);
    const apiClient = useApiClientWithLoading(setLoading);
    const beneficialClient = new BeneficialClient(apiClient)
    const typeLevel1Client = new TypeLevel1Client(apiClient)

    useEffect(() => {
        setLoading(true);
        (async () => {
            const { rows } = await typeLevel1Client.getAllWithProjectTemplate();
            setLevels1(rows)
            setLoading(false);
        })();
    }, []);
    useEffect(() => {
        const templates = state.projectTemplate?.filter((template) => {
            return (
                template.level1Id === levelsFilter?.level1Id &&
                template.level2Id === levelsFilter?.level2Id &&
                template.level3Id === levelsFilter?.level3Id
            );
        });
        setMatchingTemplates(templates || []);
    }, [levelsFilter, state?.projectTemplate]);
    const { inputValues, beneficialsMap, setBeneficialsMap, fileNames } = useContext(InputValuesContext);

    useEffect(() => {
        beneficialClient.getAll().then(({ rows }) => {
            setBeneficials(rows.filter((element) => element?.type == "Company"))
        });
    }, []);
    const handleInputChange = (event) => {
        setSearchBeneficial(event.target.value)
        setSelectedBeneficial(null)
        setFilteredCompanyData(
            (beneficials.filter(
                (element) => element?.type == 'Company' && element?.companyName?.toLowerCase().includes(event.target.value))
            ) as BeneficialCompanyEntity[])
        console.log('filter', filteredCompanyData)
        setShowDropdown(true)
    }
    const handleDropdownSelect = (item) => {
        setSearchBeneficial(item?.companyName)
        setSelectedBeneficial(item)
        setShowDropdown(false)
    }
    const handleOpenPopupBeneficial = () => {
        setShowDropdown(false)
        setPopupBeneficial(true)
    }
    useEffect(() => {
        setSearchBeneficial(selectedBeneficial?.name)
    }, [selectedBeneficial])
    return (
        <div className="projects px-3 pt-2 w-100">
            <section id='create-project' className='create-project'>
                <h3 className='create-project-title py-1'>{t("pages.createProject.title")}</h3>
                <form className='d-flex form-project-items'>
                    <div>
                        <div className='form-project-item'>
                            <label className='label-form-create-project'>{t("pages.createProject.projectName")}</label>
                            <p className='item-description'>{t("pages.createProject.projectNameDescription")}</p>
                            <input type='text' value={data.name}
                                onChange={(e) => { setData({ ...data, name: e.target.value }); setProjectNameUnicity(true) }} />
                            {validationData.name && !validationData.name.isValid && (
                                <div className='text-danger mt-2'>{t('pages.createProject.errors.errorNameProject')}</div>
                            )}
                            {!projectNameUnicity && (
                                <div className='text-danger mt-2'>{t('pages.createProject.errors.errorNameProjectUnicity')}</div>
                            )}

                        </div>
                        <div className='form-project-item'>
                            <label className='label-form-create-project'>{t("pages.createProject.projectDescription")}</label>
                            <p className='item-description'>{t("pages.createProject.projectSubDescription")}</p>
                            <textarea className='project-description'
                                value={data.description}
                                onChange={(e) => setData({ ...data, description: e.target.value })}
                            ></textarea>
                        </div>
                        <label className="label-beneficial">{t("pages.createProject.beneficial")}</label>
                        <p className='item-description'>{t("pages.createProject.beneficialDescription")}</p>
                        <div className='project-beneficial-name'>
                            <input
                                type="text"
                                className=""
                                value={searchBeneficial ?? ""}
                                onChange={(event) => handleInputChange(event)}
                            />
                            {selectedBeneficial && selectedBeneficial?.profilePhoto ? <img src={selectedBeneficial?.profilePhoto?.url} className={`selected-beneficial-project-img ${language == "ar" && "selected-beneficial-project-img-arabic"} `} /> : <img src={lookup} className={`lookup-img ${language == "ar" && "lookup-img-arabic"}`} />}
                            {showDropdown && (
                                <div className="list-beneficial-project">
                                    {filteredCompanyData.length > 0 ? filteredCompanyData.map((item, index) => (
                                        <div className="item-beneficial-project" key={index} onClick={() => handleDropdownSelect(item)}>
                                            <span>{item.companyName}</span>
                                            {item?.profilePhoto && <img className='beneficial-project-img' src={item?.profilePhoto?.url} alt='photo' />}
                                        </div>
                                    )) : (
                                        <div className="item-beneficial-project" onClick={() => handleOpenPopupBeneficial()}>
                                            <span className='add-new-project-beneficial'>{t("pages.createProject.addNewBeneficial")}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {popupBeneficial && (
                                <AddBenificialModel
                                    onClose={() => setPopupBeneficial(false)}
                                    beneficialsMap={beneficialsMap}
                                    setBeneficialsMap={setBeneficialsMap}
                                    setSelectedBeneficial={setSelectedBeneficial}
                                    currentBenificialData={{}}
                                    onSave={(beneficialId) => {
                                        // handleInputChange(
                                        //     { target: { value: beneficialId } },
                                        //     param.name,
                                        //     clauseId,
                                        //     subClauseId
                                        // );
                                        setPopupBeneficial(false);
                                    }}
                                    beneficialTypes={['Company', 'Person']}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <label className='label-form-create-project'>{t("pages.createProject.projectType")}</label>
                        <p className='item-description'>{t("pages.createProject.projectTypeDescription")}</p>
                        <div className='form-project-item'>
                            <CustomCombobox
                                label={t("pages.createProject.typeProject1")}
                                options={levels1}
                                value={selectedLevel1}
                                optionDisplay={(option: TypeLevel1Entity) => getName(option, language)}
                                optionValue={(option: TypeLevel1Entity) => option?.id}
                                onChange={(level1Id: TypeLevel1Entity['id']) => {
                                    setLevelsFilter({
                                        level1Id,
                                        level2Id: null,
                                        level3Id: null
                                    })
                                }}
                                isOpen={openCombobox === "typeProject1"}
                                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "typeProject1" : null)}
                            />
                            {!selectedLevel1 && validationData?.name && <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>}
                            {levels2?.length > 0 && (
                                <>
                                    <CustomCombobox
                                        label={t("pages.createProject.typeProject2")}
                                        options={levels2}
                                        value={selectedLevel2}
                                        optionDisplay={(option: TypeLevel2Entity) => getName(option, language)}
                                        optionValue={(option: TypeLevel2Entity) => option?.id}
                                        onChange={(level2Id: TypeLevel2Entity['id']) => {
                                            setLevelsFilter({
                                                ...levelsFilter,
                                                level2Id,
                                                level3Id: null
                                            })
                                        }}
                                        isOpen={openCombobox === "typeProject2"}
                                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "typeProject2" : null)}
                                    />
                                    {!selectedLevel2 && validationData?.name && <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>}
                                </>
                            )}
                            {levels3?.length > 0 && (
                                <>
                                    <CustomCombobox
                                        label={t("pages.createProject.typeProject3")}
                                        options={levels3}
                                        value={selectedLevel3}
                                        optionDisplay={(option: TypeLevel3Entity) => getName(option, language)}
                                        optionValue={(option: TypeLevel3Entity) => option?.id}
                                        onChange={(level3Id: TypeLevel3Entity['id']) => {
                                            setLevelsFilter({
                                                ...levelsFilter,
                                                level3Id,
                                            })
                                        }}
                                        isOpen={openCombobox === "typeProject3"}
                                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "typeProject3" : null)}
                                    />
                                    {!selectedLevel3 && validationData?.name && <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>}
                                </>
                            )}
                        </div>
                    </div>
                </form>
                <MatchingTemplates setProjectNameUnicity={setProjectNameUnicity} selectedBeneficial={selectedBeneficial} projectTemplates={matchingTemplates} dataProject={data} setValidationData={setValidationData} />
            </section>
        </div>
    )
}

export default CreateProject

