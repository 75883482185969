import { BarLoader } from "react-spinners";

import React, { useState, useEffect } from "react";

function Loading({ height = "90vh", withTimer = false }) {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let interval;
    if (withTimer) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [withTimer]); 

  const formatTime = (time) => {
    return `${time}s`;
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center loading-container"
      style={{
        height: height,
      }}
    >
      <BarLoader
        loading={true}
        color="#2f14e5"
        width={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {withTimer && (
        <div className="timer-loading">
          {formatTime(seconds)}
        </div>
      )}
    </div>
  );
}

export default Loading;
