export const icons = {
    "admin": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0H2V16H14V7H7V0Z" fill="#8094AE" />
        <path d="M9 0V5H14L9 0Z" fill="#8094AE" />
    </svg>,
    "templates": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5V3L8 0L15 3V5L8 8L1 5Z" fill="#8094AE" />
        <path d="M8 16L1 13V11L8 14L15 11V13L8 16Z" fill="#8094AE" />
        <path d="M1 9L8 12L15 9V7L8 10L1 7V9Z" fill="#8094AE" />
    </svg>,
    "contracts": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 0H5V12H15V4L11 0Z" fill="#8094AE" />
        <path d="M1 4V16H11V14H3V4H1Z" fill="#8094AE" />
    </svg>,
    "groupContracts": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1H4V11H16V3H11L9 1Z" fill="#8094AE" />
        <path d="M0 5V15H12V13H2V5H0Z" fill="#8094AE" />
    </svg>,
    "ressources": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0H14V16H12L10 14L8 16L6 14L4 16H2V0ZM5 4H11V6H5V4ZM11 8H5V10H11V8Z" fill="#8094AE" />
    </svg>,
    "projects": <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H10V4H4V10H0V0Z" fill="#8094AE" />
        <path d="M16 6H6V16H16V6Z" fill="#8094AE" />
    </svg>,
    "analytics": <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1063_6319)">
            <path d="M4 1V15H18V13H6V1H4Z" fill="#8094AE" />
            <path d="M8 7L10 5L13 8L18 3V11H8V7Z" fill="#8094AE" />
        </g>
    </svg>,
    "listener": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 0H2V4H14V0Z" fill="#8094AE" />
        <path d="M2 6H7V10H2V6Z" fill="#8094AE" />
        <path d="M2 12H7V16H2V12Z" fill="#8094AE" />
        <path d="M9 12H14V16H9V12Z" fill="#8094AE" />
        <path d="M14 6H9V10H14V6Z" fill="#8094AE" />
    </svg>,
    "support": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7 8H6V6H9V11H10V13H7V8ZM9 5V3H7V5H9Z" fill="#8094AE" />
    </svg>,
        "users": <svg width="20" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 2C9.10457 2 10 2.89543 10 4C10 5.10457 9.10457 6 8 6C6.89543 6 6 5.10457 6 4C6 2.89543 6.89543 2 8 2ZM8 14C6.34315 14 4.80429 13.4706 3.63604 12.5503C4.08524 11.3195 5.5767 10.5 8 10.5C10.4233 10.5 11.9148 11.3195 12.364 12.5503C11.1957 13.4706 9.65685 14 8 14Z" fill="#8094AE" />
    </svg>


}