import React from 'react';
import './fallBack.scss';
import { useTranslation } from '../../contexts/TranslationProvider';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const Fallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  const handleReset = () => {
    resetErrorBoundary(); 
    window.history.back(); 
    setTimeout(() => {
      window.location.reload(); 
    }, 100);
  };

  return (
    <div className="fallBack-error-container">
      <h2 className="error-title">{t('fallback.title')}</h2>
      <p className="error-message">{t('fallback.message')}</p>
      <button className="retry-button" onClick={handleReset}>
        {t('fallback.button')}
      </button>
    </div>
  );
};

export default Fallback;
