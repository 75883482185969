import {
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import SelectionContext from "../../../contexts/SelectionContext";
import "./contractTemplateParamList.scss";
import { useForm } from "react-hook-form";
import React from "react";
import ContractTemplateClauseInput from "./ContractTemplateClauseInput";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";


function ContractTemplateParamList({
  inputListRef,
}: {
  inputListRef: React.MutableRefObject<any>,
}) {
  const {
    contractTemplate,
  } = useContext(EditContractTemplateContext);
  const { selected, setSelected } = useContext(SelectionContext);

  const inputGroupRef = useRef(null);


  const handleClick = useCallback(
    (clauseId) => {
      setSelected({
        eventType: 'Param',
        clauseId: clauseId,
        paramName: null,
      });
    },
    [setSelected]
  );

  useEffect(() => {
    if (selected.clauseId && selected.eventType != "ParamValueChange" && inputListRef.current) {
      const elementClassName = `clause-input-${selected.clauseId}`;
      const element = document.getElementsByClassName(elementClassName)[0];

      if (element) {
        setTimeout(() => {
          const container = inputListRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          const newScrollTop = container.scrollTop + relativeTop;
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }, 500);
      }
    }
  }, [selected.clauseId, inputListRef]);



  const form = useForm();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  function onSubmit(e) {
    e.preventDefault();
  }


  return (
    <div className="template-variable-input">
      <form onSubmit={handleSubmit(onSubmit)}>
        {contractTemplate.clauses?.map((clause, clauseIndex) => {
          return (
            <React.Fragment key={clause.id}>
              <ContractTemplateClauseInput
                form={form}
                key={`clause-${clause.id}`}
                clauseIndex={clauseIndex}
                handleClick={handleClick}
                inputGroupRef={inputGroupRef}
                clause={clause}
              />
            </React.Fragment>
          );
        })}
      </form>


    </div>
  );
}
export default ContractTemplateParamList;
