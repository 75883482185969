import React, {useState, useRef} from 'react'
import { useContext } from 'react'
import AdvancedTableContext from '../../contexts/AdvancedTableContext'
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import { useTranslation } from '../../contexts/TranslationProvider'
import { IoIosCloseCircleOutline } from "react-icons/io";
function DateColumnFilter({column}) {
  const { columns, data, sort, setSort, setAppliedFilters, appliedFilters } = useContext(AdvancedTableContext)
  const { t, language } = useTranslation()
  const [filterValue, setFilterValue] = useState(appliedFilters.find(filter => filter.key === column.accessorKey)?.value ?? "")
  const [calendarOpen, setCalendarOpen] = useState(false)
  return (
    <div className="date-filter-container">
     <div>
          <DatePicker
            selected={filterValue}
            onChange={(date) => {
              setFilterValue(date)
              setAppliedFilters((prevFilters) => {
                const newFilters = prevFilters.filter(filter => filter.key !== column.accessorKey)
                if (date) {
                  newFilters.push({ key: column.accessorKey, value: date, type: "date" })
                }
                return newFilters
              })
            }}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            onCalendarClose={() => setCalendarOpen && setCalendarOpen(false)}
            onCalendarOpen={() => {
              setCalendarOpen && setCalendarOpen(true);
            }}
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() + 10))
            }
            placeholderText="JJ/MM/AAAA"
            id={column.accessorKey}
          />
          {
            filterValue ? (<IoIosCloseCircleOutline
              className="input-icon"
              style={{cursor: "pointer", top: "20px", marginRight:language=="ar"&&"150px", color: "gray", pointerEvents: "auto"}}
              onClick={() => {
                setFilterValue("")
                setAppliedFilters((prevFilters) => {
                  return prevFilters.filter(filter => filter.key !== column.accessorKey)
                })
              }}
              />) : (<AiOutlineCalendar className="input-icon" style={{marginRight:language=="ar"&&"150px", top: "20px"}} />)
          }
        </div>
    </div>
  )
}

export default DateColumnFilter