import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
const { default: Delete } = require("../../../../assets/delete.svg");

function EnumRender({ value, page, param, isEditing, valueName, onChange, onChangeName, errors, onDescriptionChange }) {
  const { onOptionChanged, onOptionDelete, onOptionAdd, paramValues, onValueChange } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  const enumOptions = param?.args && param?.args?.map((arg) => arg.option);
  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>

      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterLabel} : {param.label}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            name={param.name}
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} : {param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
      </div>
      <div className=" flex-column align-items-start px-3 pb-1 gap-0">
        {enumOptions?.map((option, index) => (
          <div key={index} className="form-check">
            <div style={{ display: isEditing ? "flex" : "" }}>
              {!isEditing ? (
                <>
                  <input
                    type={"radio"}
                    className={"form-check-input"}
                    value={index}
                    checked={paramValues[param.name] !== undefined && paramValues[param.name] === index}
                    onChange={(event) => {
                      const checked = paramValues[param.name] !== undefined && paramValues[param.name] === index
                      if (!checked) {
                        onValueChange(page.id, param, index)
                      }
                    }}
                  />
                </>
              ) : (
                <textarea
                  className="custom-form-textarea"
                  style={{ height: "50px" }}
                  value={option}
                  onChange={(e) => {
                    onOptionChanged(
                      page.id,
                      param,
                      e.target.value,
                      index
                    );
                  }}
                />
              )}
              {!isEditing ? (
                <label className="form-check-label" htmlFor={String(index)}>
                  {option}
                </label>
              ) : (
                <img
                  alt="img"
                  src={Delete}
                  style={{ marginRight: language == "ar" && "20px" }}
                  className="delete-single-option"
                  onClick={() => onOptionDelete(page.id, param, index)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {isEditing && (
        <p
          className="add-enum-single-option"
          onClick={() => {
            onOptionAdd(page.id, param, {
              option: "",
              text: "",
            });
          }}
        >
          + {t("pages.contractTemplateParam.edition.enum.option")}
        </p>
      )}
    </div>

  )
}

export default EnumRender