import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import Loading from "../../common/Loading";
import { toast } from "react-toastify";
import "./addType.scss";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import {
  TypeLevel1Entity,
  TypeLevel2Entity,
  TypeLevel3Entity,
} from "../../../domain/entities";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { TypeLevel2Client } from "../../../services/api/TypeLevel2Client";
import { TypeLevel3Client } from "../../../services/api/TypeLevel3Client";
import { TypeLevel1Stat } from "../../../domain/types/TypeLevel";
import { normalizeName } from "../../../utils/string";
import AddTranslation from "../../common/AddTranslation";
const { default: Delete } = require("../../../assets/delete.svg");

interface AddTypeProps {
  onClose: () => void;
  titleEdit?: string;
  setNewtype?: (value: boolean) => void;
  type?: any[];
  allTypes: TypeLevel1Stat[];
}

function AddType({ onClose, setNewtype, titleEdit, type = [], allTypes }: AddTypeProps) {
  const [loading, setLoading] = useState(false);
  const [newType2, setAddNewType2] = useState(false);
  const [newType3, setAddNewType3] = useState(false);
  const [level2Deleted, setLevel2Deleted] = useState(false);
  const [errorUnicity, setErrorUnicity] = useState("");
  const [level3Deleted, setLevel3Deleted] = useState(false);
  const apiClient = useApiClientWithLoading();
  const type1Client = new TypeLevel1Client(apiClient);
  const type2Client = new TypeLevel2Client(apiClient);
  const type3Client = new TypeLevel3Client(apiClient);
  const { t, language } = useTranslation();
  const translationAdd = t("pages.typeLevels.addPopup");
  const translationEdit = t("pages.typeLevels.editPopup");
  const [level1, setLevel1] = useState<TypeLevel1Entity>(null);
  const [level2, setLevel2] = useState<TypeLevel2Entity>(null);
  const [level3, setLevel3] = useState<TypeLevel3Entity>(null);
  const [filteredLevel1, setFilteredLevel1] = useState<TypeLevel1Stat[]>([]);
  const [filteredLevel2, setFilteredLevel2] = useState<TypeLevel2Entity[]>([]);
  const [filteredLevel3, setFilteredLevel3] = useState<TypeLevel3Entity[]>([]);
  const [translationsLevel1, setTranslationsLevel1] = useState<TypeLevel1Entity["translations"]>(level1?.translations);
  const [translationsLevel2, setTranslationsLevel2] = useState<TypeLevel2Entity["translations"]>(level2?.translations);
  const [translationsLevel3, setTranslationsLevel3] = useState<TypeLevel3Entity["translations"]>(level3?.translations);

  useEffect(() => {
    setLevel1(type?.length >= 1 ? type[0] : null);
    setLevel2(type?.length >= 2 ? type[1] : null);
    setLevel3(type?.length === 3 ? type[2] : null);
  }, [type]);


  const handleAutocomplete = (inputValue, level) => {
    if (level === 1) {
      const filtered = allTypes?.filter((type) =>
        type.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredLevel1(filtered);
    } else if (level === 2) {
      if (level1) {
        const filtered = level1?.levels2?.filter((type) =>
          type.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredLevel2(filtered);
      }
    } else if (level === 3) {
      if (level2) {
        const filtered = level2?.levels3?.filter((type) =>
          type.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredLevel3(filtered);
      }
    }
  };
  const handleSelectType = (type, level) => {
    if (level === 1) {
      setLevel1(type);
      setFilteredLevel1([]);
    } else if (level === 2) {
      setLevel2(type);
      setFilteredLevel2([]);
    } else if (level === 3) {
      setLevel3(type);
      setFilteredLevel3([]);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (type?.length === 0) {
        if (level1) {
          const existingLevel1 = allTypes.find((t) => normalizeName(t.name) === normalizeName(level1.name));
          const newLevel1 = existingLevel1 ? level1 : await type1Client.create({ ...level1, translations: translationsLevel1 });
          if (newLevel1.id && level2) {
            const existingLevel2 = newLevel1?.levels2?.find((t) => normalizeName(t.name) === normalizeName(level2.name));
            const newLevel2 = existingLevel2 ? level2 : await type2Client.create({
              ...level2,
              level1Id: newLevel1.id,
              translations: translationsLevel2
            });
            if (newLevel2.id && level3) {
              const existingLevel3 = newLevel2?.levels3?.find((t) => normalizeName(t.name) === normalizeName(level3.name));
              if (!existingLevel3) {
                await type3Client.create({
                  ...level3,
                  level2Id: newLevel2.id,
                  translations: translationsLevel3
                });
              }
            }
          }
        }
        toast.success(t(translationAdd.toasts.success));
      } else {
        if (level2Deleted || level3Deleted) {
          toast.success(t(translationEdit.toasts.success));
        }
        else {
          if (level1) {
            await type1Client.update(level1.id, { ...level1, translations: translationsLevel1 });
          }
          if (level2) {
            const existingLevel2 = level1?.levels2?.find((t) => t.id === level2.id);
            const newLevel2 = existingLevel2 ? level2 : await type2Client.create({
              ...level2,
              level1Id: level1.id,
              translations: translationsLevel2,
            });
            await type2Client.update(newLevel2.id, {
              ...level2,
              level1Id: level1.id,
              translations: translationsLevel2,
            });
          }
          if (level3) {
            const existingLevel3 = level2.levels3.find((t) => t.id === level3.id);
            const newLevel3 = existingLevel3 ? level3 : await type3Client.create({
              ...level3,
              level2Id: level2.id,
              translations: translationsLevel3,
            });
            await type3Client.update(newLevel3.id, {
              ...level3,
              level2Id: level2.id,
              translations: translationsLevel3,
            });
          }
          toast.success(t(translationEdit.toasts.success));
        }
      }
      if (setNewtype) {
        setTimeout(() => {
          setNewtype(true);
        }, 0);
      }
      onClose();
    } catch (error) {
      if (error.response?.data?.name === "SequelizeUniqueConstraintError") {
        setErrorUnicity(t(translationAdd.exists));
      }
      toast.error(type?.length === 0 ? t(translationAdd.toasts.error) : t(translationEdit.toasts.error));
      console.log('error :>> ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLevel2 = async () => {
    setAddNewType2(false);
    if (level2 && type?.length >= 2) {
      const { deleted } = await type2Client.delete(level2?.id);
      if (deleted) {
        setLevel2Deleted(true);
      }
    }
    setLevel2(null);

  };

  const handleDeleteLevel3 = async () => {
    setAddNewType3(false);
    setAddNewType2(true)
    if (level3 && type?.length === 3) {
      const { deleted } = await type3Client.delete(level3?.id);
      if (deleted) {
        setLevel3Deleted(true);
      }
    }
    setLevel3(null);
  };
  const handleLanguageChangeLevel1 = (updatedTranslations) => {
    setTranslationsLevel1(updatedTranslations);
  };

  const handleLanguageChangeLevel2 = (updatedTranslations) => {
    setTranslationsLevel2(updatedTranslations);
  };

  const handleLanguageChangeLevel3 = (updatedTranslations) => {
    setTranslationsLevel3(updatedTranslations);
  };
  const handleDeleteTranslationLevel1 = (index) => {
    setTranslationsLevel1(translationsLevel1.filter((_, i) => i !== index));
  };

  const handleDeleteTranslationLevel2 = (index) => {
    setTranslationsLevel2(translationsLevel2.filter((_, i) => i !== index));
  };

  const handleDeleteTranslationLevel3 = (index) => {
    setTranslationsLevel3(translationsLevel3.filter((_, i) => i !== index));
  };
  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="type-add-popup" className="type-add-popup">
        <div className="modal modal-add-type d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {type?.length > 0
                    ? t(translationEdit.title)
                    : t(translationAdd.title)}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" && "75%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-add-type">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group" style={{ position: "relative" }}>
                      <label htmlFor="level1">{t(translationAdd.type1)}</label>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control"
                          id="level1"
                          name="level1"
                          value={level1?.name || ""}
                          onChange={(e) => {
                            setLevel1({ ...level1, name: e.target.value });
                            setErrorUnicity("");
                            handleAutocomplete(e.target.value, 1);
                          }}
                        />
                      </div>
                      {filteredLevel1?.length > 0 && (
                        <ul className="autocomplete-list">
                          {filteredLevel1.map((type) => (
                            <li
                              id={`type_${type.name}`}
                              key={type.id}
                              onClick={() => handleSelectType(type, 1)}
                            >
                              {type.name}
                            </li>
                          ))}
                        </ul>
                      )}
                      <AddTranslation translationList={level1?.translations} handleLanguageChange={handleLanguageChangeLevel1}
                        handleDeleteTranslation={handleDeleteTranslationLevel1}
                      />
                      {!newType2 && !level2 && (
                        <button
                          className="btn-handler-type"
                          onClick={() => setAddNewType2(true)}
                        >
                          +
                        </button>
                      )}
                    </div>
                    {(level2 || level3 || newType2) && (
                      <div className="form-group" style={{ position: "relative" }}>
                        <label htmlFor="level2">
                          {t(translationAdd.type2)}
                        </label>
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "space-between",
                            width: ((newType2) || (!newType3 && type?.length !== 3)) && "105%",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="level2"
                            name="level2"
                            value={level2?.name || ""}
                            onChange={(e) => {
                              setLevel2({ ...level2, name: e.target.value });
                              setErrorUnicity("");
                              handleAutocomplete(e.target.value, 2);
                            }}
                          />
                          {((level2 && !newType3 && type?.length !== 3) || (newType2 && !newType3)) && (
                            <img
                              alt="img-delete"
                              src={Delete}
                              className="remove-type-level"
                              onClick={() => handleDeleteLevel2()}
                            />
                          )}
                        </div>
                        {filteredLevel2?.length > 0 && (
                          <ul className="autocomplete-list">
                            {filteredLevel2.map((type) => (
                              <li
                                id={`type_${type.name}`}
                                key={type.id}
                                onClick={() => handleSelectType(type, 2)}
                              >
                                {type.name}
                              </li>
                            ))}
                          </ul>
                        )}
                        <AddTranslation translationList={level2?.translations} handleLanguageChange={handleLanguageChangeLevel2}
                          handleDeleteTranslation={handleDeleteTranslationLevel2}
                        />
                        {!newType3 && !level3 && (
                          <button
                            className="btn-handler-type"
                            onClick={() => setAddNewType3(true)}
                          >
                            +
                          </button>
                        )}
                      </div>
                    )}
                    {(level3 || newType3) && (
                      <div className="form-group" style={{ position: "relative" }}>
                        <label htmlFor="level3">
                          {t(translationAdd.type3)}
                        </label>
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "space-between",
                            width: "105%",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="level3"
                            name="level3"
                            value={level3?.name || ""}
                            onChange={(e) => {
                              setLevel3({ ...level3, name: e.target.value });
                              setErrorUnicity("");
                              handleAutocomplete(e.target.value, 3);
                            }}
                          />
                          {(level3 || newType3) && (
                            <img
                              alt="img-delete"
                              src={Delete}
                              className="remove-type-level"
                              onClick={() => handleDeleteLevel3()}
                            />
                          )}
                        </div>
                        {filteredLevel3?.length > 0 && (
                          <ul className="autocomplete-list">
                            {filteredLevel3.map((type) => (
                              <li
                                id={`type_${type.name}`}
                                key={type.id}
                                onClick={() => handleSelectType(type, 3)}
                              >
                                {type.name}
                              </li>
                            ))}
                          </ul>
                        )}

                        <AddTranslation translationList={level3?.translations} handleLanguageChange={handleLanguageChangeLevel3}
                          handleDeleteTranslation={handleDeleteTranslationLevel3}
                        />

                      </div>
                    )}
                    {errorUnicity && (
                      <p
                        className="mt-3"
                        style={{ color: "red", fontSize: "14px" }}
                      >
                        {errorUnicity}
                      </p>
                    )}
                    <div className="actions-popup-type">
                      <button type="submit" className="add-signle-type">
                        {type?.length > 0
                          ? t(translationEdit.btns.edit)
                          : t(translationAdd.btns.add)}
                      </button>
                      <button
                        type="reset"
                        className="cancel-adding-type"
                        onClick={onClose}
                      >
                        {t(translationAdd.btns.cancel)}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddType;
