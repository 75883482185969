import { AxiosInstance } from "axios";
import { FormTemplateEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity, UserEntity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";
import { GenerateTemplateFromDocumentRequest } from "../../domain/ContractTemplate";

export class FormTemplateClient extends ModelClient<FormTemplateEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'FormTemplate')
  }
  async activate(id: FormTemplateEntity['id']) {
    try {
      const response = await this.apiClient.post<{ row: FormTemplateEntity }>(`${this.modelUrl}/activate`, {}, {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }
  async deactivate(id: FormTemplateEntity['id']) {
    try {
      const response = await this.apiClient.post<{ row: FormTemplateEntity }>(`${this.modelUrl}/deactivate`, {}, {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }


  async import(file: File, type: 'xlsx' | 'json' = 'xlsx', name?: string, level1Id?: TypeLevel1Entity['id'],
    level2Id?: TypeLevel2Entity['id'],
    level3Id?: TypeLevel3Entity['id'],) {
    const url = type == 'xlsx' ? new URL(this.modelUrl + "/importFromXlsx")
      : new URL(this.modelUrl + "/importFromJSON")
    const formData = new FormData();
    formData.append("file", file);
    url.searchParams.append("name", name);
    url.searchParams.append("level1Id", level1Id.toString());
    level2Id&&url.searchParams.append("level2Id", level2Id.toString());
    level3Id&&url.searchParams.append("level3Id", level3Id.toString());
    const response = await this.apiClient.post<{
      errors: any[],
      rows: {
        id: FormTemplateEntity['id'], name: FormTemplateEntity['name'],
      }[]
    }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async export(id: FormTemplateEntity['id'], type: 'xlsx' | 'json' = 'xlsx') {
    try {
      const url = type == 'xlsx' ? new URL(this.modelUrl + "/exportToXlsx")
        : new URL(this.modelUrl + "/exportToJSON")
      url.searchParams.append("id", String(id))

      const response = await this.apiClient.get<{ url: string }>(url.href);
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  }

  async select(level1Id: TypeLevel1Entity['id'],
    level2Id: TypeLevel2Entity['id'],
    level3Id: TypeLevel3Entity['id'],
    language: UserEntity['language'],
    schema: string = "full") {
    try {
      console.log(level1Id, level2Id, level3Id)
      const response = await this.apiClient.get<{ row: FormTemplateEntity }>(`${this.modelUrl}/select`, {
        params: {
          level1Id,
          level2Id,
          level3Id,
          language,
          schema,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error selecting template : ${[
        level1Id,
        level2Id,
        level3Id,]}`, error);
      return null;
    }
  }

  async generateFromXLSX(req: GenerateTemplateFromDocumentRequest) {
    const url = new URL(this.modelUrl + "/generateFromXLSX")

    const formData = new FormData();
    formData.append("file", req.file);
    req.name && formData.append("name", req.name);
    req.level1Id && formData.append("level1Id", String(req.level1Id));
    req.level2Id && formData.append("level2Id", String(req.level2Id));
    req.level3Id && formData.append("level3Id", String(req.level3Id));
    const response = await this.apiClient.post<{ row: FormTemplateEntity }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async addPage(formTemplateId: FormTemplateEntity['id'], pageName: string, index: string) {
    const response = await this.apiClient.post<{ row: FormTemplateEntity }>(`${this.modelUrl}/addPage`, {
      id: formTemplateId,
      name: pageName,
      index,
    });
    return response.data.row;
  }
  async removePage(formTemplateId: FormTemplateEntity['id'], index: string) {
    const response = await this.apiClient.post<{ row: FormTemplateEntity }>(`${this.modelUrl}/removePage`, {
      id: formTemplateId,
      index,
    });
    return response.data.row;
  }
}