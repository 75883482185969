import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import {
  AiFillCheckSquare,
  AiFillDelete,
  AiFillEdit,
  AiFillPlusSquare,
  AiFillSave,
} from "react-icons/ai";
import "./ContractDocxExportPage.scss";
import { toast } from "react-toastify";
import { LoadingContext } from "../../contexts/LoadingContext";
import { ContractDocxExportEntity } from "../../domain/entities/ContractDocxExport";
import { ContractDocxExportClient } from "../../services/api/ContractDocxExportClient";
import FileInputAdvanced from "../../components/common/FileInputAdvanced";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";

type ContractDocxExportReq = ContractDocxExportEntity & {
  file?: ContractDocxExportEntity["file"] | (Buffer & { name: string });
};

function ContractDocxExportPage() {
  const { t, language } = useTranslation();
  const [docxExports, setdocxExports] = useState<ContractDocxExportEntity[]>(
    []
  );
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [itemToHandle, setItemToHandle] =
    useState<ContractDocxExportReq | null>(null);

  const [newDocxExport, setNewDocxExport] = useState<ContractDocxExportReq>({});
  const [editDocxExport, setEditDocxExport] =
    useState<ContractDocxExportReq | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const contractDocxExportClient = new ContractDocxExportClient(
    useApiClientWithLoading()
  );

  const { isLoading, setLoading } = useContext(LoadingContext);

  const refresh = async () => {
    const { rows } = await contractDocxExportClient.getAll();
    setdocxExports(rows);
  };
  useEffect(() => {
    refresh();

    return () => {};
  }, []);

  const HandleActivate = async (id: ContractDocxExportEntity["id"]) => {
    try {
      setLoading(true);
      await contractDocxExportClient.setDefault(id);
      toast.success(t("pages.docxExport.popup.messages.successCreate"));
      refresh();
      setLoading(false);
    } catch (err) {
      toast.error(t("pages.docxExport.popup.messages.errorDelete"));
      setLoading(false);
    }
  };

  const HandleDeleteDocxExport = async (id: ContractDocxExportEntity["id"]) => {
    try {
      setLoading(true);
      const { deleted } = await contractDocxExportClient.delete(id);
      if (deleted) {
        toast.success(t("pages.docxExport.popup.messages.successCreate"));
        setLoading(false);
        refresh();
      } else {
        throw "";
      }
    } catch (err) {
      toast.error(t("pages.docxExport.popup.messages.errorDelete"));
      setLoading(false);
    }
  };

  const handleCreateNewDocxEcport = async () => {
    try {
      setLoading(true);
      await contractDocxExportClient.createMultiForm(newDocxExport);
      toast.success(t("pages.docxExport.popup.messages.successCreate"));
      setNewDocxExport({});
      setLoading(false);
      refresh();
    } catch (err) {
      toast.error(t("pages.docxExport.popup.messages.errorCreate"));
      setLoading(false);
    }
  };
  const handleSaveEditDocxExport = async (
    toUpdate: ContractDocxExportEntity
  ) => {
    try {
      setLoading(true);
      await contractDocxExportClient.updateMultiForm(
        toUpdate.id,
        editDocxExport
      );
      toast.success(t("pages.docxExport.popup.messages.successUpdate"));
      setEditDocxExport(null);
      setLoading(false);
      refresh();
    } catch (err) {
      toast.error(t("pages.docxExport.popup.messages.errorUpdate"));
      setLoading(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="beneficials-list w-100">
          <span className="current-pathname">
            {t("pages.docxExport.title")}
          </span>
          <div className="table-container">
            <table
              className={`fixed-header-table ${
                language === "ar" && "arabic-table"
              }`}
            >
              <thead>
                <tr>
                  <th>{t("pages.docxExport.table.headers.name")}</th>
                  <th>{t("pages.docxExport.table.headers.file")}</th>
                  <th>{t("pages.docxExport.table.headers.preambule")}</th>
                  <th>{t("pages.docxExport.table.headers.signature")}</th>
                  <th>{t("pages.docxExport.table.headers.actions")}</th>
                </tr>
              </thead>

              <tbody>
                <tr className="data-table-types">
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <input
                    name={newDocxExport.name}
                    className="nameDocx"
                      value={newDocxExport.name}
                      onChange={(e) => {
                        setNewDocxExport({
                          ...newDocxExport,
                          name: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <FileInputAdvanced
                      label={t(
                        "pages.listener.popups.contract.inputs.file.placeholder"
                      )}
                      onChange={(file) =>
                        setNewDocxExport({
                          ...newDocxExport,
                          file: file,
                        })
                      }
                      value={
                        (newDocxExport?.file as any)?.name
                          ? newDocxExport?.file
                          : { name: newDocxExport?.file?.key }
                      }
                      borderClassName={
                        newDocxExport?.file ? "green-border" : "grey-border"
                      }
                      className="mt-3 mb-1"
                    />
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="preamble"
                      checked={newDocxExport.hasPreamble}
                      onChange={(e) => {
                        setNewDocxExport({
                          ...newDocxExport,
                          hasPreamble: e.target.checked,
                        });
                      }}
                    />
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="signature"
                      checked={newDocxExport.hasSignature}
                      onChange={(e) => {
                        setNewDocxExport({
                          ...newDocxExport,
                          hasSignature: e.target.checked,
                        });
                      }}
                    />
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <AiFillPlusSquare
                      className="create-docx"
                      style={{
                        color: "#2f14e5",
                        cursor: "pointer",
                        marginTop: "1px",
                        fontSize: "18px",
                      }}
                      onClick={handleCreateNewDocxEcport}
                    />
                  </td>
                </tr>
                {docxExports.map((de) => {
                  const isEditing = de.id == editDocxExport?.id;
                  return (
                    <React.Fragment key={`tag_${de.id}`}>
                      <tr
                        className={`data-table-types ${
                          de.isDefault ? "default-row" : ""
                        }`}
                      >
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {isEditing ? (
                            <input
                            id={`input_${editDocxExport.name}`}
                              value={editDocxExport.name}
                              onChange={(e) => {
                                setEditDocxExport({
                                  ...editDocxExport,
                                  name: e.target.value,
                                });
                              }}
                            />
                          ) : (
                            de?.name ?? <i>NULL</i>
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {isEditing ? (
                            <FileInputAdvanced
                              label={t(
                                "pages.listener.popups.contract.inputs.file.placeholder"
                              )}
                              onChange={(file) =>
                                setEditDocxExport({
                                  ...editDocxExport,
                                  file: file,
                                })
                              }
                              value={
                                (editDocxExport?.file as any)?.name
                                  ? editDocxExport?.file
                                  : { name: editDocxExport?.file?.key }
                              }
                              borderClassName={
                                editDocxExport?.file
                                  ? "green-border"
                                  : "grey-border"
                              }
                              className="mt-3 mb-1 file"
                            />
                          ) : (
                            <a href={de?.file?.url} className="fileName">{de?.file?.key}</a>
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {isEditing ? (
                            <input
                              type="checkbox"
                              className="preamble"
                              checked={editDocxExport.hasPreamble}
                              onChange={(e) => {
                                setEditDocxExport({
                                  ...editDocxExport,
                                  hasPreamble: e.target.checked,
                                });
                              }}
                            />
                          ) : de?.hasPreamble ? (
                            t("pages.docxExport.data.yes")
                          ) : (
                            t("pages.docxExport.data.no")
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {isEditing ? (
                            <input
                              type="checkbox"
                               className="signature"
                              checked={editDocxExport.hasSignature}
                              onChange={(e) => {
                                setEditDocxExport({
                                  ...editDocxExport,
                                  hasSignature: e.target.checked,
                                });
                              }}
                            />
                          ) : de?.hasSignature ? (
                            t("pages.docxExport.data.yes")
                          ) : (
                            t("pages.docxExport.data.no")
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {isEditing ? (
                            <AiFillSave
                            className="save-docx"
                              style={{
                                color: "#2f14e5",
                                cursor: "pointer",
                                marginTop: "1px",
                                fontSize: "18px",
                              }}
                              onClick={() => handleSaveEditDocxExport(de)}
                            />
                          ) : (
                            <AiFillEdit
                            className="edit-docx"
                              style={{
                                color: "#2f14e5",
                                cursor: "pointer",
                                marginTop: "1px",
                                fontSize: "18px",
                              }}
                              onClick={() => {
                                setEditDocxExport(de);
                              }}
                            />
                          )}
                          <AiFillDelete
                            className="delete-docx"
                            style={{
                              color: "#e5142f",
                              cursor: "pointer",
                              marginTop: "1px",
                              fontSize: "18px",
                              marginLeft: "25px",
                            }}
                            onClick={() => {
                              setItemToHandle(de);
                              setConfirmDelete(true);
                            }}
                          />
                                            
                          {!de.isDefault && (
                            <AiFillCheckSquare
                            className="check-docx"
                              style={{
                                color: "#2f14e5",
                                cursor: "pointer",
                                marginTop: "1px",
                                fontSize: "18px",
                              }}
                              onClick={() => {
                                HandleActivate(de.id);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="button-add-new-beneficial">
            <div className="vertical-line me-3"></div>
          </div>
          {showConfirmDelete && itemToHandle && (
              <ConfirmDeletePopup
                msgDelete={t(
                  t("pages.docxExport.msgDelete")
                )}
                onClose={() => {
                  setConfirmDelete(false);
                  setItemToHandle(null);
                }}
                entityToDelete={(itemToHandle?.name || "")}
                onDelete={()=>  
                  HandleDeleteDocxExport(itemToHandle.id)
                }
              />
            )}
        </section>
      )}
    </>
  );
}

export default ContractDocxExportPage;
