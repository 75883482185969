import { useEffect, useRef, useState } from "react";
import "./importJsonProject.scss"
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PendingAi from "../../common/newDesign/PendingAi";
import CustomCombobox from "../../common/CustomCombobox";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { useForm } from "react-hook-form";
import {
  TypeLevel1Entity,
  TypeLevel2Entity,
  TypeLevel3Entity,
} from "../../../domain/entities";
import { getName } from "../../../helpers/Translation";
import FileInputAdvanced from "../../common/FileInputAdvanced";
import { ProjectTemplateClient } from "../../../services/api/ProjectTemplateClient";

function ImportJsonProject({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>(null);
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [templateExistsError, setTemplateExistsError] = useState(false);
  const [checkTemplateExistsTimeout, setCheckTemplateExistsTimeout] =
    useState<NodeJS.Timeout>(null);
  const { t, language } = useTranslation();
  const modalContentRef = useRef(null);
  const [req, setReq] = useState({
    name: "",
    file: file,
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[];
    level2: TypeLevel2Entity[];
    level3: TypeLevel3Entity[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const { watch, register } = useForm();
  const apiClient = useApiClientWithLoading();
  const projectTemplateClient = new ProjectTemplateClient(apiClient);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const typeLevel1Client = new TypeLevel1Client(apiClient);
  const translationPath = "modals.importJsonProject.";
  const navigate = useNavigate();
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (isFormValid) {
        setLoading(true);
        const response = await projectTemplateClient.import(
          req?.file,
          "json",
          req.name,
          req.level1Id,
          req.level2Id,
          req.level3Id
        );
        onClose();
        navigate(`/edition-project-template/${response?.rows[0]?.id}`);
        toast.success(t(translationPath+"toasts.success"));
      } else {
        setLoading(false);
        setError(t(translationPath+"toasts.errorValidation"));
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(translationPath+"toasts.error"));
    }
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAll();
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => item.id === value);
    setReq({
      ...req,
      level1Id: selectedLevel1 ? selectedLevel1.id : null,
      level2Id: null,
      level3Id: null,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level2: selectedLevel1 ? selectedLevel1.levels2 : [],
      level3: [],
    }));
    setError("");
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => item.id === value);
    setReq({
      ...req,
      level2Id: selectedLevel2 ? selectedLevel2.id : null,
      level3Id: null,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level3: selectedLevel2 ? selectedLevel2.levels3 : [],
    }));
    setError("");
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => item.id === value);
    setReq({
      ...req,
      level3Id: selectedLevel3 ? selectedLevel3.id : null,
    });
    setError("");
  };
  const validateForm = () => {
    const isValid =
      req.name !== "" &&
      !templateExistsError &&
      req.file !== null &&
      req.level1Id != null &&
      (options.level2.length === 0 || req.level2Id != null) &&
      (options.level3.length === 0 || req.level3Id != null);

    setIsFormValid(isValid);
  };
  useEffect(() => {
    validateForm();
  }, [req]);
  useEffect(() => {
    if (checkTemplateExistsTimeout) {
      clearTimeout(checkTemplateExistsTimeout);
    }
    setCheckTemplateExistsTimeout(
      setTimeout(async () => {
        try {
          if (req?.name) {
            const count = await projectTemplateClient.countAllByFilter({
              name: String(req?.name),
            });
            setTemplateExistsError(count >= 1);
          } else {
            setTemplateExistsError(false);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setCheckTemplateExistsTimeout(null);
        }
      }, 500)
    );
  }, [req?.name]);

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                {t(translationPath+"title")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "83%" }}
                ></button>
              </div>

              <div className="modal-body pb-5">
                {loading ? (
                  <PendingAi height="100%" />
                ) : (
                  <div ref={modalContentRef}>
                    <div className="pb-5" style={{ minHeight: "200px" }}>
                      <p className="fw-bolder">
                      {t(translationPath+"required")}
                      </p>
                      <div className="form-input-content">
                        <div
                          className="form-input-first-content"
                          style={{ width: "100%" }}
                        >
                          <label
                            htmlFor="contractName"
                            className="case-form-labelName"
                          >
                            {t(translationPath + "inputs.name")}{" "}
                          </label>
                          <div className="form-floating mb-3 case-form-labelInput">
                            <input
                              style={{
                                width: "80%",
                                marginTop: "20px",
                                borderRadius: "8px",
                              }}
                              type="text"
                              className="custom-color-input"
                              id="contractName"
                              {...register("templateName", { required: true })}
                              onChange={(e) => {
                                e.preventDefault();
                                setReq({ ...req, name: e.target.value });
                                setError("");
                                setTemplateExistsError(false);
                              }}
                            />
                            {templateExistsError && (
                              <p className="text-danger py-0 my-0 py-0 my-0">
                              {t(translationPath + "input.errorName")}{" "}
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className="form-input-last-content"
                          style={{ width: "80%" }}
                        >
                          <CustomCombobox
                            label={t(translationPath + "inputs.level1")}

                            options={options.level1.map((item) => item)}
                            optionDisplay={(option) =>
                              getName(option, language)
                            }
                            optionValue={(option) => option?.id ?? null}
                            value={options.level1.find(
                              (option) => option.id == req.level1Id
                            )}
                            onChange={(value) => {
                              handleLevel1Change(value);
                            }}
                            isOpen={openCombobox === "level1"}
                            setIsOpen={(isOpen) =>
                              setOpenCombobox(isOpen ? "level1" : null)
                            }
                            fullWidth={true}
                          />

{(!req.level1Id || options.level2.length > 0) && (
                            <CustomCombobox
                            label={t(translationPath + "inputs.level2")}

                              options={options.level2.map((item) => item)}
                              optionDisplay={(option) =>
                                getName(option, language)
                              }
                              optionValue={(option) => option?.id ?? null}
                              value={options.level2.find(
                                (option) => option.id == req.level2Id
                              )}
                              onChange={(value) => {
                                handleLevel2Change(value);
                              }}
                              isOpen={openCombobox === "level2"}
                              setIsOpen={(isOpen) =>
                                setOpenCombobox(isOpen ? "level2" : null)
                              }
                              fullWidth={true}
                            />
                          )}
                             {(!req.level1Id ||
                (options.level2.length > 0 && !req.level2Id) ||
                options.level3.length > 0) && (
                            <CustomCombobox
                              label={t(translationPath + "inputs.level3")}
                              options={options.level3.map((item) => item)}
                              optionDisplay={(option) =>
                                getName(option, language)
                              }
                              optionValue={(option) => option?.id ?? null}
                              value={options.level3.find(
                                (option) => option.id == req.level3Id
                              )}
                              onChange={(value) => {
                                handleLevel3Change(value);
                              }}
                              isOpen={openCombobox === "level3"}
                              setIsOpen={(isOpen) =>
                                setOpenCombobox(isOpen ? "level3" : null)
                              }
                              fullWidth={true}
                            />
                          )}
                        </div>
                      </div>
                      <div style={{ marginBottom: "6vh" }}>
                        <FileInputAdvanced
                          label={t(translationPath + "inputs.file")}
                          onChange={(file) =>
                            setReq({
                              ...req,
                              file,
                            })
                          }
                          value={req?.file}
                          borderClassName={
                            file ? "green-border" : "grey-border"
                          }
                          className="mt-3 mb-1"
                        />
                      </div>
                      {!isFormValid && error && (
                        <p style={{ color: "red", fontSize: "13px" }}>
                          {error}
                        </p>
                      )}
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary btn-import-project-json"
                          onClick={handleSave}
                        >
                          {t(translationPath + "btn")}

                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ImportJsonProject;
