import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { EditFormTemplateContext } from "../../../../contexts/EditFormTemplateContext";
import "./formTemplateParameterInput.scss";
import BeneficialRender from "./BeneficialRender";
import TableRender from "./TableRender";
import BooleanRender from "./BooleanRender";
import FileRender from "./FileRender";
import ListRender from "./ListRender";
import EnumRender from "./EnumRender";
import DefaultRender from "./DefaultRender";
import NumberRender from "./NumberRender";
import PropertyRender from "./PropertyRender";
import { FormParam, FormType } from "../../../../domain/types/FormParams";
import BeneficialListRender from "./BeneficialListRender";
import DateRender from "./DateRender";
import { FormTemplatePageEntity } from "../../../../domain/entities";

function FormTemplateParameterInput({
  page,
  param,
  isEditing,
}: {
  page: FormTemplatePageEntity;
  param: FormParam;
  isEditing: boolean;
}) {
  const { onParamChanged, onParamTypeChange } = useContext(EditFormTemplateContext);
  const [localValue, setLocalValue] = useState<FormParam['label']>(param.label);
  const [localNameValue, setLocalNameValue] = useState<FormParam['name']>(param.name);
  const [description, setDescription] = useState<FormParam['description']>(param.description);
  const [errors, setErrors] = useState([])
  const { t, language } = useTranslation();
  const translationPathErrors = t("pages.contractTemplateParam.addParamForm");

  useEffect(() => {
    setLocalValue(param.label);
    setLocalNameValue(param.name)
  }, [param.label, param.name]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocalValue(value);
    onParamChanged(page.id, { ...param, label: value });
  };

  const handleInputNameChange = (e) => {
    const value = e.target.value;
    const newErrors = [];

    if (!/^[a-z_][a-z0-9_]*$/i.test(value)) {
      newErrors.push({ where: "name", why: translationPathErrors.errors.nameWrong });
    }

    const existingParam = page.params.find(p => p.name === value);
    if (existingParam && existingParam.type !== param.type) {
      newErrors.push({ where: "name", why: translationPathErrors.errors.nameExists });
    }

    setLocalNameValue(value);
    setErrors(newErrors);

    if (newErrors.length === 0) {
      onParamChanged(page.id, { ...param }, value);
    } else {
      return
    }
  };
  const handleInputformulaChange = (e) => {
    const formula = e.target.value;
    onParamChanged(page.id, { ...param, formula });
  }
  const handleInputformatChange = (format: string) => {
    onParamChanged(page.id, { ...param, format });
  }
  const handleInputConstraintChange = (constraints: FormType.Constraint[]): (void) => {
    onParamChanged(page.id, { ...param, constraints: constraints });
  }
  const handleInputDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    onParamChanged(page.id, { ...param, description: value });
  }
  switch (param.type) {
    case "beneficial":
      return (
        <BeneficialRender
          value={localValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          param={param}
          errors={errors}
          page={page}
          valueName={localNameValue}
          isEditing={isEditing}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );
    case "beneficial[]":
      return (
        <BeneficialListRender
          value={localValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          param={param}
          errors={errors}
          page={page}
          valueName={localNameValue}
          isEditing={isEditing}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );
    case "table":
      return (
        <TableRender
          value={localValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          param={param}
          errors={errors}
          valueName={localNameValue}
          page={page}
          isEditing={isEditing}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );
    case "boolean":
      return (
        <BooleanRender
          value={localValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          valueName={localNameValue}
          errors={errors}
          onDescriptionChange={handleInputDescriptionChange}
          param={param}
          page={page}
          isEditing={isEditing}
        />
      );
    case "file":
      return (
        <FileRender
          value={localValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          valueName={localNameValue}
          errors={errors}
          param={param}
          isEditing={isEditing}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );
    case "csv":
      return (
        <FileRender
          value={localValue}
          errors={errors}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          valueName={localNameValue}
          onDescriptionChange={handleInputDescriptionChange}
          param={param}
          isEditing={isEditing}
        />
      );
    case "list":
      return (
        <ListRender
          value={localValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          valueName={localNameValue}
          errors={errors}
          param={param}
          page={page}
          isEditing={isEditing}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );
    case "enum":
      return (
        <>
          <EnumRender
            value={localValue}
            onChange={handleInputChange}
            errors={errors}
            onChangeName={handleInputNameChange}
            valueName={localNameValue}
            onDescriptionChange={handleInputDescriptionChange}
            param={param}
            page={page}
            isEditing={isEditing}
          />
        </>
      );
    case "number":
      return (
        <NumberRender
          pageId={page.id}
          onParamTypeChange={onParamTypeChange}
          value={localValue}
          valueName={localNameValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          onChangeFormula={handleInputformulaChange}
          onChangeConstraint={handleInputConstraintChange}
          onChangeFormat={handleInputformatChange}
          param={param}
          errors={errors}
          isEditing={isEditing}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );
    case "date":
      return (
        <DateRender
          value={localValue}
          pageId={page.id}
          onParamTypeChange={onParamTypeChange}
          valueName={localNameValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          onChangeFormat={handleInputformatChange}
          param={param}
          onChangeFormula={handleInputformulaChange}
          errors={errors}
          isEditing={isEditing}
          onChangeConstraint={handleInputConstraintChange}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );

    case 'property':
      return (
        <>
          <PropertyRender
            value={localValue}
            pageId={page.id}
            onParamTypeChange={onParamTypeChange}
            onChange={handleInputChange}
            errors={errors}
            onChangeName={handleInputNameChange}
            valueName={localNameValue}
            onDescriptionChange={handleInputDescriptionChange}
            param={param}
            page={page}
            isEditing={isEditing}
          />
        </>
      );
    default:
      return (
        <DefaultRender
          value={localValue}
          pageId={page.id}
          onParamTypeChange={onParamTypeChange}
          valueName={localNameValue}
          onChange={handleInputChange}
          onChangeName={handleInputNameChange}
          param={param}
          onChangeFormula={handleInputformulaChange}
          errors={errors}
          isEditing={isEditing}
          onChangeConstraint={handleInputConstraintChange}
          onDescriptionChange={handleInputDescriptionChange}
        />
      );
  }
}

export default FormTemplateParameterInput;