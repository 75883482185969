import React, { useState } from "react";
import "./adminFunction.scss";
import { useNavigate } from "react-router-dom";

function AdminFunction({ title, description, buttonTitle, render, path }: {
  title: string;
  description: string;
  buttonTitle: string;
  render?: (onClose: () => void) => React.JSX.Element;
  path?:string
}) {
  const [showModal, setShowModal] = useState(false);
  const component = render&&render(() => setShowModal(false))
  const navigate=useNavigate()
  return (
    <div className="main-function admin-main-functions">
      <h2>{title}</h2>
      <p className="mt-1 w-100">{description}</p>
      <button className="btn mt-2 btn-action-admin" onClick={() =>{path&&navigate(path); setShowModal(true)}}>
        {buttonTitle}
      </button>
      {showModal && component}
    </div>
  );
}

export default AdminFunction;
