import React, {useRef, useState, useCallback} from 'react'
import AskYourQuestionsContext from '../../../contexts/AskYourQuestionsContext'
import Loading from '../../../components/common/Loading'
import { IoMdAttach } from "react-icons/io";
import { AiFileClient } from '../../../services/rag/AiFileClient';
import document from "../../../assets/new-design-icons/Document.svg"
import ragClientWithLoading from '../../../services/rag/RagClient';
import { FaArrowUp } from "react-icons/fa";
function ChatQuery() {
  const { getAllAiFiles, currentFile, setCurrentFile, handleQueryChange, query, handleSubmit, pendingQuestion } = React.useContext(AskYourQuestionsContext)
  const fileInputRef = useRef(null);
  const ragClient = ragClientWithLoading()
  const aiFileClient = new AiFileClient(ragClient)
  const [file, setFile] = useState(null)
  const [uploadLoading, setUploadLoading] = useState(false)
  const documentIcon = document.toString()
  const handleUpload = async (file) => {
    setFile(file)
    setUploadLoading(true)
    try {
      const result = await aiFileClient.createFromFile(file);
      if(!result.row) throw new Error("Error uploading file")
      const aiFile = result.row
      // compile file
      const compiledFileResult = await aiFileClient.compile(aiFile.id)
      if(!compiledFileResult.row) throw new Error("Error compiling file")
      const compiledAiFile = compiledFileResult.row
      setCurrentFile(compiledAiFile)
    } catch (err) {
      console.log(err)
    }finally{
      setUploadLoading(false)
      getAllAiFiles()
    }
  }
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);
  const handleFileChange = async (e) => {
    const files: FileList = e.target.files;
    const file = files[0];
    if (file) {
      await handleUpload(file);
    }
  };
  return (
    <div className='body-interactions-input-container'>
      {
        !currentFile && <div onClick={triggerFileInput} className="add-file">
        <div className="d-flex">
          <IoMdAttach
            style={{
              width: "30px",
              height: "30px",
              color: "gray",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            className='icon'
          />
        </div>
        <input
          type="file"
          style={{ display: "none", border: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </div>
      }

      <div className='input-section'>
        {
          uploadLoading && <div className='added-file'>
            <img src={documentIcon} alt="icon" style={{ width: "20px", height: "20px" }} />
         
            <Loading height="7vh" /></div>
        }
        {
          currentFile && <div className="added-file">
          <img src={documentIcon} alt="icon" style={{ width: "20px", height: "20px" }} />
          <span>{currentFile.name}</span>
        </div>
        }
        <input className='query-input' type='text' placeholder='Ask your question' onChange={handleQueryChange} value={query} />
      </div>
      <div className='button-section'>
        <button onClick={() => handleSubmit("")} className='ask-btn'>
          <FaArrowUp className='icon' style={{
            color: pendingQuestion ? "lightgray" : "gray",
          }}/>
        </button>
      </div>
    {/* {uploadLoading && <div className='add-file'><Loading height="7vh" /></div>}
    {!currentFile 
    ? 
    <div onClick={triggerFileInput} className="add-file">
      <div className="d-flex ms-1">
        <IoMdAttach
          style={{
            marginRight: "0.5rem",
            color: "#2f14e5",
            cursor: "pointer",
          }}
          className='icon'
        />
      </div>
      <input
        type="file"
        style={{ display: "none", border: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div> 
    : 
    <div onClick={()=>console.log(currentFile)} className="current-file">
      <img src={documentIcon} alt="icon" style={{ width: "20px", height: "20px" }} />
      <span>{currentFile.name}</span>
    </div>
    }
    <input className='ask-input' type='text' placeholder='Ask your question' onChange={handleQueryChange} value={query} />
    <button style={{ backgroundColor: pendingQuestion && "gray" }} disabled={pendingQuestion} onClick={() => handleSubmit("")} className='ask-button'>
      Ask
    </button> */}
  </div>
  )
}

export default ChatQuery