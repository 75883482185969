import React from 'react'
import EventManager from "../../../../services/EventManager";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'
import { useNavigate } from 'react-router-dom';

const ActivateTemplateButton = () => {
    const { t } = useTranslation();
    const handleActivateTemplate = async () => {
        EventManager.invoke('ActivateTemplate', true)
    };
    return (
        <button className="navbar-button" onClick={handleActivateTemplate}>
            {t("navbar.activateTemplate")}
        </button>
    )
}

export default ActivateTemplateButton