import React from 'react'
import EventManager from "../../../../services/EventManager";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'

const ExportJsonProjectTemplate = () => {
    const { t } = useTranslation();
    const exportJsonProjectTemplate = async () => {
        EventManager.invoke('ExportJsonProjectTemplate', true)
    };
    return (
        <button className="navbar-button" onClick={exportJsonProjectTemplate}>
            {t("navbar.exportJsonProjectTemplate")}
        </button>
    )
}

export default ExportJsonProjectTemplate