import { useEffect, useRef } from 'react';
import React from 'react'
import { JustificationContext } from '../../contexts/JustificationContext'
import { optimizeStr } from '../../utils/string';

function TextRender({text}:{text:string}) {
  const { justification } = React.useContext(JustificationContext);
  const [textLines, setTextLines] = React.useState<string[]>(text.split(/(?:\r?\n\s*)+/).map(line => optimizeStr(line).trim()));
  const containerRef = useRef<HTMLDivElement>(null);
  const highlightRef = useRef<HTMLSpanElement>(null);

  //handle scrolling the highlighted element into view
  useEffect(() => {
    if (highlightRef.current && containerRef.current) {
      containerRef.current.scrollTo({
        top: highlightRef.current.offsetTop - containerRef.current.offsetTop - 200,
        behavior: "smooth",
      });
    }
  }, [justification]);

  return (
    <div ref={containerRef} className="ai-text-container w-50" style={{ overflowX: "hidden", overflowY: "auto" }} >
      <div className='ai-text'>
        {
          textLines.map((line, index) => 
           {
            if(justification.includes(index)){
              return <span key={index} ref={highlightRef} style={{ backgroundColor: "orange", borderRadius: '5px', padding: "3px" }}>{line + "\n"}</span>
            }else{
              return <span key={index}>{line + "\n"}</span>
            
            }
            }
          )
        }
      </div>
    </div>
  )
}

export default TextRender