import React, { useContext, useEffect, useState } from "react";
import "../commonmodal.scss";
import "./deleteSubClauseModal.scss";
import { useTranslation } from "../../../contexts/TranslationProvider";

function DeleteSubClauseModal({ onConfirm, onClose }) {
  const { t } = useTranslation();
  const translationPath = "pages.contractTemplateParam.deleteSubClauseModal"; 
  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t(translationPath + ".title")}
                </h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
              </div>
              <div className="modal-body delete-body">
                <span className="delete-body-title">
                  {t(translationPath + ".description")}
                </span>
                <div className="delete-body-content">
                  <button className="delete-action" onClick={() => onConfirm(false)}>
                    {t(translationPath + ".discard")}
                  </button>
                  <button className="delete-action active" onClick={() => onConfirm(true)}>
                    {t(translationPath + ".keep")}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteSubClauseModal;
