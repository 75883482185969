import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import PendingAi from "../../../components/common/newDesign/PendingAi";
import "../../../components/modals/commonmodal.scss"
import { ProjectRequirementProps } from "./RessourceContract";
import { ProjectTemplateRequirementEntity } from "../../../domain/entities";

function RessourceDocument({
  projectTemplate,
  requirement,
  loading,
  setRessources,
  setLoading,
  onClose,
  ressources
}
  : ProjectRequirementProps
) {

  const [templateExistsError, setTemplateExistsError] = useState(false);

  const [required, setChecked] = useState(requirement ? !requirement.isOptional : true);
  const handleCheckboxChange = () => {
    setChecked(!required);
  };
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const { t, language } = useTranslation();
  const translationPath = t("pages.admin.actionsOptionProjectTemplate")
  const modalContentRef = useRef(null);
  const [requirementData, setRequirementData] = useState<ProjectTemplateRequirementEntity>({
    name: requirement.name ? requirement.name : ""
  });


  useEffect(() => {
    if (requirementData?.name) {
      const existing = ressources?.find(
        (r) =>
          r.name.trim() === requirementData.name.trim()
         &&
          (r.id !== requirementData.id || (r.id == null && r.index !== requirementData.index))
      );
      setTemplateExistsError(!!existing);
    }
  }, [requirementData?.name]);
  const handleSave = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Veuillez remplir tous les champs nécessaires");
      return;
    }
  
    const isNewResource = !requirement?.name;
  
    const dataRequirement = {
      ...requirementData,
      isOptional: !required,
      types: [1],
      index: isNewResource ? Date.now().toString() : requirement.index, 
    };
  
    setRessources((prev) => {
      if (!isNewResource) {
        return prev.map((r) =>
         r.index === requirement.index
            ? { ...r, ...dataRequirement }
            : r
        );
      } else {
        return [...prev, dataRequirement];
      }
    });
  
    onClose();
  };
  const validateForm = () => {
    const isValid =
      requirementData.name !== ""
      && !templateExistsError

    setIsFormValid(isValid);
  };
  useEffect(() => {
    validateForm();
  }, [requirementData, templateExistsError]);
  return (
    <div>
      {loading ? (
        <PendingAi height="100%" />
      ) : (
        <div ref={modalContentRef}>
          <div style={{ minHeight: "200px" }}>
            <p className="fw-bolder">
              {t("pages.listener.popups.contract.required")}
            </p>
            <div className="form-input-content">
              <div className="form-input-first-content" style={{ width: '100%' }}>
                <label htmlFor="contractName" className="case-form-labelName">
                  {translationPath.document.name}
                </label>
                <div className="form-floating mb-3 case-form-labelInput">
                  <input
                    style={{ width: "80%", marginTop: "20px", borderRadius: "8px" }}
                    type="text"
                    value={requirementData?.name}
                    className="custom-color-input"
                    id="documentName"
                    onChange={(e) => {
                      e.preventDefault()
                      setRequirementData({ ...requirementData, name: e.target.value });
                      setError("")
                    }}
                  />
                  {templateExistsError && (
                    <p className="text-danger py-0 my-0 py-0 my-0">
                      {t("modals.createBlankTemplate.unicityError")}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <p >
              <label className="case-form-labelName">{translationPath.required}</label>  <input
                type="checkbox"
                checked={required}
                id="checkbox-required"
                value={required.toString()}
                onChange={handleCheckboxChange}
              />
            </p>
            {!isFormValid && <p style={{ color: "red", fontSize: "13px" }}>{error}</p>}
            <div className="modal-footer">
              <button
                type="submit"
                disabled={templateExistsError}
                className="btn btn-primary btn-display"
                style={{ left: "4.5%" }}
                onClick={handleSave}
              >
                {requirement.name ? "Editer " : translationPath.document.btn}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RessourceDocument;
