import React, { useState } from "react";
import Loading from "../../common/Loading";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import { ContractTemplateEntity, ProjectEntity, ProjectTemplateEntity, UserEntity } from "../../../domain/entities";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeleteMessage.scss";
import { toast } from "react-toastify";
import { UserClient } from "../../../services/api/UserClient";
import { ProjectClient } from "../../../services/api/ProjectClient";
import { RestrictedUserEntity } from "../../../domain/entities/RestrictedUser";
import { RestrictedUserClient } from "../../../services/api/RestrictedUserClient";
import { ProjectTemplateClient } from "../../../services/api/ProjectTemplateClient";
type ConfirmDeleteMessageProps = {
  onClose: (value: boolean) => void;
  msgDeleteUser?: any;
  template?: ContractTemplateEntity;
  reloadTemplates?: () => void;
  setNewUser?: (value: boolean) => void;
  user?: UserEntity;
  restrictedUser?: RestrictedUserEntity;
  type?: string,
  messageDelete?: string,
  project?: ProjectEntity,
  projectTemplate?: ProjectTemplateEntity,
  setNewChange?: (value: boolean) => void;
  onDelete?: (value: boolean) => void;
}
function ConfirmDeleteMessage({
  msgDeleteUser = "",
  template = {} as ContractTemplateEntity,
  onClose = () => { },
  reloadTemplates = () => { },
  setNewUser = () => { },
  user,
  restrictedUser,
  type,
  messageDelete,
  project,
  onDelete,
  setNewChange,
  projectTemplate
}: ConfirmDeleteMessageProps) {
  const [loading, setLoading] = useState(false);
  const [msgModal, setMessageModal] = useState("");
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const projectClient = new ProjectClient(apiClient);
  const projectTemplateClient = new ProjectTemplateClient(apiClient);

  const userClient = new UserClient(apiClient);
  const restrictedUserClient = new RestrictedUserClient(apiClient);

  const handleDelete = async (template: ContractTemplateEntity) => {
    setLoading(true);
    try {
      const { deleted } = await contractTemplateClient.delete(template.id);
      if (deleted) {
        await reloadTemplates();
        onClose(false);
        toast.success(t("modals.confirmDelete.messages.success"));
      } else {
        setMessageModal(t("modals.confirmDelete.inactiveMessage"));
      }
    } catch (error) {
      console.error("Error deleting template: ", error);
      toast.error(t("modals.confirmDelete.messages.error"));
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteUser = async (id) => {
    try {
      setLoading(true);
      const { deleted } = await userClient.delete(id);
      if (deleted) {
        toast.success(t("pages.users.popup.messages.successDelete"))
        setNewUser(true);
        setLoading(false);
        onClose(false);
      } else {
        throw ""
      }
    } catch (err) {
      toast.error(t("pages.users.popup.messages.errorDelete"))
      setLoading(false);
      onDelete(false)
      onClose(false);

    }
  };
  const handleDeleteRestrictedUser = async (id: RestrictedUserEntity['id']) => {
    try {
      setLoading(true);
      const { deleted } = await restrictedUserClient.delete(id);
      if (deleted) {
        toast.success(t("pages.users.popup.messages.successDelete"))
        setNewUser(true)
        setLoading(false);
        onClose(false);
        onDelete?.(true)
      } else {
        throw ""
      }
    } catch (err) {
      toast.error(t("pages.users.popup.messages.errorDelete"))
      setLoading(false);
      onDelete?.(true)
      onClose(false);
    }
  };
  const handleDeleteProject = async (id) => {
    try {
      setLoading(true);
      const { deleted } = await projectClient.delete(id);
      if (deleted) {
        setNewChange(true)
        onClose(false);
        toast.success(t("pages.viewProject.messages.successDelete"))
      }
    } catch (err) {
      toast.error(t("pages.viewProject.messages.errorDelete"))
      setLoading(false)

    }
  };
  const handleDeleteProjectTemplate = async (id) => {
    try {
      setLoading(true);
      const { deleted } = await projectTemplateClient.delete(id);
      if (deleted) {
        setNewChange(true)
        onClose(false);
        toast.success(t("pages.viewProject.messages.successDelete"))
      }
    } catch (err) {
      toast.error(t("pages.viewProject.messages.errorDelete"))
      setLoading(false)

    }
  };

  const handleMakeInactive = async (template: ContractTemplateEntity) => {
    setLoading(true);
    try {
      const updateTemplate = await contractTemplateClient.update(template.id, {
        ...template,
        activated: false,
      });
      if (updateTemplate) {
        await reloadTemplates();
      }
      onClose(false);
      toast.success(t("modals.confirmDelete.messages.inactive"));
    } catch (error) {
      console.error("Error updating template: ", error);
      toast.error(t("modals.confirmDelete.messages.inactiveError"));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (project) {
      setMessageModal(
        `${messageDelete} ${project.name}${language !== "ar" ? " ?" : " ؟"}`
      );
    }
    else if(projectTemplate) {
        setMessageModal(
          `${messageDelete} ${projectTemplate.name}${language !== "ar" ? " ?" : " ؟"}`
        );
    }
    else if (user) {
      setMessageModal(
        `${msgDeleteUser} ${user.fullName ?? user.email}${language !== "ar" ? " ?" : " ؟"}`
      );
    } else if (restrictedUser) {
      setMessageModal(
        `${msgDeleteUser} ${restrictedUser.email}${language !== "ar" ? " ?" : " ؟"}`
      );
    } else if (template) {
      setMessageModal(
        `${t("modals.confirmDelete.deleteMessage")} ${template.name}${language !== "ar" ? "?" : " ؟"}`
      );
    }

  }, [template, t, language, project]);

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">{msgModal}</h1>
                    <div className="modal-buttons">
                      {!msgModal.includes(
                        t("modals.confirmDelete.inactiveMessage")
                      ) && (
                          <button
                            className="btn submit-delete"
                            onClick={() => {
                              user && handleDeleteUser(user.id)
                                || restrictedUser && handleDeleteRestrictedUser(restrictedUser.id)
                                || project && handleDeleteProject(project.id)
                                ||projectTemplate&&handleDeleteProjectTemplate(projectTemplate.id)
                                || template && handleDelete(template)
                            }}
                          >
                            {t("modals.confirmDelete.delete")}
                          </button>
                        )}
                      {msgModal.includes(
                        t("modals.confirmDelete.inactiveMessage")
                      ) && (
                          <button
                            className="btn  make-inactive"
                            onClick={() => handleMakeInactive(template)}
                            style={{ marginLeft: "20px" }}
                          >
                            {t("modals.confirmDelete.makeInactive")}
                          </button>
                        )}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onClose(false)
                        }}
                        className="btn btn-secondary cancel-delete"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "gray",
                        }}
                      >
                        {t("modals.confirmDelete.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteMessage;
