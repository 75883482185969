import React, { useEffect, useState, useRef, useMemo } from 'react'
import SVGRender from '../../helpers/SVGRender'
import { close } from '../../assets/icons/close'
import './askYourQuestions.scss'
import Loading from '../../components/common/Loading'
import { RiDeleteBinLine } from "react-icons/ri";
import ragClientWithLoading from '../../services/rag/RagClient'
import { ReglementClient } from '../../services/rag/ReglementClient'
import { useParams } from 'react-router-dom'
import { ReglementEntity, ConversationEntity } from '../../domain/entities'
import { ConversationClient } from '../../services/rag/ConversationClient'
import { AiFileClient } from '../../services/rag/AiFileClient'
import { useNavigate } from 'react-router-dom'
import chat from "../../assets/new-design-icons/Chat.svg"
import document from "../../assets/new-design-icons/Document.svg"
import { get } from 'http'
import ConversationList from './components/ConversationList'
import AskYourQuestionsContext from '../../contexts/AskYourQuestionsContext'
import ChatSuggestions from './components/ChatSuggestions'
import ChatBody from './components/ConversationChatBody'
import { AiFileEntity } from '../../domain/entities/AiFile'
import { set } from 'react-hook-form'
import { toast } from 'react-toastify'
import ConversationChatBody from './components/ConversationChatBody'
import FileChatBody from './components/FileChatBody'

function AskYourQuestions() {
  const chatIcon = chat.toString()
  const documentIcon = document.toString()
  const navigate = useNavigate(); 
  const [pageLoading, setPageLoading] = useState(false)
  const [conversations, setConversations] = useState<ConversationEntity[]>([]);
  const [currentFile, setCurrentFile] = useState<AiFileEntity | null>(null);
  const [reglements, setReglements] = useState([])
  const [currentConversation, setCurrentConversation] = useState<ConversationEntity | null>(null)
  const [currentReglement, setCurrentReglement] = useState<ReglementEntity | null>(null)
  const [aiFiles, setAiFiles] = useState<AiFileEntity[]>([])
  const [newName, setNewName] = useState('')
  const [query, setQuery] = useState('')
  const [pendingQuestion, setPendingQuestion] = useState(null)
  const [loading, setLoading] = useState(null)

  const scorllRef = useRef(null)
  const listRef = useRef(null)
  const [add, setAdd] = useState(false)
  const ragClient = ragClientWithLoading()
  const reglementClient = new ReglementClient(ragClient)
  const conversationClient = new ConversationClient(ragClient)
  const aiFileClient = new AiFileClient(ragClient)
  const getAllAiFiles = async () => {
    try {
      setLoading(true);
      const result = await aiFileClient.getAll();
      if (!result || !result.rows) return;
      setAiFiles(result.rows.filter(file => file.has_vectorstore));
    } catch (err) {
      console.log(err)
      toast.error("Error getting ai files")
    } finally {
      setLoading(false);
    }
  }
  const getAllConversations = async () => {
    try {
      setLoading(true);
      const result = await conversationClient.getAll();
      if (!result || !result.rows) return;
      setConversations(result.rows.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()));
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }
  const getReglements = async () => {
    try {
      setLoading(true);
      const result = await reglementClient.getAll();
      if (!result || !result.rows) return;
      setReglements(result.rows.filter(reglement => reglement.has_vectorstore));
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const initialGet = async () => {
    try {
      setPageLoading(true);
      await getReglements()
      await getAllConversations()
      await getAllAiFiles()
    } catch (err) {
      console.log(err)
    }finally{
      setPageLoading(false)
    }
  }

  useEffect(() => {
    initialGet()
  }, []);

  useEffect(() => {
    if(currentConversation){
      setCurrentFile(null)
      if(currentConversation.reglement){
        setCurrentReglement(reglements.find(reglement => reglement.id === currentConversation.reglement))
      }else{
        setCurrentReglement(null)
      }
    }
  }, [currentConversation])

  const handleNameChange = (e) => {
    setNewName(e.target.value)
  }

  const handleDelete = async (e, ressource) => {
    e.stopPropagation()
    try {
      if(ressource.type === "conversation"){
      if (currentConversation && (currentConversation.id === ressource.id)) {
        setCurrentConversation(null)
      }
      const data = await conversationClient.delete(ressource.id);
      await getAllConversations()
    }else{
      if (currentFile && (currentFile.id === ressource.id)) {
        setCurrentFile(null)
      }
      const data = await aiFileClient.delete(ressource.id);
      await getAllAiFiles()
    }
    } catch (e) {
      console.log(e)
    }
  }
  const handleAddConversation = async (id?) => {
    try {
      const data = await conversationClient.create({
        name: newName,
        reglement: id,
        messages: []
      });

      setNewName('')
      setAdd(false)
      console.log("data", data)
      setCurrentConversation(data)
      await getAllConversations()
      return data
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (currentFile) {
      setCurrentConversation(null)
      setCurrentReglement(null)
      // scroll to bottom of list ref
      setTimeout(() => {
        try{
          const container = listRef.current;
        container.scrollTo({
          top: container.scrollHeight,
          // not smooth
          behavior: "smooth",
        });
        }catch(err){
          console.log(err)
        }
      }, 100);
    }
  }, [currentFile])
  useEffect(() => {
    if (currentConversation) {
      // scroll to bottom of list ref
      setTimeout(() => {
        const container = listRef.current;
        container.scrollTo({
          top: container.scrollHeight,
          // not smooth
          behavior: "smooth",
        });
      }, 100);
    }
  }, [currentConversation]);
  const handleQueryChange = (e) => {
    setQuery(e.target.value)
  }
  const handleSubmit = async (comingQuery = "") => {


    if(currentFile){
      setPendingQuestion({
        "question": query,
      })
      const question = query
      setQuery('')
      try{
        const data = await aiFileClient.query(currentFile.id, question)
        setCurrentFile(data)
        await getAllAiFiles()
    }catch(err){
      console.log(err)
    }
    finally{
      setPendingQuestion(null)
      return
    }
  }
    if (!currentConversation) {
      setPendingQuestion({
        "question": comingQuery || query,
      })
      const question = comingQuery || query
      setQuery('')
      try{
        const data = await conversationClient.createAndQuery(question, currentReglement ? currentReglement.id : null)
        setCurrentConversation(data)
        await getAllConversations()
      }catch(err){
        console.log(err)
      }finally{
        setPendingQuestion(null)
        return
      }
    }
    setPendingQuestion({
      "question": comingQuery || query,
    })
    const question = comingQuery || query
    try {
      // scroll to scrollRef to the top of the list ref
      setTimeout(() => {
        const container = listRef.current;
        if (!container) return;
        const containerRect = container.getBoundingClientRect();
        const element = scorllRef.current;
        if (element) {
          const elementRect = element.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          const newScrollTop = container.scrollTop + relativeTop;
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }

      }, 400);
    } catch (e) {
      console.log(e)
    }
    setQuery('')
    if (!currentConversation || !currentConversation.id) return;
    try {
      const data = await conversationClient.query(currentConversation.id, question)
      setCurrentConversation(data)
      setPendingQuestion(null)
    } catch (e) {
      console.log(e)
      setPendingQuestion(null)
    }
  }
  useEffect(() => {
    if (currentReglement) {
      setCurrentFile(null)
    }
  }, [currentReglement])
  const AskYourQuestionsContextValue = useMemo(() => ({
    conversations,
    setConversations,
    reglements,
    setReglements,
    currentConversation,
    setCurrentConversation,
    pendingQuestion,
    setPendingQuestion,
    handleQueryChange,
    handleSubmit,
    query,
    setAdd,
    add,
    handleNameChange,
    newName,
    handleAddConversation,
    handleDelete,
    currentReglement,
    setCurrentReglement,
    currentFile,
    setCurrentFile,
    aiFiles,
    setAiFiles,
    getAllAiFiles

  }), [conversations, reglements, setConversations, setReglements, currentConversation, setCurrentConversation, pendingQuestion, setPendingQuestion, handleQueryChange, handleSubmit, query, setAdd, add, handleNameChange, newName, handleAddConversation, handleDelete, currentReglement, setCurrentReglement, currentFile, setCurrentFile, aiFiles, setAiFiles, getAllAiFiles])
  return (
    <AskYourQuestionsContext.Provider value={AskYourQuestionsContextValue}>
      <div className='ask-your-question-page-container'>
        {
          pageLoading ? 
          <Loading /> :
          <>
          <div className='ask-page-header'>
          <div className='left-section'>
            <span style={{ cursor: "pointer" }} onClick={() => navigate("/")} className='close'>
              <SVGRender svgContent={close} />
            </span>
            <div className='title'>
              {
                reglements && reglements.length > 0 && (
                  <>
                    <div className='reglement-list'>
                      <div key={-1} className={`reglement-item ${!currentReglement && "reglement-item-active"}`} onClick={() => {
                        setCurrentReglement(null)
                        setCurrentConversation(null)
                        setCurrentFile(null)
                      }}>
                        <span className='title'>
                          Contractzlab Assistant
                        </span>
                      </div>
                      {
                        reglements.map((reglement, index) => (
                          <div key={index} className={`reglement-item ${currentReglement?.id === reglement.id && "reglement-item-active"}`} onClick={() => {
                            setCurrentReglement(reglement)
                            setCurrentConversation(null)
                          }}>
                            <span className='title'>{reglement.name}</span>
                          </div>
                        ))
                      }
                    </div>
                  </>
                )
              }
            </div>
          </div>
          <div className='right-section'>
          </div>
        </div>
        <div className='ask-page-body'>
          <div className='body-interactions-container'>
{ currentFile ? <FileChatBody listRef={listRef} scorllRef={scorllRef} /> : <><ConversationChatBody listRef={listRef} scorllRef={scorllRef} /></>  }
          </div>
          <div className='body-conversations-container'>
            <ConversationList />
          </div>
        </div>
        </>
        }

      </div>
    </AskYourQuestionsContext.Provider>
  )
}

export default AskYourQuestions