import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const TableColorPicker = ({ onColorChange, color }) => {
  const [pickerColor, setPickerColor] = useState(color || '#000000');

  const handleColorChange = (newColor) => {
    setPickerColor(newColor.hex);
    onColorChange(newColor.hex); // Pass the color data to parent if needed
  };

  return (
    <div
    onMouseDown={(e) => e.stopPropagation()}
    onDragStart={(e) => e.preventDefault()}
    onDragCapture={(e) => e.preventDefault()}
    style={{cursor: 'default', marginTop: "10px"}}
    >
      <SketchPicker
        color={pickerColor}
        onChange={handleColorChange}
      />
    </div>
  );
};

export default TableColorPicker;
