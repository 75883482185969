import React, { useEffect, useState } from 'react'
import EventManager from "../../../../services/EventManager";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'

const DocumentSummaryButton = () => {
	const { t } = useTranslation();
	const [hasSummary, setHasSummary] = useState(false)

	useEffect(() => {
		const handler = (value) => {
			setHasSummary(value)
		}
		EventManager.subscribe('hasSummary', handler)
		return () => {
			EventManager.unsubscribe('hasSummary', handler)
		}
	}, [])


	const handleResponseValidation = async () => {
		EventManager.invoke('DocumentSummary')
	};
	return (
		<React.Fragment>
			{hasSummary &&
				< button className="navbar-button" onClick={handleResponseValidation}>
					{"Summary"}
				</button>
			}
		</React.Fragment >
	)
}

export default DocumentSummaryButton