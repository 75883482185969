import React, {useState, useEffect} from 'react'
import AdvancedTableContext from '../../contexts/AdvancedTableContext'
import './optionsColumnFilter.scss'
import { BsChevronDown, BsX } from "react-icons/bs";
import { useTranslation } from '../../contexts/TranslationProvider';

function OptionsColumnFilter({options, columnIdx}: {options: {value: any, display: string}[], columnIdx: number}) {
  const { setAppliedFilters, columns, appliedFilters } = React.useContext(AdvancedTableContext)
  const { language, t } = useTranslation()
  const onFilterChange = (value) => {
    if(!value){
      setAppliedFilters(prev => prev.filter(f => f.key != columns[columnIdx].accessorKey)) 
      return;
    }
    const newAppliedFilters = [...appliedFilters]
    const concernedFilter = newAppliedFilters.find(filter => filter.key === columns[columnIdx].accessorKey)
    if (concernedFilter) {
      concernedFilter.value = value
    } else {
      newAppliedFilters.push({key: columns[columnIdx].accessorKey, value: value, type: "custom"})
    }
    setAppliedFilters(newAppliedFilters)
  }
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [currentChoice, setCurrentChoice] = useState(appliedFilters.find(filter => filter.key === columns[columnIdx].accessorKey)?.value ?? "")
  useEffect(() => {
    const appliedValue = appliedFilters.find(filter => filter.key === columns[columnIdx].accessorKey)?.value
    if(!appliedValue){
      setCurrentChoice(null)
    }else{
      setCurrentChoice(options.find(option => option.value === appliedValue)?.display)
    }
  }, [appliedFilters])
  return (
    <>
      <div className="table-options-filter-container">
        <div onClick={() => setDropDownOpen(!dropDownOpen)} className='current-choice'>
          <span>{currentChoice ? currentChoice : t("pages.projects.filterPlaceholder")}</span>
          {
            currentChoice && <BsX
            color="#ff0000" // Set the color for the X icon
            style={{ position: "absolute", cursor: "pointer", right: language == "ar" ? "auto" : "30px", left: language == "ar" ? "30px" : "auto", top: "15px"}}
            onClick={(e) => {
              e.stopPropagation()
              onFilterChange(null)
            }}
          />
          }
          <BsChevronDown color="#2f14e5" style={{ position: "absolute", right: language == "ar" ? "auto" : "10px", left: language == "ar" ? "10px" : "auto", top: "15px"}} />
          </div>
          {
            dropDownOpen && (
              <div className="options-dropdown">
                {
                  options.map(option => (
                    <div
                    className='option-dropdown'
                     style={{cursor: "pointer"}} 
                     key={option.value} 
                     onClick={() => {
                      setCurrentChoice(option.display)
                      onFilterChange(option.value)
                      setDropDownOpen(false)
                    }}>{option.display}</div>
                  ))
                }
              </div>
            )
          }
      </div>
    </>
  )
}

export default OptionsColumnFilter