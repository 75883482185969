import { useNavigate } from "react-router-dom";
import RessourceCard from "../../components/common/newDesign/RessourceCard";
import React from "react";
import { ContractEntity } from "../../domain/entities";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { getName } from "../../helpers/Translation";
import { useTranslation } from "../../contexts/TranslationProvider";

function GroupContractsList({ contracts, groups, reloadContracts }) {
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading()
  const contractClient = new ContractClient(apiClient)

  const handleClick = (contractId) => {
    navigate(`/edition-contrat/${contractId}`);
  };

  const handleDelete = async (contractId: ContractEntity["id"]) => {

    const { deleted } = await contractClient.delete(contractId)
    if (deleted) {
      await reloadContracts();
    }
  };

  const sortedContracts = React.useMemo(() => contracts.map(c=>({...c, type: "Contract"})).sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()), [contracts]);

  const GroupColumn = (group) => {
    const groupContracts = sortedContracts.filter((contract) => contract.assignedToGroupId === group.id);
    return (
      <div className="contracts-space" key={group.id}>
        <h3 className="m-3 contracts-space-title">{getName(group, language)}</h3>
        <div className="contracts-per-level">
          {groupContracts.map((contract) => {
            return (
              <div key={contract.id} className="m-2 ressource-card">
                <RessourceCard data={contract}
                  onClick={() => handleClick(contract.id)}
                  onDelete={() => handleDelete(contract.id)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <section id="contracts-list" className="group-contracts-list">
      <div className="contracts-list-container">
        {groups.map((group) => GroupColumn(group))}
      </div>
    </section>
  );
}

export default GroupContractsList;
