
import React from "react";
import { ClauseEntity, ContractEntity } from "../../../domain/entities";
import ClauseText from "./ClauseText";
import { isSubClauseExcluded } from "../../../helpers/helper";
import { useTranslation } from "../../../contexts/TranslationProvider";


interface ComparisonClauseProps {
    inputValues: Record<string, any>;
    fileNames: ContractEntity['fileNames'];
    beneficialsMap: ContractEntity['beneficialsMap'];
    segmentsOverrides: Record<string, string>;
    clause: ClauseEntity;
    excludedSubClauses: ContractEntity['excludedSubClauses'];
}
function ComparisonClause({
    fileNames,
    beneficialsMap,
    segmentsOverrides,
    inputValues,
    clause,
    excludedSubClauses,
}: ComparisonClauseProps) {

    const {t, language}=useTranslation()

    return (
        <>
            <div className="clause-comparison-current">
                <div className=" mb-4" style={{marginBottom:"10px"}}>
                    <span className="clause-comparison-cummon-title">
                    {t("modals.clauseComparaison.clauseClausier")}
                    </span>
                    <br />
                    <span className="clause-comparison-actual-title half-border-bottom pb-3">
                        {clause.name}
                    </span>
                </div>
                <div className="clause-comparison-body mt-3">
                    <ClauseText
                        segmentation={clause.segmentation}
                        fileNames={fileNames}
                        beneficialsMap={beneficialsMap}
                        inputValues={inputValues}
                        segmentsOverrides={segmentsOverrides}
                    />
                    {clause?.subClauses?.map((subClause) => {
                        const isExcludedSubclause = isSubClauseExcluded(
                            subClause.id,
                            excludedSubClauses
                        );
                        return (
                            !isExcludedSubclause && (
                                <div
                                    key={subClause.id}
                                    className="my-2"
                                    id={`${clause.id}-${subClause.id}`}
                                >
                                    <h5 className="fw-bold">{subClause.name}</h5>
                                    <div>
                                        {subClause.segmentation?.segmentedText?.length > 0 && (
                                            <ClauseText
                                                segmentation={subClause.segmentation}
                                                fileNames={fileNames}
                                                beneficialsMap={beneficialsMap}
                                                inputValues={inputValues}
                                                segmentsOverrides={segmentsOverrides}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default ComparisonClause;
