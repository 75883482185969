import { createContext } from "react";
import { ClauseEntity } from "../domain/entities";

export type SelectedType = {
    eventType: 'Param' | 'Text' | 'ParamValueChange',
    clauseId: ClauseEntity['id'],
    paramName: string,
}
interface SelectionContextType {
    selected: SelectedType;
    setSelected: React.Dispatch<React.SetStateAction<SelectedType>>;
    isClauseEditing: boolean;
    setIsClauseEditing: React.Dispatch<React.SetStateAction<boolean>>;
}
const SelectionContext = createContext<SelectionContextType>({} as any);

export default SelectionContext;
