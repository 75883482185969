import { useContext, useState } from "react";
import ContractTemplateClause from "./ContractTemplateClause";
import SelectionContext from "../../../contexts/SelectionContext";
import { useEffect } from "react";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { FiPlusCircle } from "react-icons/fi";
import AddClausePlaceholder from "./AddClausePlaceholder";

// display clauses and their subClauses
function ContractTemplateClauseList({ clauseListRef }: {
  clauseListRef: React.MutableRefObject<any>,
}) {

  const { selected, setSelected } = useContext(SelectionContext);
  const { contractTemplate } = useContext(EditContractTemplateContext);
  const [addNewClause, setAddNewClause] = useState(null);
  const [openPopups, setOpenPopups] = useState<Boolean>(false)

  //used to handle scrolling when an input has been selected
  useEffect(() => {
    if (selected && clauseListRef.current) {
      const elementId = selected.eventType == 'ParamValueChange' ?
        `param-${selected.paramName}` : `${selected.clauseId}`;
      const element = document.getElementById(elementId);

      if (element) {
        setTimeout(() => {
          const container = clauseListRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          const newScrollTop = container.scrollTop + relativeTop;
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }, 400);
      }
    }
  }, [selected.clauseId, clauseListRef]);

  return (
    <div className="clauses-list">
      {contractTemplate?.clauses
        ?.map((clause, clauseIndex) => <>
        <AddClausePlaceholder clauseIndex={clauseIndex} setAddNewClause={setAddNewClause} addNewClause={addNewClause} clauses={contractTemplate.clauses}
/>
          <ContractTemplateClause
            clauseListRef={clauseListRef}
            key={clause.id}
            clause={clause}
            clauseIndex={clauseIndex} 
            setOpenedPopups={setOpenPopups}
            openPopups={openPopups}/>
        </>)}
        <AddClausePlaceholder clauseIndex={contractTemplate?.clauses?.length} setAddNewClause={setAddNewClause} addNewClause={addNewClause} clauses={contractTemplate.clauses}
/>
    </div>
  );
}

export default ContractTemplateClauseList;
