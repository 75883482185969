import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./EditContractPage.scss";
import EditContract from "./components/EditContract";
import Loading from "../../components/common/Loading";
import { useContractData } from "../../hooks/useContractData";
import SaveDraftModel from "../../components/modals/SaveDraftModal";
import MiddlewareManager from "../../services/MiddlewareManager";
import { useCallback, useEffect } from "react";
import { PromiseSub, createPromiseSub } from "../../utils/promise";
import { ContractClient } from "../../services/api/ContractClient";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { useState, useMemo } from "react";
import { ContractEntity } from "../../domain/entities/Contract";

function EditContractPage() {
  const location = useLocation();
  const { contractId } = useParams<{ contractId?: string }>() || {};
  const apiClient = useApiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);
  const navigate = useNavigate();

  const onLocation = async (pathname: string) => {
    if (location.pathname.includes("/edition-contrat") && !pathname.includes("/edition-contrat")) {
      const newSub = createPromiseSub()
      setSaveDraft(true);
      setSub(newSub);
      await newSub.promise
    }
  };

  useEffect(() => {
    MiddlewareManager.subscribe('location', onLocation);
    return () => {
      MiddlewareManager.unsubscribe('location', onLocation);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== "/edition-contrat") {
      setSaveDraft(false);
    }
    return () => { };
  }, [location]);
  const initialContractName =
    location.state?.contractDataFromModal?.contractName;
  const levelsFilter = location.state?.levelsFilter;
  const {
    templateData,
    isLoading,
    contractName,
    isEditing,
    paramValues,
    excludedClauses,
    excludedSubClauses,
  } = useContractData(
    parseInt(contractId),
    initialContractName,
    levelsFilter,
  );
  const [allParamValues, setParamValues] = useState<ContractEntity['paramValues']>({});
  const [isDataReady, setIsDataReady] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      if(Object.keys(allParamValues).length<Object.keys(paramValues).length){
      if (paramValues && Object.keys(paramValues).length > 0) {
        setParamValues(paramValues);
        setIsDataReady(false);  
      } else {
        setIsDataReady(true); 
      }
      }
    else {
      setIsDataReady(true);
    }
  }
  else {
    setIsDataReady(false);
  }
  }, [paramValues, isLoading, allParamValues]); 
  
  useEffect(() => {
    if(!isLoading){
      if(Object.keys(allParamValues).length<Object.keys(paramValues).length){
      if (Object.keys(allParamValues).length === Object.keys(paramValues).length) {
        setIsDataReady(true);
      }
    }
    }
  }, [allParamValues, paramValues, isLoading]);
  useEffect(() => {
    const checkContractCount = async () => {
      try {
        const count = await contractClient.countAllByFilter({ name: contractName });
        if (count > 0 && location.pathname === "/edition-contrat") {
          navigate("/");
        }
      } catch (err) {
        console.log(err);
        navigate("/");
      }
    };
    checkContractCount();
  }, [contractName, location.pathname, navigate]);

  const { previousPathname } = location?.state || { previousPathname: [] };
  return (
    <div className="contract-container d-flex justify-content-between align-items-center w-100">
      {!isDataReady? (
        <div className="contract-loading-container">
          <Loading height="90vh" />
        </div>
      ) : (
        <div className="edition-contract-page">
          <div className="px-2 container-paths">
            {previousPathname && previousPathname?.map((item, index) => (
              <span key={index}>
                <span className="previous-pathname" onClick={() => navigate(item.link)}>{item.label}</span>
                <span className="path-separator"> {">"} </span>
              </span>
            ))}
            {previousPathname && previousPathname.length > 0 && <span className="current-pathname">{contractName}</span>}
          </div>
          <EditContract
            templateData={templateData}
            contractName={contractName}
            isEditing={isEditing}
            setParamValues={setParamValues}
            paramValues={allParamValues}
            initialExcludedClauses={excludedClauses}
            initialExcludedSubClauses={excludedSubClauses}
          />
        </div>
      )}
      {draft && (
        <SaveDraftModel
          type="contract"
          onClose={() => { setSaveDraft(false); sub.reject() }}
          onSave={(saved: boolean) => { sub.resolve() }}
        />
      )}
    </div>
  );
}

export default EditContractPage;
