import React, { useState } from 'react'
import EventManager from "../../../../services/EventManager";
import { useTranslation } from '../../../../contexts/TranslationProvider';
const { default: EyeOffIcon } = require("../../../../assets/eye-off.svg");
const { default: EyeOnIcon } = require("../../../../assets/eye-on.svg");

const PreviewTemplate = () => {
    const { t } = useTranslation();
    const [preview, setPreview] = useState(false)
    const handleClick = () => {
        const newValue = !preview
        setPreview(newValue)
        EventManager.invoke('Preview', newValue);
    }

    return (
        <button className="navbar-button" onClick={handleClick}>
           {t("navbar.preview")}
            <img
                src={preview ? EyeOnIcon : EyeOffIcon}
                style={{
                    'maxHeight': '16px',
                    transition: "transform 0.3s ease", /* Add transition for smooth scaling */
                }}
                alt="#"
            />
        </button>
    )
}

export default PreviewTemplate