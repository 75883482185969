import React, { useEffect } from 'react'
import "./carousel.scss"
import { useTranslation } from '../../../../contexts/TranslationProvider';
import { TransitionGroup, CSSTransition } from "react-transition-group";

function CarouselContainer() {
  const { t, language } = useTranslation()
  const translationPath = "carousel.content"
  const [index, setIndex] = React.useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % t(translationPath).length);
    }, 7000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className='carousel-container'>
      <video autoPlay muted loop className="background-video">
        <source src="/home_video.mp4" type="video/mp4" />
      </video>
      <div className={`vid-content ${language === "ar" && "arabic"}`}>
        <div className='content'>
          <TransitionGroup>
            <CSSTransition key={index} timeout={500} classNames="slide">
              <div>
                <span className={`title ${language == "ar" && "arabic"}`}>
                  {t(translationPath)[index].title}
                </span>
                <span className={`description ${language == "ar" && "arabic"}`}>
                  {t(translationPath)[index].description}
                </span>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <div className='navigation'>
        </div>
      </div>
    </div>
  )
}

export default CarouselContainer