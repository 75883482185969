import React, { ReactNode } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import Fallback from "./components/FallBack";

interface ErrorBoundaryProps {
  children: ReactNode;
  onReset: () => void;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, onReset }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={Fallback}
      onReset={onReset}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
