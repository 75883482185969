import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useTranslation from '../../../hooks/useTranslation';
function StatusBarChart({ contracts, statuses }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );
  const { t } = useTranslation();
  const translationPath = 'pages.analytics.graphs.statusBarChart';
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t(translationPath + '.title'),
      },
      datalabels: {
        display: true,
        color: "white",
        anchor: 'end' as 'end',
        align: 'start' as 'start',
        padding: {
          top: -10
        },
        formatter: function (value, context) {
          return value > 1 ? value : "";
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 20,
          precision: 0, // Set precision to 0 to remove decimal values
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
      },
    },
  };

  const labels = statuses;

  const data = {
    labels: labels.map((status) => t(`status.${status}`) ?? ""),
    datasets: [
      {
        label: t(translationPath + '.label'),
        data: statuses.map((status) => {
          const groupContracts = contracts.filter((contract) => contract.status === status);
          return groupContracts.length;
        }),
        backgroundColor: '#7014E5',

      },
    ],
  };

  return (
    <div className="chart" style={{ minWidth: "500px" }}>
      <Bar options={options} data={data} />
    </div>
  );
}

export default StatusBarChart;
