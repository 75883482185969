import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
const { default: Delete } = require("../../../../assets/delete.svg");

function TableRender({ param, page, isEditing, value, valueName, onChange, onChangeName, errors, onDescriptionChange }) {
  const { onParamChanged, onOptionDelete, onOptionAdd } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  return (

    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      {!isEditing ? (
        <>
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label" htmlFor={param.name}>
            {translationPath.parameterLabel} :  {param.label}
          </label>
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
          <div className="d-flex px-3">
            <input
              type="checkbox"
              checked={param.transposed}
              onChange={(e) =>
                onParamChanged(page.id, {
                  ...param,
                  transposed: e.target.checked,
                })
              }
            />
            <label
              className="px-2"
              style={{ color: "#1f2b3a", fontWeight: "400" }}
            >
              {" "}
              {t(translationPath.table.transposed)}{" "}
            </label>
          </div>
          <div className="d-flex container-table-element mb-1 mt-1">
            <span className="header-table-edit">
              {" "}
              {t(translationPath.table.colonnes)}{" "}
            </span>
            <span className="header-table-edit">
              {t(translationPath.table.typesTitle)}
            </span>
          </div>
          {param?.args &&
            param?.args?.map((arg, index) => (
              <div key={index} className="d-flex  container-table-element">
                <p> {arg.header}</p>
                <p>{arg.type}</p>
              </div>
            ))}
        </>
      ) : (
        <>
          <textarea
            className="custom-form-textarea"
            name={param.name}
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        </>
      )}

      {param.args &&
        param?.args?.map((arg, index) => {
          return (
            isEditing && (
              <div
                className="d-flex justify-content-between  align-items-center px-2 mb-1 mt-2"
                style={{ width: "90%" }}
              >
                <input
                  className="label-input-edit"
                  style={{ height: "30px", width: "50%" }}
                  value={arg.header}
                  onChange={(e) => {
                    console.log(e.target.value);
                    console.log({
                      ...param,
                      args: param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, header: e.target.value }
                          : arg
                      ),
                    });
                    onParamChanged(page.id, {
                      ...param,
                      args: param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, header: e.target.value }
                          : arg
                      ),
                    });
                  }}
                />
                <select
                  value={arg.type}
                  style={{ height: "30px", width: "30%" }}
                  className="label-input-edit"
                  onChange={(e) =>
                    onParamChanged(page.id, {
                      ...param,
                      args: param.args
                        ? param.args.map((arg, i) =>
                          i === index
                            ? {
                              ...arg,
                              type: e.target.value as
                                | "string"
                                | "number"
                                | "date",
                            }
                            : arg
                        )
                        : [],
                    })
                  }
                >
                  <option value="string">
                    {" "}
                    {t(translationPath.table.types.string)}{" "}
                  </option>
                  <option value="number">
                    {" "}
                    {t(translationPath.table.types.number)}
                  </option>
                  <option value="date">
                    {" "}
                    {t(translationPath.table.types.date)}
                  </option>
                </select>

                <img
                  src={Delete}
                  className="delete-single-option"
                  onClick={() => onOptionDelete(page.id, param, index)}
                />
              </div>
            )
          );
        })}
      {isEditing && (
        <p
          className="add-single-option"
          style={{ marginLeft: "8px" }}
          onClick={() => {
            onOptionAdd(page.id, param, { header: "", type: "string" });
          }}
        >
          +
        </p>
      )}
    </div>
  )
}

export default TableRender