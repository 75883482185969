import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CustomPropertyEntity } from '../domain/entities/CustomProperty';
import useApiClientWithLoading from '../services/api/ApiClient';
import { useLocation } from 'react-router-dom';
import { CustomPropertyClient } from '../services/api/CustomPropertyClient';
import { getNestedProperties } from '../domain/CustomProperty';

export interface CustomPropertiesContextType {
  properties: CustomPropertyEntity[];
  setProperties: Dispatch<SetStateAction<CustomPropertyEntity[]>>;
  propertiesGroup: Record<CustomPropertyEntity['tag'], CustomPropertyEntity[]>;
  setPropertiesGroup: Dispatch<SetStateAction<Record<CustomPropertyEntity['tag'], CustomPropertyEntity[]>>>;
  tags: CustomPropertyEntity['tag'][];
  setTags: Dispatch<SetStateAction<CustomPropertyEntity['tag'][]>>;
  tagMapping: Record<string, string>;
  setTagMapping: Dispatch<SetStateAction<Record<string, string>>>;
  refresh();
  getPropertyParentOptions: (propertyTag: CustomPropertyEntity['tag']) => CustomPropertyEntity[];
  displayProperty: (property: CustomPropertyEntity) => string;

}

const CustomPropertiesContext = createContext<CustomPropertiesContextType>({} as any);

export default CustomPropertiesContext;

export const CustomPropertiesProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {

  const [properties, setProperties] = useState<CustomPropertyEntity[]>([]);
  const [propertiesGroup, setPropertiesGroup] = useState<Record<string, CustomPropertyEntity[]>>({});
  const [tags, setTags] = useState<CustomPropertyEntity['tag'][]>([]);
  const [tagMapping, setTagMapping] = useState<Record<string, string>>({});

  const refresh = () => {
    customPropertyClient.getAll().then(({ rows }) => {
      setProperties(rows)
      const { propertiesGroup, tagMapping } = getNestedProperties(rows)
      for (const key in propertiesGroup) {
        propertiesGroup[key] = propertiesGroup[key].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      }
      setPropertiesGroup(propertiesGroup)
      setTagMapping(tagMapping)
      setTags(Object.keys(propertiesGroup).sort())
    })
  }

  const getPropertyParentOptions: CustomPropertiesContextType['getPropertyParentOptions'] = (propertyTag: CustomPropertyEntity['tag']) => {
    let options = properties
    if (propertyTag && tagMapping[propertyTag]) {
      const parentTag = tagMapping[propertyTag]
      options = options.filter(option => option.tag == parentTag)
    }
    return options
  }

  const displayProperty: CustomPropertiesContextType['displayProperty'] = (property) => {
    return property ? `${property.tag}:\t${property.value}` : ""
  }

  const location = useLocation()
  const propertiesContext: CustomPropertiesContextType = useMemo(
    () => ({
      properties,
      setProperties,
      propertiesGroup,
      setPropertiesGroup,
      tags,
      setTags,
      tagMapping,
      setTagMapping,
      refresh,
      getPropertyParentOptions,
      displayProperty,
    }),
    [properties, propertiesGroup, tags]
  );
  const apiClient = useApiClientWithLoading()
  const customPropertyClient = new CustomPropertyClient(apiClient)
  useEffect(() => {
    refresh()
    return () => {
    }
  }, [location.pathname])

  return (
    <CustomPropertiesContext.Provider value={propertiesContext}>
      {children}
    </CustomPropertiesContext.Provider>
  );
}