import "./contractTemplateClause.scss";
import { useContext, useEffect, useRef, useState, useCallback } from "react";
import SelectionContext from "../../../contexts/SelectionContext";
import { getClauseName, getSubClauseName } from "../../../helpers/ParseTemplateData";
import RenderTemplateSegmentedText from "./RenderTemplateSegmentedText";
import { useCollapse } from "react-collapsed";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { scrollParamToTopIfNotVisible, isElementVisibleInClause } from "../../../utils/clauseScroll";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { ClauseEntity, SubClauseEntity } from "../../../domain/entities";
import { toast } from "react-toastify";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import { FaTrashAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { FaExchangeAlt } from "react-icons/fa";
import AddSubClausePlaceholder from "./AddSubClausePlaceholder";
import GenerateAIPortal from "./GenerateAIPortal";
import DeleteSubClauseModal from "../../../components/modals/DeleteSubClauseModal";
import { GenerateClauseParamType } from '../../../domain/types/Clause'
import ExchangeClauseModal from "../../../components/modals/ExchangeClauseModal";
import { useTranslation } from "../../../contexts/TranslationProvider";
import ContractTemplateGroups from "./ContractTemplateGroups";
import { addPrefixToSegmentedTextId } from "../../../domain/ContractTemplate";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");
const { default: CommentIcon } = require("../../../assets/comment_icon.svg");
const { default: CommentIconOn } = require("../../../assets/comment_icon_on.svg");
const { default: AIComparison } = require("../../../assets/aiCompare.svg");
const { default: AIComparisonOn } = require("../../../assets/aiCompareOn.svg");
const { default: spinner } = require("../../../assets/spinner.svg");
const { default: EyeOffIcon } = require("../../../assets/eye-off.svg");
const { default: EyeOnIcon } = require("../../../assets/eye-on.svg");



function ContractTemplateClause({ clause, clauseIndex, clauseListRef, setOpenedPopups, openPopups }: { clause: ClauseEntity, clauseIndex: number, clauseListRef: React.MutableRefObject<any>, setOpenedPopups: (num: any) => void, openPopups: Boolean }) {
  const {
    contractTemplate, setContractTemplate,
    previewValues,
    onSegmentChange,
    onClauseDelete,
    onSelectGroupInClause,
    groups
  } = useContext(EditContractTemplateContext);
  const {
    setSelected,
    selected,
    isClauseEditing,
    setIsClauseEditing,
  } = useContext(SelectionContext);


  const [isLoading, setLoading] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isExpanded, setExpanded] = useState(true);
  const [isDeleteSubClause, setIsDeleteSubClause] = useState(false);
  const [deleteSubclauseTarget, setDeleteSubclauseTarget] = useState(null);
  const [deleteClauseTarget, setDeleteClauseTarget] = useState(null);
  const [generateAI, setGenerateAI] = useState(false);
  const [changeGroups, setChangeGroups] = useState(false);

  const [initialClause, setInitialClause] = useState(null)
  const [exchange, setExchange] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const isSelected = selected.clauseId === clause.id
  const isMinHeight = isSelected && openPopups
  const { t, language } = useTranslation()
  const translationPath = "pages.contractTemplateParam."

  useEffect(() => {
    if (changeGroups && generateAI) {
      setChangeGroups(false)
      return
    }
  }, [generateAI]);
  useEffect(() => {
    if (changeGroups && generateAI) {
      setGenerateAI(false)
      return
    }
  }, [changeGroups]);

  const handleClick = (event) => {
    if (!event.defaultPrevented) {
      if (openPopups) {
        const newSelection = { ...selected }
        setSelected(newSelection)
        return;
      } else {
        setSelected({
          eventType: 'Text',
          clauseId: clause.id,
          paramName: null,
        });
      }

    }
  };
  useEffect(() => {
    if (selected.clauseId == clause.id) {
      if (selected && clauseListRef.current) {
        const elementId = selected.eventType == 'ParamValueChange' ?
          `param-${selected.paramName}` : `${selected.clauseId}`;
        const element = document.getElementById(elementId);

        if (element) {
          setTimeout(() => {
            const container = clauseListRef.current;
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            const relativeTop = elementRect.top - containerRect.top;
            const newScrollTop = container.scrollTop + relativeTop;
            container.scrollTo({
              top: newScrollTop,
              behavior: "smooth",
            });
          }, 100);
        }
      }
    }
  }, [selected]);
  const formattedRawRef = useRef(null);
  const clauseRef = useRef(null);
  const onTextSegmentChange = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id']) => (id: string, text: string) => {
    onSegmentChange(clauseId, subClauseId, id, text)
  }

  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const generateParamsWithAIHandler = async (promptTypes: GenerateClauseParamType[], comments: string) => {
    try {
      //if(clause.params.length !== 0) return;
      setLoading(true)
      const saved = await contractTemplateClient.updateWithWarnings(contractTemplate.id, contractTemplate);
      const result = await contractTemplateClient.generateClauseParams(clause.id, promptTypes, comments);
      const { warnings, row: updatedClause } = result;
      const newContractTemplate = { ...contractTemplate }
      const clauseIdx = newContractTemplate.clauses.findIndex(c => c.id == clause.id)
      newContractTemplate.clauses[clauseIdx] = updatedClause
      setContractTemplate(newContractTemplate)
      setLoading(false)
      toast.success(t(translationPath + "toasts.saveClause.success"));
    } catch (error) {
      setLoading(false)
      console.error('Error saving template:', error);
      toast.error(t(translationPath + "toasts.saveClause.error"));
    }
  };

  const handleSubClauseDelete = useCallback((applyMerge) => {
    onClauseDelete(deleteClauseTarget, deleteSubclauseTarget, applyMerge)
    setIsDeleteSubClause(false)
  }, [deleteClauseTarget, deleteSubclauseTarget]);

  //used to handle scrolling inside clause when an input has been selected
  useEffect(() => {
    if (selected.eventType == 'ParamValueChange' && selected.paramName && clauseRef.current) {
      const elementId = `param-${selected.paramName}`
      if (!isElementVisibleInClause(elementId)) {
        scrollParamToTopIfNotVisible(elementId, clauseRef)
      }
    }
  }, [selected.paramName, clauseRef]);


  const resetClause = () => { }
  return (
    <div
      key={clause.id}
      className={`edit-template-contract-clause selectable-clause px-4 py-2 mx-1 my-2 template-clause-${clause.id
        } ${isSelected ? " selected-clause" : ""}`}
      onClick={handleClick}
      id={`${clause.id}`}
      ref={clauseRef}
      style={{
        minHeight: isExpanded && isSelected && (generateAI || changeGroups || isMinHeight) && "370px",
        width: isSelected && "99%",
      }}
    >
      {exchange && <ExchangeClauseModal
        currentClause={clause}
        onClose={() => setExchange(false)}
        clauseIndex={contractTemplate.clauses.findIndex(c => c.id == clause.id)}
      />}
      <div {...getToggleProps()} className="edit-contract-clause-header">
        <b style={{ width: '80%', overflowWrap: 'break-word' }}>{getClauseName(contractTemplate, clause, clauseIndex)}</b>
        <div className="d-flex" style={{ width: language == "ar" ? "40vh" : "35vh", marginLeft: "15px", justifyContent: "flex-end" }}>

          {isExpanded && (
            <div className="edit-contract-template-clause-header-icons w-100">
              {
                isSelected && generateAI && <div className="generate-ai-container">
                  <GenerateAIPortal initialClause={initialClause} reset={resetClause} generate={generateParamsWithAIHandler} close={() => setGenerateAI(false)} />
                </div>
              }
              {
                isSelected && changeGroups && <div className="edit-groups-container">
                  <ContractTemplateGroups clauseIndex={clause.index} onSelectGroup={onSelectGroupInClause} groups={contractTemplate.groups} close={() => setChangeGroups(false)} />
                </div>
              }
              <img
                id="select_group"
                src={changeGroups ? EyeOnIcon : EyeOffIcon}
                alt="#"
                style={{ color: "gray", maxHeight: "20px", marginRight: "15px" }}
                onClick={() => setChangeGroups(prev => !prev)}
              />
              <img
                id="ai_generator"
                src={isLoading ? spinner : (initialClause ? AIComparisonOn : AIComparison)}
                alt="#"
                style={{ color: "gray", marginLeft: language == "ar" && "15px", marginRight: language == "ar" && "5px" }}
                onClick={() => setGenerateAI(prev => !prev)}
              />
              <img
                id="comment_clause_template"
                src={isCommenting ? CommentIconOn : CommentIcon}
                alt="#"
                style={{ marginLeft: 9 }}
                onClick={() => {
                  setIsCommenting(!isCommenting);
                }}
              />
              <img
                id="edit_clause_template"
                src={isClauseEditing ? EditIconOn : EditIcon}
                alt="#"
                onClick={() => {
                  setIsClauseEditing(isSelected && !isClauseEditing);
                }}
              />
              <span style={{ marginLeft: "9px", cursor: "pointer" }}>
                {
                  confirmDelete ? <TiTick
                    fontSize={"17px"}
                    onClick={() => {
                      setConfirmDelete(false);
                      onClauseDelete(clause.id, null, null)
                    }} /> : <FaTrashAlt
                    fontSize={"17px"}
                    onClick={() => {
                      isSelected && setConfirmDelete(true);
                    }}
                  />
                }
              </span>
              <span style={{ marginLeft: "9px", cursor: "pointer" }}>
                <FaExchangeAlt
                  fontSize={"17px"}
                  onClick={() => {
                    setExchange(true);
                  }}
                />
              </span>
            </div>
          )}
          {!isExpanded ? <GoChevronDown
            style={{ marginLeft: "15px" }}
            onClick={(e) => {
              e.preventDefault()
              setExpanded(!isExpanded);
            }} /> : <GoChevronUp style={{ marginLeft: "15px", marginTop: "8px" }}
              onClick={(e) => {
                e.preventDefault()
                setExpanded(!isExpanded);
                setIsCommenting(false);
                setIsClauseEditing(false);

              }} />}
        </div>
      </div>
      <div {...getCollapseProps()}>
        {clause.segmentation?.segmentedText?.length > 0 ? (
          <div ref={isSelected ? formattedRawRef : null}>
            <RenderTemplateSegmentedText
              clauseId={clause.id}
              segmentsOverrides={{}}
              segmentation={clause.segmentation}
              onSegmentChange={onTextSegmentChange(clause.id, null)}
              isSelected={isSelected}
              isEditing={isClauseEditing && isSelected}
              formattedRawRef={formattedRawRef}
              inputValues={previewValues}
              setOpenedPopups={setOpenedPopups}
              openPopups={openPopups}
            />
          </div>
        ) : <div style={{ height: "50px" }}></div>}
        {isDeleteSubClause && <DeleteSubClauseModal onConfirm={handleSubClauseDelete} onClose={() => setIsDeleteSubClause(false)} />}
        {clause.subClauses.map((subClause, subClauseIndex) => {
          return (
            (<>
              <AddSubClausePlaceholder clauseId={clause.id} subClauseIndex={subClauseIndex} isSelected={isSelected} />
              <div
                key={subClause.id}
                className={`selectable my-2 clause-${clause.id}-${subClause.id}`}
                onClick={(event) => handleClick(event)}
                id={`${clause.id}-${subClause.id}`}
              >
                <div className="subClause-header">
                  <h6 className="fw-bold">{getSubClauseName(contractTemplate, clause, subClause, clauseIndex, subClauseIndex)}</h6>
                  <span style={{ marginLeft: "9px", cursor: "pointer" }}>
                    {
                      <FaTrashAlt
                        fontSize={"17px"}
                        onClick={() => {
                          setDeleteSubclauseTarget(subClause.id);
                          setDeleteClauseTarget(clause.id);
                          setIsDeleteSubClause(true);
                        }}
                      />
                    }
                  </span>
                </div>
                <div>
                  {" "}
                  {subClause.segmentation?.segmentedText?.length > 0 && (
                    <RenderTemplateSegmentedText
                      clauseId={clause.id}
                      segmentation={subClause.segmentation}
                      subClauseId={subClause.id}
                      segmentsOverrides={{}}
                      onSegmentChange={onTextSegmentChange(clause.id, subClause.id)}
                      isSelected={isSelected}
                      isEditing={isClauseEditing && isSelected}
                      formattedRawRef={formattedRawRef}
                      inputValues={previewValues}
                      setOpenedPopups={setOpenedPopups}
                      openPopups={openPopups}
                    />
                  )}
                </div>
              </div>
            </>
            )
          );
        })}
        <AddSubClausePlaceholder clauseId={clause.id} subClauseIndex={clause.subClauses.length} isSelected={isSelected} />
      </div>
    </div>
  );
}

export default ContractTemplateClause;
