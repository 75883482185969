export const prepareTableData = (params, inputValues, fileNames, beneficialsMap) => {
    const result = []
    for (const param of params) {
        const value = inputValues[param.name]
        const { clauseCode, clauseName, subClauseCode, subClauseName, name, type, label } = param
        const row = [clauseCode, clauseName, subClauseCode, subClauseName, name, type, label]
        switch (param.type) {
            case 'beneficial':
                const beneficial = beneficialsMap[value]
                result.push([...row, beneficial?.name ?? ""])
                break;
            case 'beneficial[]':
                const beneficials = Array.isArray(value) ? value.map(bId => beneficialsMap[bId]) : []
                result.push([...row, beneficials.map(b => b.name).join(", ")])
                break;
            case 'table':
                result.push([...row, ""])
                break;
            case 'csv':
                const [csvName] = value ?? [false, [], ""]
                result.push([...row, csvName])
                break;
            case 'file':
                if (value instanceof File) {
                    result.push([...row, value.name ?? ""])
                } else {
                    const fileName = fileNames[value]
                    result.push([...row, fileName ?? ""])
                }
                break;
            case 'list':
                result.push([...row, value?.map(idx => idx + 1)?.join(",") ?? ""])
                break;
            case 'enum':
                const arg = param.args[value]
                result.push([...row, arg?.option ?? ""])
                break;
            case 'boolean':
                result.push([...row, (value == true && "Oui") || (value == false && "Non") || ""])
                break;
            case 'comment':
            case 'date':
            case 'number':
            case 'string':
            case 'property':
                result.push([...row, value ?? ""])
                break;
            default:
                break;
        }
    }
    return result
}

export function transformTypes(types, parentDisplay = '', parentLevel1Id = null, parentLevel2Id = null) {
    let combinations = [];

    types.forEach((type) => {
        const display = parentDisplay ? `${parentDisplay}->${type.name}` : type.name;
        const level1Id = parentLevel1Id !== null ? parentLevel1Id : type.id;
        const level2Id = type.levels2 ? null : type.id;
        if (type.levels3 && type.levels3.length > 0) {
            // If levels3 exist, generate combinations for each levels3
            type.levels3.forEach((level3) => {
                combinations.push({
                    display: parentDisplay ? `${parentDisplay}->${type.name}->${level3.name}` : `${type.name}->${level3.name}`,
                    level1Id,
                    level2Id,
                    level3Id: level3.id,
                    level3Name: level3.name,
                });
            });
        } else if (!type.levels2) {
            // If no levels3 and no levels2, add a combination without level3
            combinations.push({
                display,
                level1Id,
                level2Id,
                level3Id: null,
            });
        }
        // If there are levels2 or levels3, recursively add combinations for them
        if (type.levels2) {
            // If there are levels3, recursively add combinations for levels2
            if (!type.levels3 || type.levels3.length === 0) {
                combinations = combinations.concat(transformTypes(type.levels2, display, level1Id, null));
            }
        }
    });

    return combinations;
}

export function transformTypesLevel2(types) {
    let combinations = [];
    types.forEach((type) => {
        const level1Id = type.id;
        const level1Name = type.name;
        if (type.levels2 && type.levels2.length > 0) {
            type.levels2.forEach((level2) => {
                combinations.push({
                    level1Id,
                    name: level1Name + "->" + level2.name,
                    level2Id: level2.id,
                    level2Name: level2.name,
                });
            });
        }
    })

    return combinations;
}