import React, { useState, useMemo } from 'react'
import './projectTemplateCard.scss'
import project from "../../../../assets/new-design-icons/Projects.svg"
import { useTranslation } from '../../../../contexts/TranslationProvider'
import { formatDate, getTypeLevels } from '../../../../helpers/helper'
import { useNavigate } from 'react-router-dom'
import ConfirmDeleteMessage from '../../../modals/ConfirmDeteleMessage'
import { FiMoreHorizontal } from "react-icons/fi";
import ProjectTemplateCardActionsModal from '../../../modals/ProjectTemplateCardActionsModal'

function ProjectTemplateCard({ data, setProjectListChanged,onDesactivate }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const translationPathStatus="pages.EditProjectTemplate."
  const translationPath = "pages.ressources."
  const [confirmDeleteMessage, setConfirmDelete] = useState(false);
  const { t, language } = useTranslation();
  const projectIcon = project.toString()
  const navigate = useNavigate();
  const onClick = () => {
    if (data && data.id) {
      navigate(`/edition-project-template/${data.id}`);
    }
  };
  const onDelete = () => {
    setConfirmDelete(true)
   }
  const typeDisplay = useMemo(() => {
      return getTypeLevels(data).map(
      level => {
        const translatedName = level.translations.find(t => t.language === language)
        if (translatedName) {
          return translatedName.name
        } else {
          return level.name
        }
      }
    ).join("/")
  }, [data, language]);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
      {confirmDeleteMessage && <ConfirmDeleteMessage onClose={()=>setConfirmDelete(false)} messageDelete={t("pages.viewProject.deleteMessage")} projectTemplate={data} setNewChange={setProjectListChanged}/>}
    <div onClick={onClick} key={data.id + '-' + data.type} className='project-card-container'>
      <div className='header'>
        <div className='title'>
          <div className='icon'>
            <img src={projectIcon} alt="icon" style={{ width: "30px", height: "30px" }} />
            <div className='name'>
              {data.name}
            </div>
          </div>
          </div>
          <div className='actions'>
            <button className="plus-icon" onClick={(e) => togglePopup(e)}>
              <FiMoreHorizontal size={20} />
            </button>
            {isPopupOpen &&
              <div className='popup'>
                <ProjectTemplateCardActionsModal onView={onClick} onDelete={onDelete} onDesactivate={onDesactivate}/>
              </div>
            }
          </div>
        </div>
        <div className='body'>
        <div className='body-section'>
          <span>
            <span className='label'>
              {t(translationPath + "cardLabels.type")}
            </span>
            <span className='value'>
              {typeDisplay}
            </span>
          </span>

        </div>
        <div className='body-section'>

        </div>
        <div className='timestamp'>
          <div>
            <span className='value'>
              {t(translationPath + "cardLabels.updatedAt")}
            </span>
            <span className='value'>
              {formatDate(data.updatedAt as any as string)}
            </span>
          </div>
          <div>
            <span className='value'>
              {t(translationPath + "cardLabels.createdAt")}
            </span>
            <span className='value'>
              {formatDate(data.createdAt as any as string)}
            </span>
          </div>
        </div>
      </div>
        <div className='d-flex footer justify-content-between'>
         <div  className={`${data.activated ? "status-active" : "status"}`}> {data?.activated ? t(translationPathStatus+"status.active") : t(translationPathStatus+"status.inactive")}</div>
        </div>
    </div>
    </>
  )
}

export default ProjectTemplateCard