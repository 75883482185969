import "./groupProjects.scss";
import { BsSearch } from "react-icons/bs";
import { useCallback, useEffect, useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import { useTranslation } from '../../contexts/TranslationProvider';
import { ContractEntity } from "../../domain/entities";
import CustomCombobox from "../../components/common/CustomCombobox";
import { ProjectClient } from "../../services/api/ProjectClient";
import GroupProjectsList from "./GroupProjectsList";
import LayoutFilter from "../../components/LayoutFilter";
import GroupProjectsTableItem from "./GroupProjectsTableItem";

function GroupProjects() {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [updateInProjectsList, setProjectListChanged] = useState(false);
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const projectClient = new ProjectClient(apiClient)
  const statuses: ContractEntity["status"][] = ['Draft', 'Done', 'Negotiation', 'ToBeSigned', 'Signed']
  const translationPath = "pages.groupProjects."
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const [displayCards, setDisplayCards] = useState("grid");
  
  const fetchData = useCallback(async () => {
    try {
      const data = await projectClient.getAssignedGroupProjects();
      const projects = data.rows;
      const groups = data.groups;
      setGroups(groups);
      setProjects(projects);
      setFilteredProjects(projects);
      setLoading(false); 
    } catch (error) {
      console.error("Error fetching projects: ", error);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData();
    setProjectListChanged(false)
  }, [updateInProjectsList]);
  useEffect(() => {
    if (!searchTerm) {
      setFilteredProjects(projects);
      return;
    }else{
      setFilteredProjects(projects.filter((project) =>
        project.name?.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    }
  }, [searchTerm]);
  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
    if (status) {
      setFilteredProjects(projects.filter((project) => project.status === status));
    } else {
      setFilteredProjects(projects);
    }
  }
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  return (
    <div className="group-projects-page">
      <section id="projects" className="pt-2 px-2">
        <span className="current-pathname">{t("pages.groupProjects.title")}</span>
        <div style={{ padding: "20px" }} className="search-zone">
          <div className="ressources-filters">
            <div className="filter-item">
            <CustomCombobox
          canDeselect
          label=""
          onDropdownOpen={() => { }}
          options={statuses}
          value={statusFilter}
          onChange={handleStatusFilterChange}
          optionDisplay={(option) => (<>
            {option ? <div className="filter-type-item">
              <span>
                {t(translationPath + option)}
              </span>
            </div> :
              <span>
                {t(translationPath + "status")}
              </span>
            }
          </>)}
          fullWidth={true}
          isOpen={openCombobox === "statusFilter"}
          setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "statusFilter" : null)}
        />
            </div>
          </div>
          <div className="search-section">
            <div className="search w-75">
              <BsSearch className="search-icon" />
              <input
                type="text"
                placeholder={t("pages.groupProjects.search")}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
       <LayoutFilter onLayoutChange={handleLayoutChange} />
        <div className="ressources-list">
          {loading ? (
            <div style={{ width: "83vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Loading height="75vh" />
            </div>
          ) :  displayCards=="grid"? (
            <div>
              <GroupProjectsList reloadProjects={setProjectListChanged} projects={filteredProjects} groups={groups} />
            </div>
          ):
          <GroupProjectsTableItem reloadProjects={setProjectListChanged} projects={filteredProjects} groups={groups} />
          }
        </div>
      </section>
    </div>
  );
}

export default GroupProjects;