import React, { useState, useEffect, useContext } from 'react'
import CustomCombobox from '../../../components/common/newDesign/CustomCombobox'
import { useTranslation } from '../../../contexts/TranslationProvider'
import { TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from '../../../domain/entities'
import { ValidationResult } from '../../../domain/Project'
import { getName } from '../../../helpers/Translation'
import { TypeLevel1Client } from '../../../services/api/TypeLevel1Client'
import useApiClientWithLoading from '../../../services/api/ApiClient'
import CreateProjectContext from '../../../contexts/CreateProjectContext'
function ClassificationSection() {
  const apiClient = useApiClientWithLoading();
  const typeLevel1Client = new TypeLevel1Client(apiClient);
  const [loading, setLoading] = useState(false);
  const [type1error, setType1Error] = useState<boolean>(false);
  const [type2error, setType2Error] = useState<boolean>(false);
  const [type3error, setType3Error] = useState<boolean>(false);
  const { t, language } = useTranslation()
  const [validationData, setValidationData] = useState<ValidationResult['errors']>({});
  const { projectTemplate, setProjectTemplate, existingProjectTemplates, existingProjects, typeError, setTypeError } = useContext(CreateProjectContext);
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const [levels1, setLevels1] = useState<TypeLevel1Entity[]>([]);
  const selectedLevel1 = levelsFilter.level1Id && levels1.find(l => l.id == levelsFilter.level1Id)
  const levels2 = selectedLevel1 && selectedLevel1.levels2
  const selectedLevel2 = levelsFilter.level2Id && levels2?.find(l => l.id == levelsFilter.level2Id)
  const levels3 = selectedLevel2 && selectedLevel2.levels3
  const selectedLevel3 = levelsFilter.level3Id && levels3?.find(l => l.id == levelsFilter.level3Id)
  useEffect(() => {
    setLoading(true);
    (async () => {
      const { rows } = await typeLevel1Client.getAllWithProjectTemplate();
      setLevels1(rows)
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (typeError) {
      if (!levelsFilter.level1Id) {
        setType1Error(true)
        return
      }
      if (!levelsFilter.level2Id) {
        setType2Error(true)
        return
      }
      if (!levelsFilter.level3Id) {
        setType3Error(true)
        return
      }
    } else {
      setType1Error(false)
      setType2Error(false)
      setType3Error(false)
    }
  }, [typeError])


  useEffect(() => {
    const templates = existingProjectTemplates?.filter((template) => {
      return (
        template.activated &&
        template.level1Id === levelsFilter?.level1Id &&
        template.level2Id === levelsFilter?.level2Id &&
        template.level3Id === levelsFilter?.level3Id &&
        template.level1Id
      );
    });
    setProjectTemplate(templates?.[0]);
    setTypeError(false)
  }, [levelsFilter]);

  return (
    <div className='section'>
      <span className='section-title'>
        {t('pages.createProject.classification')}
      </span>
      <div className='section-body'>
        <div>
          <CustomCombobox
            placeholder={t("pages.createProject.typeProject1")}
            options={levels1}
            value={selectedLevel1}
            optionDisplay={(option: TypeLevel1Entity) => getName(option, language)}
            optionValue={(option: TypeLevel1Entity) => option?.id}
            onChange={(level1Id: TypeLevel1Entity['id']) => {
              setLevelsFilter({
                level1Id,
                level2Id: null,
                level3Id: null
              })
            }}
            isOpen={openCombobox === "typeProject1"}
            setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "typeProject1" : null)}
          />
          {
            typeError && type1error &&
            <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>
          }
        </div>

        {!selectedLevel1 && validationData?.name && <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>}
        {levels2?.length > 0 && (
          <div>
            <CustomCombobox
              placeholder={t("pages.createProject.typeProject2")}
              options={levels2}
              value={selectedLevel2}
              optionDisplay={(option: TypeLevel2Entity) => getName(option, language)}
              optionValue={(option: TypeLevel2Entity) => option?.id}
              onChange={(level2Id: TypeLevel2Entity['id']) => {
                setLevelsFilter({
                  ...levelsFilter,
                  level2Id,
                  level3Id: null
                })
              }}
              isOpen={openCombobox === "typeProject2"}
              setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "typeProject2" : null)}
            />
            {
              typeError && type2error &&
              <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>
            }
            {!selectedLevel2 && validationData?.name && <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>}
          </div>
        )}
        {levels3?.length > 0 && (
          <div>
            <CustomCombobox
              placeholder={t("pages.createProject.typeProject3")}
              options={levels3}
              value={selectedLevel3}
              optionDisplay={(option: TypeLevel3Entity) => getName(option, language)}
              optionValue={(option: TypeLevel3Entity) => option?.id}
              onChange={(level3Id: TypeLevel3Entity['id']) => {
                setLevelsFilter({
                  ...levelsFilter,
                  level3Id,
                })
              }}
              isOpen={openCombobox === "typeProject3"}
              setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "typeProject3" : null)}
            />
            {
              typeError && type3error &&
              <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>
            }
            {!selectedLevel3 && validationData?.name && <div className="text-danger">{t('pages.createProject.errors.errorLevelProject')}</div>}
          </div>
        )}
      </div>
    </div>
  )
}

export default ClassificationSection