import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { FormDocxExportEntity } from "../../domain/entities/FormDocxExport";
import { FormDocxExportText } from "../../domain/Form";


export class FormDocxExportClient extends ModelClient<FormDocxExportEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'FormDocxExport')
  }

  async getDefault() {
    const { rows } = await this.getAllByFilter({ isDefault: true }, 'pure')
    return rows[0] || null;
  }


  async setDefault(id: FormDocxExportEntity['id']) {
    const reponse = await this.apiClient.post<{ row: FormDocxExportEntity }>(`${this.modelUrl}/setDefault`, {}, {
      params: { id }
    });
    return reponse.data.row;
  }

  async dataToDocx(id: FormDocxExportEntity['id'], data: FormDocxExportText) {
    const reponse = await this.apiClient.post<{ url: string }>(`${this.modelUrl}/dataToDocx`, { data }, {
      params: { id }
    });
    return reponse.data.url;
  }

};

