import React, { useState, useMemo, useEffect } from 'react'
import { FormParam, FormType } from '../domain/types/FormParams';
import { SegmentedClauseParam } from '../domain/types/ClauseParams';
import useTranslation from './useTranslation';
import { validateConstraint } from '../domain/Form';

const constraintOperators = ['<', '>', '==', '!', '&', '|', '(', ')', ':', '?', '<=', '>=', '!=', '+', '-', '*', '/', '%'];
const predefinedFormulas = ['LCM', 'now', 'dateDiffInYears', 'calAge', 'len', 'equal', 'lessThanDate', 'greaterThanDate', 'equalDate'];


export type ConstraintProps = {
  constraint: FormType.Constraint;
  availableParams: (FormParam | SegmentedClauseParam)[];
  onSingleConstraintChange: (label: string, value: string) => void;
}
export const useConstraint = ({
  constraint,
  availableParams,
  onSingleConstraintChange,
}: ConstraintProps) => {
  const { t } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const [constraintInput, setConstraintInput] = useState(constraint.value);
  const [constraintLabel, setConstraintLabel] = useState(constraint.label);
  const [showConstraintDropDown, setShowConstraintDropDown] = useState(false);
  const [filteredConstraintParams, setFilteredConstraintParams] = useState<(FormParam | SegmentedClauseParam)[]>([]);
  const [filteredFormulas, setFilteredFormulas] = useState([]);

  useEffect(() => {
    onSingleConstraintChange(constraintLabel, constraintInput);
  }, [constraintInput, constraintLabel]);

  useEffect(() => {
    const filteredParams = availableParams.filter((p) =>
      p.name.toLowerCase().includes(constraintInput?.toLowerCase())
    );
    const formulas = predefinedFormulas.filter((f) =>
      f.toLowerCase().includes(constraintInput?.toLowerCase())
    );
    setFilteredConstraintParams(filteredParams);
    setFilteredFormulas(formulas);
  }, []);

  const replaceLastPart = (
    selectedValue: string,
    input: string,
  ) => {
    const regex = new RegExp(`([^${constraintOperators.join("\\")}]+)$`);
    const newFormula = input.replace(regex, selectedValue);
    setConstraintInput(newFormula);
    setShowConstraintDropDown(false)
  };

  const unvalidConstraint = useMemo(
    () =>
      constraintInput &&
      !validateConstraint(constraintInput, availableParams),
    [constraintInput, availableParams]
  );

  const handleConstraintChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setConstraintInput(value);

    const lastPart = value
      .split(new RegExp(`[${constraintOperators.map((op) => "\\" + op).join("")}]`))
      .pop()
      ?.trim();

    if (lastPart) {
      setFilteredConstraintParams(
        availableParams.filter((p) =>
          p.name.toLowerCase().includes(lastPart.toLowerCase())
        )
      );
      setFilteredFormulas(
        predefinedFormulas.filter((f) =>
          f.toLowerCase().includes(lastPart.toLowerCase())
        )
      );
    }

    setShowConstraintDropDown(!!lastPart?.length);
  };

  return {
    closeConstraintDropDown: () => (setShowConstraintDropDown(false)),
    constraintLabel,
    constraintInput,
    handleConstraintChange,
    setConstraintLabel,
    showConstraintDropDown,
    filteredConstraintParams,
    filteredFormulas,
    replaceLastPart,
    unvalidConstraint,
  }
};

export default useConstraint