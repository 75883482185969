import React from 'react'
import { useTranslation } from '../../../../../contexts/TranslationProvider'
import { formatDate } from '../../../../../helpers/helper'
const aiIcon = require('../../../../../assets/new-design-icons/ai.png')

function ContractBody({ data, type, updatedSince, createdSince }) {
  const { t, language } = useTranslation()
  const translationPath = "pages.ressources."
  return (
    <div className='body'>
      <div className='body-section'>
        <span>
          <span className='label'>
          {t(translationPath + "cardLabels.type")}
        </span>
        <span className='value'>
          {type}
        </span>
        </span>
      </div>
     {data.template && <div className='body-section'>
      <span>
         <span className='label'>
        {t(translationPath + "cardLabels.templateName")}
        </span>
        <span className='value'>
          {data.template?.name}
        </span>
      </span>
      </div>}
      {
        data.type === "Summary" && <div className='body-section'>
          <span>
            <span className='label'>
          {t(translationPath + "cardLabels.templateName")}
          </span>
          <span className='value'>
            {data.summary?.contractTemplate?.name}
          </span>
          </span>
          
        </div>
      }
      <div className='body-section'>
        <span>
        <span className='label'>
          {t(translationPath + "cardLabels.source")}
          </span>
          <span className='value'>
            {
              data.type === "Summary" ? <span>{t("pages.contracts.filters.AI")} </span> : <span>{t("pages.contracts.filters.template")}</span>
            }
          </span>
        </span>
        </div>
      <div className='body-section'>

      </div>
      <div className='timestamp'>
        <div>
          <span className='value'>
          {t(translationPath + "cardLabels.updatedAt")}
          </span>
          <span className='value'>
            {formatDate(data.updatedAt as any as string)}
          </span>
        </div>
        <div>
          <span className='value'>
          {t(translationPath + "cardLabels.createdAt")}
          </span>
          <span className='value'>
         {formatDate(data.createdAt as any as string)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ContractBody