export type Country = {
  alpha3: string;
  name_en: string;
  demonym_en: string;
  name_fr?: string;
  demonym_fr?: string;
  name_ar?: string;
  demonym_ar?: string;
}
export const countries: Country[] = require('./countries.json');

export function getCountryByA3(alpha3: string) {
  return countries.find(c => c.alpha3 == alpha3) ?? null
}
