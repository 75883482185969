import { createContext } from "react";


export interface AdvancedTableContextType {
  data: any;
  columns: { display: string, accessorKey: string, width?: number, render?: (item: any) => any, sortable?: boolean, filter?: (setter: any, item: any) => any, isDate?: boolean, filtrable?: boolean, filterOptions?: {value: any, display: any}[] }[];
  appliedFilters: { key: string, value: any, type: "basic" | "date" | "custom" }[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<{ key: string, value: any, type: "basic" | "date" | "custom" }[]>>;
  sort: { key: string, direction: "asc" | "desc" } | null;
  setSort: React.Dispatch<React.SetStateAction<{ key: string, direction: "asc" | "desc" } | null>>;
  displayedData: any;
  setDisplayedData: React.Dispatch<React.SetStateAction<any>>;
}

const AdvancedTableContext = createContext<AdvancedTableContextType>({

} as any);

export default AdvancedTableContext;
