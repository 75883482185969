import React, { useState } from "react";
import Loading from "../../common/Loading";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeleteMessage.scss";
import { getName } from "../../../helpers/Translation";
import { toast } from "react-toastify";

interface ConfirmDeleteGroupProps {
  onClose: () => void;
  group?: any;
  onDelete?: (value) => void;
}

function ConfirmDeleteGroup({
  group,
  onDelete,
  onClose,
}: ConfirmDeleteGroupProps) {
  const [loading, setLoading] = useState(false);
  const { t, language } = useTranslation();
  const translationPath = t("modals.deleteGroup");
  const deleteGroup = async () => {
    try {
      onDelete(group);
      onClose();
      toast.success(translationPath.messages.success);

    } catch(err) {
      console.log('err :>> ', err);
      toast.success(translationPath.messages.error);
    }
  };
  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">
                      {t(translationPath.title)} {getName(group, language)}
                    </h1>

                    <div className="modal-buttons">
                      <button
                        className="btn submit-delete"
                        onClick={deleteGroup}
                      >
                        {t("modals.confirmDelete.delete")}
                      </button>

                      <button
                        onClick={() => onClose()}
                        className="btn btn-secondary cancel-delete"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "gray",
                        }}
                      >
                        {t("modals.confirmDelete.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteGroup;
