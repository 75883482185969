export function splitArrayToMatrix<T>(array: T[], rowLength: number): T[][] {
    if (rowLength <= 0) {
        throw new Error("Row length must be greater than 0");
    }

    const matrix: T[][] = [];
    for (let i = 0; i < array.length; i += rowLength) {
        matrix.push(array.slice(i, i + rowLength));
    }

    return matrix;
}

export function sortByOriginalOrder<T>(original: T[], sublist: T[]) {
    // Create a map that stores the index of each string in the original list
    const indexMap = new Map();
    original.forEach((item, index) => indexMap.set(item, index));

    // Sort the sublist based on the indices stored in the map
    sublist.sort((a, b) => (indexMap.get(a) ?? Infinity) - (indexMap.get(b) ?? Infinity));

    return sublist;
}
export function groupBy<T extends any>(array: T[], key: keyof T | ((item: T) => any)): Record<any, T[]> {
    let out: Record<any, T[]> = {} as Record<any, T[]>
    for (const item of array) {
        let value: any
        if ((typeof key == "function"))
            value = key(item)
        else
            value = item[key]
        out[value] = out[value] || []
        out[value].push(item)
    }
    return out
}
export function groupByUnique<T>(array: T[], key: (keyof T) | ((item: T) => keyof T)) {
    let out = {}
    for (const item of array) {
        let realKey
        if ((typeof key == "function"))
            realKey = key(item)
        else
            realKey = key
        out[realKey] = item
    }
    return out
}
export function intersectionByKey<T>(arrayA: T[], arrayB: T[], key: keyof T) {
    return arrayA.filter((itemA) => arrayB.find((itemB) => itemB[key] === itemA[key]));
}
export function initArray<T>(value: T, length: number) {
    let array: T[] = []
    for (var i = 0; i < length; ++i)
        array.push(value)
    return array

}
export function transpose<T>(matrix: T[][]) {
    if (matrix.length === 0) {
        return [];
    }

    const numRows = matrix.length;
    const numCols = matrix[0].length;

    // Initialize the transposed matrix with the correct dimensions
    const transposed: T[][] = new Array(numCols).fill(null).map(() => new Array(numRows));

    for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
            transposed[j][i] = matrix[i][j];
        }
    }

    return transposed;
}

export function removeDuplicates<T, K = string & keyof T>(array: T[], key: K): T[] {
    const uniqueMap: Record<string, boolean> = {};

    return array.filter(item => {
        const keyValue = item[key as any];
        if (!(keyValue in uniqueMap)) {
            uniqueMap[keyValue] = true
            return true;
        }
        return false;
    });
}
