import React, { useState } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import "../buttons.scss";
import ImportJsonProject from "../../../modals/ImportJsonProject";

function ImportJsonProjectTemplate() {
  const [showPopUp, setShowPopup] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <button
        className="navbar-button button-migrate"
        onClick={() => setShowPopup(!showPopUp)}
        style={{ position: "relative" }}
      >
        {t("navbar.importJsonProjectTemplate")}
      </button>
      {showPopUp && <ImportJsonProject onClose={() => setShowPopup(false)} />}
    </>
  );
}

export default ImportJsonProjectTemplate;
