import React, { useState, useEffect, useContext } from 'react';
import './editGroups.scss';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { toast } from 'react-toastify';
import { getName } from '../../../helpers/Translation';
import { FormTemplateEntity, FormTemplatePageEntity } from '../../../domain/entities';
import EditContractTemplateContext from "../../../contexts/EditFormTemplateContext";
import { BiEdit } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';

const FormTemplateGroups = ({ pageGroups, groups, close, onSelectGroup, pageIndex }: {
  onSelectGroup: (index: string, groups: FormTemplateEntity['groups'], viewGroups?: FormTemplateEntity['groups']) => void;
  close: () => void;
  pageIndex: FormTemplatePageEntity['index'];
  groups: FormTemplateEntity['groups'];
  pageGroups: FormTemplateEntity['groups'];


}) => {
  const { groups: originalGroups } = useContext(EditContractTemplateContext);
  const [groupsSelected, setGroupsSelected] = useState<FormTemplateEntity['groups']>([]);
  const [viewGroupsSelected, setViewGroupsSelected] = useState<FormTemplateEntity['groups']>([]);
  const { t, language } = useTranslation();
  const translationPath = "pages.contractTemplateParam.addGroups";

  const handleChange = (e, groupSelected) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedGroup = [...groupsSelected, groupSelected];
      const newViewSelectedGroup = viewGroupsSelected.filter(group => group.id !== groupSelected.id);
      onSelectGroup(pageIndex, newSelectedGroup, newViewSelectedGroup);
    } else {
      const newSelectedGroup = groupsSelected.filter(group => group.id !== groupSelected.id);
      onSelectGroup(pageIndex, newSelectedGroup, viewGroupsSelected);
    }
  };
  const handleChangeView = (e, groupSelected) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newViewSelectedGroup = [...viewGroupsSelected, groupSelected];
      const newSelectedGroup = groupsSelected.filter(group => group.id !== groupSelected.id);

      onSelectGroup(pageIndex, newSelectedGroup, newViewSelectedGroup);
    } else {
      const newViewSelectedGroup = viewGroupsSelected.filter(group => group.id !== groupSelected.id);
      onSelectGroup(pageIndex, groupsSelected, newViewSelectedGroup);
    }
  };


  useEffect(() => {
    if (groups) {
      const newSelectedGroup = groups.filter(group =>
        group.Group_FormTemplate?.pageCodes?.some(code => code === pageIndex)
      );
      const newViewSelectedGroup = groups.filter(group =>
        group.Group_FormTemplate?.viewPageCodes?.some(code => code === pageIndex)
      );
      setGroupsSelected(newSelectedGroup)
      setViewGroupsSelected(newViewSelectedGroup)
    } else {
      setGroupsSelected([]);
    }
  }, [pageIndex, JSON.stringify(groups)]);

  return (
    <div>
      <div className="generate-ai-portal" style={{ marginTop: "" }}>
        <div className="generate-ai-portal-header">
          <span className='close-dialog' onClick={() => close()}>X</span>
        </div>
        <div className="generate-ai-portal-content">
          <div className="generate-ai-portal-content-checkbox">
            <BiEdit style={{ width: 14, height: 14 }} />
            <BsEye style={{ width: 14, height: 14 }} />
            <label className='generate-ai-portal-label' >{ }</label>
          </div>
          {groups.map((group, index) => {
            const isChecked = groupsSelected.some(selectedGroup =>
              selectedGroup.id === group.id &&
              selectedGroup.Group_FormTemplate?.pageCodes?.some(code => code === pageIndex)
            );
            const isViewChecked = viewGroupsSelected.some(selectedGroup =>
              selectedGroup.id === group.id &&
              selectedGroup.Group_FormTemplate?.viewPageCodes?.some(code => code === pageIndex)
            );
            const originalGrp = originalGroups.find(g => g.id === group.id);
            return (
              <div key={index} className="generate-ai-portal-content-checkbox">
                <input
                  type="checkbox"
                  id={String(group.id)}
                  name={group.name}
                  checked={isChecked}
                  onChange={(e) => handleChange(e, group)}
                />
                <input
                  type="checkbox"
                  id={String(group.id)}
                  name={group.name}
                  checked={isViewChecked}
                  onChange={(e) => handleChangeView(e, group)}
                />
                <label className='generate-ai-portal-label' htmlFor={String(group.id)}>{getName(originalGrp, language)}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FormTemplateGroups;
