
import React, { } from "react";
import { ClauseEntity, ContractEntity } from "../../../domain/entities";
import { getRenderSegments } from "../../../helpers/segmentation";
import { RenderSegments, SegmentedTextType } from "../../../domain/types/ClauseParams";
import { useTranslation } from "../../../contexts/TranslationProvider";


interface DisplayClauseProps {
	inputValues: Record<string, any>;
	segmentation: ClauseEntity['segmentation'];
	fileNames: ContractEntity['fileNames'];
	beneficialsMap: ContractEntity['beneficialsMap'];
	segmentsOverrides: Record<string, string>;
	formattedRawRef?: React.MutableRefObject<any>;
}
function DisplayClause({
	segmentation,
	fileNames,
	beneficialsMap,
	segmentsOverrides,
	inputValues,
}: DisplayClauseProps) {

	const { t, language } = useTranslation();

	const renderSegments: RenderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
		fileNames, beneficialsMap, segmentation.segmentedParams, t, language)
		?.map(seg => {
			return {
				...seg,
				value: seg.value?.replaceAll(/\r\n/g, "\n"),
			}
		})

	return (
		<>
			{renderSegments?.map((segment, idx) => {
				const { id, value, type } = segment
				const key = idx;
				const text = id in segmentsOverrides ? segmentsOverrides[id] || "\u200B" : value

				const breakedHTMLText = text?.replaceAll(/\n/g, "<br/>")
				switch (type) {
					case SegmentedTextType.STATIC:
						return <React.Fragment key={key}>
							<span dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
						</React.Fragment>
					case SegmentedTextType.COMMENT:
						return <React.Fragment key={key}>
							<span className="comment-prefix">☞ &nbsp;</span>
							<span className='comment' dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
						</React.Fragment>
					case SegmentedTextType.PARAM:
						const paramPath = value.split(".");
						const paramLabel = segmentation.segmentedParams.find((param) => param.name == segment.paramName)?.label;
						return (
							<span id={`param-${segment.paramName}`} key={key} className={`param`}>
								{paramLabel ?? value}{paramPath[1] && `(${paramPath[1]})`}
							</span>
						);
					case SegmentedTextType.PARAM_VALUE:
						return <React.Fragment key={key}>
							<span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`param-value`}>{text}</span>
						</React.Fragment>
					case SegmentedTextType.PARAM_TABLE_VALUE:
						const [transposed, tableRows] = JSON.parse(value)
						const [headers, ...rows] = tableRows as string[][]
						return transposed ? (
							<table id={`param-${segment.paramName}`} key={key} className="my-4" style={{ borderCollapse: "collapse", width: "100%" }}>
								<tbody>
									{headers?.map((header, idx) => (
										<tr key={idx}>
											<th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
												{header}
											</th>
											{rows?.map((row, rowIndex) => (
												<td key={rowIndex} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
													{row[idx]}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						) :
							(
								<table id={`param-${segment.paramName}`} key={key} className="my-4" style={{ borderCollapse: "collapse", width: "100%" }}>
									<thead>
										<tr>
											{headers?.map((header, idx) =>
												<th key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
													{header}
												</th>)}
										</tr>
									</thead>
									<tbody>
										{rows?.map((row, idx) =>
											<tr key={idx} >
												{row?.map((cell, idx) =>
													<td key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
														{cell}
													</td>)}
											</tr>)
										}
									</tbody>
								</table>
							);
					case SegmentedTextType.PARAM_COMMENT_VALUE:
						return <React.Fragment key={key}>
							<span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`comment`}>{text}</span>
						</React.Fragment>
					default:
						break;
				}
			})}
		</>
	);
}

export default DisplayClause;
