import { AxiosInstance } from "axios";
import { AiFileEntity } from "../../domain/entities/AiFile";
import { ModelClient } from "../ModelClient";

export class AiFileClient extends ModelClient<AiFileEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'AiFile')
  }
  async createFromFile(file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.apiClient.post<{ row: AiFileEntity }>(`${this.modelUrl}/createFromFile`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async compile(id: string): Promise<any> {
    const response = await this.apiClient.post<{ row: AiFileEntity }>(`${this.modelUrl}/compile/${id}`);
    return response.data;
  }

  async query(id: string, query: string, language: string = "english"): Promise<any> {
    const response = await this.apiClient.post<{ row: AiFileEntity }>(`${this.modelUrl}/query`, {
      data:{
        ai_file_id: id,
        query,
        language,
      }
    });
    return response.data;
  }
}