import React, { useEffect, useRef } from 'react';
import './notificationPopup.scss';
import { formatNotificationDate } from '../../../helpers/helper';
import { NotificationEntity, Notification_UserEntity } from '../../../domain/entities';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from '../../../services/api/ApiClient';
import { UserClient } from '../../../services/api/UserClient';
import { getMessage } from '../../../helpers/Translation';
const NotificationPopup = ({ notifications, onClose, onNotificationClick }: { notifications: Notification_UserEntity[]; onClose: () => void, onNotificationClick: (id: NotificationEntity["id"]) => void }) => {
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, onClose]);

  const apiClient = useApiClientWithLoading();
  const userClient = new UserClient(apiClient)

  const handleNotificationClick = async (notification: NotificationEntity) => {
    onNotificationClick(notification?.id)
    try {
      await userClient.clickNotification(notification?.id);
    } catch (error) {
      console.error('Error marking notification as seen:', error);
    }

    switch (notification?.ressourceName) {
      case 'Contract':
        navigate(`/edition-contrat/${notification?.ressourceId}`);
        onClose();
        break;
      case 'Project':
        navigate(`/projet/${notification.ressourceId}`);
        onClose();
        break;
        case 'Form':
        navigate(`/edit-form/${notification.ressourceId}`);
        onClose();
        break;
      default:
    }
  };
  return (
    <div className={`notification-popup ${language == "ar" && "notification-popup-arabic"}`} ref={popupRef}>
      {notifications.length === 0 ? (
        <p className="no-notifications-message">{t("navbar.notifications.empty")}</p>
      ) : (
        <ul>
          {notifications?.map((item, index) => (
            <div key={index} className={`${item?.seenDate == null ? 'notification-not-seen' : ''}`} onClick={() => handleNotificationClick(item.Notification)}>
              <li
                className="notification-message"
              >
                {getMessage(item.Notification, language)}
              </li>
              <span className="notification-time">{formatNotificationDate(new Date(item.Notification?.createdAt).toISOString())}</span>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};
export default NotificationPopup;
