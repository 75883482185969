import { API_BASE_URL } from "../config";
import { AxiosInstance } from 'axios'

export type BaseEntity = {
	id?: number | string;
	createdAt?: Date;
	updatedAt?: Date;
}

export class ModelClient<E extends BaseEntity> {
	protected apiUrl: string;
	protected modelUrl: string;
	constructor(protected apiClient: AxiosInstance, protected modelName: string) {
		const baseUrl = apiClient.defaults.baseURL
		this.apiUrl = baseUrl || API_BASE_URL
		this.modelUrl = `${this.apiUrl}/${this.modelName}`
	}

	async create(data: E, schema = "pure"): Promise<E> {
		const url = new URL(this.modelUrl)
		url.searchParams.append("schema", schema)
		const response = await this.apiClient.post<{ row: E }>(url.href, { data }, {
			headers: {
				"Content-Type": "application/json"
			}
		})
		return response.data.row
	}
	async createMultiForm(data: Partial<Record<keyof E, any>>, schema = "pure"): Promise<E> {
		const url = new URL(this.modelUrl)
		url.searchParams.append("schema", schema)
		const formData = new FormData();
		for (const key in data)
			formData.append(key, data[key]);

		const response = await this.apiClient.post<{ row: E }>(url.href, formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return response.data.row;
	};

	async update(id: E['id'], data: E, schema = "pure"): Promise<E> {
		const url = new URL(this.modelUrl)
		url.searchParams.append("schema", schema)
		url.searchParams.append("id", String(id))
		const response = await this.apiClient.patch<{ row: E }>(url.href, { data }, {
			headers: {
				"Content-Type": "application/json"
			}
		})
		return response.data.row
	}
	async updateRestricted(id: E['id'], data: E, restrictedToken: string, schema = "pure"): Promise<E> {
		const url = new URL(`${this.modelUrl}/restricted`)
		url.searchParams.append("schema", schema)
		url.searchParams.append("id", String(id))
		const response = await this.apiClient.patch<{ row: E }>(url.href, { data }, {
			headers: {
				"user-token": restrictedToken,
				"Content-Type": "application/json",
			}
		})
		return response.data.row
	}
	async updateMultiForm(id: E['id'], data: Partial<Record<keyof E, any>>, schema = "pure"): Promise<E> {
		const url = new URL(this.modelUrl)
		url.searchParams.append("schema", schema)
		url.searchParams.append("id", String(id))
		const formData = new FormData();
		for (const key in data)
			formData.append(key, data[key]);

		const response = await this.apiClient.patch<{ row: E }>(url.href, formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return response.data.row;
	};

	async upsert(data: E, schema = "pure"): Promise<{ row: E }> {
		const url = new URL(this.modelUrl)
		url.searchParams.append("schema", schema)
		const response = await this.apiClient.put<{ row: E }>(url.href, { data }, {
			headers: {
				"Content-Type": "application/json"
			}
		})
		return response.data
	}
	async upsertMultiForm(data: Partial<Record<keyof E, any>>, schema = "pure") {
		const url = new URL(this.modelUrl)
		url.searchParams.append("schema", schema)
		const formData = new FormData();
		for (const key in data)
			formData.append(key, data[key]);

		const response = await this.apiClient.put<{ row: E }>(url.href, formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return response.data;
	};
	async getById(id: E['id'], schema = "full"): Promise<E> {
		const url = new URL(this.modelUrl)
		url.searchParams.append("id", String(id))
		url.searchParams.append("schema", schema)
		const response = await this.apiClient.get<{ row: E }>(url.href)
		return response.data.row
	}

	async getByIdRestricted(id: E['id'], restrictedToken: string, schema = "full"): Promise<E> {
		const url = new URL(`${this.modelUrl}/restricted`)
		url.searchParams.append("id", String(id))
		url.searchParams.append("schema", schema)
		const response = await this.apiClient.get<{ row: E }>(url.href, {
			headers: {
				"user-token": restrictedToken,
			}
		})
		return response.data.row
	}

	async getAll(schema = "nested"): Promise<{ rows: E[], count: number }> {
		const url = new URL(this.modelUrl + "/all")
		url.searchParams.append("schema", schema)
		const response = await this.apiClient.get<{ rows: E[], count: number }>(url.href)
		return response.data
	}
	async countAll(): Promise<number> {
		const url = new URL(this.modelUrl + "/count")
		const response = await this.apiClient.get<{ count: number }>(url.href)
		return response.data.count
	}

	async getAllByFilter(filter: E, schema = "nested") {
		try {
			const url = new URL(this.modelUrl + "/all")
			url.searchParams.append("schema", schema)
			const response = await this.apiClient.post<{ count: number, rows: E[] }>(url.href,
				{ where: filter },
				{
					headers: {
						"Content-Type": "application/json"
					},
				}
			);
			return response.data;
		} catch (error) {
			console.error(
				`Error fetching with filter: ${filter}`,
				error
			);
			return null;
		}
	}

	async countAllByFilter(filter: E): Promise<number> {
		try {
			const url = new URL(this.modelUrl + "/all")
			const response = await this.apiClient.post<{ count: number }>(url.href,
				{ where: filter },
				{
					headers: {
						"Content-Type": "application/json"
					},
				}
			);
			return response.data.count;
		} catch (error) {
			console.error(
				`Error counting with filter: ${filter}`,
				error
			);
			return null;
		}
	}

	async delete(id: E['id']) {
		const url = new URL(this.modelUrl)
		url.searchParams.append("id", String(id))
		const response = await this.apiClient.delete<{ deleted: boolean }>(url.href);
		return response.data;
	};
	async uploadFile(id: E['id'], fileName: keyof E, file: any) {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await this.apiClient.put(`${this.modelUrl}/file/${String(fileName)}?id=${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
			});

			return response.data;
		} catch (error) {
			console.error("Error uploading file:", error);
			throw error;
		}
	};
}