import { useContext, useEffect, useMemo, useState } from "react";
import { extractDataFromSummarySheetGroupedByClauseName } from "../../helpers/ParseTemplateData";
import AiValidationContext, { AiResponsesStatusRow } from "../../contexts/AiValidationContext";
import { BiAddToQueue } from "react-icons/bi";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import {
  extractRemovedAnswers,
  extractValidatedAnswers,
} from "../../helpers/helper";
import { useTranslation } from "../../contexts/TranslationProvider";
function ContractAIVariableInput() {
  const {
    aiResponsesStatus,
    rawSummarySheet,
    setRawSummarySheet,
    setAiCompletionPercentage,
    setAiResponseStatus,
  } = useContext(AiValidationContext);
  const {t}=useTranslation()
  const translationPath=t("pages.editionContractAi.edition")
  useEffect(() => {
    if (rawSummarySheet) {
      const validatedAnswers = extractValidatedAnswers(aiResponsesStatus);
      const removedAnswers = extractRemovedAnswers(aiResponsesStatus);
      const newCompletionPercentage = Math.round(
        ((validatedAnswers.length + removedAnswers.length) /
          aiResponsesStatus.length) *
        100
      );
      setAiCompletionPercentage(newCompletionPercentage);
    }
  }, [aiResponsesStatus, rawSummarySheet]);
  const [newQuestion, setNewQuestion] = useState("");
  const groupedData = useMemo(
    () =>
      rawSummarySheet
        ? extractDataFromSummarySheetGroupedByClauseName(rawSummarySheet)
        : [],
    [rawSummarySheet]
  );

  const [editingGroup, setEditingGroup] = useState(null);

  const handleAddNewQuestionClick = (clauseName) => {
    setEditingGroup(clauseName);
  };

  const handleAddSubmit = (clauseName) => {
    setRawSummarySheet((prev) => [
      ...prev,
      [clauseName, "", "", newQuestion],
    ]);
    setEditingGroup(null);
    setNewQuestion("");
  };

  const handleAddClick = (item) => {
    setAiResponseStatus((prevAnswers) => {
      const updatedAnswers = prevAnswers.map((answer) =>
        answer[2] === item.index ? [answer[0], 1, answer[2]] as AiResponsesStatusRow : answer
      );

      return updatedAnswers;
    });
  };

  const handleRemoveClick = (item) => {
    setAiResponseStatus((prevAnswers) => {
      const updatedAnswers = prevAnswers.map((answer) =>
        answer[2] === item.index ? [answer[0], 2, answer[2]] as AiResponsesStatusRow : answer
      );

      return updatedAnswers;
    });
  };

  return (
    <div className="variable-input">
      {Object.entries(groupedData).map(([clauseName, group]: [string, any[]]) => (
        <div className="d-flex flex-column pb-5 border my-5" key={clauseName}>
          <div className="d-flex justify-content-between my-2">
            <h4 className="input-group-title text-start ">{clauseName}</h4>
          </div>
          {/* this block is reserved for question adding */}
          {/* {editingGroup === clauseName ? (
            <div className="d-flex gap-3 align-items-center">
              <input
                type="text"
                placeholder="Ajouter une nouvelle question"
                className="question-input"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
              />
              <button
                className="ai-validate-button"
                onClick={() => handleAddSubmit(clauseName)}
              >
                Ajouter
              </button>
            </div>
          ) : (
            <div
              className="d-flex gap-2 cursor-pointer"
              onClick={() => handleAddNewQuestionClick(clauseName)}
            >
              <BiAddToQueue
                className="cursor-pointer"
                size={25}
                color="#2f14e5"
                title="Ajouter une nouvelle question"
              />

              <div className="input-group-title">Ajouter une question</div>
            </div>
          )} */}
          {group.map((item) => {
            const isAcceptedAnswer = aiResponsesStatus.some(
              (aiResponse) =>
                aiResponse[2] === item.index &&
                aiResponse[0] === item.response &&
                aiResponse[1] === 1
            );

            const isRemovedAnswer = aiResponsesStatus.some(
              (aiResponse) =>
                aiResponse[2] === item.index &&
                aiResponse[0] === item.response &&
                aiResponse[1] === 2
            );

            return (
              <div
                className="d-flex justify-content-between align-items-center my-2"
                key={`${item.index}_${clauseName}_${item.subClauseName}_${item.question}_${item.response}`}
              >
                <div className="d-flex align-items-center gap-2">
                  {isRemovedAnswer ? (
                    <div className="input-error ms-2">{t(translationPath.error)}</div>
                  ) : isAcceptedAnswer ? (
                    <div className="input-done ms-2">{t(translationPath.complete)}</div>
                  ) : (
                    <div className="input-inprogress ms-2">{t(translationPath.inProgress)}</div>
                  )}
                  <div className="d-flex flex-column">
                    <label htmlFor={item.response}>{item.question}</label>
                    <div className="ai-response">{item.response}</div>
                  </div>
                </div>
                <div className="d-flex">
                  <AiFillLike
                    className={`ai-validate-button cursor-pointer ${isAcceptedAnswer ? "up-active" : "inactive"
                      }`}
                    onClick={() =>
                      isAcceptedAnswer ? null : handleAddClick(item)
                    }
                    size={23}
                  />
                  <AiFillDislike
                    className={`mx-1 ai-validate-button cursor-pointer ${isRemovedAnswer ? "down-active" : "inactive"
                      }`}
                    onClick={() => handleRemoveClick(item)}
                    size={23}
                  />
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  );
}

export default ContractAIVariableInput;
