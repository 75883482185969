import React, { useEffect, useRef } from 'react';
import { logout } from '../../../services/api/UserAuth';
import "./userPopup.scss"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../contexts/TranslationProvider';
const UserPopup = ({ onClose }) => {
    const { t, language } = useTranslation();
    const popupRef = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef, onClose]);
    const handleLogout = () => {
        logout();
        navigate("/")
        onClose();
    };

    return (
        <div className={`popup-logout ${language == "ar" && "popup-logout-arabic"}`} ref={popupRef}>
            <div className="logout-btn" onClick={handleLogout}>
                {t("navbar.user.logout")}
            </div>
        </div>
    );
};

export default UserPopup;
