import React, { useEffect, useState, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SVGRender from '../../helpers/SVGRender'
import { close } from '../../assets/icons/close'
import './editProject.scss'
import projectSVG from "../../assets/new-design-icons/Projects.svg"
import BasicSection from './sections/BasicSection'
import BeneficialSection from './sections/BeneficialSection'
import ClassificationSection from './sections/ClassificationSection'
import RequirementSection from './sections/RequirementSection'
import CreateProjectContext from '../../contexts/CreateProjectContext'
import { ProjectEntity, ProjectTemplateEntity, BeneficialEntity } from '../../domain/entities'
import useApiClientWithLoading from '../../services/api/ApiClient'
import { ProjectClient } from '../../services/api/ProjectClient'
import { ProjectTemplateClient } from '../../services/api/ProjectTemplateClient'
import { useTranslation } from '../../contexts/TranslationProvider'
import { toast } from 'react-toastify'
import { set } from 'react-hook-form'
import Loading from '../../components/common/Loading'

function EditProject() {
  const projectIcon = projectSVG.toString()
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [project, setProject] = useState<ProjectEntity>(null)
  const [projectTemplate, setProjectTemplate] = useState<ProjectTemplateEntity>(null)
  const [selectedBeneficials, setSelectedBeneficials] = useState<BeneficialEntity[]>([]);
  const [existingProjects, setExistingProjects] = useState<ProjectEntity[]>([])
  const [existingProjectTemplates, setExistingProjectTemplates] = useState<ProjectTemplateEntity[]>([])
  const [projectName, setProjectName] = useState<string>('')
  const [projectDescription, setProjectDescription] = useState<string>('')
  const [notSelectedReq, setSelectedReq] = useState<any[]>([])
  const [nameError, setNameError] = React.useState<string[]>([])
  const [typeError, setTypeError] = React.useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const apiClient = useApiClientWithLoading()
  const projectClient = new ProjectClient(apiClient)
  const projectTemplateClient = new ProjectTemplateClient(apiClient)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const data = await projectClient.getAll();
        const dataTemplate = await projectTemplateClient.getAll();
        setExistingProjects(data?.rows);
        setExistingProjectTemplates(dataTemplate?.rows);
        const currentProject = await projectClient.getById(parseInt(projectId))
        if (!currentProject) return
        setProject(currentProject)
        setProjectName(currentProject.name)
        setProjectDescription((currentProject as any).description)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])
  useEffect(() => {
    if (projectTemplate) {
      setTypeError(false)
    }
  }, [projectTemplate])
  const handleUpdate = async () => {
    if (projectName === "") {
      setNameError([t('pages.createProject.errors.errorNameProject')])
      return
    }
    try {
      if (nameError.length > 0) return
      const data = { name: projectName, description: projectDescription };
      const row = await projectClient.update(project.id, data);
      toast.success(t("pages.createProject.messages.successUpdate"));
      navigate(`/projet/${row?.id}`)
    } catch (err) {
      console.error('Error updating project', err);
      toast.error(t("pages.createProject.messages.errorUpdate"));
    }
  }
  const handleCreate = async () => {
    console.log(projectTemplate)
    if (projectName === "") {
      setNameError([t('pages.createProject.errors.errorNameProject')])
      return
    }
    if (!projectTemplate) {
      setTypeError(true)
      return
    }
    try {
      if (nameError.length > 0) return
      if (typeError) return
      const data = { templateId: projectTemplate.id, values: {}, excludedRequirements: notSelectedReq, name: projectName, description: projectDescription };
      const row = await projectClient.create(data);
      if (row?.id && selectedBeneficials) {
        const project = await projectClient.update(row?.id, { ressource: { beneficialsId: selectedBeneficials.map(b => b.id) } });
      navigate(`/projet/${project?.id}`);

      }
      toast.success(t("pages.createProject.messages.success"));
      navigate(`/projet/${row?.id}`);
    } catch (err) {
      console.error('Error creating project:', err);
      toast.error(t("pages.createProject.messages.error"));
    }
  }
  useEffect(() => {
    console.log(projectTemplate)
  }, [projectTemplate])
  const CreateProjectContextValue = useMemo(() => ({
    project,
    setProject,
    projectTemplate,
    setProjectTemplate,
    existingProjects,
    existingProjectTemplates,
    selectedBeneficials,
    setSelectedBeneficials,
    projectName,
    setProjectName,
    projectDescription,
    setProjectDescription,
    notSelectedReq,
    setSelectedReq,
    nameError,
    setNameError,
    typeError,
    setTypeError
  }), [project, setProject, projectTemplate, setProjectTemplate, existingProjects, existingProjectTemplates, selectedBeneficials, setSelectedBeneficials, projectName, setProjectName, projectDescription, setProjectDescription, notSelectedReq, setSelectedReq, nameError, setNameError, typeError, setTypeError])

  return (
    <CreateProjectContext.Provider value={CreateProjectContextValue}>
      <div className='edit-project'>
        {
          loading ? <Loading height='30vh' /> :
            <>
              <div className='page-header'>
                <div className='left-section'>
                  <span style={{ cursor: "pointer" }} onClick={() => navigate("/projets")} className='close'>
                    <SVGRender svgContent={close} />
                  </span>
                  <div className='title'>
                    <img src={projectIcon} alt='project' style={{ width: '40px', height: '40px' }} />
                    <span className='name'>
                      {
                        project ? project.name : t('pages.createProject.title')
                      }
                    </span>

                  </div>
                </div>
                <div className='right-section'>
                </div>
              </div>
              <div className='page-body'>
                <BasicSection />
                {!project && <>
                  <ClassificationSection />
                  <BeneficialSection />
                  <RequirementSection />
                </>}
                <div className='section' style={{ justifyContent: "right", alignItems: "right" }}>
                  {
                    project ? (
                      <div className={`update-project-button ${nameError.length > 0 && "disabled-update"}`} onClick={handleUpdate}>
                        {t('pages.createProject.updateProject')}
                      </div>
                    ) : (
                      <div className={`create-project-button ${(!projectTemplate || nameError.length > 0) && "disabled-create"}`} onClick={handleCreate}>
                        {t('pages.createProject.createProject')}
                      </div>
                    )
                  }
                </div>

              </div>
            </>
        }


      </div>
    </CreateProjectContext.Provider>
  )
}

export default EditProject