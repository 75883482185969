import React, { useEffect, useState } from "react";
import "../commonmodal.scss";

import useApiClientWithLoading from "../../../services/api/ApiClient";

import { BaseEntity, ModelClient } from "../../../services/ModelClient";
import CustomCombobox from "../../common/CustomCombobox";
interface EntityLookupProps<E extends BaseEntity> {
	label?: string;
	onChange: (entity: E | null) => void;
	display: (entity: E) => string;
	lookUpField: (keyof E) & string;
	value: E | null;
	filter: (entity: E) => boolean;
}

function getEntityLookupComboBox<E extends BaseEntity>(modelName: string) {
	const EntityLookupComboBox = ({
		label,
		value,
		onChange,
		display,
		filter,
		lookUpField,
	}: EntityLookupProps<E>) => {
		label = label || lookUpField
		const [entity, setEntity] = useState<E | null>(value);
		const [openCombobox, setOpenCombobox] = useState<string | null>("");

		const [entities, setEntities] = useState<E[]>([]);

		const apiClient = useApiClientWithLoading();
		const modelClient = new ModelClient<E>(apiClient, modelName)

		useEffect(() => {
			modelClient.getAll().then(({ rows }) => {
				setEntities(rows.filter(filter))
			})
			return () => {
			}
		}, [filter])
		return (
			<CustomCombobox
				canDeselect
				isValid={true}
				label={label}
				onDropdownOpen={() => { }}
				options={entities ?? []}
				value={entity}
				onChange={(e) => {
					setEntity(e as E)
					onChange(e as E)
				}}
				optionValue={(option) => option}
				optionDisplay={display}
				isOpen={openCombobox === "name"}
				setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "name" : null)}
			/>
		);
	}
	return EntityLookupComboBox
}


export default getEntityLookupComboBox;
