import React from "react";
import { ClauseEntity } from "../../../domain/entities";
import ClauseText from "./ClauseText";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { getName } from "../../../helpers/Translation";

interface ComparisonClauseProps {
  clause: ClauseEntity;
}
function ComparisonClause({ clause }: ComparisonClauseProps) {
  const { t, language } = useTranslation();

  return (
    <>
      <div className="clause-comparison-current">
        <div className="d-flex header-clause-current">
          {" "}
          <div className="clause-comparison-header mb-4">
            <br />
            <span className="clause-comparison-actual-title half-border-bottom">
              {clause.name}
            </span>
          </div>
          <div className="d-flex flex-col">
            {clause?.template?.level1?.name && (
              <span className="level-types-template">
                {getName(clause?.template?.level1, language) +
                  (clause?.template?.level2
                    ? "/" + getName(clause?.template?.level2, language)
                    : "") +
                  (clause?.template?.level3
                    ? "/" + getName(clause?.template?.level3, language)
                    : "")}
              </span>
            )}
            <span>{clause.template?.name}</span>
          </div>
        </div>

        <div className="clause-comparison-body">
          <ClauseText segmentation={clause.segmentation} />
          {clause?.subClauses?.map((subClause) => {
            const isExcludedSubclause = false;
            return (
              !isExcludedSubclause && (
                <div
                  key={subClause.id}
                  className="my-2"
                  id={`${clause.id}-${subClause.id}`}
                >
                  <h5 className="fw-bold">{subClause.name}</h5>
                  <div>
                    {subClause.segmentation?.segmentedText?.length > 0 && (
                      <ClauseText segmentation={subClause.segmentation} />
                    )}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ComparisonClause;
