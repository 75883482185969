import React, { useEffect, useMemo, useState, useContext } from 'react'
import EditContractTemplateContext from '../../../contexts/EditFormTemplateContext';
import AddParamForm from './AddParamForm';
import FormTemplateNestedChildParam from './FormTemplateNestedChildParam';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './FormTemplateNestedChildrenParams.scss'
import useTranslation from '../../../hooks/useTranslation';
import { FormTemplatePageEntity } from '../../../domain/entities';
import { FormParamItem } from './FormTemplatePageInput';

function FormTemplateNestedChildrenParams({ page, param, params }: {
  page: FormTemplatePageEntity;
  param: FormParamItem;
  params: FormParamItem[];
}) {
  const { onAddParam, formTemplate, paramValues } = useContext(EditContractTemplateContext);
  const [showAddParam, setShowAddParam] = useState(false);
  const { setLanguage, language, t } = useTranslation();
  const editionTemplateTranslation = "pages.editionFormTemplate"
  const paramValue = useMemo(() => paramValues[param.name], [paramValues, param]);
  const nestedParams = useMemo(() => {
    const conditionalParams = params
      .filter(p => p.condition && p.condition[0]?.find(c => c.name === param.name && (c.value as any) === paramValue));
    return conditionalParams;
  }, [param, params, paramValue]);
  const [nestedList, setList] = useState(nestedParams);
  useEffect(() => {
    setList(nestedParams);
  }, [nestedParams]);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const item = nestedList.splice(sourceIndex, 1);
    const newList = [...nestedList];
    newList.splice(destinationIndex, 0, item[0]);
    setList(newList);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="nested-params">
          {(provided) => (
            <div
              className='nested-param-list'
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
                paramValue !== undefined && nestedList.map((nestedParam, index) => {
                  return (
                    <Draggable
                      key={index + " element"}
                      draggableId={index + " element"}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          style={{ backgroundColor: "red" }}
                          className='nested-param'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FormTemplateNestedChildParam
                            page={page}
                            param={nestedParam}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        showAddParam && paramValue !== undefined &&
        <AddParamForm
          params={nestedParams}
          handleAdd={(newParam) => onAddParam(page.id, { ...newParam, condition: [[{ name: param.name, value: paramValues[param.name] }]] })}
          handleCancel={() => setShowAddParam(false)}
        />
      }
      {
        !showAddParam && paramValue !== undefined &&
        <span className='add-tag' onClick={() => setShowAddParam(true)}>{t(editionTemplateTranslation + ".actions.addParam")}</span>
      }
    </div>
  )
}

export default FormTemplateNestedChildrenParams