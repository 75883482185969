import React from 'react'
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { forgetPassword } from '../../services/api/UserAuth';
import CarouselContainer from '../../components/common/newDesign/Carousel';
import "./forgetPassword.scss"
import { useTranslation } from '../../contexts/TranslationProvider';
import { useNavigate } from 'react-router-dom';
import FlagCombobox from '../../components/common/CustomCombobox/FlagCombobox';
import InputField from '../../components/common/newDesign/InputField';
interface FormData {
    email: string,
}
const ForgetPassword = () => {
    const navigate = useNavigate();
    const { t, language } = useTranslation()
    const translationPath = t("pages.forgetPassword")
    const [formData, setFormData] = useState<FormData>({
        email: ''
    });

    const [errors, setErrors] = useState<Record<string, string[]>>({
        email: [],
    });
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, email: [] });
    };
    const handlePrevious = () => {
        navigate("/sign-in")
    }

    const handleSubmit = async () => {
        if (!validateEmail(formData.email)) {
            setErrors({ ...errors, email: [t("pages.signIn.newDesign.inputs.email.error")] });
            return;
        }

            try {
                const message = await forgetPassword(formData.email);

                console.log('Password reset request successful:', message);
                setPasswordResetSuccess(true);
            } catch (error) {
                console.error('Error sending password reset request:', error);
            }
        
    };
    return (
        <div className='forget-password-content'>
            <CarouselContainer />
            <div className='sign-up-sub-content'>
                <div className='header'>
                    <div onClick={handlePrevious} className='logo-back'></div>
                    <FlagCombobox
                        optionValue={(option) => option?.language || null}
                        optionDisplay={(option) => option?.flag || ""}
                    />
                </div>
                <div className='form-container'>
                    <div className='form-section'>
                        <h1 className='greeting'>
                            {t(translationPath.title)}
                        </h1>
                        <div className='divider-container'>
                            <div className='divider'></div>
                            <p className='content'>
                                {t(translationPath.instructions)}
                            </p>
                            <div className='divider'></div>
                        </div>
                        <div className='signin-form'>
                            <InputField
                                value={formData.email}
                                onChange={handleInputChange}
                                name="email"
                                label={t(translationPath.emailLabel)}
                                error={errors.email}
                                type={"email"}
                                placeholder={""}
                            />
                            <button
                                disabled={errors.email.length !== 0}
                                className={`create-account ${errors.email.length !== 0 && "disabled"}`}
                                onClick={handleSubmit}
                            >
                                {t(translationPath.sendButton)}
                            </button>
                            {passwordResetSuccess && (
                                <div className="alert alert-success" style={{ width: "100%" }}>
                                    {t(translationPath.successMessage)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ForgetPassword