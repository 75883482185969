import React, { useState, useEffect, useMemo } from 'react'
import "./ressource.scss"
import { useTranslation } from '../../contexts/TranslationProvider'
import { FiMoreHorizontal } from "react-icons/fi";
import form from "../../assets/new-design-icons/Form.svg"
import contract from "../../assets/new-design-icons/Contract.svg"
import calculate from "../../assets/new-design-icons/Calculate.svg"
import chat from "../../assets/new-design-icons/Chat.svg"
import { ar, fr, enUS } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { getTypeLevels } from '../../helpers/helper';
import { ContractEntity, FormEntity, DocumentEntity } from '../../domain/entities';
import Loading from '../../components/common/Loading';
import { getName } from '../../helpers/Translation';
const aiIcon = require('../../assets/new-design-icons/ai.png')
const docIcon = require('../../assets/new-design-icons/doc.png')

function RessourceRequired({
  data,
  onDelete,
  onClick
}) {
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const [updatedSince, setUpdatedSince] = useState("")
  const [createdSince, setCreatedSince] = useState("")
  const calculateIcon = calculate.toString()
  const chatIcon = chat.toString()
  const { t, language } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  const typeDisplay = useMemo(() => {
    if(data.type === 'SUMMARY'){
  const level1 = data.summary.level1 || null;
  const level2 = data.summary.level2 || null;
  const level3 = data.summary.level3 || null;
  return [level1, level2, level3].filter((level) => level !== null).map((level) => {
    const translatedName = level.translations.find(t => t.language === language);
    if (translatedName) {
      return translatedName.name;
    }
    return level.name;
  }).join("/");
    }else{
      return getTypeLevels(data).map(
      level => {
        const translatedName = level.translations.find(t => t.language === language)
        if (translatedName) {
          return translatedName.name
        } else {
          return level.name
        }
      }
    ).join("/")
    }
     
  }, [data, language]);
  useEffect(() => {
    const updateDate = new Date(data?.updatedAt);
    const creationDate = new Date(data?.createdAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setUpdatedSince(time);
    }
    if (Date.parse(creationDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(creationDate, { addSuffix: true, locale });
      setCreatedSince(time);
    }
  }, [data, language]);

  const handleClick = () => {
    onClick()
  }
  return (
    <div onClick={handleClick} key={data.id + '-' + data.type} className='ressource-required-card-container'>
      <div className='header'>
        <div className='title'>
          <div className='icon'>
            {
              data.type === 'FORM' ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                data.type === 'CONTRACT' || data.type === 'CONTRACTAI' || data.type === 'SUMMARY' ? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                  data.type === 'DOCUMENT' ? <img src={docIcon} alt="icon" style={{ width: "25px", height: "25px" }} /> :
                    <></>
            }
          </div>
          <div className='name'>
         {data.name}
          </div>
        </div>
        <div className='actions'>
          {/* <button className="plus-icon" onClick={(e) => togglePopup(e)}>
            <FiMoreHorizontal size={20} />
          </button> */}

          {
            data.type === "DOCUMENT" &&
            data.useCaseResponses &&
            data.useCaseResponses.length > 0 &&
            <img src={aiIcon} alt="ai-icon" className="ai-icon" />
          }
          {
            data.type === "FORM" &&
            data.isAIGenerated &&
            <img src={aiIcon} alt="ai-icon" className="ai-icon" />
          }
        </div>
      </div>
      <div className='body'>
      {data.type != "DOCUMENT" && <div className='body-section'>
        <span>
          <span className='label'>
          {t("pages.ressources." + "cardLabels.type")}
        </span>
        <span className='value'>
          {typeDisplay}
        </span>
        </span>
      </div>}
      </div>
      <div className='footer'>
        <div className={`users-display ${language == "ar" && "users-arabic-display"}`}>
          {(data as ContractEntity)?.ressource?.users?.slice(0, 2).map((user, index) => (
            <div key={user.id} className={`avatar-circle layer-${2 - index}`}>
              {user?.email ? <p>{user.email.charAt(0).toUpperCase()}</p> : null}
            </div>
          ))}
          {((data as ContractEntity)?.ressource?.users.length > 2) ? (
            <div className="avatar-circle layer-3">
              <p>+{((data as ContractEntity)?.ressource?.users.length - 2)}</p>
            </div>
          ) : null}
          {(data as FormEntity)?.ressource?.users?.slice(0, 2).map((user, index) => (
            <div key={user.id} className={`avatar-circle layer-${2 - index}`}>
              {user?.email ? <p>{user.email.charAt(0).toUpperCase()}</p> : null}
            </div>
          ))}
          {((data as FormEntity)?.ressource?.users.length > 2) ? (
            <div className="avatar-circle layer-3">
              <p>+{((data as FormEntity)?.ressource?.users.length - 2)}</p>
            </div>
          ) : null}
          {data.users && data.users.length > 0 && data.users[0] && data.users[0].fullName &&
            <div className='user-name'>
              {data.users[0].fullName}
            </div>
          }
        </div>
        <div className='status'>
          {t('pages.viewProject.document.createDocument')}
          </div>
      </div>
    </div>
  )
}

export default RessourceRequired