import { useState, useEffect } from 'react';
import React from 'react'
import AskYourQuestionsContext from '../../../contexts/AskYourQuestionsContext'
import { RiDeleteBinLine } from "react-icons/ri";
import document from "../../../assets/new-design-icons/Document.svg"
function ConversationList() {
  const { setCurrentFile, currentFile, aiFiles, conversations, currentConversation, setCurrentConversation, setPendingQuestion, setAdd, handleNameChange, handleAddConversation, newName, add, handleDelete, currentReglement } = React.useContext(AskYourQuestionsContext)
  const [combinedRessources, setCombinedRessources] = useState([])
  const documentIcon = document.toString()
  useEffect(() => {
    const allRessources = [...aiFiles.map(aiFile => ({ ...aiFile, type: 'file' })),
    ...conversations.map(conversation => ({ ...conversation, type: 'conversation' }))
    ].sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1
      } else {
        return 1
      }
    })
    setCombinedRessources(allRessources)
  }, [aiFiles, conversations])
  return (
    <div className='conversations-container'>
      <div className='add-conversation-container'>
        {add ?
          <div className='add-conversation-form'>
            <div onClick={() => setAdd(false)} className='close'>X</div>
            <input onChange={handleNameChange} value={newName} className='add-conversation-input' type='text' placeholder='Enter conversation name' />
            <button onClick={() => handleAddConversation(currentReglement ? currentReglement.id : null)}>
              Add
            </button>
          </div> :
          <div className='add-conversation' onClick={() => setAdd(true)}>
            Add new conversation
          </div>}
      </div>
      <div className='conversation-list'>
        {/* <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: "20px", flex: 1 }}> */}
          {combinedRessources.map((ressource, index) => {
            if (ressource.type === "conversation") {
              return (
                <div style={{ cursor: "pointer", color: currentConversation?.id === ressource.id ? "blue" : "black" }} key={index} onClick={() => {
                  setCurrentConversation(ressource)
                  setPendingQuestion(null)
                }} className='conversation'>
                  {ressource.name ? ressource.name : "conversation"}
                  <RiDeleteBinLine onClick={(e) => handleDelete(e, ressource)} />
                </div>
              )
            } else {
              return (
                <div 
                key={index} 
                className='ai-file' 
                style={{ color: currentFile?.id === ressource.id ? "blue" : "black" }}
                onClick={()=>{
                  setCurrentFile(ressource)
                }}
                >
                  <div className='ai-file-header'>
                  <img src={documentIcon} alt="icon" style={{ width: "20px", height: "20px" }} />
                  <span>{ressource.name}</span>
                  </div>
                  <RiDeleteBinLine onClick={(e) => handleDelete(e, ressource)} />
                </div>
              )
            }
          })}
      </div>


    </div>
  )
}

export default ConversationList