class MiddlewareManager {
  static subscribers = {};

  static subscribe(eventName, callback) {
    if (!MiddlewareManager.subscribers[eventName]) {
      MiddlewareManager.subscribers[eventName] = [];
    }
    MiddlewareManager.subscribers[eventName].push(callback);

    // Return a function to unsubscribe
    return () => {
      MiddlewareManager.unsubscribe(eventName, callback);
    };
  }

  static unsubscribe(eventName, callback) {
    if (MiddlewareManager.subscribers[eventName]) {
      MiddlewareManager.subscribers[eventName] = MiddlewareManager.subscribers[eventName].filter(
        (cb) => cb !== callback
      );
    }
  }

  static async invoke(eventName, data) {
    if (MiddlewareManager.subscribers[eventName]) {
      for (const callback of MiddlewareManager.subscribers[eventName]) {
        try {
          await callback(data);
        } catch (error) {
          throw error
        }
      }
    }
  }
}

export default MiddlewareManager;
