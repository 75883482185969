import { createContext } from "react";
import { ClauseEntity, ContractEntity, ContractTemplateEntity, GroupEntity, SubClauseEntity } from "../domain/entities";
import { EnumOption, ListOption, ListParam, RenderSegments, SegmentedBooleanParam, SegmentedClauseParam, SegmentedEnumParam, TableLigne, TableParam } from "../domain/types/ClauseParams";


export interface EditContractTemplateContextType {
	contractTemplate: ContractTemplateEntity;
	setContractTemplate: React.Dispatch<React.SetStateAction<ContractTemplateEntity>>;
	combinedTemplateParams: SegmentedClauseParam[];
	groups: GroupEntity[];
	setCombinedTemplateParams: React.Dispatch<React.SetStateAction<SegmentedClauseParam[]>>;
	previewValues: ContractEntity['paramValues'];
	setPreviewValues: React.Dispatch<React.SetStateAction<ContractEntity['paramValues']>>;
	onParamChanged: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: SegmentedClauseParam) => void;
	onParamDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: SegmentedClauseParam) => void;
	onOptionAdd: (
		param: SegmentedEnumParam | ListParam | TableParam | SegmentedBooleanParam,
		option: EnumOption | ListOption | TableLigne | boolean
	) => void;
	onSegmentChange: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string, text: string) => void
	onAddParam: (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string, newParam: SegmentedClauseParam, textBefore: string, textAfter: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], field: string, deletedSegments: RenderSegments) => void;
	contractListParentRef: React.MutableRefObject<any>,
	onOptionChanged: (param: SegmentedEnumParam | ListParam,
		option: SegmentedEnumParam['args'][number]['option'] | ListParam['args'][number], index: number) => void;
	onOptionDelete: (param: SegmentedClauseParam, index: number) => void;
	onParamTypeChange: (param: SegmentedClauseParam, type: SegmentedClauseParam['type']) => void;
	onParamReorder: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], oldIndex: number, newIndex: number) => void;
	onClauseDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], applyMerge: Boolean) => Promise<ContractTemplateEntity>;
	onSegmentDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => void;
	insertClause: (index: number, name: string) => Promise<ContractTemplateEntity>;
	onClauseNameChange: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], name: string) => void;
	insertSubClause: (insertIndex: number, clauseId: ClauseEntity['id'], subClauseName: string) => Promise<ContractTemplateEntity>;
	insertImportedClause: (insertIndex: number, clauseId: ClauseEntity['id'], newName: string) => Promise<ContractTemplateEntity>;
	insertSubClauseWithContent: (insertIndex: number, clauseId: ClauseEntity['id'], subClause: SubClauseEntity) => Promise<ContractTemplateEntity>;
	onClauseSwap: (index: number, localId: number, externalId: number, externalName: string) => Promise<ContractTemplateEntity>;
	onSelectGroupInClause: (index: string, group: GroupEntity[], viewGroup?: GroupEntity[]) => void;
	onParamNameChanged: (param: SegmentedClauseParam, newName: string) => void;
	setTemplateGroups: (group: GroupEntity, add: boolean) => void;
	onApplySegmentation: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => void;
	updateClauseContent: (clauseId: ClauseEntity['id'], newClause: ClauseEntity) => void;
}

const EditContractTemplateContext = createContext<EditContractTemplateContextType>({

} as any);

export default EditContractTemplateContext;
