import React, { useState } from "react";
import { Ressource } from "../../components/common/RessourceCard";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { ContractAIClient } from "../../services/api/ContractAIClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import { FormClient } from "../../services/api/FormClient";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationProvider";
import RessourceTableItem from "../../components/common/newDesign/RessourceCard/RessourceTableItem";
import { FiMoreHorizontal } from "react-icons/fi";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import AdvancedTable from "../../components/AdvancedTable";
import { formatDate, getTypeLevels } from "../../helpers/helper";
import form from "../../assets/new-design-icons/Form.svg";
import contract from "../../assets/new-design-icons/Contract.svg";
import calculate from "../../assets/new-design-icons/Calculate.svg";
import chat from "../../assets/new-design-icons/Chat.svg";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
import { toast } from "react-toastify";

function RessourceTable({
  ressources,
  reloadRessources,
}: {
  ressources: Ressource[];
  reloadRessources: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const { t, language } = useTranslation();
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const docIcon = calculate.toString();
  const [openActionPopup, setOpenActionPopup] = useState<{
    type: Ressource["type"];
    id: string | number;
  } | null>(null);
  const [ressourceToHandle, setRessourceToHandle] = useState<Ressource | null>(
    null
  );
  const togglePopup = (e, ress) => {
    e.stopPropagation();
    if (!ress)
      return setOpenActionPopup(null);
    if(openActionPopup?.type === ress.type && openActionPopup?.id === ress.id)
      return setOpenActionPopup(null);
    setOpenActionPopup({
      type: ress.type,
      id: ress.id,
    })
  };
  const handleClick = (ressource: Ressource) => {
    switch (ressource.type) {
      case "Form":
        return navigate(`/edit-form/${ressource.id}`);
      case "Contract":
        return navigate(`/edition-contrat/${ressource.id}`);
      case "ContractAI":
        return navigate(`/ai-edition-contrat/${ressource.id}`);
      case "Document":
        return navigate(`/document/${ressource.id}`);
      case "Summary":
        return navigate(`/document-summary/${ressource.id}`);
      case "ContractTemplate":
        return navigate(`/edition-template/${ressource.id}`);
    }
  };
  const apiClient = useApiClientWithLoading();
  const handleDelete = async (ressource: Ressource) => {
    const contractClient = new ContractClient(apiClient);
    const contractAIClient = new ContractAIClient(apiClient);
    const documentClient = new DocumentClient(apiClient);
    const formClient = new FormClient(apiClient);
    let deleted = false;
    try{
      switch (ressource.type) {
        case "Contract":
          ({ deleted } = await contractClient.delete(ressource.id));
          break;
        case "Form":
          ({ deleted } = await formClient.delete(ressource.id));
          break;
        case "ContractAI":
          ({ deleted } = await contractAIClient.delete(ressource.id));
          break;
        case "Document":
        case "Summary":
          ({ deleted } = await documentClient.delete(ressource.id));
          break;
      }
      toast.success(t("pages.docxExport.popup.messages.successDelete"));
    }catch(e){
      toast.error(t("pages.docxExport.popup.messages.errorDelete"));
    }

    if (deleted) {
      await reloadRessources();
    }
  };
  return (
    <section id="contracts-table" className="contracts-table">
      <>
        <AdvancedTable
          columns={[
            {
              display: t("pages.projects.tableHeaders.name"),
              accessorKey: "name",
              render: (row) => {
                const displayType = row.ressourceType;
                return (
                  <div className="title-table-item">
                    {displayType === "Form" ? (
                      <img
                        src={formIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : displayType === "Contract" ||
                      displayType === "ContractAI" ||
                      displayType === "Summary" ? (
                      <img
                        src={contractIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : displayType === "Document" ? (
                      <img
                        src={docIcon}
                        alt="icon"
                        style={{ width: "25px", height: "25px" }}
                      />
                    ) : (
                      <></>
                    )}
                    <div style={{ marginLeft: "20px" }}>{row.name}</div>
                  </div>
                )
              },
              width: 3
            },
            {
              display: t("pages.projects.tableHeaders.type"),
              accessorKey: "type",
              width: 2
            },
            {
              display: t("pages.projects.tableHeaders.status"),
              accessorKey: "status",
              width: 2,
              render: (row) => {
                return row.status ? (
                  <div className="status">{row.status}</div>
                ) : (
                  ""
                );
              }
            },
            {
              display: t("pages.projects.tableHeaders.createdAt"),
              accessorKey: "createdAt",
              render: (row) => {
                if (row.createdAt) {
                  return formatDate(row.createdAt);
                }
              },
              width: 2,
              isDate: true
            },
            {
              display: t("pages.projects.tableHeaders.updatedAt"),
              accessorKey: "updatedAt",
              render: (row) => {
                if (row.updatedAt) {
                  return formatDate(row.updatedAt);
                }
              },
              width: 2,
              isDate: true
            },
            {
              display: t("pages.projects.tableHeaders.actions"),
              accessorKey: "actions",
              sortable: false,
              filtrable: false,
              width: 2
            }
          ]}
          originalData={ressources.map((ressource) => ({
            ...ressource,
            status: (ressource as any).status ? t("status." + (ressource as any).status) : "",
            ressourceType: ressource.type,
            type: ressource.type === "Summary" ? [ressource.summary.level1, ressource.summary.level2, ressource.summary.level3]
              .filter((level) => level !== null)
              .map((level) => {
                const translatedName = level.translations.find(
                  (t) => t.language === language
                );
                if (translatedName) {
                  return translatedName.name;
                }
                return level.name;
              })
              .join("/") : getTypeLevels(ressource)
                .map((level) => {
                  const translatedName = level.translations.find(
                    (t) => t.language === language
                  );
                  if (translatedName) {
                    return translatedName.name;
                  } else {
                    return level.name;
                  }
                })
                .join("/"),
            actions: (
              <div className="actions">
                <button className="plus-icon" onClick={(e) => togglePopup(e, ressource)}>
                  <FiMoreHorizontal size={20} />
                </button>
                {(openActionPopup?.type === ressource.type && openActionPopup?.id == ressource.id) && (
                  <div className="">
                    <ContractCardActionsModal
                      onView={() => handleClick(ressource)}
                      onDelete={() => {
                        setRessourceToHandle(ressource);
                        setConfirmDelete(true);
                      }}
                    />
                  </div>
                )}
              </div>
            ),
            onDelete: () => {
          setRessourceToHandle(ressource);
        setConfirmDelete(true);
              },
        onEdit:()=>{
          handleClick(ressource)
        }
          }))}
        />
      </>
      {showConfirmDelete && ressourceToHandle && (
        <ConfirmDeletePopup
          msgDelete={t("pages.contracts.msgDeleteRessource")}
          onClose={() => {
            setOpenActionPopup(null);
            setConfirmDelete(false);
            setRessourceToHandle(null);
          }}
          entityToDelete={ressourceToHandle?.name || ""}
          onDelete={() => handleDelete(ressourceToHandle)}
        />
      )}
    </section>
  );
}

export default RessourceTable;
