import { useEffect, useState } from "react";
import { EditFormContextType } from "../../../../contexts/EditFormContext";
import EventManager from "../../../../services/EventManager";
import {  useNavigate } from "react-router-dom";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'

function SaveDocumentButton() {
  const navigate=useNavigate()
  const [editFormContext, setEditFormContext] = useState<EditFormContextType>(null)
  const { t } = useTranslation();
  const handleFormSubmit = async () => {
   EventManager.invoke('SaveDoc')
  };

  return (
    <button className="navbar-button" type="submit" onClick={handleFormSubmit}>
      {t("navbar.saveForm2")}
    </button>
  );
}

export default SaveDocumentButton;
