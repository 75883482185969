import React, { createContext, useState, ReactNode, useContext, Dispatch, SetStateAction } from "react";
// loading template is a pair of type and id
type LoadingTemplateType = {
  type: string;
  id: number;
}
type LoadingTemplateContextType = {
  loadingTemplates: LoadingTemplateType[];
  setLoadingTemplates: Dispatch<SetStateAction<LoadingTemplateType[]>>;
};

export const LoadingTemplateContext = createContext<LoadingTemplateContextType>(null);


const LoadingTemplateProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [loadingTemplates, setLoadingTemplates] = useState<LoadingTemplateType[]>([]);
  return (
    <LoadingTemplateContext.Provider value={{ loadingTemplates, setLoadingTemplates }}>
      {children}
    </LoadingTemplateContext.Provider>
  );
}
export default LoadingTemplateProvider
