import React, { useState, useEffect } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./addTranslation.scss"
const { default: Delete } = require("../../../assets/delete.svg");

const AddTranslation = ({
  translationList = [],
  handleLanguageChange,
  handleDeleteTranslation,
}) => {
  const { t, language } = useTranslation();
  const translationPath = t("component.addLanguage");
  const languageOptions = [
    { value: "en", label:translationPath.languages.english},
    { value: "ar", label: translationPath.languages.arabic },
    { value: "fr", label: translationPath.languages.frensh },
  ];
  const [translations, setTranslations] = useState(
    translationList?.length === 3
      ? [...translationList]
      : [...translationList, { name: "", language: "" }]
  );

  useEffect(() => {
    if (translationList?.length > 0 && translationList?.length < 3) {
      setTranslations([...translationList, { name: "", language: "" }]);
    } else if (translationList.length === 3) {
      setTranslations([...translationList]);
    }
  }, [translationList]);
  useEffect(() => {
    const filteredTranslations = translations.filter(t => t.name !== '' && t.language !== '');
    handleLanguageChange(filteredTranslations);
  }, [translations]);

  const onLanguageChange = (language: "en" | "fr" | "ar", index: number) => {
    const updatedTranslations = [...translations];
    updatedTranslations[index].language = language;
    setTranslations(updatedTranslations);

    const usedLanguages = updatedTranslations
      .filter((t) => t.language)
      .map((t) => t.language);
    if (
      index === translations.length - 1 &&
      language &&
      usedLanguages.length < languageOptions.length
    ) {
      setTranslations([...updatedTranslations,{ name: "", language: "" }]);
    }
  };

  const onNameChange = (name: string, index: number) => {
    const updatedTranslations = [...translations];
    updatedTranslations[index].name = name;
    setTranslations(updatedTranslations);
  };

  const deleteTranslation = (index: number) => {
    const updatedTranslations = translations.filter((_, i) => i !== index);
    setTranslations(
      updatedTranslations.length > 0
        ? updatedTranslations
        : [{ name: "", language: "" }]
    );
    handleDeleteTranslation(index);
  };

  const usedLanguages = translations.map((t) => t.language).filter(Boolean);

  return (
    <div className="language-add-form">
      {translations.map((translation, index) => (
        <div key={index} className="translation-entry">
          <input
            type="text"
            placeholder="Name"
            className="language-input form-control"
            value={translation.name || ""}
            onChange={(e) => onNameChange(e.target.value, index)}
          />
          <select
            className="language-select"
            value={translation.language || ""}
            onChange={(e) =>
              onLanguageChange(e.target.value as "en" | "fr" | "ar", index)
            }
          >
            <option value="" disabled>
              {translationPath.chooseLanguage}
            </option>
            {languageOptions.map((lang) => (
              <option
                key={lang.value}
                value={lang.value}
                disabled={
                  usedLanguages.includes(lang.value) &&
                  lang.value !== translation.language
                }
              >
                {lang.label}
              </option>
            ))}
          </select>
          <img
            alt="img-delete"
            src={Delete}
            className="remove-type-level"
            onClick={() =>deleteTranslation(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default AddTranslation;