import { useContext } from "react";
import ContractAIVariableInput from "./ContractAIVariableInput";
import { getCompletionClassName } from "../../helpers/helper";
import AiValidationContext from "../../contexts/AiValidationContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationProvider";

function EditContractAI() {
  const { aiCompletionPercentage, aiContractData } =
    useContext(AiValidationContext);
    const location = useLocation();
    const {t}=useTranslation()
    const translationPath=t("pages.editionContractAi.edition")
  return (
    <div className="row mt-2 pt-2">
      <div className="col-6">
        <h4 className="contract-name">{aiContractData.contractName}</h4>

        <div className="clause-list-container selected">
          <div className="ai-text">{aiContractData.textContent}</div>
        </div>
      </div>
      <div className="col-6 px-5">
        <div className="d-flex justify-content-between">
          <h4>
            {t(translationPath.title)}
            </h4>
          <h5 className="me-3">
          {t(translationPath.progress)}{" "}
            <span className={getCompletionClassName(aiCompletionPercentage)}>
              {aiCompletionPercentage}%
            </span>{" "}
          </h5>
        </div>

        <div className="variable-input-container">
          <ContractAIVariableInput />
        </div>
      </div>
    </div>
  );
}

export default EditContractAI;
