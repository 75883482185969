import React, { useEffect, useState, useCallback } from "react";
import AuthRouter from "./AuthRouter";
import AppRouter from "./AppRouter";
import { getAuthUser } from "../../services/api/UserAuth";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TranslationProvider } from "../../contexts/TranslationProvider";
import ErrorBoundary from "../../ErrorBoundary";
import ErrorPage from "../../pages/ErrorPage";

function Router() {
  const { userToken, user } = getAuthUser();
  const [isLoggedIn, setIsLoggedIn] = useState(!!userToken && !!user);
  const [key, setKey] = useState(0); 

  useEffect(() => {
    if (userToken && user && !isLoggedIn) setIsLoggedIn(true);
  }, [userToken, user, isLoggedIn]);

  useEffect(() => {
    const handleLogout = () => setIsLoggedIn(false);
    const handleLogin = () => setIsLoggedIn(true);
    window.addEventListener("logout", handleLogout);
    window.addEventListener("login", handleLogin);
    return () => {
      window.removeEventListener("logout", handleLogout);
      window.removeEventListener("login", handleLogin);
    };
  }, []);

  const resetAppState = useCallback(() => {
    console.log("Resetting application state...");
    setKey((prevKey) => prevKey + 1);
    setIsLoggedIn(!!userToken && !!user);
  }, [userToken, user]);

  return (
    <TranslationProvider>
      <ErrorBoundary onReset={resetAppState}>
        <BrowserRouter>
          <Routes key={key}>
            {isLoggedIn ? (
              <Route path="/*" element={<AppRouter />} />
            ) : (
              <Route path="/*" element={<AuthRouter isLoggedIn={isLoggedIn} />} />
            )}
            <Route path="/error-test" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </TranslationProvider>
  );
}

export default Router;
