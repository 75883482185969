import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import AdminFunction from '../../components/AdminFunction';
import CreateContractModal from '../../components/modals/CreateContractModal';
import CreateFormModal from '../../components/modals/CreateFormModal';
import { useTranslation } from '../../contexts/TranslationProvider';
import CreateContractAIModal from '../../components/modals/CreateContractAIModal';
import ImportDocumentModal from '../../components/modals/ImportDocumentModal';
import "./createDocumentRequirement.scss"
import { getName } from '../../helpers/Translation';
import FillDocumentSummaryModal from '../../components/modals/FillDocumentSummaryModal';
function CreateDocument() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dataProject = { requirement: state?.req, project: state?.project, previousPathname: state?.previousPathname }
  const { t, language } = useTranslation();
  return (
    <div className="projects px-3 pt-2 w-100">
      <section id='create-documents-requirement' className='create-documents-requirement'>
        {dataProject?.previousPathname.map((item, index) => (
          <span key={index}>
            <span className='previous-pathname' onClick={() => navigate(item.link)}>
              {item.label}
            </span>
            <span className="path-separator"> {">"} </span>
          </span>
        ))}
        <span className='current-pathname'>{getName(dataProject?.requirement, language)}</span>
        <h3 className='create-requirement-title py-1'>{getName(dataProject?.requirement, language)}</h3>
        <div className="container-action-requirement">
          {dataProject?.requirement?.types?.includes(2) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.pilot.cards.contract.title")} description={t("pages.pilot.cards.contract.description")} buttonTitle={t("pages.pilot.cards.contract.button")}
              render={(onClose) => <CreateContractModal onClose={onClose} dataProject={dataProject} typeReq={2} />} />
          </div>}
          {dataProject?.requirement?.types?.includes(8) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.pilot.cards.form.title")} description={t("pages.pilot.cards.form.description")} buttonTitle={t("pages.pilot.cards.form.button")}
              render={(onClose) => <CreateFormModal onClose={onClose} dataProject={dataProject} typeReq={8} />} />
          </div>}
          {dataProject?.requirement?.types?.includes(4) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.listener.cards.contract.title")} description={t("pages.listener.cards.contract.description")} buttonTitle={t("pages.listener.cards.contract.button")}
              render={(onClose) => <FillDocumentSummaryModal onClose={onClose} dataProject={dataProject} typeReq={4} />} />
          </div>}
          {dataProject?.requirement?.types?.includes(1) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.viewProject.popups.importDocument.header")} description={t("pages.viewProject.popups.importDocument.header")} buttonTitle={t("pages.listener.cards.question.button")}
              render={(onClose) => <ImportDocumentModal onClose={onClose} dataProject={dataProject} typeReq={1} />} />
          </div>}
        </div>
      </section>
    </div>
  )
}

export default CreateDocument