import React, { createContext, useState, ReactNode, useContext, Dispatch, SetStateAction, useEffect } from "react";
import { UserEntity, UserRoleType } from "../domain/entities";
import { getAuthUser } from "../services/api/UserAuth";

type AuthUserContextType = {
  authUser: UserEntity;
  setAuthUser: Dispatch<SetStateAction<UserEntity>>;
  activeRole: UserRoleType;
  setActiveRole: Dispatch<SetStateAction<UserRoleType>>;
};

export const AuthUserContext = createContext<AuthUserContextType>(null);


const AuthUserProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const { user } = getAuthUser()
  const isUserRoleType = (value: string): value is UserRoleType => {
    return user?.roles?.includes(value as UserRoleType);
  };
  const [authUser, setAuthUser] = useState<UserEntity>(user);
  const storedRole = localStorage.getItem('activeRole');
  const defaultRole = storedRole && isUserRoleType(storedRole) ? storedRole : user?.roles[0]; 
  const [activeRole, setActiveRole] = useState<UserRoleType>(defaultRole);
  useEffect(() => {
    setAuthUser(user)
    if (!user?.roles.includes(activeRole)) {
      setActiveRole(user?.roles[0]);
    }
    localStorage.setItem('activeRole', activeRole);
    return () => {};
  }, [user?.email, activeRole]); 

  return (
    <AuthUserContext.Provider value={{
      authUser, setAuthUser,
      activeRole, setActiveRole,
    }}>
      {children}
    </AuthUserContext.Provider>
  );
}
export default AuthUserProvider
