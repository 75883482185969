import { createContext } from "react";
import { ContractAIEntity, ProjectEntity, ProjectTemplateRequirementEntity } from "../domain/entities";
type AIContractData = {
  contractName: ContractAIEntity['name'];
  contractId: ContractAIEntity['id'];
  formId: ContractAIEntity['formId'];
  textContent?: string;
}
export type AiResponsesStatusRow = [ContractAIEntity['aiResponsesStatus'][number][0], ContractAIEntity['aiResponsesStatus'][number][1], number]
interface AiValidationContextType {
  aiValidationValues: any;
  setAiValidationValues: React.Dispatch<React.SetStateAction<any>>;
  aiContractData: AIContractData;
  setAiContractData: React.Dispatch<React.SetStateAction<AIContractData>>;
  aiResponsesStatus: AiResponsesStatusRow[];
  setAiResponseStatus: React.Dispatch<React.SetStateAction<AiResponsesStatusRow[]>>;
  aiCompletionPercentage: number;
  setAiCompletionPercentage: React.Dispatch<React.SetStateAction<number>>;
  rawSummarySheet: ContractAIEntity['summarySheet'];
  setRawSummarySheet: React.Dispatch<React.SetStateAction<ContractAIEntity['summarySheet']>>;
  project?: ProjectEntity
  setProject?:React.Dispatch<React.SetStateAction<ProjectEntity>>;
  requirement?: ProjectTemplateRequirementEntity,
  setRequirement?:React.Dispatch<React.SetStateAction<ProjectTemplateRequirementEntity>>
  requirementType?:number,
  setRequirementType?:React.Dispatch<React.SetStateAction<number>>

}
const AiValidationContext = createContext<AiValidationContextType>({
  aiValidationValues: {},
  setAiValidationValues: () => { },
  aiContractData: {} as any,
  setAiContractData: () => { },
  aiResponsesStatus: [],
  setAiResponseStatus: () => { },
  aiCompletionPercentage: 0,
  setAiCompletionPercentage: () => { },
  rawSummarySheet: [],
  setRawSummarySheet: () => { },
  project:{},
  requirement:{},
  requirementType:0,
  setProject: () => { },
  setRequirement: () => { },
  setRequirementType: () => { },
});

export default AiValidationContext;
