import React, { useState } from "react";
import { dots } from "../../../assets/icons/dots"
import ContractAnalysisModal from "../../../components/modals/ContractAnalysisModal";
import ContractElement from "./ContractElement";
import useTranslation from "../../../hooks/useTranslation";

function ContractTable({ contracts, groups }) {
    const [showContractModal, setShowContractModal] = useState(false);
    const [selectedContractId, setSelectedContractId] = useState(null);
    const { t } = useTranslation();
    const translationPath = "pages.analytics.contracts.table";
    const getGroupName = (groupId) => {
        const group = groups.find((group) => group.id === groupId);
        return group?.name;
    }
    return <>
        {showContractModal && (
            <ContractAnalysisModal
                onClose={() => setShowContractModal(false)}
                currentContractId={selectedContractId}
            />
        )}
        <div style={{ display: "flex", flexDirection: "column"}}>
            <div key={0} className="contract-header" style={{ marginTop: "5px", display: "flex", flexDirection: "row", borderBottom: "1px solid #DBDFE" }}>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }} >
                        {t(`${translationPath}.name`)}
                    </span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t(`${translationPath}.updatedAt`)}
                    </span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t(`${translationPath}.createdAt`)}
                    </span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t(`${translationPath}.status`)}
                    </span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t(`${translationPath}.group`)}
                    </span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t(`${translationPath}.client`)}
                    </span>
                </div>
                <div className="header-cell" style={{ flex: 1 }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t(`${translationPath}.author`)}
                    </span>
                </div>
            </div>

            <div className="contract-rows">
            {contracts.length === 0 && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", opacity: 0.5 }}>{t(`${translationPath}.empty`)}</div>}
                {
                    contracts.map((contract, index) => {
                        return (
                            <div key={index}>
                                <ContractElement
                                    contract={contract}
                                    getGroupName={getGroupName}
                                    setShowContractModal={setShowContractModal}
                                    setSelectedContractId={setSelectedContractId} />
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
    </>
}

export default ContractTable;
