import axios from "axios";
import { API_BASE_URL } from "../../config";
import { logout } from "./UserAuth";
import { useLocation, useNavigate } from "react-router-dom";

// ApiClient.js
export const useApiClientWithLoading = (
  setLoading: (v: boolean) => void = null,
  contentType = "application/json",
  logoutOnUnauthenticated = true,
) => {
  const location = useLocation();
  const client = axios.create({
    baseURL: API_BASE_URL,
  });
  const navigate = useNavigate()

  client.interceptors.request.use((config) => {
    if (!config.headers["Content-Type"])
      config.headers["Content-Type"] = contentType;
    if (setLoading) {
      setLoading(true);
    }

    const token = localStorage.getItem("user-token");

    if (token) {
      config.headers["user-token"] = config.headers["user-token"] || token;
    }
    return config;
  });

  client.interceptors.response.use(
    (response) => {
      if (setLoading) {
        setLoading(false);
      }
      return response;
    },
    (error) => {
      if (setLoading) {
        setLoading(false);
      }
      console.error(error);
      const isRestricted = location.pathname.startsWith("/restricted/")
      if (logoutOnUnauthenticated && !isRestricted && error?.response?.status == 401) {
        logout();
        navigate("/")
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default useApiClientWithLoading;
