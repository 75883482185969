import { useContext, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import "./editContractTemplate.scss";
import SelectionContext, { SelectedType } from "../../../contexts/SelectionContext";
import ContractTemplateParamList from "./ContractTemplateParamList";
import ContractTemplateClauseList from "./ContractTemplateClauseList";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import EventManager from "../../../services/EventManager";
import * as XLSX from "xlsx"
import { saveWorkbook } from "../../../utils/csv";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import EditContractClauseList from "../../EditContractPage/components/EditContractClauseList";
import EditContractInputList from "../../EditContractPage/components/EditContractInputList";
import parseTemplateData from "../../../helpers/ParseTemplateData";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingTemplateContext } from "../../../contexts/LoadingTemplateContext";
import { fixTemplateIndexation, getAllParams, SegementedParamsToFormParams } from "../../../domain/ContractTemplate";
import { downloadFileJSON } from "../../../utils/json";
import GroupsForm from "./GroupsForm";
import { createPromiseSub, PromiseSub } from "../../../utils/promise";
import MiddlewareManager from "../../../services/MiddlewareManager";
import SaveDraftModel from "../../../components/modals/SaveDraftModal";
import { BsGearFill, BsNodePlusFill } from "react-icons/bs";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { GetAccessUser } from "../../../hooks/useContractData";
import ClauseSettingsModal from "../../../components/modals/ClauseSettingsModal";
import WorkflowSettingsModal from "../../../components/modals/WorkflowSettingsModal";
import { GroupsGraph } from "../../../domain/entities";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EyeOffIcon } = require("../../../assets/eye-off.svg");
const { default: EyeOnIcon } = require("../../../assets/eye-on.svg");

//maintain the state of the fetched contract data and handle the interactions between the ClauseList and VariableInput components.
function EditContractTemplate({
}: {
  }) {
  const {
    setContractTemplate,
    contractTemplate,
    contractListParentRef
  } = useContext(EditContractTemplateContext);
  console.log('contractTemplate :>> ', contractTemplate);
  const {
    setContractTemplate: previewSetContractTemplate,
    setAccessUser,
  } = useContext(InputValuesContext);
  const { t, language, setLanguage } = useTranslation()
  const { loadingTemplates, setLoadingTemplates } = useContext(LoadingTemplateContext)
  const [preview, setPreview] = useState<boolean>(false)
  const navigate = useNavigate()
  const [selected, setSelected] = useState<SelectedType>({
    eventType: null,
    clauseId: null,
    paramName: null,
  });

  const params = useMemo(() => {
    return SegementedParamsToFormParams(
      getAllParams(contractTemplate)
    )
  }, [contractTemplate])
  const templateData = useMemo(() => parseTemplateData(contractTemplate), [contractTemplate])
  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const [isEditingName, setIsEditingName] = useState(false);
  const [showClauseSettings, setShowClauseSettings] = useState(false)
  const [showTransitionsSettings, setShowTransitionsSettings] = useState(false)
  const [changeGroups, setChangeGroups] = useState(false)
  const [newTemplateName, setNewTemplateName] = useState(contractTemplate.name);

  const location = useLocation()
  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);

  useEffect(() => {
    previewSetContractTemplate(contractTemplate)
    const accessUser = GetAccessUser(contractTemplate, contractTemplate.groups.map(g => g.id))
    setAccessUser(accessUser)
    return () => {

    }
  }, [contractTemplate])

  const onLocation = async (pathname: string) => {
    if (location.pathname.includes("/edition-contrat-template") && !pathname.includes("/edition-contrat-template")) {
      const newSub = createPromiseSub()
      setSaveDraft(true);
      setSub(newSub);
      await newSub.promise
    }
  };

  useEffect(() => {
    MiddlewareManager.subscribe('location', onLocation);
    return () => {
      MiddlewareManager.unsubscribe('location', onLocation);
    };
  }, []);
  const saveTemplateAsCSV = async () => {
    try {

      const { url } = await contractTemplateClient.export(contractTemplate?.id);
      const apiUrl = new URL(url);
      const response = await fetch(apiUrl.toString());
      const arrayBuffer = await response.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      saveWorkbook(workbook, `${contractTemplate?.name}.xlsx`);
    } catch (error) {
      console.log('error', error);
    }
  };
  const saveTemplateAsJSON = async () => {
    try {
      if (!contractTemplate?.id) {
        console.error('No contract template ID found');
        return;
      }
      const { url } = await contractTemplateClient.export(contractTemplate.id, 'json');
      await downloadFileJSON(url, `${contractTemplate.name}.json`);
    } catch (error) {
      console.error('Error saving template as JSON:', error);
    }
  };

  useEffect(() => {
    const arabicRegex = /[\u0600-\u06FF]/;
    if (arabicRegex.test(contractTemplate?.name)) {
      setLanguage("ar")
    }
  }, [])
  useEffect(() => {
    EventManager.subscribe('ExportCSV', saveTemplateAsCSV)
    return () => {
      EventManager.unsubscribe('ExportCSV', saveTemplateAsCSV)
    }
  }, [])
  useEffect(() => {
    EventManager.subscribe('ExportJSON', saveTemplateAsJSON)
    return () => {
      EventManager.unsubscribe('ExportJSON', saveTemplateAsJSON)
    }
  }, [])


  const saveContractTemplateHandler = async () => {
    try {
      setLoadingTemplates(prev => [...prev, { id: contractTemplate.id, type: "ContractTemplate" }]);
      let updateData = fixTemplateIndexation(contractTemplate)
      const updatedTemplate = await contractTemplateClient.updateWithWarnings(contractTemplate.id, updateData);
      toast.success(t("pages.contractTemplateParam.toasts.saveTemplate.success"));
      setLoadingTemplates(prev => prev.filter(temp => temp.id !== contractTemplate.id && temp.type === "ContractTemplate"));
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error(t("pages.contractTemplateParam.toasts.saveTemplate.error"));
      setLoadingTemplates(prev => prev.filter(temp => temp.id !== contractTemplate.id && temp.type === "ContractTemplate"));
    }
  };
  useEffect(() => {
    EventManager.subscribe('SaveContractTemplate', saveContractTemplateHandler);
    return () => {
      EventManager.unsubscribe('SaveContractTemplate', saveContractTemplateHandler);
    };

  }, [contractTemplate]);

  const activateTemplate = async () => {
    try {
      await saveContractTemplateHandler().then(async () => {
        contractTemplateClient.activate(contractTemplate?.id).then(() => {
          navigate("/contract-templates")
          toast.success(t("pages.contractTemplateParam.toasts.activateTemplate.success"));
        })
      })
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    EventManager.subscribe('ActivateTemplate', activateTemplate)
    return () => {
      EventManager.unsubscribe('ActivateTemplate', activateTemplate)
    }
  }, [])

  const [isClauseEditing, setIsClauseEditing] = useState(false)

  // Memoize the value object for SelectionContext.Provider
  const selectionContextValue = useMemo(
    () => ({
      selected, setSelected,
      isClauseEditing, setIsClauseEditing,
    }),
    [selected, setSelected, isClauseEditing, setIsClauseEditing]
  );


  const clauseListRef = useRef(null);
  const inputListRef = useRef(null);

  const titleRef = useRef(null);
  useEffect(() => {
    EventManager.subscribe('Preview', setPreview);
    return () => {
      EventManager.unsubscribe('Preview', setPreview);
    };
  }, [setPreview]);
  const handleNameEdit = () => {
    setIsEditingName(true);
  };

  const handleNameChange = (e) => {
    setNewTemplateName(e.target.value);
  };

  const handleNameSave = async () => {
    setIsEditingName(false);
    contractTemplate.name = newTemplateName;
    await saveContractTemplateHandler();
  };

  const onSaveGroupsGraph = async (groupsGraph: GroupsGraph) => {
    console.log(groupsGraph);

    setContractTemplate((prev) => (
      {
        ...prev,
        groupsGraph,
      }
    ))
  }

  return (
    <SelectionContext.Provider value={selectionContextValue}>
      <div style={{ flex: 1 }} className="row edit-contract-template">
        <div className="col-8" ref={contractListParentRef} id="contractListParent" style={{ position: "relative" }}>
          <div className="contract-header d-flex justify-content-between align-items-center px-2">
            {isEditingName ? (
              <input
                type="text"
                value={newTemplateName}
                onChange={handleNameChange}
                onBlur={handleNameSave}
                className="contract-name-input"
                autoFocus
              />
            ) : (
              <div className="d-flex justify-content-between w-100">
                <h1 className="contract-name" ref={titleRef} id="contractName" >
                  {contractTemplate.name}
                </h1>
                <div style={{ position: "relative" }}>
                  {/* <BsNodePlusFill
                    className="edit-template-name"
                    style={{ color: "gray", maxHeight: "20px", marginRight: "15px", marginLeft: "10px" }}
                    onClick={() => setShowTransitionsSettings(true)}
                  /> */}
                  <BsGearFill
                    className="edit-template-name"
                    style={{ color: "gray", maxHeight: "20px", marginRight: "15px", marginLeft: "10px" }}
                    onClick={() => setShowClauseSettings(true)}
                  />
                  <img
                    id="select_group"
                    src={changeGroups ? EyeOnIcon : EyeOffIcon}
                    alt="#"
                    className="edit-template-name"
                    style={{ color: "gray", maxHeight: "20px", marginRight: "15px", marginLeft: "10px" }}
                    onClick={() => setChangeGroups(prev => !prev)}
                  />
                  <img src={EditIcon} alt="edit" className="edit-template-name" onClick={handleNameEdit} />
                  {
                    changeGroups ?
                      <div className="set-groups-container">
                        <GroupsForm onClose={() => setChangeGroups(false)} />
                      </div>
                      : null
                  }
                </div>

              </div>
            )}
            <div className="contract-tools">
            </div>
          </div>
          <div ref={clauseListRef} className="clause-list-container" id="clauseListContainer">
            {preview ? <EditContractClauseList clauseListRef={clauseListRef} templateData={templateData} />
              :
              <ContractTemplateClauseList clauseListRef={clauseListRef} />
            }
          </div>
        </div>
        <div className="col-4 contract-clause">
          <div className="d-flex px-3 contract-clause-header justify-content-between align-items-center">
            <div className="contract-clause-name">
              <h4 style={{ fontSize: "20px" }}>{t("pages.contractTemplateParam.edition.edit")}</h4>
            </div>
          </div>
          <div ref={inputListRef} className="variable-input-container">
            {preview ? <EditContractInputList templateData={templateData} inputListRef={inputListRef} isEditing={false} />
              :
              <ContractTemplateParamList inputListRef={inputListRef} />
            }
            <div style={{ height: 500 }} />
          </div>
        </div>
        {draft && (
          <SaveDraftModel
            type=''
            onClose={() => { setSaveDraft(false); sub.reject() }}
            onSave={(saved: boolean) => { sub.resolve() }}
            handleSave={async () => {
              await saveContractTemplateHandler()
              return true
            }}
          />
        )}
        {
          showClauseSettings && (
            <ClauseSettingsModal
              onClose={() => setShowClauseSettings(false)}
            />
          )
        }
        {
          // showTransitionsSettings && (
          //   <WorkflowSettingsModal
          //     groups={contractTemplate.groups}
          //     groupsGraph={contractTemplate.groupsGraph}
          //     params={params}
          //     onClose={() => setShowTransitionsSettings(false)}
          //     onSave={onSaveGroupsGraph}
          //   />
          // )
        }
      </div>
    </SelectionContext.Provider>
  );
}
export default EditContractTemplate;
