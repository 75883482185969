import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { ProjectEntity, ProjectTemplateEntity } from '../domain/entities';

export interface EditProjectContextType {
  project: ProjectEntity;
  setProject: Dispatch<SetStateAction<ProjectEntity>>;
}

const EditProjectContext = createContext<EditProjectContextType>({
} as any);

export default EditProjectContext;

export const EditProjectProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [project, setProject] = useState<ProjectEntity>({});
  const editProjectContext: EditProjectContextType = useMemo(
    () => ({
      project,
      setProject,
    }),
    [project, setProject]
  );

  return (
    <EditProjectContext.Provider value={editProjectContext}>
      {children}
    </EditProjectContext.Provider>
  );
}