import DatePicker from "react-datepicker";
import "./dateInput.scss";
import { AiOutlineCalendar } from "react-icons/ai";
import { useController } from "react-hook-form";
import { useTranslation } from "../../../contexts/TranslationProvider";

interface DateInputProps {
  name: string;
  control: any;
  onChange: (date: any) => void;
  defaultValue: any;
  setCalendarOpen?: (isOpen: boolean) => void;
}

function DateInput({ name, control, onChange, defaultValue, setCalendarOpen }: DateInputProps) {
  const {
    field: { value, onChange: fieldOnChange, ref },
  } = useController({
    name,
    control,
    defaultValue,
    rules: { required: "Ce champs est obligatoire" },
  });

  // Convert value from YYYY-MM-DD string to Date object
  const dateValue = defaultValue ? new Date(defaultValue) : null;

  const handleChange = (date) => {
    fieldOnChange(date);
    onChange(date);
  };
  const { t , language} = useTranslation();

  return (
    <>
      <div className="date-input-container">
        <div className="input-with-icon">
          <DatePicker
            selected={dateValue}
            onChange={handleChange}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            onCalendarClose={() => setCalendarOpen && setCalendarOpen(false)}
            onCalendarOpen={() => {
              setCalendarOpen && setCalendarOpen(true);
            }}
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() + 10))
            }
            placeholderText="JJ/MM/AAAA"
            id={name}
            ref={ref}
          />
          <AiOutlineCalendar className="input-icon" style={{marginRight:language=="ar"&&"150px"}} />
        </div>
      </div>
    </>
  );
}

export default DateInput;
