import React, { createContext, useState, ReactNode, useContext, Dispatch, SetStateAction } from "react";

type JustificationContextType = {
  justification: number[];
  setJustification: Dispatch<SetStateAction<number[]>>;
};

export const JustificationContext = createContext<JustificationContextType>(null);


const JustificationProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [justification, setJustification] = useState<number[]>([]);
  return (
    <JustificationContext.Provider value={{ justification, setJustification }}>
      {children}
    </JustificationContext.Provider>
  );
}
export default JustificationProvider
