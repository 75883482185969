import React, { useState } from "react";
import { GridLayoutIcon } from "../../assets/icons/gridLayoutIcon";
import { TabLayoutIcon } from "../../assets/icons/tabLayoutIcon";
import "./layoutFilter.scss"

interface LayoutFilterProps {
  onLayoutChange: (layout: "grid" | "table") => void;
}

const LayoutFilter: React.FC<LayoutFilterProps> = ({ onLayoutChange }) => {
  const [layout, setLayout] = useState<"grid" | "table">("grid");

  const handleLayoutChange = (newLayout: "grid" | "table") => {
    setLayout(newLayout);
    onLayoutChange(newLayout);
  };

  return (
    <div className="d-flex justify-content-end layoutFilter">
      <div className="btn-group group-layout">
        <button
          onClick={() => handleLayoutChange("grid")}
          className={`btn ${layout === "grid" ? "active" : ""}`}
          aria-label="Grid Layout"
        >
          <GridLayoutIcon fill={layout === "grid" ? "#2F14E5" : "#707274"} />
        </button>

        <button
          onClick={() => handleLayoutChange("table")}
          className={`btn ${layout === "table" ? "active" : ""}`}
          aria-label="Table Layout"
        >
          <TabLayoutIcon fill={layout === "table" ? "#2F14E5" : "#707274"} />
        </button>
      </div>
    </div>
  );
};

export default LayoutFilter;
