import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AiFillDelete, AiOutlineUpload } from 'react-icons/ai';
import { useTranslation } from '../../../contexts/TranslationProvider';
import useApiClientWithLoading from '../../../services/api/ApiClient';
import { DocumentClient } from '../../../services/api/DocumentClient';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import "./uploadPDFPopup.scss"
import { ContractClient } from '../../../services/api/ContractClient';
import { toast } from 'react-toastify';

function UploadPDFPopup({ isVisible, onCancel, body, contractId, setParamValues }) {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t ,language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const documentClient = new DocumentClient(apiClient);
  const navigate = useNavigate();
  const contractClient = new ContractClient(apiClient);
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  const updateContract = async () => {
    try {
      const updatedContract = await contractClient.update(contractId, body);
      if (updatedContract) {
        setParamValues(updatedContract.paramValues);
      }
    } catch (error) {
      toast.error(t("pages.contracts.updating.messages.errorStatus")); 
      console.error("Error updating contract:", error);
    }
  };
  useEffect(() => {
  if(isVisible){
    updateContract();
  }
  
  }, []); 
  
  const deleteFile = (onChange) => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      const event = new Event("input", { bubbles: true });
      fileInputRef.current.dispatchEvent(event);
    }
    onChange(null);
  };

  const onSubmit = async () => {
    if (file) {
      try {
        setLoading(true);
        await updateContract();
        const { row: { id: documentId } } = await documentClient.upsertMultiForm({ file, name: file.name });
        navigate(`/document/${documentId}`);
        
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  if (!isVisible) return null; 
  return (
    <>
  <div className="modal-backdrop"></div>
  <div id="contractz-lab">
    <div className="modal d-flex justify-content-center align-items-center">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("modals.uploadPDF.title")}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onCancel}
              aria-label="Close"
              style={{ marginRight: language === "ar" ? "80%" : undefined }}
            ></button>
          </div>

          <div className="modal-body pb-3">
            <div className="form-group">
              <h6 className="question-modal">
              {t("modals.uploadPDF.msg")}
              </h6>
              <div>
                <Controller
                  name="file"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div
                      className="file-upload mt-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "dashed 2px #ccc",
                        padding: "0.5rem",
                        cursor: "pointer",
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="d-flex ms-1">
                        {file?.name && (
                          <AiFillDelete
                            style={{
                              marginRight: "0.5rem",
                              color: "#2f14e5",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteFile(field.onChange)}
                          />
                        )}
                        <AiOutlineUpload
                          style={{
                            marginRight: "0.5rem",
                            color: "#2f14e5",
                          }}
                          onClick={triggerFileInput}
                        />
                      </div>
                      <p style={{ margin: 0 }}>
                        {file?.name || t("pages.listener.popups.contract.inputs.file.placeholder")}
                      </p>

                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleFileChange(e);
                          field.onChange(e.target.files[0]);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
                <button className="btn  upload-document-signed" onClick={onSubmit}>
                  {t("modals.uploadPDF.btn")}
                </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</>

  
  
  );
}

UploadPDFPopup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UploadPDFPopup;
