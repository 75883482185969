import React from 'react';

const SVGRender = ({ svgContent = null, color = 'black', zoom = "0.9" }) => {
  return (
    <div style={{color: color, zoom: zoom}}>
     {svgContent}
    </div>
  );
};

export default SVGRender;