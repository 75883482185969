import React, { createContext,useEffect, useState, ReactNode, useContext, Dispatch, SetStateAction } from "react";
// loading template is a pair of type and id
type LoadingRessourceType = {
  type: string;
  id: number;
}
type LoadingRessourceContextType = {
  loadingRessources: LoadingRessourceType[];
  setLoadingRessources: Dispatch<SetStateAction<LoadingRessourceType[]>>;
};

export const LoadingRessourceContext = createContext<LoadingRessourceContextType>(null);


const LoadingRessourceProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [loadingRessources, setLoadingRessources] = useState<LoadingRessourceType[]>([]);
  useEffect(() => {
    console.log("loadingRessources:", loadingRessources)
  }, [loadingRessources])
  return (
    <LoadingRessourceContext.Provider value={{ loadingRessources, setLoadingRessources }}>
      {children}
    </LoadingRessourceContext.Provider>
  );
}
export default LoadingRessourceProvider
