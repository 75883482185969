import React, { useState, useEffect, useContext } from 'react'
import FormTemplateParameterInput from './FormTemplateParameterInput'
import EditContractTemplateContext from '../../../contexts/EditFormTemplateContext';
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { FormTemplatePageEntity } from '../../../domain/entities';
import { FormParam } from '../../../domain/types/FormParams';
import { FormParamItem } from './FormTemplatePageInput';

function FormTemplateNestedChildParam({ page, param }: {
  page: FormTemplatePageEntity;
  param: FormParamItem
}) {
  const [isEditing, setIsEditing] = useState(false);
  const { onDeleteParam } = useContext(EditContractTemplateContext);
  const handleDelete = () => {
    onDeleteParam(page.id, param.id)
  }
  const triggerEdit = () => {
    setIsEditing(!isEditing);
  }
  return (
    <div className='template-input-body'>
      <FormTemplateParameterInput
        page={page}
        param={param}
        isEditing={isEditing}
      />
      <div className='template-input-actions'>
        <button onClick={handleDelete} className='template-input-action'>
          <FaTrashAlt />
        </button>
        <button onClick={triggerEdit} className='template-input-action'>
          <FiEdit2 color={isEditing && "blue"} />
        </button>
      </div>
    </div>
  )
}

export default FormTemplateNestedChildParam