import { useEffect, useContext } from "react";

import useApiClientWithLoading from "../services/api/ApiClient";
import { EditFormContextType } from "../contexts/EditFormContext";
import { LoadingContext } from "../contexts/LoadingContext";
import { FormTemplateEntity, GroupEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../domain/entities";
import { FormClient } from "../services/api/FormClient";
import { FormTemplateClient } from "../services/api/FormTemplateClient";
import { useTranslation } from "../contexts/TranslationProvider";
import { useSearchParams } from "react-router-dom";
import { getUserGroups } from "../services/api/UserAuth";
import { GroupClient } from "../services/api/GroupClient";

export const GetAccessUser = (template: FormTemplateEntity, userGroupsId: GroupEntity['id'][]) => {
  // Extract clause codes and group IDs from the template  
  const clauseGroup = template.groups.map((group) => {
    return group.Group_FormTemplate.pageCodes.map((code) => ({
      code,
      groupId: group.Group_FormTemplate.GroupId
    }));
  });
  const viewClauseGroup = template.groups.map((group) => {
    return group.Group_FormTemplate.viewPageCodes.map((code) => ({
      code,
      groupId: group.Group_FormTemplate.GroupId
    }));
  });

  // Create a mapping of clause codes to group IDs
  const clauseMapGroup = {};
  clauseGroup.forEach((codes) => {
    codes.forEach(({ code, groupId }) => {
      clauseMapGroup[code] = clauseMapGroup[code] || [];
      if (!clauseMapGroup[code].includes(groupId)) {
        clauseMapGroup[code].push(groupId);
      }
    });
  });

  // Create a mapping of clause codes to group IDs
  const viewClauseMapGroup = {};
  viewClauseGroup.forEach((codes) => {
    codes.forEach(({ code, groupId }) => {
      viewClauseMapGroup[code] = viewClauseMapGroup[code] || [];
      if (!viewClauseMapGroup[code].includes(groupId)) {
        viewClauseMapGroup[code].push(groupId);
      }
    });
  });

  // Check user access for each clause code
  let accessMap = {};
  template?.pages?.forEach((clause) => {
    const code = clause.index
    if (!clauseMapGroup[code] && !viewClauseMapGroup[code])
      return accessMap[code] = true;
    accessMap[code] = false
    userGroupsId.forEach((groupId) => {
      const access = clauseMapGroup[code]?.includes(groupId);
      const viewAccess = viewClauseMapGroup[code]?.includes(groupId);
      if (access) {
        accessMap[code] = true;
      }
      else if (viewAccess) {
        accessMap[code] = accessMap[code] == true ? true : 'view';
      } else {
        accessMap[code] = accessMap[code] ? accessMap[code] : false;
      }
    });
  });
  return accessMap
}
export function useFormData(
  context: EditFormContextType,
  levelsFilter: {
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }
) {
  const { isLoading, setLoading } = useContext(LoadingContext);
  const { language, setLanguage } = useTranslation();
  const {
    form, setForm,
    formId, setFormId,
    template, setTemplate,
    name, setName,
    setParamValues,
    setFileNames,
    setBeneficialsMap,
    setTemplateId,
    setGroups,
    setAccessUser,
    groupForm,
    setGroupForm,

  } = context;
  const [searchParams] = useSearchParams();

  const apiClient = useApiClientWithLoading(setLoading);
  useEffect(() => {
    const fetchData = async () => {
      const formClient = new FormClient(apiClient)
     const groupClient = new GroupClient(apiClient)

      const formTemplateClient = new FormTemplateClient(apiClient)

      const restrictedToken = searchParams.get("restrictedToken")

      if (form && template)
        return
      const row = await formClient.getById(formId);
      if (row.template) {
        const { languages } = row.template;
        if (languages.length !== 0 && (!(languages as string[]).includes(language))) {
          setLanguage(languages[0]);
        }
      }
      setForm(row);
      setFormId(formId);
      setTemplate(row.template)
      setTemplateId(row?.templateId);
      setName(row?.name);
      setParamValues(row?.paramValues);
      setFileNames(row?.fileNames);
      setBeneficialsMap(row?.beneficialsMap)
      setGroups(row?.template?.groups!);
      groupClient.getAll().then(({ rows }) => {
        const foundGroup = rows?.find(group => group?.id === row.assignedToGroupId);
        if (foundGroup) {
          setGroupForm(foundGroup.name)
        }
      });
      if (restrictedToken) {
        const accessUser = GetAccessUser(row?.template!, [])
        setGroups([]);
        setAccessUser(accessUser)
      } else {
        await getUserGroups(apiClient)
          .then((usergroupdata) => {
            const usergroupIds = usergroupdata.rows.map((group) => group.id);
            setGroups(usergroupdata.rows);
            const accessUser = GetAccessUser(row?.template!, usergroupIds)
            if (row.status === "Signed") {
              const updatedAccessUser = { ...accessUser };
              Object.keys(updatedAccessUser).forEach((key) => {
                if (updatedAccessUser[key] === true) {
                  updatedAccessUser[key] = 'view';
                }
              });
              setAccessUser(updatedAccessUser);
            }
            else {
              setAccessUser(accessUser)
            }
            console.log('accessUser :>> ', accessUser);
          })
          .catch((error) => {
            console.error('Error fetching user groups:', error);
          });
      }
    };
    fetchData();
  }, [formId, name]);
}
