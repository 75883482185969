import React from 'react'
import EventManager from "../../../../services/EventManager";
import { useTranslation } from '../../../../contexts/TranslationProvider';

const SaveSummaryButton = () => {
    const { t } = useTranslation();
    const handleClick = () => {
        EventManager.invoke('SaveSummary', true);
    }
    return (
        <button className="navbar-button" onClick={handleClick}>
           {t("navbar.saveSummary")}
        </button>
    )
}

export default SaveSummaryButton