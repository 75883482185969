import React from 'react'
import useTranslation from '../../../hooks/useTranslation'
import RiskElement from './RiskElement'
import { DocumentUseCaseRiskEntity } from '../../../domain/entities/DocumentUseCase'
import { DocumentUseCaseResponseRiskEntity } from '../../../domain/entities'

function RegulationCard({
	risks,
	regulationName,
	response,
}: {
	risks: DocumentUseCaseRiskEntity['args'][number]['risks'],
	regulationName: DocumentUseCaseRiskEntity['args'][number]['regulation'],
	response: DocumentUseCaseResponseRiskEntity['response'],
}) {
	const { t } = useTranslation()

    return (
        <>
            <div className='regulation-name'>
                <span className='opposite-rotation'>{regulationName}</span>
            </div>
            <div className='regulation-questions'>
                {
                    risks.map((item, index) => {
                        const actualRisk = response.find((responseItem) => responseItem.id === item.id)
                        return (
                            <RiskElement
                                index={index}
                                risk={item.risk}
                                remedialAction={actualRisk ? actualRisk.remedialAction : ''}
                                justification={actualRisk ? actualRisk.justification : ''}
                                score={actualRisk?.score || 0}
                            />
                        )
                    }
                    )
                }
            </div>
        </>
    )
}

export default RegulationCard