import React from 'react'
import { useTranslation } from '../../contexts/TranslationProvider'

function NotFound() {
    const {t}=useTranslation()
    return (
        <div>
            {t("pages.notFound.title")}
        </div>
    )
}

export default NotFound