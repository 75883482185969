import React, { useState } from 'react'
import CreateProjectContext from '../../../contexts/CreateProjectContext'
import { useTranslation } from '../../../contexts/TranslationProvider';
import { getName } from '../../../helpers/Translation';
import form from '../../../assets/new-design-icons/Form.svg'
import contract from '../../../assets/new-design-icons/Contract.svg'
import { ProjectTemplateRequirementType } from '../../../domain/Project';
const docIcon = require('../../../assets/new-design-icons/doc.png')

function RequirementSection() {
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const { t, language } = useTranslation()
  const { projectTemplate, notSelectedReq, setSelectedReq } = React.useContext(CreateProjectContext)

  const onDeSelect = (selectedReqId) => {
    setSelectedReq((prevNotSelectedReq) => {
      const isAlreadySelected = prevNotSelectedReq.find(id => id === selectedReqId);
      if (isAlreadySelected) {
        return prevNotSelectedReq.filter(id => id !== selectedReqId);
      } else {
        return [...prevNotSelectedReq, selectedReqId];
      }
    });
  };
  return (
    <>
      {projectTemplate ? <div className='section'>
        <span className='section-title'>
          {t('pages.createProject.chooseTemplate')}
        </span>
        <div className='section-body'>
          <div className="template-reqs">
            {projectTemplate?.requirements?.map((req, index) => (
              <>
                <div className={`ressource-card ${notSelectedReq.includes(req?.id) && "ressource-disabled"}`}
                  onClick={() => req.isOptional ? onDeSelect(req?.id) : null}>
                  <img src={
                    req?.types?.includes(ProjectTemplateRequirementType.DOCUMENT) ? docIcon :
                      req?.types?.includes(ProjectTemplateRequirementType.CONTRACT) ? contractIcon :
                        req?.types?.includes(ProjectTemplateRequirementType.FORM) ? formIcon : null
                  } alt="icon" style={{ width: "30px", height: "30px" }} />
                  <p className='title'>
                    {getName(req, language)}
                  </p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
        : null
      }
    </>

  )
}

export default RequirementSection