import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import InputValuesContext from "../../../contexts/InputValuesContext";
import SelectionContext from "../../../contexts/SelectionContext";
import { ContractTemplateParsedData, extractOptionalClauses, extractOptionalSubclauses, generateCombinedParams, getClauseNestedGroupParams } from "../../../helpers/ParseTemplateData";
import { OptionalClause, OptionalSubClause, ParsedClauseParam } from "../../../helpers/ParseTemplateData";
import { useForm } from "react-hook-form";
import React from "react";
import ClauseInput from "./ClauseInput";
import { isClauseExcluded, isSubClauseExcluded } from "../../../helpers/helper";
import { getRenderedParams } from "../../../helpers/renderedParams";


function ContractInputList({
  inputListRef,
  isEditing,
  templateData,
  justifications
}: {
  inputListRef: React.MutableRefObject<any>,
  isEditing: boolean;
  templateData: ContractTemplateParsedData,
  justifications?: Record<string, number[]>
}) {
  const {
    inputValues,
    setInputValues,
    fileNames,
    beneficialsMap,
    completionPercentage,
    excludedClauses,
    setExcludedClauses,
    excludedSubClauses,
    setExcludedSubClauses,
    setCompletionPercentage,
    contractListParentRef,
  } = useContext(InputValuesContext);
  const { selected, setSelected } = useContext(SelectionContext);


  const optionalClauses = useMemo(() => extractOptionalClauses(templateData), [templateData]);
  const optionalSubClauses = useMemo(() => extractOptionalSubclauses(templateData), [templateData]);

  const combinedParams: ParsedClauseParam[] = useMemo(() => {
    const renderedParamNames = []
    templateData
      ?.filter((clause) => !isClauseExcluded(clause.id, excludedClauses))
      ?.map((clause) => {
        renderedParamNames.push(...getRenderedParams(clause.segmentation, inputValues, fileNames, beneficialsMap))
        clause.subClauses
          .filter((subClause) => !isSubClauseExcluded(subClause.id, excludedSubClauses))
          .map((subClause) => {
            renderedParamNames.push(...getRenderedParams(subClause.segmentation, inputValues, fileNames, beneficialsMap))
          });
      });

    const combinedParams = generateCombinedParams(templateData, isEditing, inputValues).map((param) => {
      param.rendered = renderedParamNames.includes(param.name);
      return param;
    });
    return combinedParams;
  }, [templateData, isEditing, inputValues, excludedClauses, excludedSubClauses]);

  const renderedParams = useMemo(() => combinedParams.filter((param) => param.rendered), [combinedParams]);

  const inputGroupRef = useRef(null);


  const handleClick = useCallback(
    (clauseId) => {
      setSelected({
        eventType: 'Param',
        clauseId: clauseId,
        paramName: null,
      });
    },
    [setSelected]
  );

  useEffect(() => {
    if (selected && selected.clauseId && selected.eventType != "ParamValueChange" && inputListRef.current) {
      const elementClassName = `clause-input-${selected.clauseId}`;
      const element = document.getElementsByClassName(elementClassName)[0];
      console.log(element);

      if (element) {
        setTimeout(() => {
          const container = inputListRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          const newScrollTop = container.scrollTop + relativeTop;
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }, 500);
      }
    }
  }, [selected?.clauseId, inputListRef]);


  function handleExclusionToggle(event, id, isSubclause) {
    event.stopPropagation();
    if (isSubclause) {
      setExcludedSubClauses((prevExcludedSubClauses) => {
        if (prevExcludedSubClauses.includes(id)) {
          return prevExcludedSubClauses.filter(
            (excludedId) => excludedId !== id
          );
        } else {
          return [...prevExcludedSubClauses, id];
        }
      });
    } else {
      setExcludedClauses((prevExcludedClauses) => {
        if (prevExcludedClauses.includes(id)) {
          return prevExcludedClauses.filter((excludedId) => excludedId !== id);
        } else {
          return [...prevExcludedClauses, id];
        }
      });
    }
  }

  const form = useForm();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  function onSubmit(e) {
    e.preventDefault();
  }


  return (
    <div className="variable-input">
      <form onSubmit={handleSubmit(onSubmit)}>
        {templateData.map((clauseData, clauseIndex) => {

          const nestedGroupedParam = getClauseNestedGroupParams(clauseData, renderedParams)

          return (
            <React.Fragment key={clauseData.id}>
              <ClauseInput
                key={`clauseData-${clauseIndex}`}
                form={form}
                clauseIndex={clauseIndex}
                handleClick={handleClick}
                handleExclusionToggle={handleExclusionToggle}
                inputGroupRef={inputGroupRef}
                isEditing={isEditing}
                isOptionalClause={!!clauseData.isOptional}
                nestedGroupedParam={nestedGroupedParam}
                optionalSubClauses={optionalSubClauses}
                clause={clauseData}
                justifications={justifications}
              />
            </React.Fragment>
          );
        })}
      </form>

    </div>
  );
}
export default ContractInputList;
