import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import "./fonts.scss";
import "./colors.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { SocketServer, socket } from "./services/SocketServer";
import { getAuthUser } from "./services/api/UserAuth";
import { toast } from "react-toastify";
import Router from "./components/Router";
import ErrorBoundary from "./ErrorBoundary";

// App Component
const App = () => {
  const { userToken } = getAuthUser();

  useEffect(() => {
    if (userToken) {
      SocketServer.Connect(userToken);
    } else {
      socket?.disconnect();
    }
    return () => {
      if (socket?.connected) socket.disconnect();
    };
  }, [userToken]);

  useEffect(() => {
    const onConnect = () => {
      // toast("connected");
    };
    const onDisconnect = (reason, description) => {
      // toast("disconnected " + reason);
    };
    if (socket) {
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
    }
    return () => {
      if (socket) {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
      }
    };
  }, [socket]);

  return <Router />;
};


// Root rendering wrapped in ErrorBoundary
const root = ReactDOM.createRoot(document.getElementById("root") as any);
root.render(
    <App />
);
window.onerror = function () {
  return true;
};

window.onunhandledrejection = function () {
  return true;
};


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
