import { AxiosInstance } from "axios";
import { ConversationEntity } from "../../domain/entities/Conversation";
import { ModelClient } from "../ModelClient";

export class ConversationClient extends ModelClient<ConversationEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Conversation')
  }
  async getAllFromReglement(reglementId: string): Promise<any> {
    const response = await this.apiClient.get<{ rows: ConversationEntity[] }>(`${this.modelUrl}/byReglement/${reglementId}`);
    return response.data;
  }
  async query(conversation_id: string, query: string, language: string = "english"): Promise<any> {
    const response = await this.apiClient.post<{ rows: ConversationEntity[] }>(`${this.modelUrl}/query`, {
     data: {
      conversation_id,
      query,
      language
     }
    });
    return response.data;
  }
  async createAndQuery(question: string, reglement: string, language: string = "english"): Promise<any> {
    const response = await this.apiClient.post<{ rows: ConversationEntity[] }>(`${this.modelUrl}/createAndQuery`, {
     data: {
      question,
      reglement,
      language
     }
    });
    return response.data;
  }
}