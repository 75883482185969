import React, { useState, useEffect } from 'react';
import './generateAIportal.scss';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { toast } from 'react-toastify';
import { getName } from '../../../helpers/Translation';
import { ProjectTemplateEntity } from '../../../domain/entities';

const ProjectTemplateGroups = ({ clauseGroup, groups, close, onSelectGroup }: {
  onSelectGroup: (groups: ProjectTemplateEntity['groups']) => void;
  close: () => void;
  groups: ProjectTemplateEntity['groups'];
  clauseGroup: ProjectTemplateEntity['groups'];
}) => {
  const [groupsSelected, setGroupsSelected] = useState<typeof groups>([]);
  const { t, language } = useTranslation();
  const translationPath = "pages.contractTemplateParam.addGroups";

  const handleChange = (e, groupSelected) => {
    if (e.target.checked) {
      let group = groupsSelected.find(g => g.id === groupSelected.id);
      if (group) {
        setGroupsSelected(prevGroups => [...prevGroups]);
      } else {
        setGroupsSelected(prevGroups => [
          ...prevGroups,
          {
            ...groupSelected,
          }
        ]);


      }
    } else {
      setGroupsSelected(prevGroups =>
        prevGroups.filter(group => group.id !== groupSelected.id)
      );
    }
  };

  const handleSelectGroup = async () => {
    try {

      await onSelectGroup(groupsSelected);
      toast.success(t(translationPath + ".toasts.success"));
      close()
    } catch (error) {
      console.error("Error selecting groups:", error);
      toast.error(t(translationPath + ".toasts.error"));
      close()
    }
  }

  useEffect(() => {
    setGroupsSelected(clauseGroup);
  }, []);

  return (
    <div>
      <div className="generate-ai-portal" style={{ marginTop: "-18px" }}>
        <div className="generate-ai-portal-header">
          <span className='close-dialog' onClick={() => close()}>X</span>
        </div>
        <div className="generate-ai-portal-content">
          {groups.map((group, index) => {

            const isCheckedGroupProject = groupsSelected.some(selectedGroup =>
              selectedGroup.id === group.id
            );
            return (
              <div key={index} className="generate-ai-portal-content-checkbox">
                <input
                  type="checkbox"
                  id={String(group.id)}
                  name={group.name}
                  checked={isCheckedGroupProject}
                  onChange={(e) => handleChange(e, group)}
                />
                <label className='generate-ai-portal-label' htmlFor={String(group.id)}>{getName(group, language)}</label>
              </div>
            );
          })}
        </div>
        <div className='generate-ai-portal-footer'>
          <button className='generate-ai-portal-button add-groups' onClick={handleSelectGroup}>
            {t(translationPath + ".addGroups")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProjectTemplateGroups;
