import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { BeneficialEntity, beneficialTypes } from '../../../../domain/entities';
import { lowerCase } from 'lodash';
import { FormParam, FormType } from '../../../../domain/types/FormParams';
import { sortByOriginalOrder } from '../../../../utils/array';

function BeneficialRender({ param, page, isEditing, value, valueName, onChange, onChangeName, errors, onDescriptionChange }) {
  const { onParamChanged } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"


  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterLabel} : {param.label}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} : {param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
      </div>
      {beneficialTypes.map((type) => (
        <div className="checkbox-beneficial">
          <input
            type="checkbox"
            checked={
              param?.args?.beneficialTypes?.includes(type) || false
            }
            name={type}
            onChange={(e) => {
              const updatedParam: FormType.BeneficialParam = { ...param };
              if (!updatedParam.args) {
                updatedParam.args = { beneficialTypes: [] };
              }
              updatedParam.args.beneficialTypes = e.target.checked
                ? sortByOriginalOrder(beneficialTypes, [...new Set([...updatedParam.args.beneficialTypes, type])])
                : updatedParam.args.beneficialTypes?.filter(i => i != type);
              onParamChanged(page.id, updatedParam);
            }}
            disabled={false}
          />
          <label htmlFor={type}>
            {translationPath.beneficial[lowerCase(type)]}{" "}
          </label>
        </div>
      ))}
    </div>
  )
}

export default BeneficialRender