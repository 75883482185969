import React, {useMemo} from 'react'
import { Line } from "react-chartjs-2";
import useTranslation from '../../../../../hooks/useTranslation';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

function CPAMarketPeriod({ contracts }) {
    const { t, language } = useTranslation();
    const translationPath = 'pages.analytics.CPA.period.graphs.licence';
    const periods = [
        {
            name: t(translationPath + ".15dy"),
            value: 0.5
        },
        {
            name: t(translationPath + ".1mo"),
            value: 1
        },
        {
            name: t(translationPath + ".3mo"),
            value: 3
        },
        {
            name: t(translationPath + ".6mo"),
            value: 6
        },
    ]
    const labels = useMemo(() => {
        return ["" ,
        t(translationPath + ".15dy"),
        t(translationPath + ".1mo"),
        t(translationPath + ".3mo"),
        t(translationPath + ".6mo"),
        t(translationPath + ".plus")
    ]
    }, [language]);
    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                display: false,
                labels: {
                    filter: function(item, chart) {
                        // Logic to remove a particular legend item goes here
                        console.log(chart)
                        return false;
                    }
                }
            },
            title: {
                display: true,
                text: t(`${translationPath}.title`),
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if(context.dataset){
                          const label = context.dataset.label;
                        const value = context.parsed.y;
                        const xValue = context.parsed.x;
                        return `${t(translationPath + ".label")} ${labels[xValue]}: ${value}`; 
                        }
                    },
                },
                filter: function (tooltipItem) {
                    return tooltipItem.datasetIndex === 0;
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1, // Set the step size to 1 to ensure whole numbers
                    precision: 0, // Set precision to 0 to remove decimal values

                },
            },
        }
    };
    const correctMarcheContracts = contracts.filter(con => (
        con.paramValues &&
        con.paramValues.date_effet &&
        con.paramValues.duree_marche
    ))
    function extraireNombresFromString(str) {
        const matches = str.match(/\d+/g); // Utilise une expression régulière pour trouver les nombres
        return matches ? matches.map(Number) : []; // Convertit les correspondances en nombres
    }
    const cpaMarche = correctMarcheContracts.map(con => {
        const date_effet = con.paramValues.date_effet
        const duree_marche = extraireNombresFromString(con.paramValues.duree_marche)[0]
        const name = con.name
        const startDate = new Date(date_effet);
        startDate.setMonth(startDate.getMonth() + parseInt(duree_marche));
        const dernier_delai = startDate.toISOString().split('T')[0];
        return {
            name,
            date_effet,
            duree_marche,
            dernier_delai
        }
    })
    const today = new Date();
    const classifiedContracts = cpaMarche.map((contract) => {
        const dernierDelai = new Date(contract.dernier_delai);
        const differenceInMonths = (dernierDelai.getFullYear() - today.getFullYear()) * 12 +
            (dernierDelai.getMonth() - today.getMonth());
        const diffrenceInDays = (dernierDelai.getTime() - today.getTime()) / (1000 * 3600 * 24);

        // Classify the contract based on the difference in months
        if (diffrenceInDays < 15)
            return { ...contract, classification: t(translationPath + ".15dy") };
        const period = periods.find((period) => period.value >= differenceInMonths);
        if (period) {
            return { ...contract, classification: period.name };
        } else {
            return { ...contract, classification: t(translationPath + ".plus") };
        }
    });



    const data = {
        labels,
        datasets: [
            {
                label: t(`${translationPath}.label`),
                data: labels.map((label) => {
                    const contracts = classifiedContracts.filter(con => con.classification === label)
                    return label == "" ? undefined : contracts.length
                }),
                borderColor: '#7014E5',
                backgroundColor: "rgba(47, 20, 229, 0.1)",
                tension: 0,
                pointBorderWidth: 7,
                pointBackgroundColor: 'white',
                pointBorderColor: 'rgb(47, 20, 229)',
                fill: true,
            },
            {
                label: t(`${translationPath}.label`),
                data: labels.map((label) => {
                    const contracts = classifiedContracts.filter(con => con.classification === label)
                    return label !== "" && label !== t(translationPath + ".15dy") ? undefined : contracts.length
                }),
                borderColor: '#7014E5',
                backgroundColor: "rgba(255, 20, 0, 0.2)",
                tension: 0,
                pointBorderWidth: 7,
                pointBackgroundColor: 'white',
                pointBorderColor: 'rgb(47, 20, 229)',
                fill: true,
            },
        ],
    };
    return (
        <div className='chart-line'>
            <Line options={options} data={data} />
        </div>
    )
}

export default CPAMarketPeriod