import { AxiosInstance } from "axios";
import { ContractEntity, ContractTemplateEntity, FormEntity, FormParamValueEntity, GroupEntity, UserEntity, ValidateReq } from "../../domain/entities";
import { ModelClient } from "../ModelClient";
import { CreateFormFromDocRequest } from "../../domain/Form";
import { SegmentedClauseParam } from "../../domain/types/ClauseParams";
import { FormParam } from "../../domain/types/FormParams";
import { FormCommit } from "../../domain/types/FormCommit";

export class FormClient extends ModelClient<FormEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Form')
  }
  async getFormParamValues(id: FormEntity['id']) {
    const response = await this.apiClient.get<{
      formParamValues: FormParamValueEntity[],
      commits: FormCommit[],
      fileNames: FormEntity['fileNames'],
      beneficialsMap: FormEntity['beneficialsMap'],
    }>(`${this.modelUrl}/${id}/get/FormParamValues`);
    return response.data;
  }

  async generateFormFromDocAndTemplate(req: CreateFormFromDocRequest) {
    const url = new URL(this.modelUrl + "/generateWithAIAndFormTemplate")

    const formData = new FormData();
    formData.append("file", req.file);
    req.name && formData.append("name", req.name);
    url.searchParams.append("templateId", String(req.templateId))
    const response = await this.apiClient.post<{ row: FormEntity }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
  async migrateToContract(id: FormEntity['id'], name: ContractEntity['name'], contractTemplateId: ContractTemplateEntity['id'],
    paramNameMap: Record<SegmentedClauseParam['name'], FormParam['name']> = {}) {
    const url = new URL(this.modelUrl + "/migrateToContract")
    const response = await this.apiClient.post<{ row: ContractEntity }>(url.href, {
      paramNameMap
    }, {
      params: { id, name, contractTemplateId },
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }
  async validate(id: FormEntity['id'], validationData: ValidateReq) {
    const response = await this.apiClient.post<{ count: number, rows: FormEntity[] }>(`${this.modelUrl}/${id}/validate`, validationData);
    return response.data;
  }

  async getAssignedGroupForms(schema: string = 'nested') {
    const reponse = await this.apiClient.get<{ count: number, rows: FormEntity[], groups: GroupEntity[] }>(`${this.modelUrl}/assignedGroupForms?schema=${schema}`);
    return reponse.data;
  }
}