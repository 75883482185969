import { useContext } from "react";
import useApiClientWithLoading from "../services/api/ApiClient";
import { toast } from "react-toastify";
import { isFileType } from "../helpers/helper";
import { LoadingContext } from "../contexts/LoadingContext";
import { DocumentClient } from "../services/api/DocumentClient";
export function useParamProcessing() {
  const { setLoading } = useContext(LoadingContext);

  const apiClient = useApiClientWithLoading(setLoading);
  const processParamValues = async (paramValues: Record<string, any>) => {
    const processedParamValues: Record<string, any> = {};
    let hasError = false;
    const documentClient = new DocumentClient(apiClient)

    for (const key of Object.keys(paramValues)) {
      const value = paramValues[key];

      if (isFileType(value)) {
        const file = value;
        const name = file.name;
        try {
          const {
            row: { id },
          } = await documentClient.upsertMultiForm({ file, name });
          processedParamValues[key] = id;
        } catch (error) {
          hasError = true;
          console.error(`Error uploading file for key ${key}:`, error);
          toast.error(`Error uploading file for ${key}`);
          break;
        }
      } else {
        if (
          value &&
          value.isinitial === true &&
          value.isinitial !== undefined
        ) {
          processedParamValues[key] = value?.id;
        } else {
          processedParamValues[key] = value;
        }
      }
    }

    return { processedParamValues, hasError };
  };

  return processParamValues;
}
