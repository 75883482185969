export function isElementVisibleInClause(elementId: string) {
    const element = document.getElementById(elementId);

    if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
    }

    return false;
};

export function scrollParamToTopIfNotVisible(elementId: string, reference: any = null) {
    const element = document.getElementById(elementId);

    if (element && reference?.current) {
        setTimeout(() => {
            const container = reference.current;
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            const relativeTop = elementRect.top - containerRect.top;
            const newScrollTop = container.scrollTop + relativeTop - 100;
            container.scrollTo({
                top: newScrollTop,
                behavior: "smooth",
            });
        }, 400);
    }
}

export function scrollToTopClause(clauseRef: any) {
    if (clauseRef?.current) {
        setTimeout(() => {
        const container = clauseRef.current;
        container?.scrollTo({
            top: 0,
            behavior: "smooth",
        })}, 1000);
    }
}