import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { ContractEntity } from "../../domain/entities/Contract";
import { ContractParamValueEntity, GroupEntity, UserEntity, ValidateReq } from "../../domain/entities";
import { ContractCommit } from "../../domain/types/ContractCommit";
import { UserGrantEntity } from "../../domain/entities/UserGrant";
import { UserRestrictionEntity } from "../../domain/entities/UserRestriction";


export class ContractClient extends ModelClient<ContractEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Contract')
  }
  async getContractParamValues(id: ContractEntity['id']) {
    const response = await this.apiClient.get<{
      contractParamValues: ContractParamValueEntity[],
      commits: ContractCommit[],
      fileNames: ContractEntity['fileNames'],
      beneficialsMap: ContractEntity['beneficialsMap'],
    }>(`${this.modelUrl}/${id}/get/contractParamValues`);
    return response.data;
  }
  async validate(id: ContractEntity['id'], validationData: ValidateReq) {
    const response = await this.apiClient.post<{ count: number, rows: ContractEntity[] }>(`${this.modelUrl}/${id}/validate`, validationData);
    return response.data;
  }

  async getAssignedGroupContracts(schema: string = 'nested') {
    const reponse = await this.apiClient.get<{ count: number, rows: ContractEntity[], groups: GroupEntity[] }>(`${this.modelUrl}/assignedGroupContracts?schema=${schema}`);
    return reponse.data;
  }
  async inviteUserToContract(id: ContractEntity['id'], email: UserEntity['email'], access: UserGrantEntity['access'], meta: UserGrantEntity['meta']) {
    try {
      const reponse = await this.apiClient.post<{ grant: UserGrantEntity } | { restriction: UserRestrictionEntity }>(`${this.modelUrl}/${id}/invite`, {
        email,
        access,
        meta: meta || {},
      });
      const grant = (reponse.data as { grant: UserGrantEntity }).grant || (reponse.data as { restriction: UserRestrictionEntity }).restriction
      return grant;
    } catch (error) {
      console.error("Error inviting user to contract: ", error);
      throw error;
    }
  };

};

