import React, { useContext, useState, useEffect, useRef } from "react";
import Loading from "../../common/Loading";
import "./shareModal.scss"
import { useTranslation } from "../../../contexts/TranslationProvider";
const { default: Sign } = require('../../../assets/Sign Up.svg');
const { default: AddUser } = require('../../../assets/add-user.svg');


const ShareModal = ({ onClose , setIsVisible}) => {
  const modalref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalref.current && !modalref.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalref]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  return (
      <>
        <div
          id="contractz-lab"
          ref={modalref}
          className="contractz-lab-share"
          style={{ position: "absolute", top: "9vh", right: "35vh" }}
        >
          <div className="modal-dialog share-modal-specific">
            <div className="modal-content" style={{ width: "fit-content" }}>
              <div className="modal-header header-share">
                <h5 className="modal-title share-title">
                  {t("pages.editionContract.popups.share.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-share">
                  <div className="form-group">
                    <p className="export-contract-text">
                      {t("pages.editionContract.popups.share.description")}
                    </p>
                    <div className="form-floating mb-3 share-buttons">
                      <button
                        className="btn sign-btn"
                        onClick={() => {}}
                        style={{ margin: "10px 0px" }}
                      >
                      <img  src={Sign}  width={20} />
                       &nbsp;
                        <p>
                          {t("pages.editionContract.popups.share.signature")}
                        </p>
                      </button>

                      <>
                        <button
                          className="btn share-btn"
                          onClick={() => {setIsVisible(); onClose()}}
                          style={{ margin: "10px 0px" }}
                        >
                         <img  src={AddUser}  width={20}/>
                       &nbsp;

                          <p>
                            {t(
                              "pages.editionContract.popups.share.collaborate"
                            )}
                          </p>
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
      );
};

export default ShareModal;
