import * as XLSX from "xlsx"
import { saveAs } from 'file-saver';

export function cellsToXLSXWorkbook(spreadsheets: Record<string, string[][]>) {
    const workbook = XLSX.utils.book_new();
    for (const sheetName of Object.keys(spreadsheets)) {
        try {
            const truncatedSheetName = sheetName.length > 31 ? sheetName.substring(0, 31) : sheetName;
            const sheetData = spreadsheets[sheetName];
            const sheet = XLSX.utils.aoa_to_sheet(sheetData);
            XLSX.utils.book_append_sheet(workbook, sheet, truncatedSheetName);
        } catch (error) {
            console.error(sheetName);
            console.error(spreadsheets);
            throw error
        }
    }
    return workbook
}
export function saveWorkbook(workbook: XLSX.WorkBook, path: string) {
    const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    console.log(buffer);
    const blob = new Blob([buffer]); // JavaScript Blob
    console.log(blob);
    saveAs(blob, path);
}

export function extractXLSXRows(file: File, callback: (rows: any[]) => void) {
    const reader = new FileReader();
    reader.onload = (e) => {

        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array', cellDates: false });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        callback(jsonData)
    };
    reader.readAsArrayBuffer(file);

}
export function extractXLSXCells(file: File, callback: (rows: string[][]) => void) {
    const reader = new FileReader();
    reader.onload = (e) => {

        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array', cellDates: false });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Include headers

        const matrix = [];
        for (let r = 0; r < sheetData.length; r++) {
            const row = sheetData[r] as string[];
            matrix[r] = [];
            for (let c = 0; c < row.length; c++) {
                matrix[r][c] = row[c];
            }
        }
        callback(matrix)

    };
    reader.readAsArrayBuffer(file);
}