import React, { useContext } from 'react'
import AdvancedTableContext from '../../contexts/AdvancedTableContext'
import { useTranslation } from '../../contexts/TranslationProvider'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
function TableHeaderCell({column, index}) {
  const { language } = useTranslation()
  const { columns, data, sort, setSort } = useContext(AdvancedTableContext)
  const sortApplied = sort?.key === column.accessorKey
  return (
    <th style={{ flex: column.width ?? 1}} key={index} className={`${language == "ar" && "text-right"}`}>
              <span style={{flex: 1}}>
                {column.display}
              </span>
              <div className={`${sortApplied ? "sort-shown" : "sort-hidden"}`}>
                {
                  (column.sortable == undefined || column.sortable !== false) && (
                    <>
                      <FaLongArrowAltUp
                        onClick={() => {
                          if (sort?.key === column.accessorKey && sort.direction === "asc") {
                            setSort(null)
                          } else {
                            setSort({ key: column.accessorKey, direction: "asc" })
                          }
                        }}
                        style={{cursor: "pointer"}}
                        color={sort?.key === column.accessorKey && sort.direction === "asc" ? "black" : "gray"}
                      />
                      <FaLongArrowAltDown
                        onClick={() => {
                          if (sort?.key === column.accessorKey && sort.direction === "desc") {
                            setSort(null)
                          } else {
                            setSort({ key: column.accessorKey, direction: "desc" })
                          }
                        }}
                        style={{cursor: "pointer"}}
                        color={sort?.key === column.accessorKey && sort.direction === "desc" ? "black" : "gray"}
                      />
                    </>
                  )
                }
              </div>
              </th>
  )
}

export default TableHeaderCell