//this function is used to calculate the completionPercentage of VariableInput
// updated so it does not concider calculating the checkboxes as a false and true are considered inputs


import { areConditionsMet } from "../domain/FormTemplate";
import { ClauseEntity, ContractEntity, FormEntity, FormTemplatePageEntity } from "../domain/entities";
import { SegmentedClauseParam } from "../domain/types/ClauseParams";
import { FormParam } from "../domain/types/FormParams";

import { ParsedClauseParam } from "./ParseTemplateData";
import { getRenderedParams } from "./renderedParams";

export function isValidInputValue(param: FormParam | SegmentedClauseParam, value: any) {
  switch (param.type) {
    case 'beneficial[]':
    case 'csv':
      return Array.isArray(value) && value.length > 0
    case 'table':
    case 'list':
      return param.args?.length ? Array.isArray(value) && value.length > 0 : true
    case 'enum':
      return param.args?.length ? !(value === null || value === undefined || value === "") : true
    default:
      return !(value === null || value === undefined || value === "")
  }
}
export function calculateFormCompletionPercentage(
  paramValues: FormEntity['paramValues'],
  params: FormTemplatePageEntity['params']
): number {
  let filledVariablesCount = 0;
  const filteredParams = params.filter(
    (param) =>
      areConditionsMet(param, paramValues)
  );
  const totalVariablesCount = filteredParams.length;

  filteredParams.forEach((param) => {
    const value = paramValues[param.name];
    if (isValidInputValue(param, value)) {
      filledVariablesCount++;
    }
  });

  return totalVariablesCount > 0
    ? Math.round((filledVariablesCount / totalVariablesCount) * 100)
    : 0;
}
export function calculateCompletionPercentage(
  inputValues: ContractEntity['paramValues'],
  combinedParams: ParsedClauseParam[],
  excludedClauses: ContractEntity['excludedClauses'],
  excludedSubClauses: ContractEntity['excludedClauses'],
) {
  let filledVariablesCount = 0;

  const filteredCombinedParams = combinedParams.filter(
    (param) =>
      param.type !== 'index' &&
      param.type !== 'static-table' &&
      !excludedClauses?.includes(param.clauseId) &&
      !(param.subClauseId && excludedSubClauses?.includes(param.subClauseId))
  );

  let totalVariablesCount = filteredCombinedParams.length;

  filteredCombinedParams.forEach((param) => {
    const value = inputValues[param.name]
    if (isValidInputValue(param, value)) {
      filledVariablesCount++;
    }
  });

  return Math.round((filledVariablesCount / totalVariablesCount) * 100);
}

export function calculateContractClauseCompletionPercentage(
  inputValues: ContractEntity['paramValues'],
  clause: ClauseEntity
) {
  let filledVariablesCount = 0;
  const paramNames = getRenderedParams(clause.segmentation, inputValues)
  const params = clause.segmentation.segmentedParams.filter(p => paramNames.includes(p.name))
  const filteredParams = params.filter(
    (param) =>
      param.type !== 'index' &&
      param.type !== 'static-table'
  );

  let totalVariablesCount = filteredParams.length;

  filteredParams.forEach((param) => {
    const value = inputValues[param.name]
    if (isValidInputValue(param, value)) {
      filledVariablesCount++;
    }
  });

  return Math.round((filledVariablesCount / totalVariablesCount) * 100);
}