import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useTranslation from '../../../hooks/useTranslation';
import { getName } from '../../../helpers/Translation';

function GroupBarChart({ contracts, groups }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ChartDataLabels,
    Legend
  );
  const { t, language } = useTranslation();
  const translationPath = 'pages.analytics.graphs.groupBarChart';
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t(translationPath + '.title'),
      },
      datalabels: {
        display: true,
        color: "white",
        anchor: 'end' as 'end',
        align: 'start' as 'start',
        padding: {
          top: -10
        },
        formatter: function (value, context) {
          return value > 1 ? value : "";
        }
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1, // Set the step size to 1 to ensure whole numbers
          precision: 0, // Set precision to 0 to remove decimal values

        },

      },
      x: {
        ticks: {
          callback: function (value, index, values) {
            return this.getLabelForValue(value).length > 10 ? this.getLabelForValue(value).substr(0, 10) + '...' : this.getLabelForValue(value);
          }
        }
      }
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
      },
    },

  };
  const labels = groups.map((group) => getName(group, language));
  const data = {
    labels,
    datasets: [
      {
        label: t(translationPath + '.label'),
        data: groups.map((group) => {
          const groupID = group.id;
          const groupContracts = contracts.filter((contract) => contract.assignedToGroupId === groupID);
          return groupContracts.length;
        }),
        backgroundColor: '#7014E5',
      },
    ],
  };

  return (
    <div className="chart">
      <Bar options={options} data={data} />
    </div>
  );
}

export default GroupBarChart;
