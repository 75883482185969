import { useEffect } from 'react'
import React from 'react'
import InputField from '../../../components/common/newDesign/InputField'
import CreateProjectContext from '../../../contexts/CreateProjectContext'
import { useTranslation } from '../../../contexts/TranslationProvider'
import { set } from 'react-hook-form'
function BasicSection() {
  const { t } = useTranslation()
  const { projectName, setProjectName, projectDescription, setProjectDescription, existingProjects, project, nameError, setNameError } = React.useContext(CreateProjectContext)
  useEffect(() => {
    const nameAlreadyExists = (existingProjects?.find(project => project.name === projectName) && !project)
    || (existingProjects?.find(proj => proj.name === projectName && proj.id !== project.id))
    
    if (nameAlreadyExists) {
      setNameError([t('pages.createProject.errors.errorNameProjectUnicity')])
      return
    }
      setNameError([])
  }, [projectName, existingProjects])
  
  return (
    <div className='section'>
      <span className='section-title'>
        {t('pages.createProject.basicInfo')}
      </span>
      <div className='section-body'>
        <InputField 
        value={projectName}
        onChange={(e) => {
          setProjectName(e.target.value)
        }}
        placeholder={t('pages.createProject.projectName')}
        type={"text"}
        label= {t('pages.createProject.projectName')}
        required={true}
        name={"projectName"}
        description={t('pages.createProject.projectNameDescription')}
        error={nameError}
        />
        <InputField 
        className='description-input'
        value={projectDescription}
        onChange={(e) => {
          setProjectDescription(e.target.value)
        }}
        placeholder={t('pages.createProject.description')}
        type={"textarea"}
        label= {t('pages.createProject.description')}
        name={"projectDescription"}
        description={t('pages.createProject.projectSubDescription')}
        />
      </div>
    </div>
  )
}

export default BasicSection