import React, { useEffect, useState } from "react";
import '../buttons.scss'
import EntityLookupModal from "../../../modals/EntityLookupModal";
import EventManager from "../../../../services/EventManager";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormDocxExportEntity } from "../../../../domain/entities/FormDocxExport";
import { EditFormTemplateContextType } from "../../../../contexts/EditFormTemplateContext";

const FormDocxExportLookupModal = EntityLookupModal<FormDocxExportEntity>()
function EditFormTemplateSelectDocxExportButton() {
  const [showValidateFormModal, setShowValidateFormModal] = useState(false);
  const [context, setContext] = useState<EditFormTemplateContextType | null>(null)
  const { t } = useTranslation();
  const translationPath = t("modals.exportFormToDocx");
  useEffect(() => {
    EventManager.subscribe("EditFormTemplateContext", setContext)
    return () => {
      EventManager.unsubscribe("EditFormTemplateContext", setContext)
    }
  }, [])
  const { formTemplate, setFormTemplate } = context || {}

  return (
    <>
      <button className="navbar-button" onClick={() => setShowValidateFormModal(true)}>
        {translationPath.btn}
      </button>
      {showValidateFormModal &&
        <FormDocxExportLookupModal
          entityName="FormDocxExport"
          modelName={translationPath.title}
          lookUpField={translationPath.fields.name}
          value={formTemplate.formDocxExport}
          onClose={() => {
            setShowValidateFormModal(false)
          }}
          onSave={(formDocxExport) => {
            console.log(formDocxExport);
            setFormTemplate((prev) => ({
              ...prev,
              formDocxExport,
              formDocxExportId:formDocxExport?.id ?? null
            }))
          }}
        />
      }
    </>
  )
}

export default EditFormTemplateSelectDocxExportButton;
