import { createContext } from "react";
import { ReglementEntity } from "../domain/entities";
import { FormParam } from "../domain/types/FormParams";
import { EnumOption, ListOption, TableLigne } from "../domain/types/ClauseParams";


export interface EditReglementContextType {
reglement: ReglementEntity;
setReglement: React.Dispatch<React.SetStateAction<ReglementEntity>>;
}

const EditReglementContext = createContext<EditReglementContextType>({

} as any);

export default EditReglementContext;
