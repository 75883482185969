

import { Socket, io } from 'socket.io-client';
import { API_BASE_URL } from '../config';

export let socket: Socket
export class SocketServer {
	static Connect(token: string) {
		if (socket?.connected)
			socket.disconnect()
		socket = io(API_BASE_URL, {
			query: { "user-token": token }
		});
		return socket
	}
	static Disconnect() {
		socket?.disconnect()
	}

}

