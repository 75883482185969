import "./projectList.scss";
import "../index.scss"
import { BsSearch } from "react-icons/bs";
import { useContext, useEffect, useMemo, useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { useTranslation } from '../../contexts/TranslationProvider';
import Loading from "../../components/common/Loading";
import ProjectContext, { ProjectContextType } from "../../contexts/ProjectContext";
import { useNavigate } from "react-router-dom";
import { ProjectEntity, ProjectTemplateEntity, TypeLevel1Entity } from "../../domain/entities";
import { ProjectClient } from "../../services/api/ProjectClient";
import { ProjectTemplateClient } from "../../services/api/ProjectTemplateClient";
import { getName } from "../../helpers/Translation";
import ProjectCard from "../../components/common/newDesign/ProjectCard";
import LayoutFilter from "../../components/LayoutFilter";
import project from "../../assets/new-design-icons/Projects.svg";
import { formatDate } from "../../helpers/helper";
import AdvancedTable from "../../components/AdvancedTable";
import { FiMoreHorizontal } from "react-icons/fi";
import ConfirmDeleteMessage from "../../components/modals/ConfirmDeteleMessage";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
const ProjectList = () => {
  const projectIcon = project.toString();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const {
    projects, setProjects,
    projectTemplate, setProjectTemplate,
  } = useContext(ProjectContext)
  const [searchTerm, setSearchTerm] = useState("");
  const [updateInProjectsList, setProjectListChanged] = useState(false);
  const [openFormPopup, setOpenFormPopup] = useState<number | null>(null);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);

  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const projectClient = new ProjectClient(apiClient)
  const [displayCards, setDisplayCards] = useState("grid");
  const [confirmDeleteMessage, setConfirmDelete] = useState(false);

  const handleDelete = (projectId) => {
    setConfirmDeleteId(projectId);
  };
  const projectTemplateClient = new ProjectTemplateClient(apiClient)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await projectClient.getAll();
        const dataTemplate = await projectTemplateClient.getAll();
        setProjectTemplate(dataTemplate?.rows);
        setProjects(data?.rows);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects: ", error);
        setLoading(false);
      }
    };
    fetchData();
    setProjectListChanged(false)
  }, [updateInProjectsList]);

  const filteredProjects = projects?.filter((project) =>
    project?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedProjects = filteredProjects.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  const projectsPerLevel1: Record<TypeLevel1Entity['name'], ProjectTemplateEntity[]> = {}

  const levels1Names = [...new Set(filteredProjects.map(template => getName(template.level1, language)))]
  levels1Names.forEach(level1Name => projectsPerLevel1[level1Name] = [])

  sortedProjects.forEach(template => {
    projectsPerLevel1[getName(template.level1, language)].push(template)
  })
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  const togglePopup = (e, id) => {
    e.stopPropagation();
    setOpenFormPopup(openFormPopup === id ? null : id);
  }
  return (
    <div className="projects px-3 pt-2">
      <section id="projets">
        <button className="btn-create-project" onClick={() => navigate("/edit-project")}>{t("pages.projects.createProject")}</button>
        <div className="search-zone">
          <div className="d-flex mt-4 ps-1 align-items-center justify-content-between">
            <div className="search-bar w-75">
              <BsSearch className="search-icon search-project" style={{ marginRight: language == "ar" && "89%" }} />
              <input
                type="text"
                placeholder={t("pages.projects.search")}
                className="search-input"
                style={{ minWidth: "300px" }}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <LayoutFilter onLayoutChange={handleLayoutChange} />

        <div className="mt-5">
          {loading ? (
            <Loading height="75vh" />
          ) : displayCards == "grid" ? (
            <div className="row gap-5">
              {levels1Names?.map(level1Name => (
                <div className="col projects-space" key={level1Name}>
                  <h3 className="m-2 projects-space-title">{level1Name}</h3>
                  <div className="project-group-container">
                    <div className="projects-per-level">
                      {projectsPerLevel1[level1Name]?.map((item) => (
                        <div key={item?.id} className="m-2">
                          <div className='project-group'>
                            <ProjectCard data={item} key={item?.id} setProjectListChanged={setProjectListChanged} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) :
            <>
              <AdvancedTable
                columns={[
                  {
                    display: t("pages.projects.tableHeaders.name"),
                    accessorKey: "name",
                    render: (row) => {
                      const displayType = row.ressourceType;
                      return (
                        <div style={{ display: "flex" }}>
                          <img
                            src={projectIcon}
                            alt="icon"
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div style={{ marginLeft: "20px", flex: "1", textOverflow: "ellipsis" }}>{row.name}</div>
                        </div>
                      )
                    },
                    width: 3
                  },
                  {
                    display: t("pages.projects.tableHeaders.type"),
                    accessorKey: "type",
                    width: 2
                  },
                  {
                    display: t("pages.projects.tableHeaders.status"),
                    accessorKey: "status",
                    width: 2,
                    render: (row) => {
                      return row.status ? (
                        <div className="status">{row.status}</div>
                      ) : (
                        ""
                      );
                    }
                  },
                  {
                    display: t("pages.projects.tableHeaders.createdAt"),
                    accessorKey: "createdAt",
                    render: (row) => {
                      if (row.createdAt) {
                        return formatDate(row.createdAt);
                      }
                    },
                    width: 2,
                    isDate: true
                  },
                  {
                    display: t("pages.projects.tableHeaders.updatedAt"),
                    accessorKey: "updatedAt",
                    render: (row) => {
                      if (row.updatedAt) {
                        return formatDate(row.updatedAt);
                      }
                    },
                    width: 2,
                    isDate: true
                  },
                  {
                    display: t("pages.projects.tableHeaders.actions"),
                    accessorKey: "actions",
                    sortable: false,
                    filtrable: false,
                    width: 2
                  }
                ]}
                originalData={filteredProjects?.map((project, key) => ({
                  ...project,
                  type: [project?.level1, project?.level2, project?.level3].filter(Boolean).map(level => getName(level, language)).join("/"),
                  status: project.status ? t("status." + project.status) : "",
                  createdAt: project.createdAt,
                  updatedAt: project.updatedAt,
                  onDelete: () => {
                    setConfirmDelete(true);
                  },
                  onEdit: () => {
                    navigate(`/edit-project/${project?.id}`);
                  },
                  actions: (<>
                  {confirmDeleteId === project.id && (
                      <ConfirmDeleteMessage
                        onClose={() => {
                        setConfirmDeleteId(null)
                          setOpenFormPopup(null)
                        }}
                        messageDelete={t("pages.viewProject.deleteMessage")}
                        project={project}
                        setNewChange={setProjectListChanged}
                      />
                    )}
                    <div className="actions">
                      <button className="plus-icon" onClick={(e) => togglePopup(e, project.id)}>
                        <FiMoreHorizontal size={20} />
                      </button>
                      {openFormPopup == project.id && (
                        <div className="">
                          <ContractCardActionsModal
                            onView={() => navigate(`/projet/${project?.id}`)}
                            onDelete={() => handleDelete(project.id)}
                            onEdit={() => navigate(`/edit-project/${project?.id}`)}
                          />
                        </div>
                      )}
                    </div>
                  </>
                  )
                }))
                }
              />
            </>
          }
        </div>
      </section>
    </div>
  );
}

export default ProjectList;
