import React from 'react'
import EventManager from "../../../../services/EventManager";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'

const ValidationResponseSaveButton = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const handleResponseValidation = async () => {
        EventManager.invoke('SaveValidationResponse', true)
        navigate("/ressources")
    };
    return (
        <button className="navbar-button" onClick={handleResponseValidation}>
            {t("navbar.save2")}
        </button>
    )
}

export default ValidationResponseSaveButton