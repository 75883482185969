import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useTranslation from '../../../../../hooks/useTranslation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
function CPALevel2Value({ contracts, cpaTypes }) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ChartDataLabels
    );
    const { t } = useTranslation();
    const translationPath = 'pages.analytics.CPA.value.graphs.type';
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: t(`${translationPath}.title`),
            },
            datalabels: {
                display: true,
                color: "white",
                anchor: 'end' as const,
                align: 'start' as const,
                padding: {
                    top: -10
                },
                formatter: function (value, context) {
                    return value > 1 ? value : "";
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    precision: 0, // Set precision to 0 to remove decimal values
                },
            },
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
            },
        },
    };

    const labels = cpaTypes.map((type) => type.level2Name);
    const groupedContractsCount = cpaTypes.map((type) => {
        const level1Id = type.level1Id;
        const level2Id = type.level2Id;
        const typeContracts = contracts.filter((contract) => contract.level1Id == level1Id && contract.level2Id == level2Id);
        let value = 0;
        switch (level2Id) {
            case 6:
                value = typeContracts.reduce((acc, contract) => {
                    const price = contract.paramValues.global_price ?? 0;
                    return acc + parseFloat(price);
                }, 0);
                return value;
            case 3:
                value = typeContracts.reduce((acc, contract) => {
                    switch (contract.level3Id) {
                        case 8:
                            const prixGlobal = contract.paramValues.prix_global ?? 0
                            return acc + parseFloat(prixGlobal);
                            break;
                        default:
                            const users = contract.paramValues.nombre_utilisateurs ?? 0;
                            const membership = contract.paramValues.prix_abonnement ?? 0;
                            return acc + (parseInt(users) * parseFloat(membership));
                            break;
                    }
                }, 0);
                return value;
        }
    })
    const data = {
        labels: labels,
        datasets: [
            {
                label: t(`${translationPath}.label`),
                data: groupedContractsCount,
                backgroundColor: '#7014E5',

            },
        ],
    };
    return (
        <div className="chart" style={{ minWidth: "500px" }}>
            <Bar data={data} options={options} />
        </div>
    )
}

export default CPALevel2Value