import React from 'react'
import { AiFillDislike, AiFillLike } from 'react-icons/ai';
import { useTranslation } from '../../../contexts/TranslationProvider';

function QuestionCard({
    question,
    answer,
    isAcceptedAnswer,
    isRemovedAnswer,
    handleAddClick,
    handleRemoveClick,
    index
}) {
  const {t}=useTranslation()
  const translationPath=t("pages.responseConfig.edition")
    return (
        <>
           <div key={index} className="d-flex justify-content-between align-items-center my-2 border p-2 m-2">
                      <div className="d-flex align-items-center gap-2">
                        {isRemovedAnswer ? (
                          <div className="input-error ms-2">{t(translationPath.error)}</div>
                        ) : isAcceptedAnswer ? (
                          <div className="input-done ms-2">{t(translationPath.complete)}</div>
                        ) : (
                          <div className="input-inprogress ms-2">{t(translationPath.inProgress)}</div>
                        )}
                        <div className="d-flex flex-column">
                          <label htmlFor={question}>{question}</label>
                          <div className="ai-response">{answer}</div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <AiFillLike
                          className={`ai-validate-button cursor-pointer ${isAcceptedAnswer ? "up-active" : "inactive"
                            }`}
                          onClick={() =>
                            isAcceptedAnswer ? null : handleAddClick(index)
                          }
                          size={23}
                        />
                        <AiFillDislike
                          className={`mx-1 ai-validate-button cursor-pointer ${isRemovedAnswer ? "down-active" : "inactive"
                            }`}
                          onClick={() => handleRemoveClick(index)}
                          size={23}
                        />
                      </div>
                    </div>
        </>
    )
}

export default QuestionCard