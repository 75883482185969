import React from 'react'
import { RiDeleteBinLine } from "react-icons/ri";
import AskYourQuestionsContext from '../../../contexts/AskYourQuestionsContext'
function ChatSuggestions() {
  const { handleSubmit, currentReglement } = React.useContext(AskYourQuestionsContext)
  return (
    <div className='suggest'>
    {currentReglement && currentReglement.predefined_questions && currentReglement.predefined_questions.length > 0 && currentReglement.predefined_questions.map((question, index) => (
      <div onClick={() => {
        if(question){
          handleSubmit(question)
        }
    }}
      className='suggest-card'>
      {question}
    </div>
    ))}
  </div>
  )
}

export default ChatSuggestions