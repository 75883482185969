
import React from 'react'
import {
    useSelected,
    useFocused,
  } from 'slate-react'

const Mention = ({ attributes, element, children }) => {
    const selected = useSelected()
    const focused = useFocused()
    const style: React.CSSProperties = {
      padding: '1px 2px 1px 2px',
      margin: '3px 1px 1px',
      verticalAlign: 'baseline',
      display: 'inline-block',
      borderRadius: '4px',
      backgroundColor: '#eee3fd',
      border: '1px solid #b4d5ff',
      fontSize: '16px',
      boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
      color: '#2f14e5',
      cursor: 'pointer',
    }
    // See if our empty text child has any styling marks applied and apply those
    if (element.bold) {
      style.fontWeight = 'bold'
    }
    if (element.italic) {
      style.fontStyle = 'italic'
    }
    if (element.underline) {
      style.textDecoration = 'underline'
    }
    return (
      <span
        {...attributes}
        contentEditable={false}
        data-cy={`mention-${element.character.replace(' ', '-')}`}
        style={style}
      >
        {element.character}
        {children}
      </span>
    )
  }
export default Mention