import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { ContractAIEntity } from "../../domain/entities/ContractAI";


export class ContractAIClient extends ModelClient<ContractAIEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ContractAI')
  }
};

