import "../commonmodal.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { ReglementClient } from "../../../services/rag/ReglementClient";
import { ReglementEntity } from "../../../domain/entities";
import ragClientWithLoading from "../../../services/rag/RagClient";
import InputField from "../../common/newDesign/InputField";
import "./reglement.scss"
import { set } from "react-hook-form";

interface CreateReglementModalType {
  onClose: () => void;
}
function CreateReglementModal({ onClose }: CreateReglementModalType) {
  const modalContentRef = useRef(null);
  const { t, language } = useTranslation();
  const [name, setName] = useState("");
  const [error, setError] = useState([""]);
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const navigate = useNavigate();


  const onSubmit = async (e) => {
    e.preventDefault()
    if(!name) {
      setError(["Please enter reglement name"])
      return
    }
    const ragClient = ragClientWithLoading()
    const reglementClient = new ReglementClient(ragClient)
    try{
      const result = await reglementClient.create({name, predefined_questions: predefinedQuestions})
      if(result){
        onClose()
        navigate("/reglements")
      }
    }catch(err){
      console.log(err)
      onClose()
    }
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="modal-backdrop fade show "></div>
      <div id="contractz-lab" className="reglement-modal">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Reglement</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                <p>Create a new reglement.</p>

                <div style={{ width: "100%", display: "flex" }}>
                  <InputField
                    onChange={(e) => { setName(e.target.value) }}
                    value={name}
                    placeholder="Enter reglement name"
                    label="Reglement Name"
                    name="name"
                    required={true}
                    type={"text"}
                    error={error}
                  />
                </div>
                <div style={{marginTop: "20px"}}>
                  Predefined Questions:
                  <div className="suggestions">
                    {predefinedQuestions.length < 4 && <div onClick={(e) => {
                      e.stopPropagation()
                      !addNewQuestion && setAddNewQuestion(true)
                    }} className="suggest-add" key={-1}>
                      {
                        addNewQuestion ?
                          <>
                            <InputField
                              onChange={(e) => {
                                setNewQuestion(e.target.value)
                              }}
                              value={newQuestion}
                              placeholder="Enter question"
                              label="Question"
                              name="question"
                              required={true}
                              type={"text"}
                            />
                            <button onClick={(e) => {
                              setPredefinedQuestions([...predefinedQuestions, newQuestion])
                              setAddNewQuestion(false)
                              setNewQuestion("")
                            }}
                              style={{marginTop: "10px"}}
                            >Add</button>
                          </>
                          :
                          "Add new question"
                      }
                    </div>}
                    {predefinedQuestions.map((question, index) => (
                      <div className="suggest-card" key={index}>
                        {question}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={onSubmit}>Create</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateReglementModal;
