import React, { useState } from "react";
import Loading from "../../common/Loading";
import {FormTemplateEntity} from "../../../domain/entities";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeleteMessage.scss";
import { toast } from "react-toastify";
import { FormTemplateClient } from "../../../services/api/FormTemplateClient";
interface ConfirmDeleteMessageProps {
  onClose: (value: boolean) => void;
  template?: FormTemplateEntity;
  reloadTemplates?: () => void;
}
function ConfirmDeleteFormTemplate({
  template = {} as FormTemplateEntity,
  onClose = () => {},
  reloadTemplates = () => {},
}: ConfirmDeleteMessageProps) {
  const [loading, setLoading] = useState(false);
  const [msgModal, setMessageModal] = useState("");
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const formTemplateClient = new FormTemplateClient(apiClient);

  const handleDelete = async (template: FormTemplateEntity) => {
    setLoading(true);
    try {
      const { deleted } = await formTemplateClient.delete(template.id);
      if (deleted) {
        await reloadTemplates();
        onClose(false);
        toast.success(t("modals.confirmDelete.messages.success"));
      } else {
        setMessageModal(t("modals.confirmDelete.inactiveMessage"));
      }
    } catch (error) {
      console.error("Error deleting template: ", error);
      toast.error(t("modals.confirmDelete.messages.error"));
    } finally {
      setLoading(false);
    }
  };
  const handleMakeInactive = async (template: FormTemplateEntity) => {
    setLoading(true);
    try {
      const updateTemplate = await formTemplateClient.update(template.id, {
        ...template,
        activated: false,
      });
      if (updateTemplate) {
        await reloadTemplates();
      }
      onClose(false);
      toast.success(t("modals.confirmDelete.messages.inactive"));
    } catch (error) {
      console.error("Error updating template: ", error);
      toast.error(t("modals.confirmDelete.messages.inactiveError"));
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
      setMessageModal(
        `${t("modals.confirmDelete.deleteMessage")}${template.name}${
          language !== "ar" ? "?" : " ؟"
        }`
      );
  }, [template, t, language]);

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">{msgModal}</h1>
                    <div className="modal-buttons">
                      {!msgModal.includes(
                        t("modals.confirmDelete.inactiveMessage")
                      ) && (
                        <button
                          className="btn submit-delete"
                          onClick={() => {handleDelete(template);
                          }}
                        >
                          {t("modals.confirmDelete.delete")}
                        </button>
                      )}
                      {msgModal.includes(
                        t("modals.confirmDelete.inactiveMessage")
                      ) && (
                        <button
                          className="btn  make-inactive"
                          onClick={() => handleMakeInactive(template)}
                          style={{ marginLeft: "20px" }}
                        >
                          {t("modals.confirmDelete.makeInactive")}
                        </button>
                      )}
                      <button
                        onClick={() => onClose(false)}
                        className="btn btn-secondary cancel-delete"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "gray",
                        }}
                      >
                        {t("modals.confirmDelete.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteFormTemplate;
