import React, { useContext, useState, useEffect } from "react";
import InputValuesContext from "../../../../contexts/InputValuesContext";
import ValidateContractModal from "../../../modals/ValidateContractModal";
import useApiClientWithLoading from "../../../../services/api/ApiClient";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'
import { ContractClient } from "../../../../services/api/ContractClient";

function EditionContractValidateButton() {
  const { contractId } = useContext(InputValuesContext);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState(null);
  const [showValidateContractModal, setShowValidateContractModal] = useState(false);
  const { t } = useTranslation();

  const apiClient = useApiClientWithLoading(setLoading);
  useEffect(() => {
    if (!contractId) return;
    const contractClient = new ContractClient(apiClient)
    setLoading(true);
    contractClient.getById(contractId).then((row) => {
      setContract(row);
      setLoading(false);
    });
  }, [contractId]);

  return (
    <>
      <button className="navbar-button" onClick={() => setShowValidateContractModal(true)}>
        {t("navbar.validateContract")}
      </button>
      {showValidateContractModal && <ValidateContractModal  onClose={() => setShowValidateContractModal(false)} />}
    </>
  )
}

export default EditionContractValidateButton;
