import { useTranslation } from "../../contexts/TranslationProvider";
import React from "react";
function TableRender({ headers, rows, transposed, isHighlighted }) {
  const { language } = useTranslation();
  const cellStyle: React.CSSProperties = {
    border: "1px solid black",
    textAlign: language === "ar" ? "right" : "left",
    width: "auto",
    padding: "8px",
    wordBreak: "break-word",
  };
  return (
    <table
      className={`my-4 ${isHighlighted ? "highlighted-text" : ""}`}
      style={{
        borderCollapse: "collapse",
        width: "100%",
      }}>
      {!transposed && (<thead>
        <tr>
          {headers.map((header, idx) => (
            <th
              key={idx}
              style={{...cellStyle, width: "calc(100% / " + (headers.length > 0 ? headers.length : 1) + ")"}}>
              {header}
            </th>
          ))}
        </tr>
      </thead>)}
      <tbody>
        {transposed ? (
          headers.map((header, idx) => (
            <tr key={idx}>
              <th style={{...cellStyle, width: "20%"}}>{header}</th>
              {rows.map((row, rowIndex) => (
                <td key={rowIndex} style={{...cellStyle, width: "calc(80% / " + (rows.length > 0 ? rows.length : 1) + ")"}}>
                  {row[idx]}
                </td>
              ))}
            </tr>
          ))
        ) : (
          rows.map((row, idx) => (
            <tr key={idx}>
              {row.map((cell, cellIdx) => (
                <td key={cellIdx} style={cellStyle}>
                  {cell}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default TableRender;