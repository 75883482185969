import React from 'react';
import './progressBar.scss';

interface ProgressBarProps {
    progress: number;
    expanded: any;
}

function ProgressBar({ progress, expanded }: ProgressBarProps) {
    const progressAsNumber = Number(progress) ? Number(progress) : 0;
    const expandedBar = () => {
        return (
            <>
                <div className="d-flex justify-content-between progress-expanded-container progress-container">
                    <h6>Progress</h6>
                    <h6>{progressAsNumber}%</h6>
                </div>
                <div className="progress progress-expanded">
                    <div className={`progress-bar progress-bar-expanded bg-custom`} role="progressbar" style={{ width: `${progressAsNumber}%` }} aria-valuenow={progressAsNumber} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
            </>
        )
    }
    const collapsedBar = () => {
        return (
            <div className="progress-collapsed-container">
                <div role="progressbar" style={{ width: `${progressAsNumber}%` }} aria-valuenow={progressAsNumber} aria-valuemin={0} aria-valuemax={100}>
             
                </div>
                <h6>{progressAsNumber}%</h6>
            </div>
        )
    }

    return (
        <>
            {expanded ? expandedBar() : collapsedBar()}
        </>
    );
}

export default ProgressBar;
