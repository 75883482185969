
import React from "react";
import { ClauseEntity, ContractEntity } from "../../../domain/entities";
import ClauseText from "./ClauseText";
import { isSubClauseExcluded } from "../../../helpers/helper";
import { useTranslation } from "../../../contexts/TranslationProvider";


interface ComparisonClauseProps {
    clause: ClauseEntity;
}
function ComparisonClause({
    clause,
}: ComparisonClauseProps) {

    const {t, language}=useTranslation()

    return (
        <>
            <div className="clause-comparison-current">
                <div className="clause-comparison-header mb-4">
                    <span className="clause-comparison-cummon-title">
                    {t("modals.clauseComparaison.clauseClausier")}
                    </span>
                    <br />
                    <span className="clause-comparison-actual-title half-border-bottom">
                        {clause?.name}
                    </span>
                </div>
                <div className="clause-comparison-body">
                    <ClauseText
                        segmentation={clause?.segmentation}
                    />
                </div>
            </div>
        </>
    );
}

export default ComparisonClause;
