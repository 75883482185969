import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { BeneficialEntity } from "../../domain/entities/Beneficial";


export class BeneficialClient extends ModelClient<BeneficialEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Beneficial')
  }
};

