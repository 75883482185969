import { useContext, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import {
  ContractTemplateParsedData,
  ParsedClauseParam,
  extractOptionalClauses,
  extractOptionalSubclauses,
  generateCombinedParams,
} from "../../../helpers/ParseTemplateData";
import InputValuesContext from "../../../contexts/InputValuesContext";
import SelectionContext, {
  SelectedType,
} from "../../../contexts/SelectionContext";
import { calculateCompletionPercentage } from "../../../helpers/CompletionPercentage";
import EditContractInputList from "./EditContractInputList";
import { isClauseExcluded, isSubClauseExcluded } from "../../../helpers/helper";
import { getRenderedParams } from "../../../helpers/renderedParams";
import { ContractEntity } from "../../../domain/entities";
import EditContractClauseList from "./EditContractClauseList";
import ProgressBar from "../../../components/common/ProgressBar";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { ContractClient } from "../../../services/api/ContractClient";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { toast } from "react-toastify";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import ConfirmationPopup from "../../../components/modals/ConfirmationPopup";
import { useParamProcessing } from "../../../hooks/useParamProcessing";
import UploadPDFPopup from "../../../components/modals/UploadPDFPopup";

//maintain the state of the fetched contract data and handle the interactions between the ClauseList and VariableInput components.
function EditContract({
  templateData,
  contractName,
  isEditing,
  setParamValues,
  paramValues,
  initialExcludedClauses,
  initialExcludedSubClauses,
}: {
  templateData: ContractTemplateParsedData;
  setParamValues?:(paramValues: ContractEntity["paramValues"])=>void
  contractName: ContractEntity["name"];
  isEditing: boolean;
  paramValues: ContractEntity["paramValues"];
  initialExcludedClauses: ContractEntity["excludedClauses"];
  initialExcludedSubClauses: ContractEntity["excludedSubClauses"];
}) {
  const {
    inputValues,
    setInputValues,
    fileNames,
    beneficialsMap,
    completionPercentage,
    excludedClauses,
    setExcludedClauses,
    excludedSubClauses,
    setExcludedSubClauses,
    setCompletionPercentage,
    contractListParentRef,
    status,
    setContractStatus,
    setContract,
    contract,
    segmentsOverrides,
    commentsOverrides,
    additionalClauses,
    templateId,

  } = useContext(InputValuesContext);
  const processParamValues = useParamProcessing();
  const navigate=useNavigate()
  const { setLoading } = useContext(LoadingContext);
  const [isFrozen, setIsFrozen] = useState(contract?.status === "Signed"? true : false);
  const [isUploadPopupVisible, setUploadPdfModal] = useState(false);
  const [body, setBody] = useState({});
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [pendingStatus, setPendingStatus] = useState<
    ContractEntity["status"] | null
  >(null);

  useEffect(() => {
   if( contract?.status === "Signed"){
    setIsFrozen(true);
   }
  },[contract]);
  const apiClient = useApiClientWithLoading(setLoading, "application/json");
  const contractClient = new ContractClient(apiClient);

  const [selected, setSelected] = useState<SelectedType>({
    eventType: null,
    clauseId: null,
    paramName: null,
  });
  const { t } = useTranslation();
  const combinedParams: ParsedClauseParam[] = useMemo(() => {
    const renderedParamNames = [];
    templateData
      ?.filter((clause) => !isClauseExcluded(clause.id, excludedClauses))
      ?.map((clause) => {
        renderedParamNames.push(
          ...getRenderedParams(
            clause.segmentation,
            inputValues,
            fileNames,
            beneficialsMap
          )
        );
        clause.subClauses
          .filter(
            (subClause) =>
              !isSubClauseExcluded(subClause.id, excludedSubClauses)
          )
          .map((subClause) => {
            renderedParamNames.push(
              ...getRenderedParams(
                subClause.segmentation,
                inputValues,
                fileNames,
                beneficialsMap
              )
            );
          });
      });

    const combinedParams = generateCombinedParams(
      templateData,
      isEditing,
      paramValues
    ).map((param) => {
      param.rendered = renderedParamNames.includes(param.name);
      return param;
    });
    return combinedParams;
  }, [
    templateData,
    isEditing,
    paramValues,
    inputValues,
    excludedClauses,
    excludedSubClauses,
  ]);

  const renderedParams = useMemo(
    () => combinedParams.filter((param) => param.rendered),
    [combinedParams]
  );
  const optionalClauses = useMemo(
    () => extractOptionalClauses(templateData),
    [templateData]
  );
  const optionalSubClauses = useMemo(
    () => extractOptionalSubclauses(templateData),
    [templateData]
  );

  useEffect(() => {
    const newCompletionPercentage = calculateCompletionPercentage(
      inputValues,
      renderedParams,
      excludedClauses,
      excludedSubClauses
    );
    setCompletionPercentage(newCompletionPercentage);
  }, [inputValues, renderedParams, excludedClauses, excludedSubClauses]);

  const [isClauseEditing, setIsClauseEditing] = useState(false);

  const selectionContextValue = useMemo(
    () => ({
      selected,
      setSelected,
      isClauseEditing,
      setIsClauseEditing,
    }),
    [selected, setSelected, isClauseEditing, setIsClauseEditing]
  );

  useEffect(() => {
    if (isEditing) {
      const newInputValues = {};

      combinedParams.forEach((param) => {
        const { name, value } = param;
        newInputValues[name] = value;
      });

      setInputValues(newInputValues);
      setExcludedClauses(initialExcludedClauses);
      setExcludedSubClauses(initialExcludedSubClauses);
    }
  }, [isEditing]);

  const clauseListRef = useRef(null);
  const inputListRef = useRef(null);

  const titleRef = useRef(null);
  const location = useLocation();
  const statuses: {
    value: ContractEntity["status"];
    display: string;
  }[] = [
    {
      value: "Draft",
      display: t("status.Draft"),
    },
    {
      value: "Done",
      display: t("status.Done"),
    },
    {
      value: "Negotiation",
      display: t("status.Negotiation"),
    },

    {
      value: "ToBeSigned",
      display: t("status.ToBeSigned"),
    },
    {
      value: "Signed",
      display: t("status.Signed"),
    },
  ];

  const handleStatusChange = async (newStatus: string) => {
    if (newStatus === "Signed") {
      setPendingStatus(newStatus);
      setIsPopupVisible(true);
      return;
    }
    try {
      const { processedParamValues, hasError } = await processParamValues(
        inputValues
      );
      setContractStatus(newStatus as ContractEntity["status"]);
      const body = {
        templateId: templateId,
        paramValues: processedParamValues,
        segmentsOverrides: segmentsOverrides,
        commentsOverrides,
        additionalClauses,
        excludedSubClauses: excludedSubClauses,
        excludedClauses: excludedClauses,
        status: newStatus as ContractEntity["status"],
        name: inputValues.contractName,
      };

      const updatedContract=await contractClient.update(contract?.id, body);
      setParamValues(updatedContract.paramValues)
      toast.success(t("pages.contracts.updating.messages.successStatus"));
    } catch (error) {
      console.error("Failed to update contract status:", error);
    }
  };
  const confirmStatusChange = async () => {
    if (!pendingStatus) return;
    setIsFrozen(true);
    setUploadPdfModal(true)
    try {
      const { processedParamValues, hasError } = await processParamValues(
        inputValues
      );
      setContractStatus(pendingStatus);
      setContract({ ...contract, status: pendingStatus });
      const body = {
        templateId: templateId,
        paramValues: processedParamValues,
        segmentsOverrides: segmentsOverrides,
        commentsOverrides,
        additionalClauses,
        excludedSubClauses: excludedSubClauses,
        excludedClauses: excludedClauses,
        status: pendingStatus as ContractEntity["status"],
        name: inputValues.contractName,
      };
      setBody(body)
      setIsPopupVisible(false)
     
    } catch (error) {
      console.error("Failed to update contract status:", error);
    }
  };
  useEffect(() => {
    if (contract?.status && completionPercentage < 100 && contract?.status !== "Draft") {
        const timeoutId = setTimeout(() => {
            setContractStatus("Draft");
        }, 1000); 
        return () => clearTimeout(timeoutId);
    }
}, [completionPercentage, contract?.status]);
  return (
    <SelectionContext.Provider value={selectionContextValue}>
      <div className="row edit-contract">
        <div
          className="col-8"
          ref={contractListParentRef}
          id="contractListParent"
          style={{ position: "relative" }}
        >
          {isFrozen && (
            <div className="overlay">
              <div className="overlay-message">
              {t("pages.contracts.frozen")}
              </div>
            </div>
          )}

          <div className="contract-header d-flex justify-content-between align-items-center px-2">
            <h1 className="contract-name" ref={titleRef} id="contractName">
              {contractName}
            </h1>
            <div className="status-select">
            <div className="select-wrapper">
              <select
                value={status}
                onChange={(e) => handleStatusChange(e.target.value)}
                className="status-dropdown"
              >
                {statuses.map((option) => {
                  const isDisabled =
                    (option.value === "Done" && completionPercentage < 100) ||
                    (option.value === "Negotiation" && status !== "Done") ||
                    (option.value === "ToBeSigned" &&
                      status !== "Negotiation") ||
                    (option.value === "Signed" && status !== "ToBeSigned") ||
                    status === "Signed";
                  const isGoingBackDisabled =
                    status === "ToBeSigned" &&
                    (option.value === "Draft" ||
                      option.value === "Done" ||
                      option.value === "Negotiation")
                      ? false
                      : isDisabled;

                  return (
                    <option
                      key={option.value}
                      value={option.value}
                      disabled={isGoingBackDisabled}
                      className={
                        isGoingBackDisabled
                          ? "disabled-option"
                          : "enabled-option"
                      }
                    >
                      {option.display}
                    </option>
                  );
                })}
              </select>

              <FaCheckCircle className="icon-success" />
              <FaExclamationCircle className="icon-error" />
            </div>
            </div>
          </div>
          <div
            ref={clauseListRef}
            className="clause-list-container"
            id="clauseListContainer"
          >
            <EditContractClauseList
              clauseListRef={clauseListRef}
              templateData={templateData}
            />
          </div>
        </div>
        <div className="col-4 contract-clause">
      
          <div className="d-flex px-3 contract-clause-header justify-content-between align-items-center">
            <div style={{ width: "100%", marginTop:"-6px" , display:"flex", justifyContent:"space-between"}}>
              <h4>{t("pages.editionContract.edit")}</h4>
              <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                 <ProgressBar expanded={false} progress={completionPercentage} />
         </div>
            </div>
          </div>
          <div ref={inputListRef} className="variable-input-container">
            <EditContractInputList
              templateData={templateData}
              inputListRef={inputListRef}
              isEditing={isEditing}
            />
            <div style={{ height: 500 }} />
          </div>
        </div>
        <ConfirmationPopup
          isVisible={isPopupVisible}
          title={t("modals.confirmationSignature.title")}
          message={t("modals.confirmationSignature.msg")}
          onConfirm={confirmStatusChange}
          onCancel={() => {setIsPopupVisible(false); setContractStatus("ToBeSigned")}}
        />
        <UploadPDFPopup
          isVisible={isUploadPopupVisible}
          onCancel={() => setUploadPdfModal(false)}
          body={body}
          contractId={contract?.id}
          setParamValues={setParamValues}
        />
      </div>
    </SelectionContext.Provider>
  );
}
export default EditContract;
