import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./renderSegmentedText.scss";
import {
  ClauseEntity,
  ContractEntity,
  DocumentEntity,
  SubClauseEntity,
} from "../../domain/entities";
import {
  findParamBoundaries,
  getRenderSegments,
} from "../../helpers/segmentation";
import {
  RenderSegments,
  SegmentedText,
  SegmentedTextType,
} from "../../domain/types/ClauseParams";
import { StateSync } from "../../utils/state";
import SelectionContext from "../../contexts/SelectionContext";
import TableRender from "./TableRender";
import { useTranslation } from "../../contexts/TranslationProvider";
import FilePreviewModal from "../modals/FilePreviewModal";
import { useApiClientWithLoading } from "../../services/api/ApiClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import { fetchTextFileUrl } from "../../helpers/helper";
import { renderStyledSegment } from "../../helpers/renderSegment";
import RenderSingleSegment from "./RenderSingleSegment";
import RenderStaticTableSegmentedText from "./RenderStaticTableSegmentedText";
import { render } from "@testing-library/react";
import RenderListSegmentedText from "./RenderListSegmentedText";

interface RenderSegmentedTextProps {
  inputValues: Record<string, any>;
  clauseId: ClauseEntity["id"];
  segmentation: ClauseEntity["segmentation"];
  subClauseId?: SubClauseEntity["id"];
  fileNames: ContractEntity["fileNames"];
  beneficialsMap: ContractEntity["beneficialsMap"];
  segmentsOverrides: Record<string, string>;
  onSegmentChange: (id: string, text: string) => void;
  isSelected: boolean;
  isEditing: boolean;
  formattedRawRef?: React.MutableRefObject<any>;
}
function RenderSegmentedText({
  segmentation,
  fileNames,
  beneficialsMap,
  segmentsOverrides,
  onSegmentChange,
  isSelected,
  isEditing,
  inputValues,
}: RenderSegmentedTextProps) {
  const { selected } = useContext(SelectionContext);

  const { t, language } = useTranslation();

  const [editingSegmentId, setEditingSegmentId] = useState(null);
  const [changedParamBoundaries, setChangedParamBoundaries] = useState<
    [number, number][]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<DocumentEntity | null>(null);
  const apiClient = useApiClientWithLoading();
  const documentClient = new DocumentClient(apiClient);
  const [text, setText] = useState<string>(null);
  const spanRef = useRef(null);
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.contentEditable = true;
      spanRef.current.focus();
    }
  }, [editingSegmentId]);

  const stateSync = useMemo(
    () =>
      new StateSync(
        setEditingSegmentId,
        (prev, newValue) => {
          return prev || newValue;
        },
        100
      ),
    [setEditingSegmentId]
  );

  const renderSegments: RenderSegments = getRenderSegments(
    segmentation.segmentedText,
    inputValues,
    fileNames,
    beneficialsMap,
    segmentation.segmentedParams,
    t,
    language
  ).map((seg) => {
    return {
      ...seg,
      value: seg.value?.replaceAll(/\r\n/g, "\n"),
    };
  });
  const handlePreviewFile = async (id) => {
    if (id && Number(id)) {
      try {
        const row = await documentClient.getById(id);
        const text = await fetchTextFileUrl(row?.textFile);
        if (row && text) {
          setFileToPreview(row);
          setText(text);
          setIsModalOpen(true);
        }
      } catch (err) {
        if (err.response.status === 400) {
          setText("");
          setIsModalOpen(false)
        }
      }
    }
  };
  const handleStaticContentChange = (id: SegmentedText[number][0]) => (newContent:string) => {
    const segment = renderSegments.find((seg) => seg.id == id);
    if (segment) {
      onSegmentChange(id, newContent);
    } else {
      console.warn(id,newContent);
    }
  };
  useEffect(() => {
    const paramNames = renderSegments
      .map((seg) => (seg as any).paramName)
      .filter((seg) => seg);
    if (
      selected.eventType == "ParamValueChange" &&
      selected.paramName &&
      paramNames.includes(selected.paramName)
    ) {
      const boundaries = findParamBoundaries(
        renderSegments,
        selected.paramName
      );
      setChangedParamBoundaries(boundaries);
    } else {
      setChangedParamBoundaries([]);
    }
  }, [selected]);
  const openFilePreview = async (id) => {
    await handlePreviewFile(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFileToPreview(null);
  };
  const filteredSegments = [];
  let isInTable = false; // Flag to track if we are inside a table
  let alignment: "left" | "center" | "right" | "justify" = "left";
  let currentParagraph = { children: [], alignment: "left" };
  let insideList = false;
  let currentListElement = { children: [], alignment: "left" };
  let currentList = { children: [], type: "bulleted-list" };
  renderSegments.forEach((segment, idx) => {
    (segment as any).actualIdx = idx;
    if (segment.type === SegmentedTextType.PARAGRAPH_START) {
      if (currentParagraph.children.length > 0) {
        filteredSegments.push(
          <span key={`${filteredSegments.length}-${segment.id}`} style={{ textAlign: alignment, display: "inline-block", width: "100%", marginBottom: "10px" }}>
            {currentParagraph.children}
          </span>
        )
        currentParagraph.children = [];
      }
      alignment = segment.style?.textAlign as "left" | "center" | "right" | "justify" || "left";
      if (insideList) {
        if (currentListElement.children.length > 0) {
          currentList.children.push(currentListElement);
          currentListElement = { children: [], alignment: "left" };
        }
        if (currentList.children.length > 0) {
          filteredSegments.push(
            <RenderListSegmentedText
              list={currentList}
              handleStaticContentChange={handleStaticContentChange}
              isEditing={isEditing}
              isSelected={isSelected}
              segmentation={segmentation}
              segmentsOverrides={segmentsOverrides}
              changedParamBoundaries={changedParamBoundaries}
              openFilePreview={openFilePreview}
              editingSegmentId={editingSegmentId}
              stateSync={stateSync}
              spanRef={spanRef}
              key={segment.id}
              inputValues={inputValues}
            />
          )
          currentList = { type: 'bulleted-list', children: [] };
        }
        insideList = false;
      }
    }
    if (segment.type === SegmentedTextType.STATIC_TABLE_START) {
      insideList = false;
      if (currentParagraph.children.length > 0) {
        filteredSegments.push(
          <span key={segment.id} style={{ textAlign: alignment, display: "inline-block", width: "100%" }}>
            {currentParagraph.children}
          </span>
        )
        currentParagraph.children = [];
      }
      // Extract segments for the table separately
      const paramName = segment.paramName;
      const tableEndIndex = renderSegments.findIndex(
        seg => seg.type === SegmentedTextType.STATIC_TABLE_END && seg.paramName === paramName
      );

      // Extract table segments
      const tableSegments = renderSegments.slice(idx, tableEndIndex + 1);

      // Render table component
      const tableComponent = (
        <RenderStaticTableSegmentedText
          style={segment.style}
          handleStaticContentChange={handleStaticContentChange}
          isEditing={isEditing}
          isSelected={isSelected}
          segmentation={segmentation}
          segmentsOverrides={segmentsOverrides}
          changedParamBoundaries={changedParamBoundaries}
          tableSegments={tableSegments}
          openFilePreview={openFilePreview}
          editingSegmentId={editingSegmentId}
          stateSync={stateSync}
          spanRef={spanRef}
          key={`table-${paramName}`}
          inputValues={inputValues}
        />
      );
      filteredSegments.push(tableComponent);
      isInTable = true;
      return;
    }
    if (segment.type === SegmentedTextType.STATIC_TABLE_END) {
      isInTable = false;
      return;
    }

    if (insideList && (segment.type === SegmentedTextType.STATIC || segment.type === SegmentedTextType.PARAM || segment.type === SegmentedTextType.COMMENT || segment.type === SegmentedTextType.PARAM_VALUE)) {
      currentListElement.children.push(segment);
      return;
    }
    if (segment.type === SegmentedTextType.LIST_ITEM_START) {
      if (insideList) {
        if (currentListElement.children.length > 0) {
          currentList.children.push(currentListElement);
          currentListElement = { children: [], alignment: segment?.style?.textAlign };
        }
        const isListNew = segment.style.listType ? true : false;
        if (isListNew) {
          if (currentList.children.length > 0) {
            filteredSegments.push(
              <RenderListSegmentedText
                list={currentList}
                handleStaticContentChange={handleStaticContentChange}
                isEditing={isEditing}
                isSelected={isSelected}
                segmentation={segmentation}
                segmentsOverrides={segmentsOverrides}
                changedParamBoundaries={changedParamBoundaries}
                openFilePreview={openFilePreview}
                editingSegmentId={editingSegmentId}
                stateSync={stateSync}
                spanRef={spanRef}
                key={segment.id}
                inputValues={inputValues}
              />
            )
            currentList = { type: segment.style.listType, children: [] };
          }
        }
      } else {
        if (currentParagraph.children.length > 0) {
          filteredSegments.push(
            <span key={filteredSegments.length} style={{ textAlign: alignment, display: "inline-block", width: "100%" }}>
              {currentParagraph.children}
            </span>
          )
          currentParagraph.children = [];
        }
        currentList = { type: segment?.style?.listType, children: [] };
        currentListElement = { children: [], alignment: segment?.style?.textAlign };
        insideList = true;
      }
    }

    // If we are not inside a table, push the segment to filteredSegments
    if (!isInTable && !insideList) {
      const segmentComponent = (
        <RenderSingleSegment
          segmentIdx={(segment as any).actualIdx}
          handleStaticContentChange={handleStaticContentChange}
          isEditing={isEditing}
          isSelected={isSelected}
          segment={segment}
          segmentsOverrides={segmentsOverrides}
          changedParamBoundaries={changedParamBoundaries}
          segmentation={segmentation}
          openFilePreview={openFilePreview}
          editingSegmentId={editingSegmentId}
          stateSync={stateSync}
          inputValues={inputValues}
          spanRef={spanRef}
          key={segment.id}
        />
      );
      currentParagraph.children.push(segmentComponent);
    }
  });

  if(insideList){
    if(currentListElement.children.length > 0){
      currentList.children.push(currentListElement);
      currentListElement = { children: [], alignment: "left" };
    }
    if(currentList.children.length > 0){
      filteredSegments.push(
				<RenderListSegmentedText
          list={currentList}
          handleStaticContentChange={handleStaticContentChange}
          isEditing={isEditing}
          isSelected={isSelected}
          segmentation={segmentation}
          segmentsOverrides={segmentsOverrides}
          changedParamBoundaries={changedParamBoundaries}
          openFilePreview={openFilePreview}
          editingSegmentId={editingSegmentId}
          stateSync={stateSync}
          spanRef={spanRef}
          key={filteredSegments.length}
          inputValues={inputValues}
        />
			)
			currentList = { type: 'bulleted-list', children: [] };
    }
  }
  if (currentParagraph.children.length > 0) {
    filteredSegments.push(
      <span key={filteredSegments.length} style={{ textAlign: alignment, display: "inline-block", width: "100%" }}>
        {currentParagraph.children}
      </span>
    )
    currentParagraph.children = [];
  }
  return (
    <div className="clause-segmentation" style={{ marginTop: "30px" }}>
      {filteredSegments}
      <FilePreviewModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        fileDocument={fileToPreview}
        text={text}
      />
    </div>
  );
}

export default RenderSegmentedText;
