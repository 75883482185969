import React, { useState } from 'react';
import './tableGridSelector.scss';

function TableGridSelector({ onGridSelect, defaultRows, defaultCols }) {
  const [hoveredRows, setHoveredRows] = useState(defaultRows || 0);
  const [hoveredCols, setHoveredCols] = useState(defaultCols || 0);
  const [isHovering, setIsHovering] = useState(false);
  const maxRows = 7;
  const maxCols = 7;

  const handleMouseOver = (row, col) => {
    setIsHovering(true);
    setHoveredRows(row);
    setHoveredCols(col);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleClick = () => {
    onGridSelect(hoveredRows, hoveredCols);
  };

  return (
    <div className="table-grid-selector">
      <div
        className="grid-container"
        onMouseLeave={handleMouseLeave}  // Detect when mouse leaves the grid
      >
        {Array.from({ length: maxRows }).map((_, rowIdx) => (
          <div key={rowIdx} className="grid-row">
            {Array.from({ length: maxCols }).map((_, colIdx) => (
              <div
                key={colIdx}
                className={`grid-cell 
                  ${
                    (isHovering
                      ? rowIdx < hoveredRows && colIdx < hoveredCols
                      : rowIdx < defaultRows && colIdx < defaultCols)
                      ? 'selected-cell'
                      : ''
                  }`}
                onMouseOver={() => handleMouseOver(rowIdx + 1, colIdx + 1)}
                onClick={handleClick}
              />
            ))}
          </div>
        ))}
      </div>
      { isHovering && <div className="grid-info">
        {hoveredRows} x {hoveredCols}
      </div>}
    </div>
  );
}

export default TableGridSelector;