import React, { useState, useContext, useEffect } from 'react'
import { BeneficialEntity, BeneficialCompanyEntity } from '../../../domain/entities';
import InputValuesContext from '../../../contexts/InputValuesContext';
import useApiClientWithLoading from '../../../services/api/ApiClient';
import { BeneficialClient } from '../../../services/api/BeneficialClient';
import AddBenificialModel from '../../../components/modals/AddBeneficialModal';
import { useTranslation } from '../../../contexts/TranslationProvider';
import createProjectContext from '../../../contexts/CreateProjectContext';
import Close from '../../../assets/new-design-icons/Close.svg'

function BeneficialSection() {
  const { t, language } = useTranslation()
  const { selectedBeneficials, setSelectedBeneficials } = useContext(createProjectContext)
  const closeIcon = Close.toString()
  const lookup = require("../../../assets/lookup.png")
  const [searchBeneficial, setSearchBeneficial] = useState<string>();
  const [currentBeneficial, setCurrentBeneficial] = useState<BeneficialEntity>();
  const [filteredCompanyData, setFilteredCompanyData] = useState<BeneficialCompanyEntity[]>([]);
  const [beneficials, setBeneficials] = useState<BeneficialEntity[]>([]);
  const { inputValues, beneficialsMap, setBeneficialsMap, fileNames } = useContext(InputValuesContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popupBeneficial, setPopupBeneficial] = useState(false);

  const apiClient = useApiClientWithLoading();
  const handleInputChange = (event) => {
    setSearchBeneficial(event.target.value)
    setCurrentBeneficial(null)
    setFilteredCompanyData(
      (beneficials.filter(
        (element) => element?.type == 'Company' && element?.companyName?.toLowerCase().includes(event.target.value))
      ) as BeneficialCompanyEntity[])
    console.log('filter', filteredCompanyData)
    setShowDropdown(true)
  }

  useEffect(() => {
    const beneficialClient = new BeneficialClient(apiClient);
    beneficialClient.getAll().then(({ rows }) => {
      setBeneficials(rows.filter((element) => element?.type == "Company"))
    });
  }, []);

  const handleDropdownSelect = (item) => {
    setSearchBeneficial("")
    setCurrentBeneficial(null)
    setSelectedBeneficials(prev => {
      if (!prev.find((element) => element?.id == item?.id)) {
        return [...prev, item]
      }
      return prev
    })
    setShowDropdown(false)
  }
  const handleOpenPopupBeneficial = () => {
    setShowDropdown(false)
    setPopupBeneficial(true)
  }

  return (
    <div className='section'>
      <span className='section-title'>
        {t("pages.createProject.addNewBeneficial")}
      </span>
      <div className='section-body'>
        <div className='project-beneficial-name' >
          <input
            style={{ borderRadius: "8px" }}
            type="text"
            className=""
            value={searchBeneficial ?? ""}
            onChange={(event) => handleInputChange(event)}
            placeholder={t("pages.createProject.addNewBeneficial")}
          />
          <img src={lookup} className={`lookup-img ${language == "ar" && "lookup-img-arabic"}`} />
          {showDropdown && (
            <div className="list-beneficial-project">
              {filteredCompanyData.length > 0 ? filteredCompanyData.map((item, index) => (
                <div className="item-beneficial-project" key={index} onClick={() => handleDropdownSelect(item)}>
                  <span>{item.companyName}</span>
                  {item?.profilePhoto && <img className='beneficial-project-img' src={item?.profilePhoto?.url} alt='photo' />}
                </div>
              )) : (
                <div className="item-beneficial-project" onClick={() => handleOpenPopupBeneficial()}>
                  <span className='add-new-project-beneficial'>{t("pages.createProject.addNewBeneficial")}</span>
                </div>
              )}
            </div>
          )}
          {popupBeneficial && (
            <AddBenificialModel
              onClose={() => setPopupBeneficial(false)}
              beneficialsMap={beneficialsMap}
              setBeneficialsMap={setBeneficialsMap}
              setSelectedBeneficial={
                (newBeneficial) => {
                  setSelectedBeneficials([...selectedBeneficials, (newBeneficial as BeneficialCompanyEntity)]);
                  setPopupBeneficial(false);
                }
              }
              currentBenificialData={{}}
              onSave={(beneficialId) => {
                // handleInputChange(
                //     { target: { value: beneficialId } },
                //     param.name,
                //     clauseId,
                //     subClauseId
                // );
                setPopupBeneficial(false);
              }}
              beneficialTypes={['Company']}
            />
          )}
        </div>
        <div className='selected-beneficials'>
          {
            selectedBeneficials.map((item, index) => (
              <div className='selected-beneficial' key={index}>
                {/* <img className='beneficial-project-img' src={item?.profilePhoto?.url} /> */}
                <span>{(item as BeneficialCompanyEntity).companyName}</span>
                <img src={closeIcon} className='close-icon'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedBeneficials(selectedBeneficials.filter((element) => element?.id !== item?.id))
                  }} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default BeneficialSection