import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApiClientWithLoading from "../../services/api/ApiClient";
import "./projectTemplate.scss";
import { GroupEntity, ProjectTemplateEntity, ProjectTemplateRequirementEntity } from "../../domain/entities";
import { useTranslation } from "../../contexts/TranslationProvider";
import { ProjectTemplateClient } from "../../services/api/ProjectTemplateClient";
import { toast } from "react-toastify";
import EventManager from "../../services/EventManager";
import { downloadFileJSON } from "../../utils/json";
import Loading from "../../components/common/Loading";
import EditProjectTemplate from "./EditProjectTemplate";
import EditProjectTemplateContext from "../../contexts/ProjectTemplateContext";
import { GroupClient } from "../../services/api/GroupClient";


const ProjectTemplate = () => {
  const { t, language, setLanguage } = useTranslation();
  const [groups, setGroups] = useState<GroupEntity[]>([]);
  const translationPath = "pages.EditProjectTemplate.";

  const [projectTemplate, setProjectTemplate] =
    useState<ProjectTemplateEntity | null>(null);
  const [projectTemplateRequirements, setProjectTemplateRequirements] = useState<ProjectTemplateRequirementEntity[]>([]);

  const navigate = useNavigate();
  const { projectTemplateId } = useParams();
  const apiClient = useApiClientWithLoading();
  const projectTemplateClient = new ProjectTemplateClient(apiClient);
  const groupClient = new GroupClient(apiClient);

  const onSelectGroupInRequirement = async (newGroups: GroupEntity[]) => {
    projectTemplate.groups = newGroups as any
    const updatedContractTemplate = { ...projectTemplate };

    try {
      const result = await projectTemplateClient.update(projectTemplate.id, updatedContractTemplate);
      if (result) {
        setProjectTemplate(updatedContractTemplate);
      }
      return result;
    } catch (error) {
      console.error("Failed to update the project template:", error);
      return null;
    }
  };
  useEffect(() => {
    if (projectTemplateId) {
      (async () => {
        const row = await projectTemplateClient.getById(
          parseInt(projectTemplateId)
        );
        const groupData = await groupClient.getAll();
        setGroups(groupData.rows);
        setProjectTemplate(row);
        setProjectTemplateRequirements(row?.requirements);
        const arabicRegex = /[\u0600-\u06FF]/;
        if (arabicRegex.test(row.name)) {
          setLanguage("ar");
        }
      })();
    }
  }, [projectTemplateId]);
  const onTemplateRename = async (newName: string) => {
    let newFormTemplateValue = { ...projectTemplate };
    newFormTemplateValue.name = newName;
    setProjectTemplate(newFormTemplateValue);
  };
  const activateProjectTemplateHandler = async () => {
    try {
      await saveDraftProjectTemplateHandler()
        .then(async () => {
          await projectTemplateClient.activate(projectTemplate.id);
          toast.success(t(translationPath + "messages.activateSuccess"));
        });
    } catch (error) {
      toast.error(t(translationPath + "messages.activateError"))
      console.error("Error activating template:", error);
    }
  };
  useEffect(() => {
    EventManager.subscribe(
      "ActivateProjectTemplate",
      activateProjectTemplateHandler
    );
    return () => {
      EventManager.unsubscribe(
        "ActivateProjectTemplate",
        activateProjectTemplateHandler
      );
    };
  }, [projectTemplate]);
  const exportJsonProjectTemplateHandler = async () => {
    try {
      const { url } = await projectTemplateClient.export(
        projectTemplate.id,
        "json"
      );
      await downloadFileJSON(url, `${projectTemplate.name}.json`);
    } catch (error) { }
  };
  useEffect(() => {
    EventManager.subscribe(
      "ExportJsonProjectTemplate",
      exportJsonProjectTemplateHandler
    );
    return () => {
      EventManager.unsubscribe(
        "ExportJsonProjectTemplate",
        exportJsonProjectTemplateHandler
      );
    };
  }, [projectTemplate]);
  const saveDraftProjectTemplateHandler = async () => {
    try {
      const updatedRessources = projectTemplateRequirements.map((requirement, index) => ({
        ...requirement,
        index: String(index + 1)
      }));

      const templateData = {
        ...projectTemplate,
        requirements: updatedRessources,
      };
      const updatedTemplate = await projectTemplateClient.update(
        projectTemplate.id,
        templateData
      );
      if (updatedTemplate) {
        toast.success(
          t(translationPath + "messages.success")

        );
        navigate("/project-templates");
      }
    } catch (error) {
      toast(t(translationPath + "messages.error"))
      console.log('error :>> ', error);
    }
  };
  useEffect(() => {
    EventManager.subscribe(
      "SaveDraftProjectTemplate",
      saveDraftProjectTemplateHandler
    );
    return () => {
      EventManager.unsubscribe(
        "SaveDraftProjectTemplate",
        saveDraftProjectTemplateHandler
      );
    };
  }, [projectTemplate, projectTemplateRequirements]);
  const EditProjectTemplateContextValue = useMemo(() => {
    return {
      projectTemplate,
      setProjectTemplate,
      projectTemplateRequirements,
      setProjectTemplateRequirements,
      onSelectGroupInRequirement,
      onTemplateRename,
      groups
    }

  }, [projectTemplate, projectTemplateRequirements])
  return (
    <EditProjectTemplateContext.Provider value={EditProjectTemplateContextValue}>
      <div className="contract-container d-flex justify-content-between align-items-center">
        {!projectTemplate ? (
          <div className="contract-loading-container">
            <Loading height="90vh" />
          </div>
        ) : (
          <EditProjectTemplate />
        )}
      </div>
    </EditProjectTemplateContext.Provider>
  );
};

export default ProjectTemplate;
