import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { ProjectEntity, ProjectTemplateEntity } from '../domain/entities';

export interface ProjectContextType {
  projects: ProjectEntity[];
  projectTemplate: ProjectTemplateEntity[];
  setProjects: Dispatch<SetStateAction<ProjectEntity[]>>;
  setProjectTemplate: Dispatch<SetStateAction<ProjectTemplateEntity[]>>;
}

const ProjectContext = createContext<ProjectContextType>({
} as any);

export default ProjectContext;

export const ProjectProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [projects, setProjects] = useState<ProjectEntity[]>([]);
  const [projectTemplate, setProjectTemplate] = useState<ProjectTemplateEntity[]>([]);
  const projectContext: ProjectContextType = useMemo(
    () => ({
      projects,
      setProjects,
      projectTemplate,
      setProjectTemplate
    }),
    [projectTemplate, projects, setProjects, setProjectTemplate]
  );

  return (
    <ProjectContext.Provider value={projectContext}>
      {children}
    </ProjectContext.Provider>
  );
}