import React, { useEffect, useState } from "react";
import "../../../components/modals/commonmodal.scss";
import "./OptionsRessourceProject.scss"
import { useTranslation } from "../../../contexts/TranslationProvider";
import Slider from "../../../components/common/SliderWrapper";
import RessourceForm from "./RessourceForm";
import RessourceContract from "./RessourceContract";
import RessourceDocument from "./RessourceDocument";
import { ProjectTemplateEntity, ProjectTemplateRequirementEntity } from "../../../domain/entities";

function OptionsRessourceProject({ onClose, setRessources, selectedType, typeOfCreation, ressource = {}, ressources, projectTemplate }: {
  onClose: () => void;
  ressources?: any[];
  selectedType?: string;
  typeOfCreation?: "contract" | "form" | "document";
  setRessources: (object) => void;
  ressource?: ProjectTemplateRequirementEntity,
  projectTemplate: ProjectTemplateEntity
}) {
  const [selectedTypeRessource, setSelectedType] = useState(selectedType ? selectedType : "");
  const { t, language } = useTranslation()
  const [loading, setLoading] = useState(false);

  const translationPath = t("pages.admin.actionsOptionProjectTemplate")
  const [typeOfCreationRessource, setTypeOfCreation] = useState(typeOfCreation ? typeOfCreation : "contract")
  const options: any[] = [
    { value: "contract", label: translationPath.contract.title },
    { value: "form", label: translationPath.form.title },
    { value: "document", label: translationPath.document.title },
  ];
  const handleComboBoxChange = (field) => (value) => {
    setTypeOfCreation(value)
  };
  useEffect(() => {
    selectedTypeRessource == "" && setSelectedType(options[0].value)
  }, [selectedTypeRessource])
  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div
        id="contractz-lab"
      >
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {ressource.name ? translationPath.editTitle : translationPath.title}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "83%" }}
                ></button>
              </div>
              {!loading && <Slider
                setSelectedType={setSelectedType}
                selectedType={selectedTypeRessource}
                options={options}
                handleComboBoxChange={handleComboBoxChange}
                language={language}
                disabled={!!ressource.name}
              />}
              <div className="modal-body pb-5" style={{ minHeight: "200px" }}>
                {typeOfCreationRessource == "contract" &&
                  <RessourceContract projectTemplate={projectTemplate} requirement={ressource} ressources={ressources} setRessources={setRessources} onClose={onClose} loading={loading} setLoading={setLoading} />
                  || typeOfCreationRessource == "form" &&
                  <RessourceForm projectTemplate={projectTemplate} requirement={ressource} ressources={ressources} setRessources={setRessources} onClose={onClose} loading={loading} setLoading={setLoading} />
                  || typeOfCreationRessource == "document" &&
                  <RessourceDocument projectTemplate={projectTemplate} requirement={ressource} ressources={ressources} setRessources={setRessources} onClose={onClose} loading={loading} setLoading={setLoading} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OptionsRessourceProject;
