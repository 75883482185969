import React from 'react'
import { FileEntity } from '../../../domain/entities'
const docIcon = require('../../../assets/new-design-icons/doc.png')
function RessourceCard({ressource}: {ressource: FileEntity}) {
  return (
    <div className='ressource-card'>
      <img src={docIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> 
      <span className='title'>
        {ressource.name}
      </span>
    </div>
  )
}

export default RessourceCard