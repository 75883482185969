import React from 'react'
import { ClauseParam } from '../../../domain/types/ClauseParams'
import { useTranslation } from '../../../contexts/TranslationProvider'

function ParamDisplay({ param, handleRemove }: { param: ClauseParam, handleRemove: (param: ClauseParam) => void }) {
  const { t } = useTranslation()
  const translationPath = t("pages.contractTemplateParam.edition")
  const translationTypes = "pages.contractTemplateParam.params"
  return (
    <div className='param-display'>
      <div className='remove-param' onClick={() => handleRemove(param)}>X</div>
      <div className='param-content'>
        <div className='param-info param-type'>{t(`${translationTypes}.${param.type}`)}</div>
        <div className='param-info'>{t(translationPath.parameterName)} {param.name}</div>
        <div className='param-info'>{t(translationPath.parameterLabel)} {param.label}</div>
      </div>
    </div>
  )
}

export default ParamDisplay