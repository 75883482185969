import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { CustomPropertyEntity } from "../../domain/entities/CustomProperty";

export class CustomPropertyClient extends ModelClient<CustomPropertyEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'CustomProperty')
  }

}