import React, { useMemo, useState } from 'react'
import './adminRessourceCard.scss'
import { useTranslation } from '../../../../contexts/TranslationProvider';
import { getTypeLevels } from '../../../../helpers/helper';
import form from "../../../../assets/new-design-icons/Form.svg"
import chat from "../../../../assets/new-design-icons/Chat.svg"
import contract from "../../../../assets/new-design-icons/Contract.svg"
import { FiMoreHorizontal } from "react-icons/fi";
import ContractCardActionsModal from '../../../modals/ContractCardActionsModal';
import { formatDate } from '../../../../helpers/helper';
import Loading from '../../Loading';
const docIcon = require('../../../../assets/new-design-icons/doc.png')


type adminPropsType = {
  onClick: () => void,
  onDelete: () => void
  onDesactivate?: () => void
  data: any
  loading?: boolean,
  projectRequirement?:boolean
}
function AdminRessourceCard({
  data,
  onDelete,
  onClick,
  onDesactivate,
  loading, projectRequirement
}: adminPropsType) {
  const { t, language } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const translationPath = "pages.ressources."
  const typeDisplay = useMemo(() => {
      if (data.type === "Reglement"||(projectRequirement&&data.types[0]===1))
        return data.name
      return getTypeLevels(data).map(
        level => {
          const translatedName = level.translations?.find(t => t.language === language)
          if (translatedName) {
            return translatedName.name
          } else {
            return level.name
          }
        }
      ).join("/")
    // }
   
  }, [data, language]);
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const chatIcon = chat.toString()
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  const handleOpen = () => {
    if (loading) return;
    onClick();
  }
  return (
    <div onClick={handleOpen} className='admin-ressource-card-container'>

      <div className='header'>
        <div className='title'>
          <div className='icon'>
            {
              data.type === 'FormTemplate'||(projectRequirement&&data.types[0]===8) ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                data.type === 'ContractTemplate' ||(projectRequirement&&data.types[0]===2)? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                  data.type === 'Reglement' ? <img src={chatIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                  (projectRequirement&&data.types[0]===1)?<img src={docIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                    <></>
            }
          </div>
          <div className='name'>
            {data.name}
          </div>
        </div>
        <div className='actions'>
          <button className="plus-icon" onClick={(e) => togglePopup(e)}>
            <FiMoreHorizontal size={20} />
          </button>
          {isPopupOpen &&
            <div className='popup'>
              <ContractCardActionsModal onView={onClick} onDelete={onDelete} onDesactivate={onDesactivate} />
            </div>
          }
        </div>
      </div>
      <div className='body'>
        <div className='body-section'>
          <span>
            <span className='label'>
              {t(translationPath + "cardLabels.type")}
            </span>
            <span className='value'>
              {typeDisplay}
            </span>
          </span>

        </div>
        <div className='body-section'>

        </div>
        <div className='timestamp'>
          <div>
            <span className='value'>
              {t(translationPath + "cardLabels.updatedAt")}
            </span>
            <span className='value'>
              {formatDate(data.updatedAt as any as string)}
            </span>
          </div>
          <div>
            <span className='value'>
              {t(translationPath + "cardLabels.createdAt")}
            </span>
            <span className='value'>
              {formatDate(data.createdAt as any as string)}
            </span>
          </div>
        </div>
      </div>
      <div className='footer'>
        {
          data.type === "Reglement" ?
            <div className={`${data.has_vectorstore ? "status-active" : "status"}`}>
              {(data.has_vectorstore ? t("pages.ressources.cardLabels.activeTrue") : t("pages.ressources.cardLabels.activeFalse"))}
            </div>
            :
            <div className={`${data.activated ? "status-active" : "status"}`}>
              {(data.activated ? t("pages.ressources.cardLabels.activeTrue") : t("pages.ressources.cardLabels.activeFalse"))}
            </div>
        }

        {loading ?
          <div className='loading'> <Loading /></div>

          : null}
      </div>

    </div>
  )
}

export default AdminRessourceCard