import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { DocumentUseCaseEntity } from "../../domain/entities/DocumentUseCase";
import { DocumentEntity, DocumentUseCaseResponseEntity, UserEntity } from "../../domain/entities";

export class DocumentUseCaseClient extends ModelClient<DocumentUseCaseEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'DocumentUseCase')
  }

  async getAll(language: UserEntity['language'], schema = "nested"): Promise<{ rows: DocumentUseCaseEntity[], count: number }> {
    const url = new URL(this.modelUrl + "/all")
    url.searchParams.append("schema", schema)
    const response = await this.apiClient.get<{ rows: DocumentUseCaseEntity[], count: number }>(url.href, {
      params: { language }
    })
    return response.data
  }

  async invokeUseCase(documentUseCaseId: DocumentUseCaseEntity['id'], documentId: DocumentEntity['id']) {
    try {
      const response = await this.apiClient.post<{ row: DocumentUseCaseResponseEntity }>(
        `${this.modelUrl}/invoke?schema=nested`,
        {
          documentUseCaseId,
          documentId,
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error in API request:', error);
      throw error;
    }
  };

}