import { useContext } from "react";
import { UserEntity } from "../../../domain/entities";
import "./sidebar.scss";
import { AuthUserContext } from "../../../contexts/AuthUserContext";

function SidebarUser({ isHovering = true, user }:
  {
    isHovering: boolean;
    user: UserEntity;
  }
) {
  const { authUser, activeRole } = useContext(AuthUserContext)

  return (
    <div
      style={{ textDecoration: "none" }}
      className={`d-flex gap-3 sidebar-item`}
    >
      {isHovering ? <div className="sidebar-user-text">
        <div className="sidebar-user-role">{activeRole}</div>
        <div className="sidebar-user-name">{authUser?.fullName}</div>
      </div> : ''}
    </div>
  );
}

export default SidebarUser;
