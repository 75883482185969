import React from 'react'
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getResetPassword } from '../../services/api/UserAuth';
import { resetPassword } from '../../services/api/UserAuth';
import CarouselContainer from '../../components/common/newDesign/Carousel';
import "./resetPassword.scss"
import { useTranslation } from '../../contexts/TranslationProvider';
import InputField from '../../components/common/newDesign/InputField';
import FlagCombobox from '../../components/common/newDesign/CustomCombobox/FlagCombobox';
interface FormData {
    password: string,
    confirmPassword: string,
}
const ResetPassword = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const validatePassword = (password) => {
        const minLength = /.{8,}/;
        const uppercase = /[A-Z]/;
        const lowercase = /[a-z]/;
        const punctuation = /[!@#$%^&*(),.?":{}|<>]/;
        const number = /[0-9]/;

        if (!minLength.test(password)) {
            return t("pages.signUp.newDesign.inputs.password.error1");
        }
        if (!uppercase.test(password)) {
            return t("pages.signUp.newDesign.inputs.password.error2");
        }
        if (!lowercase.test(password)) {
            return t("pages.signUp.newDesign.inputs.password.error3");
        }
        if (!number.test(password)) {
            return t("pages.signUp.newDesign.inputs.password.error4");
        }
        if (!punctuation.test(password)) {
            return t("pages.signUp.newDesign.inputs.password.error5");
        }
        return '';
    };
    const validateConfirmPassword = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            return t("pages.signUp.newDesign.inputs.confirmPassword.error");
        }
        return '';
    }
    const { t, language } = useTranslation()
    const translationPath = t("pages.resetPassword")
    const location = useLocation();
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search);
    const resetPasswordToken = params.get('resetPasswordToken');
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);


    useEffect(() => {
        const checkResetPasswordToken = async () => {
            try {
                const { user } = await getResetPassword(resetPasswordToken);
                setCurrentUser(user);
                console.log("Reset password for user:", user);
            } catch (error) {
                console.error("Invalid or expired reset password token:", error);
                navigate("/forget-password");
            }
        };
        checkResetPasswordToken();
    }, [resetPasswordToken, navigate]);
    const [formData, setFormData] = useState<FormData>({
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState<Record<string, string[]>>({
        password: [],
        confirmPassword: [],
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, password: [], confirmPassword: [] });
    };

    const handleSubmit = async () => {
        const newErrors = {
            password: validatePassword(formData.password) ? [validatePassword(formData.password)] : [],
            confirmPassword: validateConfirmPassword(formData.password, formData.confirmPassword) ? [validateConfirmPassword(formData.password, formData.confirmPassword)] : [],
        };
        if (newErrors.password.length > 0 || newErrors.confirmPassword.length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const user = await resetPassword(formData.password, resetPasswordToken);
            if (user) {
                setPasswordResetSuccess(true);
                setTimeout(() => {
                    navigate("/sign-in")
                }, 500)
            }

        } catch (error) {
            console.error('Error resetting password:', error);
        }

    };

    const handlePrevious = () => {
        navigate("/sign-in")
    }
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ ...formData, password: value });
        const error = validatePassword(value);
        const confirmPasswordError = validateConfirmPassword(value, formData.confirmPassword);
        if (error) {
            if (confirmPasswordError) {
                setErrors({ ...errors, password: [error], signUp: [], confirmPassword: [confirmPasswordError] });
            } else {
                setErrors({ ...errors, password: [error], signUp: [] });
            }
        } else {
            if (confirmPasswordError) {
                setErrors({ ...errors, password: [], signUp: [], confirmPassword: [confirmPasswordError] });
            } else {
                setErrors({ ...errors, password: [], signUp: [], confirmPassword: [] });
            }

        }
    }

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ ...formData, confirmPassword: value });
        const error = validateConfirmPassword(formData.password, value);
        if (error) {
            setErrors({ ...errors, confirmPassword: [error], signUp: [] });
        } else {
            setErrors({ ...errors, confirmPassword: [], signUp: [] });
            return;
        }
    }
    return (
        <div className='forget-password-content'>
            <CarouselContainer />
            <div className='sign-up-sub-content'>
                <div className='header'>
                    <div onClick={handlePrevious} className='logo-back'></div>
                    <FlagCombobox
                        optionValue={(option) => option?.language || null}
                        optionDisplay={(option) => option?.flag || ""}
                    />
                </div>
                <div className='form-container'>
                    <div className='form-section'>
                        <h1 className='greeting'>
                        {currentUser?.activated ? t(translationPath.title) : t(translationPath.activationTitle)}
                        </h1>
                        <div className='divider-container'>
                            <div className='divider'></div>
                        </div>
                        <div className='signin-form'>
                            <InputField
                                value={formData.password}
                                onChange={handlePasswordChange}
                                placeholder={t("pages.signUp.newDesign.inputs.password.placeholder")}
                                type='password'
                                label={t("pages.signUp.newDesign.inputs.password.label")}
                                name='password'
                                error={errors.password}
                                required
                            />
                            <InputField
                                value={formData.confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                placeholder={t("pages.signUp.newDesign.inputs.confirmPassword.placeholder")}
                                type='password'
                                label={t("pages.signUp.newDesign.inputs.confirmPassword.label")}
                                name='confirmPassword'
                                error={errors.confirmPassword}
                                required
                            />
                            <button
                                disabled={errors.password.length !== 0 || errors.confirmPassword.length !== 0}
                                className={`create-account ${(errors.password.length !== 0 || errors.confirmPassword.length !== 0) && "disabled"}`}
                                onClick={handleSubmit}
                            >
                                {currentUser?.activated ? t(translationPath.signUpButton) : t(translationPath.activateButton)}
                            </button>
                            {passwordResetSuccess && (
                                <div className="alert alert-success" style={{ width: "100%" }}>
                                    {t(translationPath.successMessage)}
                                    <br />
                                    <Link to='/sign-in' className='btn-login'>
                                        {t(translationPath.signInLink)}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ResetPassword