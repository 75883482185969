import React from 'react'
import RenderTemplateSingleSegmentedText from './RenderTemplateSingleSegmentedText';

function RenderListSegmentedText({
  list,
  segmentation,
  segmentsOverrides,
  changedParamBoundaries,
  isSelected
}) {
  const listType = list?.type ?? "bulleted-list";
  return (<>
  {
      listType === "bulleted-list" ? (<>
        <ul>
          {list.children.map((listElement, idx) => (
            <li key={idx} style={{ marginBottom: "10px", textAlign: listElement?.alignment ?? "left"}}>
              {listElement.children.map((segment, idx) => (
                <RenderTemplateSingleSegmentedText
                  key={segment.id}
                  idx={idx}
                  segment={segment}
                  isSelected={isSelected}
                  segmentation={segmentation}
                  segmentsOverrides={segmentsOverrides}
                  changedParamBoundaries={changedParamBoundaries}
                />
              ))
              }
            </li>
          ))}
        </ul>
      </>) 
      : (<>
        <ol>
          {list.children.map((listElement, idx) => (
            <li style={{ marginBottom: "10px", textAlign: listElement?.alignment ?? "left" }} key={idx}>
              {listElement.children.map((segment, idx) => (
                <RenderTemplateSingleSegmentedText
                  key={segment.id}
                  idx={idx}
                  segment={segment}
                  isSelected={isSelected}
                  segmentation={segmentation}
                  segmentsOverrides={segmentsOverrides}
                  changedParamBoundaries={changedParamBoundaries}
                />
              ))
              }
            </li>
          ))}
        </ol>
      </>)
    }
    </>
  )
}

export default RenderListSegmentedText