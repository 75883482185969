import React from 'react';
import "./createProject.scss"
import { getName } from '../../helpers/Translation';
import { useTranslation } from '../../contexts/TranslationProvider';

const TemplateCard = ({ template, isNotSelected, onDeSelect }) => {
    const { t, language } = useTranslation();
    return (
        <div className='content'>
            {getName(template?.level1, language) && <h3 className='project-level-name'>{getName(template?.level1, language)}</h3>}
            <h3 className='project-name mb-3'>{getName(template, language)}</h3>
            <div className="template-reqs">
                {template?.requirements?.map((req, index) => (
                    <div key={index} className={`req-content ${isNotSelected(req?.id) ? 'not-selected-req' : ''}`} onClick={() => onDeSelect(req?.id)}><span>{getName(req, language)}</span></div>
                ))}
            </div>
        </div>
    );
}

export default TemplateCard;
