import React, { useContext, useState } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'
import EditProjectContext from "../../../../contexts/EditProjectContext";
import ValidateProjectModal from "../../../modals/ValidateProjectModal";
import ProjectAuditModal from "../../../modals/ProjectAuditModal";

function EditionProjectAuditButton() {
  const { project, setProject } = useContext(EditProjectContext)
  const [showAuditProjectModal, setShowAuditProjectModal] = useState(false);
  const { t } = useTranslation();


  return (
    <>
      <button className="navbar-button" onClick={() => setShowAuditProjectModal(true)}>
        {t("navbar.auditProject")}
      </button>
      {showAuditProjectModal && <ProjectAuditModal project={project} onClose={() => setShowAuditProjectModal(false)} />}
    </>
  )
}

export default EditionProjectAuditButton;
