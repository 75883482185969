import { createContext } from "react";
import { GroupEntity, ProjectTemplateEntity, ProjectTemplateRequirementEntity } from "../domain/entities";



export interface EditProjectTemplateContextType {
	projectTemplate: ProjectTemplateEntity;
	setProjectTemplate: React.Dispatch<React.SetStateAction<ProjectTemplateEntity>>;
	groups: GroupEntity[];
	onSelectGroupInRequirement: (group: GroupEntity[]) => void;
	onTemplateRename: (name) => void
	projectTemplateRequirements: ProjectTemplateRequirementEntity[],
	setProjectTemplateRequirements: React.Dispatch<React.SetStateAction<ProjectTemplateRequirementEntity[]>>;

}

const EditProjectTemplateContext = createContext<EditProjectTemplateContextType>({

} as any);

export default EditProjectTemplateContext;
