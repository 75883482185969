import React, { useState } from "react";
import Loading from "../../common/Loading";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeleteMessage.scss";
import { toast } from "react-toastify";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { TypeLevel2Client } from "../../../services/api/TypeLevel2Client";
import { TypeLevel3Client } from "../../../services/api/TypeLevel3Client";

interface ConfirmDeleteTypeProps {
  onClose: (value: boolean) => void;
  msgDeleteType?: string;
  setNewType?: (value: boolean) => void;
  type?: any[]; 
}

function ConfirmDeleteType({
  msgDeleteType = "",
  onClose,
  setNewType,
  type,
}: ConfirmDeleteTypeProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const types1 = new TypeLevel1Client(apiClient);
  const types2 = new TypeLevel2Client(apiClient);
  const types3 = new TypeLevel3Client(apiClient);
  const translationPath = t("pages.typeLevels.deletePopup");
  const deleteTypeLevel3 = async (level1Id, level2Id, level3Id) => {
    try {
      const { deleted } = await types3.delete(level3Id);
      if (deleted) {
        const level2 = type.find((t) => t.id === level2Id);
        const level1 = type.find((t) => t.id === level1Id);
        if (level2 && level2.levels3.length === 1) {
          await types2.delete(level2Id);
          if (level1 && level1.levels2.length === 1) {
            await types1.delete(level1Id);
          }
        }
        return true;
      }
      return deleted;
    } catch (error) {
      console.error("Error deleting TypeLevel3:", error);
      return false;
    }
  };
  const deleteTypeLevel2 = async (level1Id, level2Id) => {
    try {
      const { deleted } = await types2.delete(level2Id);
      if (deleted) {
        const level1 = type.find((t) => t.id === level1Id);
        if (level1 && level1.levels2.length === 1) {
          await types1.delete(level1Id);
          return true;
        }
      }
      console.log('deleted :>> ', deleted);
      return deleted;
    } catch (error) {
      console.error("Error deleting TypeLevel2:", error);
      return false;
    }
  };

  // Main function to delete the selected type(s)
  const deleteType = async () => {
    if (!type || type.length === 0) return;

    setLoading(true);

    try {
      let deleted = false;

      if (type.length === 3) {
        deleted = await deleteTypeLevel3(type[0].id, type[1].id, type[2].id);
      } else if (type.length === 2) {
        deleted = await deleteTypeLevel2(type[0].id, type[1].id);
      } else if (type.length === 1) {
        const { deleted: isDeleted } = await types1.delete(type[0].id);
        deleted = isDeleted;
      }

      if (deleted) {
        setNewType(true);
        toast.success(
          t(translationPath.toasts.success)
        );
      } else {
        toast.error(
          t(translationPath.toasts.error)
        );
      }
    } catch (err) {
      console.error("Error deleting type:", err);
      toast.error(
        t(translationPath.toasts.error)
      );
    } finally {
      setLoading(false);
      onClose(false);
    }
  };

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">
                    {t(translationPath.title)}
                      {` ${type[0]?.name || ""}${
                        type[1] ? " / " + type[1].name : ""
                      }${type[2] ? " / " + type[2].name : ""}`}
                    </h1>

                    <div className="modal-buttons">
                      <button
                        className="btn submit-delete"
                        onClick={deleteType}
                      >
                        {t("modals.confirmDelete.delete")}
                      </button>

                      <button
                        onClick={() => onClose(false)}
                        className="btn btn-secondary cancel-delete"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "gray",
                        }}
                      >
                        {t("modals.confirmDelete.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteType;
