import React, { createContext } from "react";
import { ProjectEntity, ProjectTemplateEntity, BeneficialEntity } from "../domain/entities";
import { FormParam } from "../domain/types/FormParams";
import { EnumOption, ListOption, TableLigne } from "../domain/types/ClauseParams";
import Project from "../pages/Project";


export interface CreateProjectContextType {
  project: ProjectEntity;
  setProject: React.Dispatch<React.SetStateAction<ProjectEntity>>;
  projectTemplate: ProjectTemplateEntity;
  setProjectTemplate: React.Dispatch<React.SetStateAction<ProjectTemplateEntity>>;
  existingProjects: ProjectEntity[];
  existingProjectTemplates: ProjectTemplateEntity[];
  selectedBeneficials: BeneficialEntity[];
  setSelectedBeneficials: React.Dispatch<React.SetStateAction<BeneficialEntity[]>>;
  projectName: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
  projectDescription: string;
  setProjectDescription: React.Dispatch<React.SetStateAction<string>>;
  notSelectedReq: any[];
  setSelectedReq: React.Dispatch<React.SetStateAction<any[]>>;
  nameError: string[];
  setNameError: React.Dispatch<React.SetStateAction<string[]>>;
  typeError: boolean;
  setTypeError: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateProjectContext = createContext<CreateProjectContextType>({

} as any);

export default CreateProjectContext;