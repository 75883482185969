import React, { useState } from "react";
import "./commonmodal.scss";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import FileInput from "../../../components/common/FileInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { numberToEnumList } from "../../../domain/Project";
import { ProjectEntity, ProjectTemplateRequirementEntity } from "../../../domain/entities";
import ShowCase from "../../../components/ShowCase";
import { DocumentClient } from "../../../services/api/DocumentClient";
import { DocumentUseCaseClient } from "../../../services/api/DocumentUseCaseClient";
import { ProjectClient } from "../../../services/api/ProjectClient";
import CheckBoxInput from "../../../components/common/CheckBoxInput";
interface ImportDocumentModalType {
	onClose: () => void;
	dataProject?: { requirement: ProjectTemplateRequirementEntity, project: ProjectEntity };
	typeReq?: number,
	type?: string,
	usecaseId?: number
}
function ImportDocumentModal({ onClose, dataProject, typeReq, type, usecaseId }: ImportDocumentModalType) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { t, language } = useTranslation();
	const [document, setDocument] = useState<File>(null);
	const [isScanned, setIsScanned] = useState(false);
	const apiClient = useApiClientWithLoading();
	const documentClient = new DocumentClient(apiClient)
	const documentUseCaseClient = new DocumentUseCaseClient(apiClient)
	const projectClient = new ProjectClient(apiClient)

	const handleSave = async () => {
		if (document) {
			try {
				setLoading(true)
				const {
					row: { id: documentId },
				} = await documentClient.upsertMultiForm({ file: document, name: document.name, isScanned });
				if (type === "topic-usecase") {
					try {
						setLoading(true)
						const response = await documentUseCaseClient.invokeUseCase(usecaseId, documentId);
						navigate(`/ai-document-response/${response?.row?.id}`)
					} catch (error) {
						console.error('Error calling API:', error);
					}
				}
				else {
					if (dataProject?.project) {
						const updatedProject = await projectClient.update(dataProject?.project?.id, {
							values: {
								...dataProject?.project?.values,
								[dataProject?.requirement?.id]: {
									type: numberToEnumList(typeReq)[0],
									id: documentId,
								},
							},
							excludedRequirements: [...dataProject.project.excludedRequirements]
						});
						toast.success(t("pages.viewProject.popups.importDocument.messages.success"));
						navigate(`/document/${documentId}`)
						onClose()
					}
				}

			} catch (error) {
				toast.error(error?.message ?? error ?? t("pages.viewProject.popups.importDocument.messages.error"));
				setLoading(false)
				onClose()
			}
		}
	};
	return (
		<>
			<div
				className="modal-backdrop fade show"
			></div>
			<div id="contractz-lab">
				<div className="modal d-flex justify-content-center align-items-center">
					<div className="modal-dialog">
						<div className="modal-content" style={{ minHeight: "200px" }}>
							<div className="modal-header">
								<h5 className="modal-title">{type === "topic-usecase" ? t("pages.topic.popup.header") : t("pages.viewProject.popups.importDocument.header")}
								</h5>
								<button
									type="button"
									className="btn-close"
									onClick={onClose}
									aria-label="Close"
									disabled={loading}
									style={{ marginRight: language == "ar" && "80%" }}
								></button>
							</div>
							{loading ? (
								<ShowCase height="60vh" />
							) : (
								<div className="modal-body mt-3">
									<div className="form-floating">
										<FileInput
											label={t("pages.listener.popups.question.add_doc")}
											onChange={(file) => setDocument(file)}
											value={document}
											borderClassName={document ? "green-border" : "grey-border"}
										/>
									</div>
									{/* <div className="form-floating mb-3">
										<CheckBoxInput
											checked={isScanned}
											name={t("pages.listener.popups.contract.inputs.isScanned")}
											onChange={() => setIsScanned(!isScanned)}
											disabled={false}
										/>
									</div> */}
									<div className="modal-footer" style={{ marginTop: "100px" }}>
										<button type="submit" className="btn btn-primary btn-import" style={{ left: "4.5%" }} onClick={handleSave}>
											{type === "topic-usecase" ? t("pages.topic.popup.executeBtn") : t("pages.viewProject.popups.importDocument.btn")}
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ImportDocumentModal;
