import React, { useState } from 'react'
import ParamTypeCombobox from '../../../components/common/CustomCombobox/ParamTypeCombobox'
import "./addParamForm.scss"
import { useTranslation } from '../../../contexts/TranslationProvider'
import { FormParam } from '../../../domain/types/FormParams'

function AddParamForm({ handleAdd, handleCancel, params }: {
  handleAdd: (param: FormParam) => void;
  handleCancel: () => void;
  params: FormParam[];
}) {
  const [paramType, setParamType] = useState<FormParam['type']>(null)
  const [paramName, setParamName] = useState<FormParam['name']>("")
  const [paramLabel, setParamLabel] = useState<FormParam['label']>("")
  const { t } = useTranslation()
  const translationPath = t("pages.contractTemplateParam.addParamForm");
  const [errors, setErrors] = useState([])
  const handleConfirm = () => {
    if (errors.length > 0 || !paramType || !paramName || !paramLabel) {
      return
    }
    handleAdd({ type: paramType, name: paramName, label: paramLabel } as any)
    setParamType(null)
    setParamName("")
    setParamLabel("")
    handleCancel()
  }
  const handleTypeChange = (value) => {
    setErrors(prev => prev.filter(err => err.where !== "type"));
    setParamType(value);
    if (!value) {
      setErrors(prev => [...prev, { where: "type", why: translationPath.errors.typeMissing }]);
    }
  }
  const handleNameChange = (e) => {
    setErrors(prev => prev.filter(err => err.where !== "name"));
    setParamName(e.target.value);
    if (!/^[a-z_][a-z0-9_]*$/i.test(e.target.value)) {
      setErrors(prev => [...prev, { where: "name", why: translationPath.errors.nameWrong }]);
    }
    const existingParam = params.find(param => param.name === e.target.value);
    if (existingParam && existingParam.type !== paramType) {
      setErrors(prev => [...prev, { where: "name", why: translationPath.errors.nameExists }]);
    }
  }
  const handleLabelChange = (e) => {
    setErrors(prev => prev.filter(err => err.where !== "label"));
    setParamLabel(e.target.value);
    if (!e.target.value) {
      setErrors(prev => [...prev, { where: "label", why: translationPath.errors.labelMissing }]);
    }
  }
  return (
    <div
      className="dialog-container"
      style={{
        width: "100%"
      }}>
      <div className="dialog-input-container">
        <div className="dialog-input-label">
          {translationPath.type}
        </div>
        <ParamTypeCombobox
          options={['string', 'number', 'date', 'enum', 'boolean', 'list', 'file', 'beneficial', 'beneficial[]', 'table', 'property']}
          value={paramType}
          onChange={handleTypeChange}
        />
        {
          errors.map(err => {
            if (err.where === "type") {
              return <div className="error-message-dialog">{err.why}</div>
            }
          })
        }
      </div>

      <div className="dialog-input-container">
        <div className="dialog-input-label">
          {translationPath.name}
        </div>
        <input className="dialog-input" type="text" value={paramName} onChange={(e) => handleNameChange(e)} />
        {
          errors.map((err, idx) => {
            if (err.where === "name") {
              return <div key={idx} className="error-message-dialog">{err.why}</div>
            }
          })
        }
      </div>

      <div className="dialog-input-container">
        <div className="dialog-input-label">
          {translationPath.label}
        </div>
        <input className="dialog-input" type="text" value={paramLabel} onChange={(e) => handleLabelChange(e)} />
        {
          errors.map((err, idx) => {
            if (err.where === "label") {
              return <div key={idx} className="error-message-dialog">{err.why}</div>
            }
          })
        }
      </div>
      <div className="dialog-buttons">
        <button className="dialog-button" onClick={handleCancel}>
          {translationPath.cancel}
        </button>
        <button
          className={`dialog-button ${errors.length > 0 || !paramType || !paramName || !paramLabel ? "dialog-button-disabled" : ""}`}
          onClick={handleConfirm}>
          {translationPath.confirm}
        </button>
      </div>

    </div>
  )
}

export default AddParamForm