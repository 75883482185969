import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useTranslation from '../../../hooks/useTranslation';

function PieChart({ incomingData, filedValues, filed, label, chartTitle }: {
  incomingData: any;
  filedValues: { display: string; value: string }[];
  filed: string;
  label: string;
  chartTitle: string;
}) {
    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
    const position: "right" | "left" = "right";
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: chartTitle,
            },
            datalabels: {
                display: false,
            },
            legend: {
                display: true,
                position: position,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding: 15,
                    borderRadius: 5,
                    // only display the first 10 characters of each label
                    generateLabels: chart => chart.data.labels.map((label, i) => {
                        return {
                            text: label.length > 16 ? label.substring(0, 16) + '...' : label,
                            fillStyle: chart.data.datasets[0].backgroundColor[i],
                            strokeStyle: chart.data.datasets[0].backgroundColor[i],
                            index: i,
                            fontColor: '#666'
                        };
                    }
                    ),

                }
            },
        },
        layout: {

        },
    };

    const labels = filedValues;
    const data = {
        labels: labels.map((label) => label.display),
        datasets: [
            {
                label: label,
                data: filedValues.map((filedValue) => {
                    const groupData = incomingData.filter((element) => element[filed] === filedValue.value);
                    return groupData.length;
                }),
                backgroundColor: [
                    'rgb(112, 20, 229)',
                    'rgb(47, 20, 229)',
                    "#3F83F5",
                    "#FFC542",
                    'rgba(255, 20, 0, 0.2)'
                    // Add more colors as needed
                ],
                borderwidth: 0.1,
            },
        ],
    };
    return (
        <div className="chart pie-chart">
            <Pie options={options} data={data} />
        </div>
    );
}

export default PieChart;
